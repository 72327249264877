import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-log-home',
  templateUrl: './log-home.component.html',
  styleUrls: ['./log-home.component.scss']
})
export class LogHomeComponent implements OnInit {

  constructor(private auth: AuthService,
    private config:ConfigService
  ) { }

  isDistributor = false;
  user
  
  ngOnInit(): void {
    this.isDistributor = this.auth.isDistributor();
    if(this.config.json.userType=='MSO'){
      this.user=true
    }
    else{
      this.user=false
    }
  }



}
