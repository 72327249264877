import { Component, OnInit, ViewChild } from '@angular/core';
import { ColorSelectorComponent } from '../color-selector/color-selector.component';
import { ConfigService } from 'src/app/services/config.service';
@Component({
  selector: 'app-theme-home',
  templateUrl: './theme-home.component.html',
  styleUrls: ['./theme-home.component.scss']
})
export class ThemeHomeComponent implements OnInit {

  constructor(public config:ConfigService) { }
  userType


  ngOnInit(): void {
    this.userType=this.config.json.userType
  }
  @ViewChild(ColorSelectorComponent) colorSelectorComponent: ColorSelectorComponent
  view = "selectColor"

  resetFactoryDefaults(){
    switch(this.view){
      case "selectColor":this.colorSelectorComponent.resetColors();break;
    }
  }
}
