import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdManagerComponent } from './ad-manager/ad-manager.component';
import { AdManagerRoutingModule } from './ad-manager-routing.module';
import { LcoModule } from '../lco/lco.module';
import { SharedModule } from '../shared/shared.module';
import { DistributorModule } from '../distributor/distributor.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AdManagerListComponent } from './ad-manager-list/ad-manager-list.component';


@NgModule({
  declarations: [
    AdManagerComponent,
    AdManagerListComponent
  ],
  imports: [
    CommonModule,
    AdManagerRoutingModule,
    LcoModule,SharedModule,DistributorModule,NgMultiSelectDropDownModule
  ]
})
export class AdManagerModule {
  constructor() {
    // console.log("Ad manager module loaded")
  }
}
