import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateAffiliatorComponent } from './create-affiliator/create-affiliator.component';
import { ListAffiliatorComponent } from './list-affiliator/list-affiliator.component';
import { AffiliatorRoutingModule } from './affiliator-routing.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    CreateAffiliatorComponent,
    ListAffiliatorComponent
  ],
  imports: [
    CommonModule,
    AffiliatorRoutingModule,
    SharedModule,
    NgMultiSelectDropDownModule
  ]
})
export class AffiliatorModule { }
