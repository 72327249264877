import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListLcmComponent } from './list-lcm/list-lcm.component';
import { CreateLcmComponent } from './create-lcm/create-lcm.component';
import { LcoModule } from '../lco/lco.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DistributorModule } from '../distributor/distributor.module';
import { SharedModule } from '../shared/shared.module';
import { LandingChannelManagementRoutingModule } from './landingChannelManagement-routing.module';



@NgModule({
  declarations: [
    ListLcmComponent,
    CreateLcmComponent
  ],
  imports: [LandingChannelManagementRoutingModule,
    CommonModule, LcoModule,SharedModule,DistributorModule,NgMultiSelectDropDownModule
  ]
})
export class LandingChannelManagementModule { }
