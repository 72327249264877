<div class="cms__content">
  <div class="top__heading">
    <h3>New Email</h3>
    <a [routerLink]="['/communication/mail/list']"><button class="default__button float-right">Back</button></a>
  </div>
  <form action="" [formGroup]="mailForm">
    <div class="col-12 form__style">
      <div class="row">
        <div class="col-12">
          <div class="send__all">
            <div class="send__all--content">
              <input type="checkbox" formControlName="isSendAll" />
              <label>Send to All</label>
            </div>
          </div>

          <div class="input_field" style="width: 50%;margin: -15px;">
            <div class="col-12">
              <label for="">Subscriber</label>
              <input type="text" autocomplete="off" id="searchSubCode" class="custom__field"
                formControlName="subscriberId" (input)="getSubscriberId($event)" [readonly]="fromSubscriberPage">
              <p class="clearBTN" (click)="clearInput()" *ngIf="!fromSubscriberPage">Clear</p>
              <span *ngIf="(mailForm.get('subject').touched || mailForm.get('body').touched) && mailForm.get('subscriberId').invalid" style="color: brown;">Subscriber Not Entered</span>
            </div>
            <div class="search_param" style="margin-right: 12px;" *ngIf="openSearch" >
              <app-subscriber-search [searchText]="searchText" (userDetail)="getUser($event)" > </app-subscriber-search>
            </div>
          </div>

          <!-- <datalist id="to__addresses">
            <option value="All">
            <option value="Banagalore">
            <option value="Chandra">
            <option value="Roshan">
            <option value="Sridhar">
          </datalist> -->
        </div>

        <div class="col-12">
          <label>Subject</label>
          <input [readOnly]="isSubscriberIdSelected" type="text" autocomplete="off" class="custom__field"
            formControlName="subject" />
        </div>
        <!-- <div class="col-12 col-md-6">
          <label>CC</label>
          <input class="custom__field" list="cc__to__addresses" name="to__address" id="cc__to__address">
          <datalist id="cc__to__addresses">
            <option value="All">
            <option value="Banagalore">
            <option value="Chandra">
            <option value="Roshan">
            <option value="Sridhar">
          </datalist>
        </div> -->
        <div class="col-12">
          <label>Body</label>
          <textarea [readOnly]="isSubscriberIdSelected" class="custom__field" formControlName="body"></textarea>
        </div>
        <div class="col-12">
          <!-- <button type="button" [routerLink]="['/mail/list']" class="default__button">Cancel</button> -->
        </div>
      </div>
    </div>
  </form>
  <button class="cta float-right" [disabled]="!mailForm.valid" (click)="persist()">
    Send
  </button>
</div>