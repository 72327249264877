<div class="cms__content">
  <div class="top__heading">
    <h3>Mail</h3>
    <a [routerLink]="['/communication/mail/create']"><button class="default__button float-right" *ngIf="menuAccess">+ Add New</button></a>
  </div>
  <div class="clearfix"></div>
  <div class="col-12 list__summary">
    <div class="row">
      <div class="col-6 col-md-3">
        <div class="list__card">
          <span class="list__heading">Mails</span>
          <span class="list__item">{{mailList.length}}</span>
        </div>
      </div>
      <div class="col-6 col-md-3">
        <div class="list__card">
          <span class="list__heading">Unread Mails</span>
          <span class="list__item">{{unreadMail}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 list__table">
    <div class="row">

      <div class="custom__table__filter">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <div class="table__right">
          <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> records</p>
          <div class="search__container">
            <input type="text" autocomplete="off" class="custom__field" (keydown.enter)="onSearch()" [(ngModel)]="paginationObj.searchKeyword" />
            <button (click)="onSearch()" (keydown.enter)="onSearch()"><img src="./assets/search.png" alt="search" /></button>
          </div>
        </div>
      </div>

      <div class="scroll-box">
        <table class="custom__iptv__table">
          <thead>
            <tr>
              <th (click)="sortByItem(sortByProperties.subId)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.subId}">
                Subscriber Code
              </th>
              <th (click)="sortByItem(sortByProperties.subject)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.subject}">
                Subject
              </th>
              <th (click)="sortByItem(sortByProperties.body)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.body}">
                Body
              </th>
              <th (click)="sortByItem(sortByProperties.readFlag)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.readFlag}">
                Read Flag
              </th>
              <th (click)="sortByItem(sortByProperties.udate)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.udate}">
                Last Updated
              </th>
              <th (click)="sortByItem(sortByProperties.cdate)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.cdate}">
                Sent at
              </th>
            </tr>
          </thead>
          <tbody>

            <tr *ngIf="!loadingFlag && errorFlag;">
                <td colspan="6" style="text-align: center;">No Data</td>
            </tr>

            <tr *ngIf="loadingFlag && !errorFlag; else showList">
              <td class="loading" colspan="6">
                <app-custom-spinner></app-custom-spinner>
              </td>
            </tr>

            <ng-template #showList>
              <tr *ngFor="let mail of mailList; let i = index;">
                <td>{{mail.subscriberCode}}</td>
                <td>{{mail.subject}}</td>
                <td>{{mail.body}}</td>
                <td>
                  <span [ngClass]="{'safe__label' : mail.readFlag, 'danger__label' : !mail.readFlag}">
                    {{mail.readFlag?'&#10003;':'X'}}
                  </span>
                </td>
                <td>{{mail.updatedDate | date: 'dd-MM-yyyy HH:mm:ss'}}</td>
                <td>
                  {{mail.createdDate | date: 'dd-MM-yyyy HH:mm:ss'}}
                  <div class="action__items">
                    <button (click)="edit(mail)" *ngIf="menuAccess">Edit</button>
                    <button class="danger" (click)="deleteMail(mail, i)" *ngIf="menuAccess">Delete</button>
                  </div>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>

      </div>

      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>
