import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { ReportTraiDataService } from 'src/app/report-trai/plugins/report-trai-data.service';

@Component({
  selector: 'app-report-channels-detailed-count',
  templateUrl: './report-channels-detailed-count.component.html',
  styleUrls: ['./report-channels-detailed-count.component.scss']
})
export class ReportChannelsDetailedCountComponent implements OnInit {

  
  reportList = [];
  from: any = '';
  to: any = '';
  currentDate = new Date();
  dateString;
  eve;
  broadcasterList
  selectedBroadcasterId="0"
  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) {}
  ngOnInit(): void {
    this.fetchBroadcasterList()
    this.fetchData()
  }

  
  
  fetchBroadcasterList(): void {
    this.report.getBroadcasterList().subscribe(data => {
      this.broadcasterList = data;
    });
  }


  fetchData(): void {
    let url=this.config.json.apiEndpointRdbms+'channel/detailedCount/broadcasters?broadcasterId='+this.selectedBroadcasterId
    this.http.get(url).subscribe(
      (res) => {
        this.reportList = res.data;
        if (this.reportList.length != 0) {
          this.loadingFlag = false;
        } else {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }

  loadingFlag = false;
  isLoad = 'Choose Date';

  statUS = ['Inactive', 'Active', 'Blacklisted', 'New', 'Terminated'];

  downloadTableExcel(): void {
    if (
      this.reportList == null ||
      this.reportList == undefined ||
      this.reportList?.length == 0
    ) {
      alert('No Data!');
      return;
    }
    let status = [...this.statUS, 'ALL'],filterName
    let name = 'Subscriber Report';
    if(this.from!='' && this.to !='undefined'){
      filterName = this.from +"-"+this.to;
      this.doc.exportTableToExcelwithImage(this.reportTable,name,'Channels_Detailed_report',
        undefined,undefined,'Date',filterName);
    }
    else{
      this.doc.exportTableToExcelwithImage(this.reportTable,name,'Channels_Detailed_report');
    }
   
  }

  downloadTablePdf(): void {
    // let status = [...this.statUS, 'ALL'],
    //   filterName = status[this.status];
    if (
      this.reportList == null ||
      this.reportList == undefined ||
      this.reportList?.length == 0
    ) {
      alert('No Data!');
      return;
    }
    if(this.from!='' && this.to !=''){
    this.pdf.generatePdfHtml(
      '#reportTable',
      'Channels_Detailed_report',
      `Source type : SMS, from : ${this.from}, to : ${this.to}`,
      null,
      null,
      'a3'
    );
  }
  else{
    this.pdf.generatePdfHtml(
      '#reportTable',
      'Channels_Detailed_report',
      `Source type : SMS`,
      null,
      null,
      'a3'
    );
  }
  }
  downloadTableCSV(): void {
    if (
      this.reportList == null ||
      this.reportList == undefined ||
      this.reportList?.length == 0
    ) {
      alert('No Data!');
      return;
    }
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: [
        'Sl No',
        'Subscriber ID',
        'Subscriber Code',
        'Subscriber Name',
        'Subscriber Address',
        'Partner Code',
        'Partner Name',
        'Packages',
        'Mac Address', 'Stb ID' ,'Status','Date'
      ],
    };
    let data = [];
    let i = 1;
    data=[]
    
      let csvLine = {
        slNo: 'SD',
        subsciberId: this.reportList['SDFTAChannelCount'],
        a:this.reportList['SDPaidChannelCount'],
       b:this.reportList['totalSDChannelCount']
      };
      data.push(csvLine);
      csvLine = {
        slNo: 'HD',
        subsciberId: this.reportList['HDFTAChannelCount'],
        a:this.reportList['HDPaidChannelCount'],
       b:this.reportList['totalHDChannelCount']
      };

    new ngxCsv(data, 'Channels_Detailed_report', options);
  }

  status;

  searchWord;
  // searchKeyword() {
  //   this.loadingFlag = true;
  //   let value = (document.getElementById('searchKeyword') as HTMLInputElement)
  //     .value;
  //   this.searchWord = value;
  //   let url =
  //     this.config.json.apiEndpointRdbms +
  //     `subscriber/getsubscribersListReport/?status=${
  //       this.status ? this.status : 5
  //     }&searchKeyword=` +
  //     value;
  //   this.http.get(url).subscribe(
  //     (data) => {
  //       this.reportList = data.data;
  //       if (this.reportList.length != 0) {
  //         this.loadingFlag = false;
  //       } else {
  //         this.loadingFlag = false;
  //         this.isLoad = 'No Records';
  //       }
  //     },
  //     () => {
  //       this.loadingFlag = false;
  //       this.isLoad = 'No Records';
  //     }
  //   );
  // }
}

