import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PathNames } from 'src/app/constants/path-names';
import { MailInfo } from 'src/app/models/mail/mail-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-mail',
  templateUrl: './create-mail.component.html',
  styleUrls: ['./create-mail.component.scss']
})
export class CreateMailComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private config: ConfigService
  ) { }

  mail = {} as MailInfo;

  mailForm: FormGroup;

  isEdit = false;

  ngOnInit(): void {
    this.getPathParam();
    this.initForm();
    this.listenFormChanges();
    this.getQueryParam();
  }

  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== PathNames.create) {
      this.isEdit = true;
      this.fetchRecord(id);
    }
  }
  fromSubscriberPage = false

  getQueryParam(): void {
    if (this.route.snapshot.queryParamMap.has('subscriberId')) {
      const id = this.route.snapshot.queryParamMap.get('subscriberId');
      this.mailForm.get('subscriberId').setValue([Number(id)]);
      console.log(this.isSubscriberIdSelected)
      this.isSubscriberIdSelected = false
      this.fromSubscriberPage = true
    } else if (this.route.snapshot.queryParamMap.has('all')) {
      // converting string to boolean
      this.isSendAll.setValue(true);
    }
  }

  fetchRecord(id: string): void {
    const url = this.config.json.apiEndpointRdbms + 'mail/getMail/' + id;
    this.http.get(url).subscribe(data => {
      this.mail = data.data;
      this.updateForm();
    });
  }

  initForm(): void {
    this.mailForm = new FormGroup({
      subject: new FormControl('', [Validators.required]),
      body: new FormControl('', [Validators.required]),
      subscriberId: new FormControl('', [Validators.required]),
      isSendAll: new FormControl(false)
    });
  }

  get to(): FormControl {
    return this.mailForm.get('subscriberId') as FormControl;
  }

  get isSendAll(): FormControl {
    return this.mailForm.get('isSendAll') as FormControl;
  }

  searchText!: string
  openSearch = false
  getSubscriberId(e: Event) {
    this.openSearch = false
    this.searchText = (e.target as HTMLInputElement).value
    if (this.searchText.length >= 3) {
      setTimeout(() => {
        this.openSearch = true
      }, 500);
    }
  }

  isSubscriberIdSelected = true
  getUser(e) {
    this.mailForm.get('subscriberId').setValue([e.subscriberId])
    let inputBox = document.getElementById('searchSubCode') as HTMLInputElement
    inputBox.value = `${e.subscriberCode}, ${e.subscriberName}`
    this.openSearch = false
    this.searchText = undefined
    this.isSubscriberIdSelected = false
  }

  clearInput() {
    this.openSearch = false
    this.mailForm.reset()
    let inputBox = document.querySelector('#subscriberCode') as HTMLInputElement
    inputBox.value = ""
    this.isSubscriberIdSelected = true
  }

  updateForm(): void {
    console.log(this.mail);

    this.mailForm.get('subject').setValue(this.mail.subject);
    this.mailForm.get('body').setValue(this.mail.body);
    if (this.mail.subscriberId === 0) {
      this.isSendAll.setValue(true);
    } else {
      this.mailForm.get('subscriberId').setValue(this.mail.subscriberId);
    }
  }


  persist(): void {
    this.updateModel();
    console.log(this.isSendAll.value);
    if (this.isSendAll.value) {
      this.mail.subscriberId = 'ALL' as any
    }

    this.isEdit ? this.update() : this.save();
  }

  save(): void {
    const url = this.getSaveUrl();
    this.http.post(url, this.mail).subscribe(data => {
      if (data.statusCode == 200) {
        this.router.navigate(['/communication/mail', 'list']);
      }
      else {
        window.alert(data.message)
      }
    });
  }

  getSaveUrl(): string {
    const url = this.config.json.apiEndpointRdbms + 'mail/send';
    return url;
  }


  update(): void {
    const url = this.config.json.apiEndpointRdbms + 'mail/updateMail';

    this.http.put(url, this.mail).subscribe(data => {
      if (data.statusCode == 200) {
        this.router.navigate(['/communication/mail', 'list']);
      }
      else {
        window.alert(data.message)
      }
    });
  }

  updateModel(): void {
    this.mail.subject = this.mailForm.get('subject').value;
    this.mail.body = this.mailForm.get('body').value;
    const isAll = this.isSendAll.value;
    if (isAll) {
      this.mail.subscriberId = 0;
    } else {
      this.mail.subscriberId = this.mailForm.get('subscriberId').value;
    }
  }

  listenFormChanges(): void {
    this.isSendAll.valueChanges.subscribe((value: boolean) => {
      console.log(value)
      if (value) {
        this.to.disable();
        this.isSubscriberIdSelected = false
      } else {
        this.to.enable();
      }
    });
  }

}
