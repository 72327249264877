import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateAffiliatorComponent } from './create-affiliator/create-affiliator.component';
import { ListAffiliatorComponent } from './list-affiliator/list-affiliator.component';


   
    const routes: Routes = [
        { path: '', redirectTo: 'list' },
        { path: 'list', component: ListAffiliatorComponent },
        { path: ':id', component: CreateAffiliatorComponent },
        { path: 'create', component: CreateAffiliatorComponent }
      ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AffiliatorRoutingModule { }