<div class="cms__content">
  <div class="top__heading">
    <h3>
      {{isEdit ? "Edit" : "Create"}} Distributor
      <span class="current-item"></span>
    </h3>
    <a (click)="clickonBack()">
      <button class="default__button float-right">Back</button></a>
  </div>
  <div class="wizard__navigation  hide__mobile">
    <ul>
      <li [ngClass]="{'completed-step':completeStatusForWizard >=1 }">Basic Details</li>
      <li [ngClass]="{'completed-step':completeStatusForWizard >=2 }">Add Packages</li>
      <!-- <li [ngClass]="{'completed-step':completeStatusForWizard >=3 }">Add ALacartee</li> -->
      <li [ngClass]="{'completed-step':completeStatusForWizard >=4 }">Set Package Prices</li>
      <li [ngClass]="{'completed-step':completeStatusForWizard >=5 }">Review</li>
    </ul>
  </div>
  <div [ngSwitch]="switchPage">
    <ng-template ngSwitchCase="create">
      <app-create-distributor (distributorDetails)="basicDetailsComplete($event)" [distributor]="basicDetails" ></app-create-distributor>
    </ng-template>
    <ng-template ngSwitchCase="addPackages">
      <app-add-packages (selectedPackageList)="addPackagesComplete($event)"
        [selectedPackage]="selectedPackagesFromAddPackages"
        [selectedPackagesForUpdate]="selectedPackagesFromUpdate" (goBack)="goBackTo('create')" ></app-add-packages>
    </ng-template>
    <!-- <ng-template ngSwitchCase="addAlacarteePackages">
      <app-add-alacartee-packages (selectedPackageListAlacartee)="addPackagesCompleteAlacartee($event)"
        [selectedPackageAlacartee]="selectedPackagesFromAddPackages"
        [selectedPackagesForUpdate]="selectedPackagesFromUpdate" (goBack)="goBackTo('addPackages')" ></app-add-alacartee-packages>
    </ng-template> -->
    <ng-template ngSwitchCase="setPackagePrice">
      <app-set-packages-prices [selectedPackages]="selectedPackagesFromAddPackages"
        (packagePrices)="setPackagePrices($event)" (goBack)="goBackTo('addPackages')" ></app-set-packages-prices>
    </ng-template>
    <ng-template ngSwitchCase="review">
      <h5>Basic Details</h5>
      <div *ngIf="reviewData.basicDetails as basicDetails">
        <table class="custom__iptv__table overflow">
          <tbody>
            <tr>
              <td>Name</td>
              <td>{{basicDetails.name}}</td>
            </tr>
            <tr>
              <td>MobileNumber</td>
              <td>{{basicDetails.mobileNumber}}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{{basicDetails.email}}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{{basicDetails.status}}</td>
            </tr>
            <tr>
              <td>Location ID</td>
              <td>{{basicDetails.locationId}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h5>Selected Packages</h5>
      <div *ngIf="reviewData.selectedPackages as packagesList">
        <table class="custom__iptv__table overflow">
          <thead>
            <tr>
              <th>Name</th>
              <th>Price</th>
              <th>Set Price</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let package of packagesList">
              <tr>
                <td>{{package.name}}</td>
                <td>{{package.price}}</td>
                <td>{{package.priceUpdate[package.id].distributorShare}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="button__group">
        <button (click)="goBackTo('setPackagePrice')" >Back</button>
        <button class="cta" (click)="submit()">Submit</button>
      </div>
    </ng-template>
  </div>
</div>