<div class="col-12 search">
    <div class="col-md-6 search">
      <!-- <label for="">Select All</label> -->
      <!-- <input type="checkbox" name="" id="isSelectAll" (change)="changeIsSelectAll()" > -->
      <button style="margin-bottom: 5px" *ngIf="!isSelectAll" (click)="selectAll()"> Select All</button>
    <button *ngIf="isSelectAll" (click)="unselectAll()">Unselect All </button>
    </div>
    <div class="col-md-6 search">
      <input type="text" class="custom__field" id="searchbox" (keyup.enter)="search()" 
      placeholder="Search Package" />
      <button *ngIf="!isSearch" (click)="search()" >Search</button> 
      <button *ngIf="isSearch" (click)="cancel()" >Cancel</button> 
    </div>
  </div>
  <div class="table-container overflow">
    <table class="custom__iptv__table overflow">
      <thead>
        <tr>
          <th>Select</th>
          <th>Id</th>
          <th>Name</th>
          <th>Pack Type</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let package of packagesList">
          <tr>
            <td>
              <input
                type="checkbox"
                [checked]="package.checked || isSelectAll"
                (click)="selectPackage(package, $event)"
                name=""
                id=""
              />
            </td>
            <td>{{ package.id }}</td>
            <td>{{ package.name }}</td>
            <td>{{ package.packType }}</td>
            <td>{{ package.price |currency:'INR'}}</td>
            <td>{{ package.status }}</td>
            <td>{{ package.createdDate }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="button__group">
    <button (click)="back()">Back</button>
    <button class="cta" (click)="selectPackagesToSetPrice()">Next</button>
  </div>
  