import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { environment } from 'src/environments/environment';
import { PackageInfo } from 'src/app/models/package/package-info';

@Component({
  selector: 'app-report-producr-subscription-status',
  templateUrl: './report-producr-subscription-status.component.html',
  styleUrls: ['./report-producr-subscription-status.component.scss','../report-filters.scss']
})
export class ReportProducrSubscriptionStatusComponent implements OnInit {

paginationObj = new PaginationObj();

loadingFlag = false;


disableFirstBtn = false;
disableLastBtn = false;
disableNextBtn = false;
disablePreviousBtn = false;
reportList = [];
selectedLco = 0;
from: any = '0000-00-00';
to: any = '0000-00-00';

bouquetList: PackageInfo[] = [];

currentDate = new Date();
lcoValue:any = 0
statusValue:any =2
@ViewChild('reportTable', { static: false }) reportTable: ElementRef;

constructor(
  private report: ReportTraiDataService,
  private calendar: ReportCalendarService,
  private doc: DocumentGeneratorService,
  private pdf: PdfGeneratorService,
  private http: HttpClientWrapperService,
  private config: ConfigService
) { }

ngOnInit(): void {
  this.fetchBouquetList();
  this.fetchData()
}

fetchBouquetList(): void {
  this.report.getBouquetList().subscribe((data) => {
    this.bouquetList = data.data;
  });
}

isLoad = 'Choose Date';

handlePaginationBtnDisableStatuses(): void {

  // Left section
  this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
  this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

  // Right section
  this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
}

pageSizeChange(): void {
  this.paginationObj.pageNumber = environment.defaultPageNumber;
  this.fetchData();
}

pagePreviousBtnClick(): void {
  if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
    this.paginationObj.pageNumber--;
  }
  this.handlePaginationBtnDisableStatuses();
  this.fetchData();
}

pageNextBtnClick(): void {
  if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
    this.paginationObj.pageNumber++;
  }
  this.handlePaginationBtnDisableStatuses();
  this.fetchData();
}

pageFirstBtnClick(): void {
  this.paginationObj.pageNumber = environment.defaultPageNumber;

  this.handlePaginationBtnDisableStatuses();
  this.fetchData();
}

pageLastBtnClick(): void {
  this.paginationObj.pageNumber = this.paginationObj.totalPages;

  this.handlePaginationBtnDisableStatuses();
  this.fetchData();
}
handleFetchPage(data): void {
  console.log(data.data)
  this.reportList = data.data;

  if (this.reportList.length != 0) {
    this.loadingFlag = false;
  } else {
    this.loadingFlag = false;
    this.isLoad = 'No Records';
  }
  this.handlePaginationMetadata(data.metadata);
  this.handlePaginationBtnDisableStatuses();
}

pageNumber
handlePaginationMetadata(metadata: PageMetadata): void {
  this.paginationObj.totalPages = metadata.totalPageCount;
  this.pageNumber = metadata.pageNumber
  this.paginationObj.totalElements = metadata.totalElementCount;
}

fetchData(): void { 
  this.loadingFlag = true
  this.reportList = []
  if(this.lcoValue==0){
    this.selectedLco =0
  }
  else{
    this.selectedLco = this.lcoValue.id
  }
  PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
  let url = PaginationUtilities.urlBuilder('reports/productSubscriptionStatusReport', this.paginationObj);
  url = url + "&bouquetId=" + this.selectedLco +"&status="+this.statusValue
  if(this.from!="0000-00-00"&& this.to!="0000-00-00"){
    url = url+ "&fromDate="+this.from+"&toDate="+this.to;
  }
  this.http.get(url).subscribe(
      (data) => {
        console.log(data)
        this.handleFetchPage(data);
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
}

lcoSelect(): void {
  this.from = this.calendar.getDate()['startDate']
  this.to = this.calendar.getDate()['endDate']
  this.fetchData();
}

dateString;
eve;
dateChange(ev: NgxDateRangeInfo): void {
  if (ev.startDate !== null && ev.endDate !== null) {
    [this.from, this.to] = [ev.startDate, ev.endDate];
    // // console.log(ev.startDate.date(), ev.startDate.month(), ev.startDate.year())
    // // console.log(ev.endDate.date(), ev.endDate.month(), ev.endDate.year())
    this.eve = ev;
    this.dateString =
      ev.startDate.date() +
      '-' +
      this.doc.monthNameCalculator(ev.startDate.month()) +
      '-' +
      ev.startDate.year() +
      ' to ' +
      ev.endDate.date() +
      '-' +
      this.doc.monthNameCalculator(ev.endDate.month()) +
      '-' +
      ev.endDate.year();
    this.from = ev.startDate.format('YYYY-MM-DD');
    this.to = ev.endDate.format('YYYY-MM-DD');
    console.log(this.from)
    this.fetchData();
    this.loadingFlag = true;
    this.isLoad = 'Loading';
    this.calendar.setDate(ev);
  }
}

downloadTableExcel(): void {
  //// console.log("hello")
  let headers = [];
  let name = 'Stb active list Report';
  let filters 
  if (this.from=="0000-00-00"&& this.to=="0000-00-00"){
    filters= `Till now    Source Type : SMS ,  Bouquet : ${this.lcoValue} , Status : ${this.statusValue}`
  }
  else{
    filters=   `Till now    Source Type : SMS , Status : ${this.statusValue} , Bouquet : ${this.lcoValue} , From : ${this.from} , To : ${this.to}` 
  }
  this.doc.exportTableToExcelwithImage(
    this.reportTable,
    name,
    'product_subscription_status_report',
    undefined,
    undefined,
    'Filters : ',
    filters
  );
}

downloadTablePdf(): void {
  if (
    this.reportList == null ||
    this.reportList == undefined ||
    this.reportList?.length == 0
  ) {
    alert('No Data!');
    return;
  }
  if (this.from=="0000-00-00"&& this.to=="0000-00-00") {
    this.pdf.generatePdfHtml('#print','product_subscription_status_report',
      `Till now    Source Type : SMS ,  Bouquet : ${this.lcoValue} , Status : ${this.statusValue}`
    )
  }else{
    this.pdf.generatePdfHtml('#print','product_subscription_status_report',
      `Till now    Source Type : SMS , Status : ${this.statusValue} , Bouquet : ${this.lcoValue} , From : ${this.from} , To : ${this.to}` 
    )
  }
}

formatDate(ele) {
  if (ele == undefined) {
    return false;
  }
  var d = new Date(ele),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

downloadTableCSV(): void {
  var options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    // title: false,
    useBom: true,
    // noDownload: true,
    headers: [
      'Sl No',
      'Subscriber ID',
      // 'STB Id',
      'Subscriber Name',
      'Product Name',
      'Product ID',
      'Subscriber status',
      'MAC Address',
      'Start Date',
      'End Date',
      'Bouquet Type',
   
    ],
  };
  let data = [];
  let i = 1;
  this.reportList.forEach((line) => {
    let csvLine = {
      slNo: i,
      subscriberId:line.subscriberId,
      subscriberName:line.subscriberName,
      bouquetName: line.bouquetName,
      productid:line.bouquetId,
      subscriptionStatus:line.subscriptionStatus,
      mac: line.ethernetMacAddress,
      startDate:line.startDate,
      endDate:line.endDate,
      type: line.bouquetType,      
    };
    data.push(csvLine);
    i += 1;
  });

  new ngxCsv(data, 'product_subscription_status_report', options);
}

searchKeyword() {
  let value = (document.getElementById('searchKeyword') as HTMLInputElement)
    .value;
  let url =
    this.config.json.apiEndpointRdbms + 'reports/getStbActiveListReport';
  let body = {
    fromDate: this.eve.startDate.format('YYYY-MM-DD'),
    toDate: this.eve.endDate.format('YYYY-MM-DD'),
    lcoId: this.selectedLco,
    searchKeyword: value,
  };
  this.http.post(url, body).subscribe(
    (data) => {
      this.reportList = data.data;
      if (this.reportList.length != 0) {
        this.loadingFlag = false;
      } else {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    },
    () => {
      this.loadingFlag = false;
      this.isLoad = 'No Records';
    }
  );
}

onSearch(){
  this.paginationObj.pageNumber = 1;
  this.fetchData();
}



}