<div class="cms__content">
    <app-reports-header></app-reports-header>
  
    <div class="top__heading">
      <h3>Wallet Recharge History</h3>
      <a [routerLink]="['/logs']"
        ><button class="default__button float-right">Back</button></a
      >
    </div>
    <div class="clearfix"></div>
  
    <div class="row">
      <div class="filter__group col-12 col-md-6"></div>
      <div class="download__report col-md-6">
        <button
          class="default__button"
          (click)="downloadTablePdf()"
          title="Download PDF"
        >
          PDF
          <img src="./assets/pdf.svg" alt="download" />
        </button>
        <button
          class="default__button"
          (click)="downloadTableExcel()"
          title="Download Excel"
        >
          Excel
          <img src="./assets/excel.svg" alt="download" />
        </button>
        <button
          class="default__button"
          (click)="downloadTableCSV()"
          title="Download Excel"
        >
          CSV
          <img src="./assets/csv.svg" alt="download" />
        </button>
      </div>
    </div>
  
    <div class="third_box">
      <div class="col-12 col-md-6">
        <div class="search_filter">
          <p>Select Date :</p>
          <div class="date_range">
            <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
          </div>
          <p>
            <select
              name=""
              id=""
              class="custom__field"
              (change)="selectedType($event)"
            >
              <option value="lco">LCO</option>
              <option value="distributor">Disributor</option>
              <option value="subscriber">Subscriber</option>
            </select>
            :
          </p>
          <div class="search_param">
            <input
              type="text"
              autocomplete="off"
              class="custom__field"
              id="searchUser"
              (input)="searchUser($event)"
            />
            <ng-container *ngIf="isSearch">
              <div [ngSwitch]="type">
                <div *ngSwitchCase="'lco'" class="dropdown_content">
                  <app-lco-search
                    [searchText]="searchKeyword"
                    (userDetail)="getUserDetail($event)"
                  ></app-lco-search>
                </div>
                <div *ngSwitchCase="'distributor'" class="dropdown_content">
                  <app-distributor-search
                    [searchText]="searchKeyword"
                    (userDetail)="getUserDetail($event)"
                  ></app-distributor-search>
                </div>
                <div *ngSwitchCase="'subscriber'" class="dropdown_content">
                  <app-subscriber-search
                    [searchText]="searchKeyword"
                    (userDetail)="getUserDetail($event)"
                  ></app-subscriber-search>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-12 report">
        <p>
          Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
        </p>
        <div class="table__right">
          <p class="table__summary">
            Total <span>{{ reportList.length }}</span> record{{
              reportList.length == 1 ? "" : "s"
            }}
          </p>
        </div>
      </div>
    </div>
  
    <div class="col-12 list__table">
      <div class="row scroll">
        <table class="custom__iptv__table" #reportTable id="reportTable">
          <thead>
            <tr>
              <th>Sl No.</th>
              <th>Remarks</th>
              <th><span style="text-transform:capitalize;" >{{type}}</span> Code</th>
              <th>Account type</th>
              <th>Amount</th>
              <th>Type</th>
              <th>ID</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="!loadingFlag">
              <tr>
                <td style="text-align: center !important" colspan="7">
                  <app-custom-spinner></app-custom-spinner>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="reportList.length == 0 && loadingFlag">
              <td style="text-align: center !important" colspan="7">
                {{ isLoad }}
              </td>
            </tr>
            <tr *ngFor="let report of reportList; let i = index">
              <td>{{(paginationObj.pageNumber - 1) * paginationObj.pageSize +(i + 1)}}</td>
              <td>{{ report.remarks ? report.remarks : "---" }}</td>
              <td>{{ report.codee ? report.codee : "---" }}</td>
              <td>{{ report.accType ? report.accType : "---" }}</td>
              <td>{{ report.amount ? report.amount : "---" }}</td>
              <td>{{ report.type ? report.type : "---" }}</td>
              <td>{{ report.id ? report.id : "---" }}</td>
              <td>{{ report.datee ? report.datee : "---" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  