import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MultiSelectType } from 'src/app/plugins/multiselect/multiselect.component';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { SubscriberSearchType } from '../subscriber-search/subscriber-search';
import { ActivatedRoute } from '@angular/router';
import {MultiselectComponent} from 'src/app/plugins/multiselect/multiselect.component'

@Component({
  selector: 'app-remove-subscription',
  templateUrl: './remove-subscription.component.html',
  styleUrls: ['./remove-subscription.component.scss'],
})
export class RemoveSubscriptionComponent {
  constructor(
    private fb: FormBuilder,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private auth: AuthService,
    private route:ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.initForm();
    this.getPathParam();
  }

  removeSubscriptionForm: FormGroup;
  // @ViewChild(selectedObjects) multiSelectComponent!: MultiSelectType;
  @ViewChild(MultiselectComponent) childComponent!: MultiselectComponent;

  initForm() {
    this.removeSubscriptionForm = this.fb.group({
      subscriberCode: ['', [Validators.required]],
      stbId: ['', [Validators.required]],
      bouquetId: ['', [Validators.required]],
      subscriberIdEdit:[]
    });
    this.getPackageList();
  }

  packagesOptions: MultiSelectType = {
    dataArray: [],
    keyToDisplay: '',
    idForSelected: '',
  };
  packages = new BehaviorSubject<MultiSelectType>(this.packagesOptions);

  searchText!: string;
  openSearch = false;

  getSubscriberId(e: Event) {
    this.openSearch = false;
    this.searchText = (e.target as HTMLInputElement).value;
    if(this.searchText.length >=3 ){
      setTimeout(() => {
        this.openSearch = true;
      }, 500);
    }
  }

  subscriberId;
  isSubscriberIdSelected = false;

  isEdit=false
  subid
  stbidEdit
  subscriberData
  


  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    console.log(id)
        this.subid = id;
    if ( id == null) {
      this.isEdit =false
      // this.updateFlow(id);
    } else {
      this.isEdit = true
      this.stbidEdit=atob(localStorage.getItem('stbId'))
      console.log(this.stbidEdit)
      this.getSubscriberDetails()
      // this.createFlow();
    }
  }

  


  getSubscriberDetails(){
    const url =    this.config.json.apiEndpointRdbms + 'subscriber/getSubscriber/' +  this.subid ;
    this.http.get(url).subscribe(
      (res)=>{
        this.subscriberData = res
        this.subscriberId = this.subid
        this.getSTBList()
        this.updateFormEdit()
        this.selectedSTB(event)
      }
    )
  }

  updateFormEdit(){
    let data=this.subscriberData.subscriberCode+','+this.subscriberData.firstName;
    this.subscriberIdEdit.setValue(data)
    this.removeSubscriptionForm.get('subscriberCode').setValue(this.subscriberData.subscriberCode)
    this.removeSubscriptionForm.get('stbId').setValue(this.stbidEdit)
    console.log(this.stbidEdit)
    // this.subscriberC
  }

  getUser(e: SubscriberSearchType) {
    let input = document.getElementById("searchSubCode") as HTMLInputElement
    this.removeSubscriptionForm
      .get('subscriberCode')
      .setValue(e.subscriberCode);
    this.subscriberId = e.subscriberId;
    this.openSearch = false;
    this.getSTBList();
    this.searchText = undefined;
    input.value = `${e.subscriberCode}, ${e.subscriberName}`
  }

  isDropDown = true;
  clearInput() {
    this.removeSubscriptionForm.reset();
    this.packagesOptions = {
      ...this.packagesOptions,
      selectedArray: [],
      dataArray: [],
      isSelectAll: false,
    };
    this.packages.next(this.packagesOptions);
    this.stbList = [];
    this.selectedPackages = [];
  }

  stbList;

  get subscriberIdEdit():FormControl{
    return this.removeSubscriptionForm.get('subscriberIdEdit') as FormControl;
  }

  getSTBList() {
    const url =
      this.config.json.apiEndpointRdbms +
      'stb/getSTBBySubscriberId/' +
      this.subscriberId;
    this.http.get(url).subscribe({
      next: (res) => {
        this.stbList = res;
      },
    });
  }

  selectedStbID;
  selectedSTB(e: Event) {
    if(this.isEdit){
      this.selectedStbID = +(this.stbidEdit)
    }
    else
    this.selectedStbID = +(e.target as HTMLSelectElement).value;

    this.packagesOptions = {
      dataArray: [],
      keyToDisplay: '',
      idForSelected: '',
      selectedArray: [],
      isSelectAll: false,
    };
    this.packages.next(this.packagesOptions);
    this.selectedPackages = [];
    this.getPackageList();
  }

  packageList=[]

 
  getPackageList() {
    const url =
      this.config.json.apiEndpointRdbms +
      'subscriber/getSubscriptionBySubscriberCodeAndStbIdActive';
    let bodyForPost = {
      subscriberCode: this.removeSubscriptionForm.get('subscriberCode').value,
      stbId: this.selectedStbID,
    };
    console.log(this.packagesOptions)
    this.http.post(url, bodyForPost).subscribe({
      next: (res) => {
        this.packageList=res.data
        this.packagesOptions = {
          ...this.packagesOptions,
          dataArray: res.data,
          keyToDisplay: 'name',
          idForSelected: 'bouquet_id',
        };
        this.packages.next(this.packagesOptions);
      },
    });
  }

  selectedPackages = [];
  showDisablePopup = false
  disableSave = false

  checkbasepack(e){
    const hasBasePack = e.some(pack => pack.pack_type == "BASE_PACK");
    if(hasBasePack==true){
      this.showDisablePopup= true
      this.disableSave = true
    }
    else{
      this.packageSelected(e)
      this.disableSave = false
    }
  }

  
  removeallPacks(){
    console.log("calling1")
    console.log(this.packagesOptions.dataArray)
    this.packagesOptions.dataArray.every(pack=>pack.checked=true)
    this.packagesOptions.selectedArray=this.packageList
    this.packagesOptions.isSelectAll=true
    this.childComponent.ngOnInit();
    // this.packagesOptions

    this.selectedPackages = [];
    console.log(this.selectedPackages)

    this.disableSave = false
    
    function getLastDate(date: Date) {
      return getDate(new Date(date.setDate(date.getDate() + 30)));
    }
    function getDate(date: Date) {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}-${String(date.getDate()).padStart(2, '0')}`;
    }
    let bouquetIds = '';
    for (let i = 0; i < this.packageList.length; i++) {
      this.selectedPackages.push({
        name: this.packageList[i]['name'],
        validFrom: getDate(new Date()),
        validTo: getLastDate(new Date()),
      });
      if (i == this.packageList.length - 1) {
        bouquetIds += `${this.packageList[i]['bouquet_id']}`;
      } else {
        bouquetIds += `${this.packageList[i]['bouquet_id']},`;
      }
    }
    this.removeSubscriptionForm.get('bouquetId').setValue(bouquetIds);
  }

  showlogvalues(){
    // this.removeSubscriptionForm.get('bouquetId').setValue('');
  }
  packageSelected(e) {
      this.selectedPackages = [];
      if (e?.length == 0 || e == undefined || e == null) {
        this.removeSubscriptionForm.get('bouquetId').setValue(null);
        return;
      }
      function getLastDate(date: Date) {
        return getDate(new Date(date.setDate(date.getDate() + 30)));
      }
      function getDate(date: Date) {
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          '0'
        )}-${String(date.getDate()).padStart(2, '0')}`;
      }
      let bouquetIds = '';
      for (let i = 0; i < e.length; i++) {
        this.selectedPackages.push({
          name: e[i]['name'],
          validFrom: getDate(new Date()),
          validTo: getLastDate(new Date()),
        });
        if (i == e.length - 1) {
          bouquetIds += `${e[i]['bouquet_id']}`;
        } else {
          bouquetIds += `${e[i]['bouquet_id']},`;
        }
      }
      this.removeSubscriptionForm.get('bouquetId').setValue(bouquetIds);
  }


  


  createBody() {
    this.removeSubscriptionForm
      .get('stbId')
      .setValue(+this.removeSubscriptionForm.get('stbId').value);
    return JSON.parse(JSON.stringify(this.removeSubscriptionForm.value));
  }

  save() {
    const url =
      this.config.json.apiEndpointRdbms + 'subscriber/removeSubscription';
    this.http.put(url, this.createBody()).subscribe({
      next: (res) => {
        if ((res.status as string).toUpperCase() === 'FAIL') {
          alert('Subscription was not removed. Error: ' + res.message);
          return;
        }
        alert('Thank you, subscription has been removed');
        this.clearInput();
      },
    });
  }
}
