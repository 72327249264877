<div class="cms__content">
    <div class="top__heading">
        <h3>Access Control List</h3>
        <a [routerLink]="['/settings/access-control/create']"><button class="default__button float-right" *ngIf="menuAccess">+ Add New</button></a>
    </div>
    <div class="clearfix"></div>
    <div class="col-12 list__summary">
        <div class="row">
            <div class="col-6 col-md-3">
                <div class="list__card">
                    <span class="list__heading">Total ACL Groups</span>
                    <span class="list__item">{{aclGroupList.length}}</span>
                </div>
            </div>
            <!-- <div class="col-6 col-md-3">
                <div class="list__card">
                    <span class="list__heading">Assigned ACL Groups</span>
                    <span class="list__item">5</span>
                </div>
            </div> -->
        </div>
    </div>
    <div class="col-12 list__table">
        <div class="row">
            <div class="custom__table__filter">
                <span>
                    Show
                    <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
                        <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
                    </select>
                    Entries
                </span>
                <div class="table__right">
                    <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> records</p>
                    <div class="search__container">
                        <input type="text" autocomplete="off" class="custom__field" [(ngModel)]="paginationObj.searchKeyword" />
                        <button (click)="fetchData()"><img src="./assets/search.png" alt="search" /></button>
                    </div>
                </div>
            </div>

            <table class="custom__iptv__table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Description</th>
                        <!-- <th>Created Date</th> -->
                        <th>Updated Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="loadingFlag; else showList">
                        <td class="loading" colspan="9">
                          <app-custom-spinner></app-custom-spinner>
                        </td>
                      </tr>
          
                    <ng-template #showList>
                      <tr *ngFor="let report of aclGroupList">
                        <td>{{report.id}}</td>
                        <td>{{report.name}}</td>
                        <td>{{report.description}}</td>
                        <!-- <td>{{report.createdDate | date: 'dd-MM-yy HH:mm:ss'}}</td> -->
                        <td>{{report.createdDate | date: 'dd-MM-yyyy HH:mm:ss'}}
                            <div class="action__items">
                                <button (click)="edit(report)" *ngIf="menuAccess">Edit</button>
                            </div>
                        </td>
                      </tr>
                    </ng-template>
                  </tbody>
                
            </table>
            <div class="custom__pagination">
                <ul>
                    <li>
                        <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
                            <img src="./assets/firstPage.png" /></button>
                    </li>
                    <li>
                        <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
                            <img src="./assets/previousPage.png" /></button>
                    </li>
                    <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
                    <li>
                        <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
                            <img src="./assets/nextPage.png" /></button>
                    </li>
                    <li>
                        <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
                            <img src="./assets/lastPage.png" /></button>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</div>