import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
@Component({
  selector: 'app-report-stb-active-list',
  templateUrl: './report-stb-active-list.component.html',
  styleUrls: ['./report-stb-active-list.component.scss', '../report-filters.scss'],
})
export class ReportStbActiveListComponent implements OnInit {
  reportList = [];
  selectedLco = 0;
  from: any;
  to: any;

  lcoList: LCOInfo[] = [];

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    this.fetchLcoList();
    this.fetchData('0000-00-00', '0000-00-00')
  }

  fetchLcoList(): void {
    this.report.getLCOList().subscribe((data) => {
      this.lcoList = data.data;
    });
  }

  loadingFlag = false;
  isLoad = 'Choose Date';

  fetchData(from: any, to: any): void {
    this.report
      .getStbActiveListReport({
        lcoId: this.selectedLco,
        searchKeyword: '',
        fromDate : from,
        toDate: to
      })
      .subscribe(
        (data) => {
          //// console.log(data)
          this.reportList = data.data;
          if (this.reportList.length != 0) {
            this.loadingFlag = false;
          } else {
            this.loadingFlag = false;
            this.isLoad = 'No Records';
          }
        },
        () => {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      );
  }

  lcoSelect(): void {
    this.fetchData(
      this.calendar.getDate()['startDate'],
      this.calendar.getDate()['endDate']
    );
  }

  dateString;
  eve;
  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      // // console.log(ev.startDate.date(), ev.startDate.month(), ev.startDate.year())
      // // console.log(ev.endDate.date(), ev.endDate.month(), ev.endDate.year())
      this.eve = ev;
      this.dateString =
        ev.startDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.startDate.month()) +
        '-' +
        ev.startDate.year() +
        ' to ' +
        ev.endDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.endDate.month()) +
        '-' +
        ev.endDate.year();
      this.fetchData(ev.startDate.format('YYYY-MM-DD'), ev.endDate.format('YYYY-MM-DD'));
      this.loadingFlag = true;
      this.isLoad = 'Loading';
      this.calendar.setDate(ev);
    }
  }

  downloadTableExcel(): void {
    //// console.log("hello")
    let headers = [];
    let name = 'Stb active list Report';
    this.doc.exportTableToExcelwithImage(
      this.reportTable,
      name,
      'stb_active_list_report',
      undefined,
      undefined,
      'Report Date : ',
      this.dateString
    );
  }

  downloadTablePdf(): void {
    if (
      this.reportList == null ||
      this.reportList == undefined ||
      this.reportList?.length == 0
    ) {
      alert('No Data!');
      return;
    }
    if (!this.formatDate(this.from)) {
      
      this.pdf.generatePDFForLongTable('STB Active List',
        `Till now    Source Type : SMS`,
          1800
        );
      return;
    }
   
    this.pdf.generatePDFForLongTable('STB Active List',
      `From Date: ${this.formatDate(this.from)}     To Date: ${this.formatDate(
        this.to
      )},   Source Type : SMS`,
        1800
      );
  }

  formatDate(ele) {
    if (ele == undefined) {
      return false;
    }
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: [
        'Sl No',
        'Subscriber Code',
        'Subscriber Name',
        'Partner Name',
        'STB IDs',
        'MAC Address',
        'Activation Date',
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slNo: i,
        subCode: line.subscriber_code,
        subName: line.subscriberName,
        partnerName: line.lco_name,
        stbids:line.stb_ids,
        mac: line.ethernet_mac_address,
        activeDate: line.activation_date,
      };
      data.push(csvLine);
      i += 1;
    });

    new ngxCsv(data, 'stb_active_list_report', options);
  }

  searchKeyword() {
    let value = (document.getElementById('searchKeyword') as HTMLInputElement)
      .value;
    let url =
      this.config.json.apiEndpointRdbms + 'reports/getStbActiveListReport';
      let body
      if(this.from!='0000-00-00'&& this.to!='0000-00-00'){
        body = {
          fromDate: this.from.format('YYYY-MM-DD'),
          toDate: this.to.endDate.format('YYYY-MM-DD'),
          lcoId: this.selectedLco,
          searchKeyword: value,
        };
      }
      else{
        body={
          lcoId: this.selectedLco,
          searchKeyword: value}
      }
   
    this.http.post(url, body).subscribe(
      (data) => {
        this.reportList = data.data;
        if (this.reportList.length != 0) {
          this.loadingFlag = false;
        } else {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }
}
