import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LcoSearchType } from 'src/app/lco/lco-search/lco-search';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-add-lco-funds',
  templateUrl: './add-lco-funds.component.html',
  styleUrls: ['./add-lco-funds.component.scss']
})
export class AddLcoFundsComponent implements OnInit {

  lcoFundsForm: FormGroup;
  lcoData
  lcoID
  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private auth: AuthService,
    private config: ConfigService
  ) { }

  user = 'lco'
  ngOnInit(): void {
    // console.log(this.router.url);
    // let url = this.router.url
    // if(url.includes('lco')){
    this.user = this.config.json.userType
    if (this.user == 'Distributor') {
      this.user = 'distributor'
    }
    else if (this.user == 'MSO') {
      this.user = 'mso'
    }
    console.log(this.user)
    // }
    // if(url.includes('distributor')){
    //   this.user = 'distributor'
    // }
    this.initForm()
  }

  getLcoCode() {
    const url = this.config.json.apiEndpointRdbms + 'lco/getLCOByCode/' + this.lcoCode.value
    this.http.get(url).subscribe(
      (res) => {
        this.lcoData = res.data;
        this.lcoID = this.lcoData.id;
      },
      (err) => {
        //// console.log(err);
      }
    )
  }

  searchText!: string
  openSearch = false
  getSubscriberId(e: Event) {
    console.log(e)
    this.openSearch = false
    this.searchText = (e.target as HTMLInputElement).value
    if (this.searchText.length >= 3) {
      setTimeout(() => {
        this.openSearch = true
      }, 500);
    }
  }
  clearInput() {
    let input = document.getElementById('lcoCode') as HTMLInputElement
    input.value = ""
    this.searchText = "",
      this.openSearch = false
    this.lcoFundsForm.reset()
  }

  lcoId
  getUser(e: LcoSearchType) {
    let inputBox = document.getElementById('lcoCode') as HTMLInputElement;
    if ((e as any) == '') {
      this.openSearch = false;
      this.searchText = undefined;
      this.lcoFundsForm.get('lcoCode').setValue(null);
      inputBox.value = '';
      return;
    }
    this.lcoFundsForm.get('lcoCode').setValue(e.lcoCode);
    inputBox.value = `${e.lcoCode}, ${e.lcoName}`;
    this.lcoId = e.lcoId
    this.openSearch = false;
    this.searchText = undefined;
    this.searchText = undefined;
  }

  initForm(): void {
    this.lcoFundsForm = new FormGroup({
      lcoCode: new FormControl('', [Validators.required]),
      modeOfPayment: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
      transactionID: new FormControl('', [Validators.required]),
      remarks: new FormControl('', [Validators.required])
    });
  }

  get lcoCode(): FormControl {
    return this.lcoFundsForm.get('lcoCode') as FormControl;
  }

  get modeOfPayment(): FormControl {
    return this.lcoFundsForm.get('modeOfPayment') as FormControl;
  }

  get amount(): FormControl {
    return this.lcoFundsForm.get('amount') as FormControl;
  }

  get transactionID(): FormControl {
    return this.lcoFundsForm.get('transactionID') as FormControl;
  }

  get remarks(): FormControl {
    return this.lcoFundsForm.get('remarks') as FormControl;
  }

  createBody() {
    let body = this.user == 'mso' ? {
      subscriberId: this.lcoID,
      subscriberCode: this.lcoCode.value,
      modeOfPayment: this.modeOfPayment.value,
      amount: this.amount.value,
      transactionId: this.transactionID.value,
      remarks: this.remarks.value
    } : {
      lcoId: this.lcoId,
      modeOfPayment: this.modeOfPayment.value,
      amount: this.amount.value,
      transactionId: this.transactionID.value,
      remarks: this.remarks.value,
      distributorId: this.auth.getLcoId()
    }
    return body
  }


  getWalletData() {
    let id = this.auth.getLcoId();
    let user;
    if (this.auth.isLCO()) {
      user = 'lco';
    }
    if (this.auth.isDistributor()) {
      user = 'distributor';
    }
    let url = `${this.config.json.apiEndpoint}wallet/${user}?${user}Id=${id}`;
    this.http.get(url).subscribe({
      next: (res) => {
        this.auth.amount = res.data.amount;
      },
    });
  }

  persist() {
    let body = this.createBody();
    let url = this.config.json.apiEndpointRdbms + `${this.user == 'mso' ? 'wallet/addToLco' : 'distributor/add/lcoWallet'}`
    console.log(url)

    this.http.post(url, body).subscribe(
      (res) => {
        if (res.statusCode != "200") {
          alert(res.message);
        } else {
          alert(res.message);
          this.getWalletData();
          this.lcoFundsForm.reset();
        }
      },
      (err) => {
        alert(err);
      }
    );
  }


}
