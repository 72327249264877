<div class="cms__content">
  <div class="top__heading">
    <h3>{{ isEdit ? "Edit" : "Create" }} Subscriber</h3>
    <a [routerLink]="['/manage/subscriber/list']"><button type="button" class="default__button float-right">
        Back
      </button></a>
  </div>
  <form action="" [formGroup]="subscriberForm" (ngSubmit)="!subscriberFormValidity() ? persist() : dontPersist()">
    <div class="col-12 form__style">
      <div class="row">
        <!-- <div class="col-12 col-md-6">
          <label>User ID</label>
          <input type="text" autocomplete="off" class="custom__field" formControlName="userId" />
        </div> -->
        <div class="col-12 col-md-6" style="display: none;">
          <label>Title</label>
          <select class="custom__field" formControlName="title">
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Miss">Miss</option>
          </select>
        </div>
        <div class="col-12 col-md-6">
          <label>First Name</label>
          <input type="text" class="custom__field" formControlName="firstName" />
        </div>
        <div class="col-12 col-md-6">
          <label class="not__required">Last Name</label>
          <input type="text" autocomplete="off" class="custom__field" formControlName="lastName" />
        </div>
        <div class="col-12 col-md-6">
          <label>Mobile</label>
          <input type="phone" class="custom__field" formControlName="mobile" maxlength="10"
            oninput="this.value = this.value.replace(/[^0-9]/g, '')" pattern="^\d{10}$"
            [ngClass]="{ error__field: !mobile.valid && mobile.touched }" />
            <!-- <input type="phone" class="custom__field" formControlName="mobile" maxlength="10"
            oninput="this.value = this.value.replace(/[^0-9]/g, '')" pattern="^\d{10}$"
            [ngClass]="{ error__field: !mobile.valid && mobile.touched }"  disabled *ngIf="isEdit"/> -->
          <div [hidden]="!mobile.touched">
            <span class="error__msg" *ngIf="mobile.hasError('required')">Please enter Mobile number</span>
            <span class="error__msg" *ngIf="mobile.hasError('pattern')">Please check format: +91......</span>
            <!-- <span class="error__msg" *ngIf="mobile.errors?.duplicate">Phone number is taken</span> -->
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label class="not__required">Landline</label>
          <input type="phone" class="custom__field" formControlName="landline" maxlength="10"
            oninput="this.value = this.value.replace(/[^0-9]/g, '')" pattern="^\d{10}$"
            [ngClass]="{ error__field: !landline.valid && landline.touched }" />
          <!-- <div [hidden]="!landline.touched">
            <span class="error__msg" *ngIf="landline.hasError('required')"
              >Landline number is required</span
            >
 <span class="error__msg" *ngIf="landline.hasError('pattern')"
              >Please check format: +91......</span
            >
             <span class="error__msg" *ngIf="landline.errors?.duplicate">Phone number is taken</span>
          </div> -->
        </div>

        <div class="col-12 col-md-6">
          <label>Email</label>
          <input type="email" class="custom__field" placeholder="format: abc@xyz" formControlName="email"
            [ngClass]="{ error__field: !email.valid && email.touched }" />
          <div [hidden]="!email.touched">
            <span class="error__msg" *ngIf="email.hasError('required')">Please enter email</span>
            <span class="error__msg" *ngIf="email.hasError('email')">Email is invalid</span>
            <!-- <span class="error__msg" *ngIf="email.errors?.duplicate">Email is taken</span> -->
          </div>
        </div>

        <div class="col-12 col-md-6" style="display: none;">
          <label>CAF</label>
          <input type="text" class="custom__field" formControlName="cafNumber" />
        </div>

        <div class="col-12 col-md-6">
          <label>Gender</label>
          <select class="custom__field" formControlName="gender">
            <option [ngValue]="0">Prefer not to mention</option>
            <option [ngValue]="1">Male</option>
            <option [ngValue]="2">Female</option>
            <option [ngValue]="3">Others</option>
          </select>
        </div>

        <div *msoAccess class="col-12 col-md-6">
          <label for="">Select LCO</label>
          <select class="custom__field" formControlName="lcoId" name="" id="selectLCO" (change)="getLcoId()">
            <option value="">--SELECT--</option>
            <option [value]="item.id" *ngFor="let item of lcoList">
              {{ item.name }}
            </option>
          </select>
        </div>

        <div class="col-12 col-md-6">
          <label>Location</label>
          <select class="custom__field" formControlName="location" (change)="locationSelected($event)">
            <!-- <option *ngIf="packageList.length===0" value="">No Packages to choose</option> -->
            <!-- <option value="">No location assigned</option> -->
            <option *ngFor="let location of locationList" [value]="location.id">
              {{ location.locality }} - {{ location.city }} -
              {{ location.state }}
            </option>
            <option value="other">Other ...</option>
          </select>
          <div [hidden]="!location.touched">
            <span class="error__msg" *ngIf="landline.hasError('required')">Please provide location</span>
          </div>
        </div>
        <ng-container *ngIf="isOtherLocation">
          <div style="width: 100%;padding: 0 15px;" [formGroup]="locationForm">
           <div class="row">
            <div class="col-12 col-md-6">
              <label>State</label>
              <select class="custom__field" formControlName="stateCode">
                <option *ngFor="let st of stateList" [value]="st.name">{{st.name}}</option>
              </select>
            </div>

            <div class="col-12 col-md-6">
              <label>City</label>
              <select class="custom__field" formControlName="districtCode">
                <option *ngFor="let ct of cityList" [value]="ct.name">{{ct.name}}</option>
              </select>
            </div>
           </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <label>Locality</label>
                <input type="text" autocomplete="off" class="custom__field" formControlName="locality">
              </div>

              <div class="col-12 col-md-6">
                <label>PIN Code</label>
                <input type="number" class="custom__field" formControlName="pincode"
                  [ngClass]="{'error__field': !pin.valid && pin.touched}">
                <div [hidden]="!pin.touched">
                  <span class="error__msg" *ngIf="pin.hasError('required')">Please enter PIN Code</span>
                  <span class="error__msg" *ngIf="pin.hasError('pattern')">PIN Code is invalid</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="col-12">
          <label class="not__required">Link STB</label>
          <!-- <select class="custom__field" formControlName="stb">
            <option *ngFor="let stb of stbList" [ngValue]="stb.id">MAC: {{stb.ethernetMac}}</option>
            <option *ngIf="subscriber.stbId" [ngValue]="subscriber.stbId">{{subscriber.stbId}} </option>
            <option *ngIf="!subscriber.stbId" [ngValue]="">Do not link STB</option>
            <option *ngIf="stbList.length===0" [ngValue]="">No Available STBs</option>
          </select> -->
          <ng-multiselect-dropdown [placeholder]="'Select STBs'" formControlName="stb" [settings]="dropdownSettings"
            [data]="stbDropdownList" [(ngModel)]="stbdata" class="custom__field">
          </ng-multiselect-dropdown>
        </div>

        <div class="col-12 col-md-6">
          <label>Status</label>
          <select class="custom__field" formControlName="status">
            <option [ngValue]="1">Active</option>
            <option [ngValue]="0">Inactive</option>
          </select>
        </div>

        <div class="col-12 col-md-6">
          <label>Groups</label>
          <select class="custom__field" formControlName="groupId">
            <!-- <option *ngIf="packageList.length===0" value="">No Packages to choose</option> -->
            <option value="">No Group assigned</option>
            <option *ngFor="let gp of groupList" [value]="gp.id">
              {{ gp.name }}
            </option>
          </select>
        </div>

        <!-- <div *ngIf="!isEdit" class="col-12 col-md-6">
          <label>New Password</label>
          <input type="password" class="custom__field" formControlName="password"
            [ngClass]="{'error__field': !password.valid && password.touched}" />
          <div [hidden]="!password.touched">
            <span class="error__msg" *ngIf="password.hasError('required')">Required</span>
            <span class="error__msg" *ngIf="password.hasError('minlength')">Should be atleast 5 characters long</span>
          </div>
        </div> -->

        <!-- <div *ngIf="!isEdit" class="col-12 col-md-6">
          <label>Confirm Password</label>
          <input type="password" class="custom__field" formControlName="confirmPassword"
            [ngClass]="{'error__field': !cpassword.valid && cpassword.touched}" />
          <div [hidden]="!cpassword.touched">
            <span class="error__msg" *ngIf="subscriberForm.errors?.mismatch">Passwords do not match</span>
          </div>
        </div> -->


        <div class="col-12 col-md-6">
          <label>Billing Address</label>
          <textarea class="custom__field" formControlName="billingAddress"></textarea>
        </div>
        <div class="col-12 col-md-6">
          <label>Installation Address</label> <label for="mismoDireccion" class="not__required float-right">Same as
            Billing Address &nbsp; <input type="checkbox" (click)="copyBillingAddress($event)" name=""
              id="mismoDireccion"></label>
          <textarea class="custom__field" formControlName="installationAddress"></textarea>
        </div>




        <div class="col-12">
          <label for="">Upload Documents</label>
        </div>
        <div class="col-12 document_upload">
          <label for="photo" class="list__card not__required docs"><input type="file" name="photo" id="photo"
              (change)="uploadDocuments($event, 'customerPhotoUrl')">{{isUpdated.customerPhotoUrl}}<div class="upload_icon"></div></label>
          <label for="identity" class="list__card not__required docs"><input type="file" name="identity" id="identity"
              (change)="uploadDocuments($event, 'identityProofUrl')">{{isUpdated.identityProofUrl}} <div class="upload_icon"></div></label>
          <label for="address" class="list__card not__required docs"><input type="file" name="address" id="address"
              (change)="uploadDocuments($event, 'addressProofUrl')">{{isUpdated.addressProofUrl}} <div class="upload_icon"></div></label>
          <label for="caf" class="list__card not__required docs"><input type="file" name="caf" id="caf"
              (change)="uploadDocuments($event, 'cafFormUrl')">{{isUpdated.cafFormUrl}} <div class="upload_icon"></div></label>
        </div>

        <!-- <div *ngIf="!isEdit" class="button-pack-box">
          <div class="col-12">
            <label class="not__required">Assign Packages</label> -->
        <!-- <select class="custom__field" formControlName="packages">
              <option *ngFor="let package of packageList" [value]="package.id">{{package.name}} </option>
            </select> -->
        <!-- <ng-multiselect-dropdown [placeholder]="'select packages'" [settings]="dropdownSettings" [data]="dropdownPackageList"
              [(ngModel)]="selectedPackageList" [ngModelOptions]="{standalone: true}" [ngClass]="isEdit? 'custom__field assign-pack-edited' : 'custom__field assign-pack-unedited'"  (onSelect)="onItemSelect()"
              (onDeSelect)="onItemDeSelect($event)">
            </ng-multiselect-dropdown>
          </div> -->
        <!-- <div *ngIf="isEdit" class="col-12">
            <button type="button" (click)="renewPackages()" >Renew Packages</button>
          </div> -->
        <!-- </div> -->

        <!-- <div *ngIf="isEdit" class="button-pack-box">
          <div class="col-12">
            <label class="not__required">Assign Packages</label> -->
        <!-- <select class="custom__field" formControlName="packages">
              <option *ngFor="let package of packageList" [value]="package.id">{{package.name}} </option>
            </select> -->
        <!-- <ng-multiselect-dropdown readonly [placeholder]="'select packages'" [settings]="dropdownSettings" [data]="dropdownPackageList"
              [(ngModel)]="selectedPackageList" [ngModelOptions]="{standalone: true}" [ngClass]="isEdit? 'custom__field assign-pack-edited' : 'custom__field assign-pack-unedited'"
              (change)="onItemSelect()">
            </ng-multiselect-dropdown>
          </div>
          <div *ngIf="isEdit" class="col-12">
            <button type="button" (click)="renewPackages()" >Renew Packages</button>
          </div>
        </div> -->

        <!-- <div  class="col-12">
          <label class="not__required">Assign Channels</label>
          <ng-multiselect-dropdown [placeholder]="'select channels'" [settings]="dropdownSettings" [data]="dropdownChannelList"
            [(ngModel)]="selectedList"  [ngModelOptions]="{standalone: true}" class="custom__field">
          </ng-multiselect-dropdown>
        </div> -->

        <!-- <div class="col-12 col-md-6">
          <label class="not__required" for="birthdaytime">Subscription Validity FROM:</label>
          <input *ngIf="!isEdit"  class="custom__field" type="date" id="birthdaytime" formControlName="subFrom" >
          <input *ngIf="isEdit"  class="custom__field" type="date" id="birthdaytime" formControlName="subFrom" readonly >

        </div>

        <div class="col-12 col-md-6">
          <label class="not__required" for="birthdaytime">Subscription Validity TO:</label>
          <input *ngIf="!isEdit" class="custom__field" type="date" id="birthdaytime" formControlName="subTo" >
          <input *ngIf="isEdit" class="custom__field" type="date" id="birthdaytime" formControlName="subTo" readonly >
        </div> -->

        <!-- <div class="col-12 col-md-6">
          <label>LCO</label>
          <select class="custom__field" formControlName="lco">
            <option *ngFor="let lco of lcoList" [ngValue]='lco.id'>{{lco.name}}</option>
          </select>
        </div> -->

        <div class="col-12">
          <!-- <button type="button" class="default__button" [routerLink]="['/subscriber/list']">Cancel</button> -->
          <button class="cta float-right" [disabled]="subscriberFormValidity()" (click)="subscriberFormValidity()? persist() : null" >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
