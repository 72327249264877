import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Observable, forkJoin } from 'rxjs';
import { PathNames } from '../constants/path-names';
import { AclGroupInfo } from '../models/acl-group/acl-group-info';
import { LocationInfo } from '../models/location/location-info';
import { MSOInfo } from '../models/mso/mso-info';
import { PackageInfo } from '../models/package/package-info';
import { PaginationObj } from '../models/pagination/pagination-info';
import { ConfigService } from '../services/config.service';
import { HttpClientWrapperService } from '../services/http-client-wrapper.service';
import { StoreService } from '../services/store.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Form } from '@angular/forms';
import { UserInfo } from '../models/user/user-info';
import { NavigationStart, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-selfregistration',
  templateUrl: './selfregistration.component.html',
  styleUrls: ['./selfregistration.component.scss']
})


export class SelfregistrationComponent implements OnInit {
  passwordStringMatch(): boolean {
    var myRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;
    if (myRegex.test(this.userForm.get('password').value)) {
      this.userForm.get('password').setErrors(null)
      return true
    } else {
      this.userForm.get('password').setErrors({ pattern: false })
      return false
    }
  }



  userForm: FormGroup;
  resendCounter = 0
  @ViewChild('verifyButton') verifyButtonRef: ElementRef;

  user = {} as UserInfo;

  showPassword = false;
  showConfirmPassword = false;
  // phonePattern = '^[+][9][1][6-9][0-9]{9}$';
  phonePattern = "^((\\+91-?)|0)?[0-9]{10}$"
  passwordRegex = '^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$'

  locationList: LocationInfo[] = [];

  paginationObjPackage = new PaginationObj();

  loadingFlagPackage = false;

  pincodes = []

  cities = []
  captcha: string;

  emailVerificationClicked = false;
  mobileVerificationClicked = false;
  mobileVerified = false;
  emailVerified = false;


  dropdownList = [];
  selectedList = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    textField: 'name',
  };

  states = []
  pincodebox = false;
  GstNumberbox = true;

  timer: string = '';
  interval: any;

  adharLink = ''
  panLink = ''
  isbLink = ''
  photoLink = ''
  gstLink=''


  radioButtonClicked = false
  mobileNumberClicked = false
  emailClicked = false
  companyNamePage = false
  photoPage = false
  addressPage = false
  panPage = false
  adharPage = false
  networkPage = false
  landlinePage = false
  GSTPage = false
  lcoamazingProgressPage = false
  lcocaptchaPage = false
  networkPage1 = false
  networkPage2 = false
  networkPage3 = false
  networkPage4 = false
  networkPage5 = false
  networkPage6 = false
  passwordPage = false
  private routerEventsSubscription: Subscription;

  
  @HostListener('document:visibilitychange', ['$event'])
  paymentpage
  wrongcaptchavalue = false
  saveClicked=false

  paymentStatus = "Null"
  isNumError = false
  refrealCodeCheck = false

  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private store: StoreService,
    private router: Router,
    private config: ConfigService

  ) {

  }

  ngOnInit(): void {
    // this.getFormDataFromRedirectUrl();
    this.getPathParam();
    this.initForm();
    this.generateCaptcha();
    console.log("gettingreloaded")

  }
  private subscribeToRouterEvents(url) {
    this.routerEventsSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {

      }

      if (event instanceof NavigationEnd) {

      }
    });
  }

  onVisibilityChange(event: Event) {
    if (!document.hidden) {

    this.paymentStatus = localStorage.getItem("paymentStatus");
    // alert(this.paymentStatus)
    // 
    localStorage.removeItem("paymentStatus")
    console.log(this.paymentStatus)
    }
    else{
      console.log("1")
    }
  }

  backClickedOnMobile() {
    this.mobileNumberClicked = false
    this.radioButtonClicked = false
  }

  backClickedOnEmail() {
    this.emailClicked = false
    this.mobileNumberClicked = false
  }

  MobileNextCLicked() {
    this.mobileNumberClicked = true
    this.emailClicked = false
  }

  EmailNextCLicked() {
    this.emailClicked = true
    this.companyNamePage = false
  }

  CompanynameNextClicked() {
    this.photoPage = false
    this.companyNamePage = true
  }

  photoPageNextClicked() {
    this.photoPage = true
    this.adharPage = false
  }

  adharPageNextClicked() {
    this.adharPage = true
    this.panPage = false
  }

  panPageNextClicked() {
    this.landlinePage = false
    this.panPage = true
  }

  landlinePageNextClicked() {
    if(this.edit==false){
    this.addressPage = false
    this.landlinePage = true
    }
    else{
      this.landlinePage = true
      this.addressPageNextClicked()
    }
  }



  lcoAmazingpageNextClicked() {
    this.lcoamazingProgressPage = true
    this.lcocaptchaPage = false
  }

  disAmazingpageNextClicked() {
    this.networkPage = true;
    this.networkPage1 = false
  }

  exchangePageNextClicked() {
    this.networkPage1 = true;
    this.networkPage2 = false;
  }
  dataCenterNextClicked() {
    this.networkPage2 = true;
    this.networkPage3 = false;
  }

  Ipv4NextClicked() {
    this.networkPage3 = true;
    this.networkPage4 = false;
  }

  ASNNextClicked() {
    this.networkPage4 = true;
    this.networkPage5 = false;
  }

  ISBPageNextClicked() {
    this.networkPage5 = true;
    this.networkPage6 = false;
  }


  IPAddressPageNextClicked(){
    this.lcoamazingProgressPage = true
    this.lcocaptchaPage = false
    this.networkPage6 = true
  }

  addressPageNextClicked() {
    this.addressPage = true
    this.GSTPage = false
  }

  backClickedonAddress() {
    this.addressPage = false
    this.landlinePage = false
  }

  backClickedOnCaptchaPage() {
    this.saveClicked=false
    if (this.userType.value == 'LCO') {
      this.lcoamazingProgressPage = false;
      this.lcocaptchaPage = false
    }
    else {
      this.networkPage6 = false
      this.lcoamazingProgressPage = false
      this.lcocaptchaPage = false
    }
  }
 


  NextCLickedonCaptchaPage() {
    if (this.captchaInput.value != this.captcha) {
      this.wrongcaptchavalue = true
      setTimeout(() => {
        this.wrongcaptchavalue = false;
      }, 5000);
      this.captchaInput.reset();
      this.generateCaptcha()
    }
    else {
      if(this.edit == false){
        this.persist();
      // this.paymentpage = false
      // this.lcocaptchaPage = true
      }
      else{
        this.persistEdit()
      }
    }
  }


  backClickedOnExchangePage() {
    this.networkPage = false;
    this.networkPage1 = false
  }
  backClickedOnDataCenterPage() {
    this.networkPage1 = false;
    this.networkPage2 = false
  }

  backClickedonpaymentPage() {
    this.lcocaptchaPage = false
    this.paymentpage = false
  }

  backClickedOnIpv4Page() {
    this.networkPage2 = false;
    this.networkPage3 = false
  }

  backClickedOnISBPage() {
    this.networkPage5 = false;
    this.networkPage4 = false
  }
  backClickedOnIPAddressPage(){
    this.networkPage6 = false;
    this.networkPage5 = false
  }
  backClickedOnASNPage() {
    this.networkPage3 = false;
    this.networkPage4 = false
  }
  backClickedOndisAmazingPage() {
    this.networkPage = false
    this.passwordPage = false
  }

  backClickedOnLandline() {
    this.landlinePage = false
    this.panPage = false
  }

  backClickedonAdhar() {
    this.adharPage = false
    this.photoPage = false
  }

  backClickedonGST() {
    if(this.edit==false){
    this.GSTPage = false
    this.addressPage = false
    }
    else{
      this.GSTPage=false
      this.backClickedonAddress()
    }
  }

  downloadFile(): void {
    const url = '/assets/sample/GST_Declaration_format.docx';
    window.open(url, '_blank');
  }


  backClickedonPan() {
    this.adharPage = false
    this.panPage = false
  }

  // backClickedonPan(){
  //   this.adharPage = false
  //   this.panPage = false
  // }

  backClickedOnCompanyName() {
    this.companyNamePage = false;
    this.emailClicked = false
  }

  backClickedOnPasswordPage() {
    this.GSTPage = false
    this.passwordPage = false
  }
  backClickedOnPhoto() {
    this.photoPage = false
    this.companyNamePage = false
  }

  backClickedOnlocAmazingPage() {
    if(this.edit==false){
    this.lcoamazingProgressPage = false
    this.passwordPage = false
    }
    else{
      this.lcoamazingProgressPage = false
      this.backClickedonPasswordPage()
    }
  }
  backClickedonPasswordPage() {
    this.passwordPage = false
    this.GSTPage = false
  }
  GSTPageNextClicked() {
    if(this.edit==false){
    this.GSTPage = true;
    this.passwordPage = false;
    }
    else{
      this.GSTPage = true;
      this.passwordPagePageNextClicked()
    }
  }

  passwordPagePageNextClicked() {
    if (this.userType.value == 'LCO') {
      this.lcoamazingProgressPage = false
      this.passwordPage = true
    }
    else {
      this.passwordPage = true
      this.networkPage = false
    }
  }

  moveToNext(event: any, nextInput: HTMLInputElement): void {
    console.log(nextInput);
    if (event.target.value && nextInput) {
      nextInput.focus();
    }
  }


  focusVerifyButton() {

    this.verifyButtonRef.nativeElement.focus();
  }

  moveToPrevious(event: any, previousInput: HTMLInputElement): void {
    if (!event.target.value && previousInput) {
      previousInput.focus();
    }
  }
  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.createFlow();
  }


  RadioButtonvalue() {
    this.radioButtonClicked = true;
  }

  numValidation(e: Event) {
    let input = (e.target as HTMLInputElement).value;
    this.mobileVerificationClicked = false;
    let phoneRegex: RegExp = /^[6-9]\d{9}$/;
    if (input == '') {
      return
    }
    if (phoneRegex.test(input)) {
      this.isNumError = false
      this.userForm.get('mobile').setErrors(null)
    } else {
      this.userForm.get('mobile').setErrors({ pattern: true })
      this.isNumError = true
    }
  }



  createFlow(): void {
    this.fetchCreateData();
  }


  fetchStates() {
    const url = this.config.json.apiEndpointRdbms + 'mobileApp/location/states';
    return this.http.getwithoutHeader(url);
  }

  fetchCreateData(): void {
    forkJoin([
      this.fetchStates()
    ]).subscribe(data => {
      this.states = data[0].data;
    });

  }

  getCities() {
    let url = this.config.json.apiEndpointRdbms + 'mobileApp/location/' + this.stateName.value + '/cities'
    this.http.getwithoutHeader(url).subscribe(data => {
      this.cities = data.data
    })
  }


  getPincode() {
    let url = this.config.json.apiEndpointRdbms + 'mobileApp/location/' + this.stateName.value + '/' + this.cityName.value + '/pincodes'
    this.http.getwithoutHeader(url).subscribe(data => {
      this.pincodes = data.data
    })
  }

  getLocation() {
    let url = this.config.json.apiEndpointRdbms + 'mobileApp/location/' + this.pincode.value + '/city/state'
    this.http.getwithoutHeader(url).subscribe(data => {
      this.cityName.setValue(data.data.city)
      this.stateName.setValue(data.data.state)
      this.getCities()
      this.getPincode()
    })
  }



  getAclList(): void {
    this.fetchStates().subscribe(data => {
      this.states = data;
    });
  }

  formatAclList(): void {
    this.dropdownList = this.states.map(grp => {
      return {
        id: grp.id,
        name: grp.name
      };
    });


  }

  initForm(): void {
    this.userForm = new FormGroup({
      userType: new FormControl('', [Validators.required]),
      companyName: new FormControl(''),
      contactPersonName: new FormControl('', [Validators.required]),
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
      }),
      mobile: new FormControl('', {
        validators: [Validators.required, Validators.pattern(this.phonePattern)],
      }),
      landline: new FormControl('', { validators: [Validators.required, Validators.pattern(this.phonePattern)] }),
      password: new FormControl(''),
      confirmPassword: new FormControl(''),
      gstNumber: new FormControl('', [Validators.required]),
      address: new FormControl(''),
      stateName: new FormControl('', [Validators.required]),
      cityName: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      mobileVerificationCode: new FormControl('', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
      emailVerificationCode: new FormControl('', [Validators.required]),
      captchaInput: new FormControl('', [Validators.required]),
      adharCard: new FormControl('', [Validators.required, Validators.maxLength(12), Validators.minLength(12)]),
      panCard: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      photo: new FormControl('', [Validators.required]),
      isbLicense: new FormControl(''),
      postalDetails: new FormControl('', [Validators.required]),
      exchangeName: new FormControl(''),
      exchangeLocation: new FormControl(''),
      ipv6: new FormControl(''),
      ipv4: new FormControl(''),
      ipAddress: new FormControl(''),
      ipPool: new FormControl(''),
      asn: new FormControl(''),
      dataCenterName: new FormControl(''),
      dataCenterLocation: new FormControl(''),
      otp1: new FormControl(''), otp2: new FormControl(''),
      otp3: new FormControl(''), otp4: new FormControl(''),
      otp6: new FormControl(''), otp5: new FormControl(''),
      regCode:new FormControl('')

    });

    this.conditionalValidators();
  }

  generateCaptcha() {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    this.captcha = result;
  }

  get regCode(): FormControl {
    return this.userForm.get('regCode') as FormControl;
  }
  get userType(): FormControl {
    return this.userForm.get('userType') as FormControl;
  }

  get companyName(): FormControl {
    return this.userForm.get('companyName') as FormControl;
  }
  get dataCenterName(): FormControl {
    return this.userForm.get('dataCenterName') as FormControl;
  } get dataCenterLocation(): FormControl {
    return this.userForm.get('dataCenterLocation') as FormControl;
  }
  get captchaInput(): FormControl {
    return this.userForm.get('captchaInput') as FormControl;
  }

  get contactPersonName(): FormControl {
    return this.userForm.get('contactPersonName') as FormControl;
  }

  get email(): FormControl {
    return this.userForm.get('email') as FormControl;
  }

  get mobile(): FormControl {
    return this.userForm.get('mobile') as FormControl;
  }

  get landline(): FormControl {
    return this.userForm.get('landline') as FormControl;
  }

  get password(): FormControl {
    this.passwordStringMatch()
    return this.userForm.get('password') as FormControl;
  }

  get cpassword(): FormControl {
    return this.userForm.get('confirmPassword') as FormControl;
  }

  get address(): FormControl {
    return this.userForm.get('address') as FormControl;
  }

  get gstNumber(): FormControl {
    return this.userForm.get('gstNumber') as FormControl;
  }

  get stateName(): FormControl {
    return this.userForm.get('stateName') as FormControl;
  }

  get cityName(): FormControl {
    return this.userForm.get('cityName') as FormControl;
  }

  get pincode(): FormControl {
    return this.userForm.get('pincode') as FormControl;
  }

  get mobileVerificationCode(): FormControl {
    return this.userForm.get('mobileVerificationCode') as FormControl;
  }
  get emailVerificationCode(): FormControl {
    return this.userForm.get('emailVerificationCode') as FormControl;
  }

  get adharCard(): FormControl {
    return this.userForm.get('adharCard') as FormControl;
  }

  get panCard(): FormControl {
    return this.userForm.get('panCard') as FormControl;
  }

  get photo(): FormControl {
    return this.userForm.get('photo') as FormControl;
  }

  get postalDetails(): FormControl {
    return this.userForm.get('postalDetails') as FormControl;
  }

  get ipv4(): FormControl {
    return this.userForm.get('ipv4') as FormControl;
  }

  get exchangeName(): FormControl {
    return this.userForm.get('exchangeName') as FormControl;
  }

  get exchangeLocation(): FormControl {
    return this.userForm.get('exchangeLocation') as FormControl;
  }

  get ipv6(): FormControl {
    return this.userForm.get('ipv6') as FormControl;
  }

  get asn(): FormControl {
    return this.userForm.get('asn') as FormControl;
  }

  get ipAddress(): FormControl {
    return this.userForm.get('ipAddress') as FormControl;
  }

  get ipPool(): FormControl {
    return this.userForm.get('ipPool') as FormControl;
  }

  get isbLicense(): FormControl {
    return this.userForm.get('isbLicense') as FormControl;
  }


  get otp1(): FormControl {
    return this.userForm.get('otp1') as FormControl;
  } get otp2(): FormControl {
    return this.userForm.get('otp2') as FormControl;
  } get otp3(): FormControl {
    return this.userForm.get('otp3') as FormControl;
  } get otp4(): FormControl {
    return this.userForm.get('otp4') as FormControl;
  } get otp5(): FormControl {
    return this.userForm.get('otp5') as FormControl;
  } get otp6(): FormControl {
    return this.userForm.get('otp6') as FormControl;
  }

  conditionalValidators(): void {

    this.userForm.get('password').setValidators(Validators.required);
    this.userForm.get('password').setValidators(Validators.minLength(8));
    this.userForm.get('confirmPassword').setValidators(Validators.required);
    this.userForm.setValidators(this.confirmPasswordValidator);
    if (this.userForm.get('userType').value == "distributor") {
      this.userForm.get('asn').setValidators(Validators.required);
      this.userForm.get('exchangeName').setValidators(Validators.required);
      this.userForm.get('ipv4').setValidators(Validators.required);
      this.userForm.get('ipv6').setValidators(Validators.required);
      this.userForm.get('ipAddress').setValidators(Validators.required);
      this.userForm.get('ipPool').setValidators(Validators.required);
      this.userForm.get('exchangeLocation').setValidators(Validators.required);
      this.userForm.get('datacenterName').setValidators(Validators.required);
      this.userForm.get('datacenterAddress').setValidators(Validators.required);
    }
  }

  updateForm(): void {
    // this.userForm.get('userType').setValue(this.user.userType);
    this.userForm.get('companyName').setValue(this.user.companyName);
    this.userForm.get('contactPersonName').setValue(this.user.contactPersonName);
    this.userForm.get('email').setValue(this.user.email);
    this.userForm.get('mobile').setValue(this.user.mobileNumber);
    this.userForm.get('landline').setValue(this.user.landlineNumber);
    this.userForm.get('address').setValue(this.user.address);
    this.userForm.get('gstNumber').setValue(this.user.gstNumber);
    this.userForm.get('cityName').setValue(this.user.cityName);
    this.userForm.get('password').setValue(this.user.password);
    this.userForm.get('stateName').setValue(this.user.stateName);
    this.userForm.get('pincode').setValue(this.user.pincode);
    this.userForm.get('adharCard').setValue(this.user.aadharNumber);
    this.userForm.get('panCard').setValue(this.user.panNumber),
      this.userForm.get('photo').setValue(this.user.photoUrl),
      this.userForm.get('isbLicense').setValue(this.user.isbLicense),
      this.userForm.get('postalDetails').setValue(this.user.postalRegistration),
      this.userForm.get('ipv4').setValue(this.user.ipv4)
    this.userForm.get('ipv6').setValue(this.user.ipv6)
    this.userForm.get('exchangeLocation').setValue(this.user.networkExchangeLocation)
    this.userForm.get('exchangeName').setValue(this.user.networkExchangeName)
    this.userForm.get('ipAddress').setValue(this.user.ipAddress)
    this.userForm.get('ipPool').setValue(this.user.ipPool)
    this.userForm.get('asn').setValue(this.user.ans)
    this.userForm.get('dataCenterName').setValue(this.user.dataCenterName)
    this.userForm.get('dataCenterLocation').setValue(this.user.dataCenterAddress)
    this.isbLink= this.user.isbLicenseUrl
    this.gstLink = this.user.gstUrl
    this.photoLink = this.user.photoUrl
    this.adharLink = this.user.aadharUrl
    this.panLink = this.user.panUrl
    this.paymentStatus = this.user.paymentStatus==0?"Success":"Failure"

  }

  handlePan(event: any) {
    let formData = new FormData();
    let file = event.target.files;
    let a = file.item(0);
    formData.append('file', a)
    const url = this.config.json.apiEndpointRdbms + 'leadDocuments/upload/' + 'Pan';
    this.http.postwithoutheader(url, formData).subscribe(data => {
      this.panLink = data.data.url
    })
  }
  

  handleGST(event: any) {
    let formData = new FormData();
    let file = event.target.files;
    let a = file.item(0);
    formData.append('file', a)
    const url = this.config.json.apiEndpointRdbms + 'leadDocuments/upload/' + 'Gst';
    this.http.postwithoutheader(url, formData).subscribe(data => {
      this.gstLink = data.data.url
    })
  }
  handleAdhar(event: any) {
    let formData = new FormData();
    let file = event.target.files;
    let a = file.item(0);
    formData.append('file', a)
    const url = this.config.json.apiEndpointRdbms + 'leadDocuments/upload/' + 'Adhar';
    this.http.postwithoutheader(url, formData).subscribe(data => {
      this.adharLink = data.data.url
    })
  }

  handlePhoto(event: any) {
    let formData = new FormData();
    let file = event.target.files;
    let a = file.item(0);
    formData.append('file', a)
    const url = this.config.json.apiEndpointRdbms + 'leadDocuments/upload/' + 'Photo';
    this.http.postwithoutheader(url, formData).subscribe(data => {
      this.photoLink = data.data.url
      console.log(this.config.json.imagPrefix+this.photoLink)
    })
  }

  handleISB(event: any) {
    let formData = new FormData();
    let file = event.target.files;
    let a = file.item(0);
    formData.append('file', a)
    const url = this.config.json.apiEndpointRdbms + 'leadDocuments/upload/' + 'ISB';
    this.http.postwithoutheader(url, formData).subscribe(data => {
      this.isbLink = data.data.url
    })
  }

  persistEdit(){
    let type= this.userType.value
    type= type.toLowerCase();
    this.updateModel(type)
    this.update()
  }

  persist(): void {
    this.saveClicked=true
    let type= this.userType.value
    type= type.toLowerCase();
    // this.userType.setValue(type)
    if (this.userType.value == 'distributor' &&
      this.userForm.get('exchangeName').value == '' &&
      this.userForm.get('exchangeLocation').value == '' &&
      this.userForm.get('ipv4').value == '' &&
      this.userForm.get('ipv6').value == '' &&
      this.userForm.get('ipPool').value == '' &&
      this.userForm.get('ipAddress').value == '' &&
      this.userForm.get('asn').value == ''
    ) {
      alert("Please check and Fill Network Details")
    }
    else if (this.userType.value == 'lco' && this.userForm.get('companyName').value == '') {
      alert("Company name required for lco")
    }
    else {
      this.updateModel(type)
      this.save();
    }
  }

  style = {
    position: 'relative'
  }

  refStatus = false
  payment() {
    if(this.refrealCodeCheck==false){
    const url = this.config.json.apiEndpointRdbms + 'wallet/payWithEaseBuzzForLCOForm';
    let type = this.userType.value
    let amount=""
    if(type=="LCO"){
      amount="11800"
    }
    else{
      amount ="29500"
    }
    let body = {
      "amount": amount,
      "packageName": "Wallet-Recharge",
      "firstName": this.contactPersonName.value,
      "mobileNumber": this.mobile.value,
      "email": this.email.value,
      "subsId": 0,
      "accType": type.toLowerCase()
    }

    this.http.postwithoutheader(url, body).subscribe(data => {
      this.user.transactionId=data.data.txnid
      window.open(data.data.payUrl, '_blank')
    })
  }
  else{
    let type=''
    if(this.userType.value=="LCO"){
      type='lco'
    }
    else if (this.userType.value=="Distributor"){
      type='distributor'
    }
    const url = this.config.json.apiEndpointRdbms + 'leadGeneration/validateRefCode/'+type+'/'+this.regCode.value;
    this.http.getwithoutHeader(url).subscribe(
      (res)=>{
        console.log(res.data)
        if(res.data=="success"){
          this.refStatus=true
        }
        else{
          alert("Code is not valid try again")
        }
      }
    )
  }

  }

  getFormDataFromRedirectUrl(): void {
    console.log("hai")
    const status = this.route.snapshot.queryParamMap.get('status');
    console.log(this.route)
    console.log(status)
    if (status === '0') {
      window.alert('Transaction Successful. Wallet recharged by Rs ' + this.route.snapshot.queryParamMap.get('rechargeAmount'));
      // this.walletBalance = this.route.snapshot.queryParamMap.get('walletAmount');
      // this.rechargeAmount = this.route.snapshot.queryParamMap.get('rechargeAmount');
      // this.walletBalance = this.route.snapshot.queryParamMap.get('rechargeAmount');
      // this.setPaymentStatus();
    } else if (status === '1') {
      window.alert('Transaction Failed');
    } else if (status === '2') {
      window.alert('Transaction Cancelled');
    } else if (status === 'Invalid_Response') {
      window.alert('Transaction Invalid');
    }
  }
  successPage=false

  update(): void {
    const url = this.config.json.apiEndpointRdbms + 'leadGeneration/'+this.updateId;

    this.http.postwithoutheader(url, this.user).subscribe(data => {

      if (data.statusCode == 200) {
        this.successPage = true
        this.paymentpage = true
        this.lcocaptchaPage = true
        alert(this.user.userType + " has been created thank you");
        this.userForm.reset();
      }
      else {
        alert(data.message)
      }

    },
      (error) => {
        window.alert(error.error["message"])
      }
    );
  }

  save(): void {
    const url = this.config.json.apiEndpointRdbms + 'leadGeneration';

    this.http.postwithoutheader(url, this.user).subscribe(data => {

      if (data.statusCode == 200) {
        this.successPage = true
        this.lcocaptchaPage = true
        this.paymentpage = true
        alert(this.user.userType + " has been created thank you");
        this.userForm.reset();
      }
      else {
        alert(data.message)
      }

    },
      (error) => {
        window.alert(error.error["message"])
      }
    );
  }

  pincodeChecked() {
    this.pincodebox = !this.pincodebox
  }

  GstNumberChecked() {
    this.GstNumberbox = !this.GstNumberbox
  }

  emailvalidation(event) {
    this.emailVerificationClicked = false;
  }

  updateModel(type): void {
    this.user.userType = type;
    this.user.companyName = this.userForm.get('companyName').value;
    this.user.contactPersonName = this.userForm.get('contactPersonName').value;
    this.user.email = this.userForm.get('email').value
    this.user.mobileNumber = this.userForm.get('mobile').value;
    this.user.gstNumber = this.userForm.get('gstNumber').value;
    this.user.companyName = this.userForm.get('companyName').value;
    this.user.cityName = this.userForm.get('cityName').value;
    this.user.stateName = this.userForm.get('stateName').value;
    this.user.address = this.userForm.get('address').value;
    this.user.pincode = this.userForm.get('pincode').value;
    this.user.landlineNumber = this.userForm.get('landline').value
    this.user.password = this.userForm.get('password').value
    this.user.networkExchangeLocation = this.userForm.get('exchangeLocation').value
    this.user.networkExchangeName = this.userForm.get('exchangeName').value
    this.user.ipv4 = this.userForm.get('ipv4').value
    this.user.ipv6 = this.userForm.get('ipv6').value
    this.user.ipPool = this.userForm.get('ipPool').value
    this.user.ipAddress = this.userForm.get('ipAddress').value
    this.user.ans = this.userForm.get('asn').value
    this.user.postalRegistration = this.userForm.get('postalDetails').value
    this.user.aadharUrl = this.adharLink
    this.user.photoUrl = this.photoLink
    this.user.panUrl = this.panLink
    this.user.isbLicenseUrl = this.isbLink
    this.user.aadharNumber = this.adharCard.value
    this.user.panNumber = this.panCard.value
    this.user.declarationUrl = this.gstLink
    // this.user.amount=
    // if (this.userType.value == "LCO")
    //   this.user.amount = 11800;
    // else
    //   this.user.amount = 29500;
    this.user.gstNumber = this.gstNumber.value
    this.user.gstUrl = this.gstLink
    if(this.refStatus== true){
      this.user['regCode'] = this.regCode.value;
      this.user.transactionId=null
    }
    
    // this.user.isbLicense = this.isbLicense.value
    this.user.dataCenterName = this.dataCenterName.value
    this.user.dataCenterAddress = this.dataCenterLocation.value
    // this.user.paymentStatus = this.config.json.paymentStatus
    this.user.paymentStatus =0
    // this.user.transactionId = localStorage.getItem('transactionId')
  }

  sendVerificationCodeForMobile() {
    let url = this.config.json.apiEndpointRdbms + 'leadGeneration/generateVerificationCode';
    let body = {
      "mobileNumber": this.mobile.value,
      "type": "mobile"
    }
    this.http.postwithoutheader(url, body).subscribe()
    this.mobileVerificationClicked = true
    this.startTimer()
  }


  sendVerificationCodeToEmail() {
    this.emailVerificationClicked = true
    let url = this.config.json.apiEndpointRdbms + 'leadGeneration/generateVerificationCode';
    let body = {
      "email": this.email.value,
      "type": "email"
    }
    this.http.postwithoutheader(url, body).subscribe()
    this.startTimer()
  }
  showWrongText = true
  showWrongTextforEmail = true
  resendMailWrongCounter = 0;
  resendMobileWrongCounter = 0


  verifyEmail() {
  
    this.resendMailWrongCounter = this.resendMailWrongCounter + 1;
    let code = this.otp1.value.toString() + this.otp2.value.toString() + this.otp3.value.toString() + this.otp4.value.toString() + this.otp5.value.toString() + this.otp6.value.toString()
    this.emailVerificationCode.setValue(code)
    this.otp1.setValue('')
    this.otp2.setValue('')
    this.otp3.setValue('')
    this.otp4.setValue('')
    this.otp5.setValue('')
    this.otp6.setValue('')
    let url = this.config.json.apiEndpointRdbms + 'leadGeneration/verifyCode';
    let body = {
      "email": this.email.value,
      "code": this.emailVerificationCode.value
    }
    this.http.postwithoutheader(url, body).subscribe(
      data => {
        if (data.status == 'SUCCESS') {
          this.emailVerified = true
          this.showWrongTextforEmail = true
          this.checkEmailExists();
        }
        else {
          if (this.resendMailWrongCounter < 3) {
            this.emailVerificationCode.setValue('');
            this.emailVerified = false
            this.showWrongTextforEmail = false;
            setTimeout(() => {
              this.showWrongTextforEmail = true;
            }, 5000);
          }
          else {
            alert("After three unsuccessful attempts with the wrong code, please try again after some time")
            window.location.reload();
          }
        }
      }
    )
  }
  edit = false
  updateId = ''
  getDetailsbyId(id){
    this.updateId = id
    let url=this.config.json.apiEndpointRdbms + 'leadGeneration/'+id
    this.http.getwithoutHeader(url).subscribe(
      (data)=>{
        this.user=data.data
        this.edit=true;
        this.updateForm();
      }
    )
  }

  checkNumberExists(){
    let url=this.config.json.apiEndpointRdbms + 'leadGeneration/validateMobileOrEmail';
    let type=this.userType.value
    let body = {
      "type":"mobile",
      "mobileNumber":this.mobile.value,
      "userType":type.toLowerCase()
  
    }
    this.http.postwithoutheader(url, body).subscribe(
      (data)=>{
        if(data.data.exist==true){
          this.getDetailsbyId(data.data.id)
        }
      }
    )
  }

  checkEmailExists(){
    let url=this.config.json.apiEndpointRdbms + 'leadGeneration/validateMobileOrEmail';
    let type=this.userType.value
    let body = {
      "type":"email",
      "email":this.email.value,
      "userType":type.toLowerCase()
  
    }
    this.http.postwithoutheader(url, body).subscribe(
      (data)=>{
        if(data.data.exist==true){
          this.getDetailsbyId(data.data.id)
        }
      }
    )
  }


  verifyMobile() {
    this.resendMobileWrongCounter = this.resendMobileWrongCounter + 1;

    let code = this.otp1.value.toString() + this.otp2.value.toString() + this.otp3.value .toString()+ this.otp4.value.toString() + this.otp5.value.toString() + this.otp6.value.toString()
    this.otp1.setValue('')
    this.otp2.setValue('')
    this.otp3.setValue('')
    this.otp4.setValue('')
    this.otp5.setValue('')
    this.otp6.setValue('')
    this.mobileVerificationCode.setValue(code)
    let url = this.config.json.apiEndpointRdbms + 'leadGeneration/verifyCode';
    let body = {
      "mobileNumber": this.mobile.value,
      "code": this.mobileVerificationCode.value
    }
    this.http.postwithoutheader(url, body).subscribe(
      data => {
        if (data.status == 'SUCCESS') {
          this.mobileVerified = true
          this.showWrongText = true
          this.checkNumberExists();
        }
        else {
          if (this.resendMobileWrongCounter < 3) {
            this.mobileVerificationCode.setValue('');
            this.mobileVerified = false
            this.showWrongText = false;
            setTimeout(() => {
              this.showWrongText = true;
            }, 5000);
          }
          else {
            alert("After three unsuccessful attempts with the wrong code, please try again after some time")
            window.location.reload();
          }
        }
      }
    )

  }

  submit(): void {
    this.persist();
  }

  ResendCodeForMobile() {
    this.resendCounter = this.resendCounter + 1
    if (this.resendCounter > 3) {
      this.resendCounter = 0;
      alert("sorry please try again after some time")
      this.userForm.reset();
      window.location.reload();
    }
    else {
      let url = this.config.json.apiEndpointRdbms + 'leadGeneration/generateVerificationCode';
      let body = {
        "mobileNumber": this.mobile.value,
        "type": "mobile"
      }
      this.http.postwithoutheader(url, body).subscribe()
      this.startTimer()
    }
  }
  ResendCodeForEmail() {
    this.resendCounter = this.resendCounter + 1
    if (this.resendCounter > 3) {
      this.resendCounter = 0;
      alert("sorry please try again after some time")
      this.userForm.reset();
      window.location.reload();
    }
    else {
      let url = this.config.json.apiEndpointRdbms + 'leadGeneration/generateVerificationCode';
      let body = {
        "email": this.email.value,
        "type": "email"
      }
      this.http.postwithoutheader(url, body).subscribe()
      this.startTimer()
    }
  }

  startTimer(): void {
    let seconds = 30;
    this.timer = this.formatTime(seconds);
    this.interval = setInterval(() => {
      if (seconds > 0) {
        seconds--;
        this.timer = this.formatTime(seconds);
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  formatTime(seconds: number): string {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  }


  confirmPasswordValidator(group: AbstractControl): ValidationErrors | null {
    if (group.get('confirmPassword').value !== group.get('password').value) {
      return { mismatch: true };
    }
  }



}