<div class="cms__content">
    <div class="top__heading">
        <h3>{{isEdit?'Edit':'Create'}} Access Control List </h3>
        <!-- <h3> Access Control List</h3> -->
        <a [routerLink]="['/settings/access-control']"> <button class="default__button float-right">Back</button></a>
    </div>
    <div class="clearfix"></div>
    <form action="" [formGroup]="groupForm">
        <div class="col-12 form__style">
            <div class="row">
                <div class="col-12 col-md-4">

                    <div class="col-12">
                        <label>ACL Group Name</label>
                        <input type="text" autocomplete="off" class="custom__field" formControlName="name"
                            [ngClass]="{'error__field': !name.valid && name.touched}" />
                        <div [hidden]="!name.touched">
                            <span class="error__msg" *ngIf="name.hasError('required')">Required</span>
                        </div>
                    </div>
                    <div class="col-12">
                        <label>UserType</label>
                        <select formControlName="userType" class="custom__field" >
                            <option value="mso">MSO</option>
                            <option value="lco">LCO</option>
                            <option value="distributor">Distributor</option>
                            <option value="affiliator">Affiliator</option>
                        </select>
                        <div [hidden]="!userType.touched">
                            <span class="error__msg" *ngIf="userType.hasError('required')">Required</span>
                        </div>
                    </div>
                    <div class="col-12">
                        <label>Short Description</label>
                        <textarea class="custom__field" formControlName="description"></textarea>
                    </div>

                    <div class="col-12">
                        <a [routerLink]="['/settings/access-control']"><button type="button"
                                class="default__button">Cancel</button></a>
                        <button class="cta float-right" [disabled]="!groupForm.valid" 
                        
                            (click)="checkandContinue()">Save</button>
                            <!-- <button class="cta float-right" [disabled]="!groupForm.valid" *ngIf="continueClicked"
                            (click)="persist()">Save</button> -->
                    </div>
                </div>
                <div class="col-12 col-md-8">
                    <div class="col-12 card">
                        <p style="color:red;">Check the box to have Edit Access for the menus *</p>
                        <div class="row scrollallow">
                            <div class="col-12 col-md-6">
                                <ul class="main__menu" *ngFor="let menu of menuTree| slice:0:4">
                                    <li>
                                        <label class="switch">
                                            <input type="checkbox" [checked]="menu.checked" (change)="menu.checked = !menu.checked;
                                            updateSubmenuCheckStatus(menu)">
                                            <span class=" slider round"></span>
                                        </label>
                                        <span class="highlight">{{menu.name}}</span>
                                        <ul class="sub__menu" *ngFor="let submenu of menu.subMenu">
                                            <li  class="submenu__line">
                                                <label class="switch">
                                                    <input type="checkbox" [checked]="submenu.checked" (change)="checkAccess(submenu);submenu.checked = !submenu.checked;
                                                    updateParentCheckStatus(menu)">
                                                    <span class=" slider round"></span>
                                                </label>
                                                <span  class="nameandCheckboxSpan">
                                                {{submenu.name}}
                                                <input type="checkbox" [checked]="submenu.access"
                                            (change)="submenu.access = !submenu.access;submenu.checked = true;
                                            updateParentCheckStatus(menu)"></span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>

                            <div class="col-12 col-md-6">
                                <ul class="main__menu" *ngFor="let menu of menuTree| slice:4">
                                    <li>
                                        <label class="switch">
                                            <input type="checkbox" [checked]="menu.checked" (change)="menu.checked = !menu.checked;
                                            updateSubmenuCheckStatus(menu)">
                                            <span class=" slider round"></span>
                                        </label>
                                        <span class="highlight">{{menu.name}}</span>
                                        <ul class="sub__menu" *ngFor="let submenu of menu.subMenu">
                                            <li class="submenu__line">
                                                <label class="switch">
                                                    <input type="checkbox" [checked]="submenu.checked" (change)="checkAccess(submenu);submenu.checked = !submenu.checked;
                                                    updateParentCheckStatus(menu)">
                                                    <span class=" slider round"></span>
                                                </label>
                                                <span  class="nameandCheckboxSpan">
                                                    {{submenu.name}}
                                                    <input type="checkbox" [checked]="submenu.access"
                                                (change)="submenu.access = !submenu.access;submenu.checked = true;
                                                updateParentCheckStatus(menu)"></span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 col-md-8">
                    <div class="col-12 card">
                        <p>Check the box to have Edit Access for the menus</p>
                        <div class="row scrollallow">
                            <div class="col-12 col-md-4">
                                <ul class="main__menu"  *ngFor="let menu of menus">
                                    <li class="access__class">
                                        <input type="checkbox" [checked]="menu.access"
                                            (change)="menu.access = !menu.access">
                                        <span>{{menu.name}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </form>

</div>