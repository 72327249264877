<div class="cms__content">
  <div class="top__heading">
    <h3>Categories</h3>
    <a [routerLink]="['/content/category/create']"><button class="default__button float-right" *ngIf="menuAccess">+ Add New</button></a>
  </div>
  <div class="clearfix"></div>
  <div class="col-12 list__summary">
    <div class="row">
      <div class="col-6 col-md-3">
        <div class="list__card">
          <span class="list__heading">Categories</span>
          <span class="list__item">{{TotalCategoryCount}}</span>
        </div>
      </div>
      <div class="col-6 col-md-3">
        <div class="list__card">
          <span class="list__heading">Active Categories</span>
          <span class="list__item">{{CategoryActiveCount}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 list__table">
    <div class="row overflow">

      <div class="custom__table__filter">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <div class="table__right">
          <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> records</p>
          <div class="search__container">
            <input type="text" autocomplete="off" class="custom__field" [(ngModel)]="paginationObj.searchKeyword" (keydown.enter)="onSearch()"/>
            <button (click)="onSearch()" (keydown.enter)="onSearch()"><img src="./assets/search.png" alt="search" /></button>
          </div>
        </div>
      </div>

      <table class="custom__iptv__table">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th (click)="sortByItem(sortByProperties.name)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.name}">
              Name
            </th>
            <th (click)="sortByItem(sortByProperties.desc)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.desc}">
              Description
            </th>
            <th class="no__sort">
              Channel Count
            </th>
            <th (click)="sortByItem(sortByProperties.status)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.status}">
              Status
            </th>
            <th (click)="sortByItem(sortByProperties.date)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.date}">
              Created On
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="loadingFlag; else showList">
            <td class="loading" colspan="5">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>

          <ng-template #showList>
            <tr *ngFor="let category of categoryList; let i = index">
              <td>{{((paginationObj.pageNumber-1)*paginationObj.pageSize)+(i+1)}}</td>
              <td>{{category.name}}</td>
              <td>{{category.description}}</td>
              <td>{{category.channels.length}}</td>
              <td>
                <span [ngClass]="{'safe__label' : category.status===1, 'danger__label' : !(category.status===1)}">
                  {{category.status===1?'&#10003;':'X'}}
                </span>
              </td>
              <td>
                {{category.createdDate | date: 'dd-MM-yyyy HH:mm:ss'}}
                <div class="action__items">
                  <button (click)="edit(category)" *ngIf="menuAccess">Edit</button>
                  <!-- <button class="danger" (click)="updateStatus(category)">
                    {{category.status===0?'Activate':'Deactivate'}}
                  </button> -->
                  <!-- <button (click)="delete(category)">Delete</button> -->
                </div>
              </td>

            </tr>
          </ng-template>
        </tbody>
      </table>

      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>
