import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { PackageInfo } from 'src/app/models/package/package-info';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-report-product-composition',
  templateUrl: './report-product-composition.component.html',
  styleUrls: ['./report-product-composition.component.scss', '../report-filters.scss']
})
export class ReportProductCompositionComponent implements OnInit {
  paginationObj = new PaginationObj();

  loadingFlag = false;


  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  reportList = [];
  selectedLco = 0;
  from: any = '0000-00-00';
  to: any = '0000-00-00';
  productCompositionList = []
  bouquetList: PackageInfo[] = [];
  selectedBouquetId: any = 0;
  selectBroadcaster: any = 0;
  selectBouquetType: any = 'All'
  broadcasterList = [];
  currentDate = new Date();



  isLoad = 'Choose Date'

  pageNumber
  onDate:any = '0000-00-00';

  lcoList: LCOInfo[] = [];

  statusValue = "All"
  dateString;
  eve;

  sortByProperties = {
    bouquetId: 'bouquetId',
    bouquetName: 'bouquetName',
    bouquetType: 'bouquetType',
    broadcasterName: 'broadcasterName',
    channelName: 'channelName',
    channelStatus: 'channelStatus',
    channelId: 'channelId'

  };

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchBouquetList();
    this.fetchBroadcasterList();
    this.fetchData()
  }

  fetchBouquetList(): void {
    this.report.getBouquetList().subscribe(data => {
      this.bouquetList = data.data;
    });
  }
  viewModal = false
  yearsArray = []
  yearValue = ''
  dateType
  showMonth = false
  showDateRange = false
  dateSelector = true
  tableDisplay = true

  downloadHistoricReport() {
    this.viewModal = true
    this.yearsArrayCalculator()

  }


  dateTypeSelectors(num) {
    this.yearValue = ''
    if (num == 0) {
      this.dateSelector = true
      this.showMonth = false
      this.showDateRange = false
      this.tableDisplay = true
    }
    if (num == 1) {
      this.dateSelector = false
      this.showMonth = true
      this.showDateRange = false
      this.tableDisplay = true
    }
    if (num == 2) {
      this.dateSelector = false
      this.showDateRange = true
      this.showMonth = false
      this.tableDisplay = false
      this.yearValue = new Date().getFullYear()+'-01-01'
      this.dateType='year'
      console.log(this.yearValue)
    }
  }

  yearsArrayCalculator() {
    if (this.yearsArray.length == 0) {
      let currentYear = new Date().getFullYear();
      for (let i = currentYear; i >= 2019; i--) {
        this.yearsArray.push(i);
      }
    }
  }

  generateReport() {
    let url = this.config.json.apiEndpoint + 'reports/generateHistoricalReport?value=' +
      this.yearValue + '&type=' + this.dateType + '&reportName=Bouquet_composition_report'
    this.http.get(url).subscribe(
      (res) => {
        if (res.statusCode == 200) {
          const fileUrl = res.data.fileUrl;
          const anchor = document.createElement('a');
          anchor.href = fileUrl;
          anchor.target = '_self';
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          ;this.yearValue='';
          this.viewModal=false;
          this.dateTypeSelectors(0)
        }
        else {
          alert(res.message)
        }
      }
    )
  }

  yearFilter() {
    this.yearValue = (document.getElementById('yearCalc') as HTMLSelectElement).value;
    this.yearValue=this.yearValue+'-01-01';
    this.dateType = 'year'
  }

  MonthFilter(e) {
    this.yearValue=''
    this.yearValue += e.target.value + '-01'
    console.log(this.yearValue)
    this.dateType = 'month'
  }

  // dateChange(ev: NgxDateRangeInfo): void {
  //   if (ev.startDate !== null && ev.endDate !== null) {
  //     [this.from, this.to] = [ev.startDate, ev.endDate];
  //     this.calendar.setDate(ev);
  //     this.loadingFlag = true
  //     this.isLoad = 'Loading'
  //     this.fetchData();
  //   }
  // }

  // bouquetSelect(): void {
  //   this.fetchData();
  // }


  onSearch() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchData();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }
  handleFetchPage(data): void {
    this.reportList = data.data;
    if (this.reportList.length != 0) {
      this.loadingFlag = false;
    } else {
      this.loadingFlag = false;
      this.isLoad = 'No Records';
    }
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }

  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.pageNumber = metadata.pageNumber
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      // // console.log(ev.startDate.date(), ev.startDate.month(), ev.startDate.year())
      // // console.log(ev.endDate.date(), ev.endDate.month(), ev.endDate.year())
      this.eve = ev;
      this.dateString =
        ev.startDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.startDate.month()) +
        '-' +
        ev.startDate.year() +
        ' to ' +
        ev.endDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.endDate.month()) +
        '-' +
        ev.endDate.year();
      this.from = ev.startDate.format('YYYY-MM-DD');
      this.to = ev.endDate.format('YYYY-MM-DD');
      console.log(this.from)
      this.fetchData();
      this.loadingFlag = true;
      this.isLoad = 'Loading';
      this.calendar.setDate(ev);
    }
  }

  fetchBroadcasterList(): void {
    this.report.getBroadcasterList().subscribe(data => {
      this.broadcasterList = data;
    });
  }


  fetchData(): void {
    this.loadingFlag = true
    this.reportList = []
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
    let url = PaginationUtilities.urlBuilder('reports/productCompositionReport', this.paginationObj);
    url = url + "&bouquetType=" + this.selectBouquetType + "&bouquetId=" + this.selectedBouquetId + "&broadcasterId=" + this.selectBroadcaster
    // if (this.from != "0000-00-00" && this.to != "0000-00-00") {
    //   url = url + "&fromDate=" + this.from + "&toDate=" + this.to;
    // }

    if (this.onDate != "0000-00-00") {
      url = url + "&onDate=" + this.onDate;
    }
    this.http.get(url).subscribe(
      (data) => {
        this.handleFetchPage(data);
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }

  downloadTableExcel(): void {
    let name = 'Product Composition Report',filters
    if(this.onDate == "0000-00-00"){
    filters=`Source Type : SMS , Product Type : ${this.selectBouquetType}  , Broadcaster : ${this.selectBroadcaster} , Product: ${this.selectedBouquetId}`
    }
    else{
       filters=`Source Type : SMS , Product Type : ${this.selectBouquetType}  , Broadcaster : ${this.selectBroadcaster} , Product: ${this.selectedBouquetId} , Date : ${this.onDate}`
    }
    this.doc.exportTableToExcelwithImage(this.reportTable, name, 'product_composition_report',undefined,undefined,
      "Filters: ",filters
    );
  }

  downloadTablePdf(): void {
    // const tempDate = new Date(this.selectedDate);
    // const monthYear = tempDate.toLocaleString('default', {month:'long'}) + " " + tempDate.getFullYear();
    
    if(this.onDate == "0000-00-00"){
      this.pdf.generatePdfHtml('#reportTable',
        'product_composition_report',
        `Source Type : SMS , Product Type : ${this.selectBouquetType}  , Broadcaster : ${this.selectBroadcaster} , Product: ${this.selectedBouquetId}`
      );
    }else{
      this.pdf.generatePdfHtml('#reportTable',
        'product_composition_report',
        `Source Type : SMS , Product Type : ${this.selectBouquetType}  , Broadcaster : ${this.selectBroadcaster} , Product: ${this.selectedBouquetId} , Date : ${this.onDate}`
      );
    }
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchData();
  }



  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No.", "Broadcaster name", "Product Name", "Prodcuct ID", "Prodcuct Type","Channel Status", "Channel ID", "Channel Name"]
    };
    let data = []
    let i = 1
    this.reportList.forEach(line => {
      let csvLine = {
        slNo: i,
        broadcasterName: line.broadcasterName,
        bouquetName: line.bouquetName,
        bouquetId: line.bouquetId,
        bouquetType: line.bouquetType,
        channelStatus: line.channelStatus,
        channelId: line.channelId,
        channelName: line.channelName
       
      }
      i += 1
      data.push(csvLine);
    });

    new ngxCsv(data, 'product_composition_report', options);
  }

  formatDate(ele) {
    if (ele == undefined) {
      return false;
    }
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }


}







