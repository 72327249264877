import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import * as moment from 'moment';

@Component({
  selector: 'app-report-subscription-reconciliation-count',
  templateUrl: './report-subscription-reconciliation-count.component.html',
  styleUrls: ['./report-subscription-reconciliation-count.component.scss']
})
export class ReportSubscriptionReconciliationCountComponent implements OnInit {

  reportList = [];
  isLoad = 'Choose Date';
  isLoading = false;
  currentDate = new Date();
  from:any;
  to:any;

  selected: NgxDateRangeInfo;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  locale: any = {
    format: 'DD/MM/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'DD/MM/YYYY', // default is format value
    separator: ' To ', // default is ' - '
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'Select', // detault is 'Apply'
    customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 0 // first day is monday
  };


  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config: ConfigService,
    private http: HttpClientWrapperService) { }

  ngOnInit(): void {
    this.fetchData('', '')
  }

  viewModal = false
  reportType = ''
  yearValue = ''
  dateType
  yearsArray = []
  showMonth = false
  showDateRange = false
  dateSelector = true
  tableDisplay = true

  downloadHistoricReport() {
    this.viewModal = true
    this.yearsArrayCalculator()

  }

  yearFilter() {
    this.yearValue = (document.getElementById('yearCalc') as HTMLSelectElement).value;
    this.yearValue=this.yearValue+'-01-01';
    this.dateType = 'year'
  }

  MonthFilter(e) {
    this.yearValue=''
    this.yearValue += e.target.value + '-01'
    console.log(this.yearValue)
    this.dateType = 'month'
  }

  yearsArrayCalculator() {
    if (this.yearsArray.length == 0) {
      let currentYear = new Date().getFullYear();
      for (let i = currentYear; i >= 2019; i--) {
        this.yearsArray.push(i);
      }
    }
  }
 
  generateReport() {
    let url = this.config.json.apiEndpoint + 'reports/generateHistoricalReportForReconcilation?value=' +
      this.yearValue + '&type=' + this.dateType + '&reportName=Subscription_reconcilation_report' 
    this.http.get(url).subscribe(
      (res) => {
        if (res.statusCode == 200) {
          const fileUrl = res.data.fileUrl;
          const anchor = document.createElement('a');
          anchor.href = fileUrl;
          anchor.target = '_self';
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          this.reportType='';this.yearValue='';
          this.viewModal=false;
          this.dateTypeSelectors(0)
        }
        else {
          alert(res.message)
        }
      }
    )
  }

  
  dateTypeSelectors(num) {
    this.yearValue = ''
    if (num == 0) {
      this.dateSelector = true
      this.showMonth = false
      this.showDateRange = false
      this.tableDisplay = true
    }
    if (num == 1) {
      this.dateSelector = false
      this.showMonth = true
      this.showDateRange = false
      this.tableDisplay = true
    }
    if (num == 2) {
      this.dateSelector = false
      this.showDateRange = true
      this.showMonth = false
      this.tableDisplay = false
      this.yearValue = new Date().getFullYear()+'-01-01'
      this.dateType='year'
      console.log(this.yearValue)
    }
  }


  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.fetchData(ev.startDate.format('YYYY-MM-DD'), ev.endDate.format('YYYY-MM-DD'));
      this.isLoad = 'Loading';
      this.calendar.setDate(ev);
    }
  }

  fetchData(from,to): void {
    this.report.getSubscriptionReconciliationcount(from,to).subscribe(
      (data) => {
        this.isLoading = false;
        if(!(data.data)){
          this.isLoading = false;
          this.isLoad = 'No Records';
          this.reportList = []
          return
        }
        this.reportList = data.data;
        console.log(this.reportList)
        if (this.reportList?.length == 0) {
          this.isLoading = false;
          this.isLoad = 'No Records';
        } else {
          this.isLoading = true;
        }
      },
      () => {
        this.isLoad = 'No Records';
        this.isLoading = false;
      }
    );
  }

  sortKeysByPrefix(stbCount: any): any[] {
    const keys = Object.keys(stbCount);
    const smsKeys = keys.filter(key => key.startsWith('SMS'));
    const drmKeys = keys.filter(key => key.startsWith('DRM'));
    const mismatchKeys = keys.filter(key => key.startsWith('Mismatch'));
    const typeKeys = keys.filter(key => key.startsWith('type'));

  
    return [...smsKeys, ...drmKeys, ...mismatchKeys, ...typeKeys].map(key => ({ key, value: stbCount[key] }));
  }

  

  downloadTableExcel(): void {
    let name = 'Subscription Reconciliation Report';
    this.doc.exportTableToExcelwithImage(this.reportTable, name, 'Subscription_Reconciliation_Report');
  }

  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable', 'Subscription_Reconciliation_Report', `Source Type : SMS`);
  }

  
  downloadTableCSV(): void {
    if (this.reportTable) {
      const tableData = this.getTableData(this.reportTable.nativeElement);
      if (tableData) {
        var options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: false,
          useBom: true,
          // headers: [
          //   'SMS',
          //   'DRM',
          //   'Mismatch %'
          // ],
        };
        new ngxCsv(tableData, 'Subscription_Reconciliation_Report', options);
      }
    }
  }

  private getTableData(table: any): any[] {
    const data = [];
    const rows = table.rows;
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const rowData = [];
      for (let j = 0; j < row.cells.length; j++) {
        rowData.push(row.cells[j].innerText.trim());
      }
      data.push(rowData);
    }
    return data;
}
  // downloadTableCSV(): void {
  //   var options = {
  //     fieldSeparator: ',',
  //     quoteStrings: '"',
  //     decimalseparator: '.',
  //     showLabels: true,
  //     showTitle: false,
  //     // title: false,
  //     useBom: true,
  //     // noDownload: true,
  //     headers: [
  //       'SMS',
  //       'DRM',
  //       'Mismatch %'
  //     ],
  //   };
  //   let data=this.reportList;
  //   new ngxCsv(data, 'Subscription_Reconciliation_Report', options);
  // }

  searchKeyword() {
    let value = (document.getElementById('searchKeyword') as HTMLInputElement)
      .value;
    let url =
      this.config.json.apiEndpointRdbms + 'osd/list?searchKeyword=' + value;
    this.http.get(url).subscribe(
      (data) => {
        this.reportList = data;
        this.isLoading = false;
        if (this.reportList.length == 0) {
          this.isLoad = 'No Records';
        } else {
          this.isLoading = true;
        }
      },
      () => {
        this.isLoad = 'No Records';
        this.isLoading = false;
      }
    );
  }

}
