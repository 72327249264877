import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DistributorSearchType } from 'src/app/distributor/distributor-search/distributor-search.component';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-add-distributor-funds',
  templateUrl: './add-distributor-funds.component.html',
  styleUrls: ['./add-distributor-funds.component.scss']
})
export class AddDistributorFundsComponent implements OnInit {

  
  distributorFundsForm: FormGroup;
  distributorData
  lcoID
  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private auth: AuthService,
    private config: ConfigService
  ) { }

  user = 'distributor'
  searchText!: string
  openSearch = false
  ngOnInit(): void {
    // console.log(this.router.url);
    // let url = this.router.url
    // if(url.includes('lco')){
    //   this.user = 'lco'
    // }
    // if(url.includes('distributor')){
    //   this.user = 'distributor'
    // }
    this.initForm()
  }

  

  
  getSubscriberId(e: Event) {
    console.log(e)
    this.openSearch = false
    this.searchText = (e.target as HTMLInputElement).value
    if(this.searchText.length >= 3){
      setTimeout(() => {
        this.openSearch = true
      }, 500);
    }
  }


  clearInput() {
    let input = document.getElementById('lcoCode') as HTMLInputElement
    input.value = ""
    this.searchText = "",
      this.openSearch = false
    this.distributorFundsForm.reset()
  }

  lcoId
  getUser(e: DistributorSearchType) {
    
    let inputBox = document.getElementById('lcoCode') as HTMLInputElement;
    if ((e as any) == '') {
      this.openSearch = false;
      this.searchText = undefined;
      this.distributorFundsForm.get('lcoCode').setValue(null);
      inputBox.value = '';
      return;
    }
    this.distributorFundsForm.get('lcoCode').setValue(e.distributorCode);
    inputBox.value = `${e.distributorCode}, ${e.name}`;
    this.lcoId = e.id
    this.openSearch = false;
    this.searchText = undefined;
    this.searchText = undefined;
  }


  


  initForm(): void {
    this.distributorFundsForm = new FormGroup({
      lcoCode: new FormControl('', [Validators.required]),
      modeOfPayment: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
      transactionID: new FormControl('', [Validators.required]),
      remarks: new FormControl('', [Validators.required])
    });
  }

  get lcoCode(): FormControl {
    return this.distributorFundsForm.get('lcoCode') as FormControl;
  }

  get modeOfPayment(): FormControl {
    return this.distributorFundsForm.get('modeOfPayment') as FormControl;
  }

  get amount(): FormControl {
    return this.distributorFundsForm.get('amount') as FormControl;
  }

  get transactionID(): FormControl {
    return this.distributorFundsForm.get('transactionID') as FormControl;
  }

  get remarks(): FormControl {
    return this.distributorFundsForm.get('remarks') as FormControl;
  }

  createBody() {
    let body = {
      modeOfPayment: this.modeOfPayment.value,
      amount: this.amount.value,
      transactionId: this.transactionID.value,
      remarks: this.remarks.value,
      subscriberCode : this.lcoCode.value,
    }
    return body
  }
  

  persist() {
    let body = this.createBody();
    let url = this.config.json.apiEndpointRdbms +'wallet/addToDistributor'
    console.log("url")
    console.log(body)
    this.http.post(url, body).subscribe(
      (res) => {
        if (res.status === "FAIL") {
          console.log(res)
          alert(res.message);
        } else {
          alert(res.message);
          this.getWalletData();
          this.distributorFundsForm.reset();
        }
      },
      (err) => {
        alert(err);
      }
    );
  }

  getWalletData() {
    let id = this.auth.getLcoId();
    let user;
    if (this.auth.isLCO()) {
      user = 'lco';
    }
    if (this.auth.isDistributor()) {
      user = 'distributor';
    }
    let url = `${this.config.json.apiEndpoint}wallet/${user}?${user}Id=${id}`;
    this.http.get(url).subscribe({
      next: (res) => {
        this.auth.amount = res.data.amount;
      },
    });
  }

}
