<div class="cms__content" >
  <div class="box">
    <div class="top__heading">
      <h3>Ad Manager</h3>
     
        <a [routerLink]="['/settings/ad-manager/']">
          <button class="default__button float-right">Back</button></a
        >
    </div>
    <form action="" >
      <div class="col-12 form__style">
        <!-- <div class="row" >
          
          <label for=""  >LCO</label>
          <input type="text" class="custom__field" id="lcoId" (input)="getSearchText($event)" >
            <div class="overflow" *ngIf="isSearch" style="margin-top: 10px" >
              <app-lco-search [searchText]="searchText" (userDetail)="getLCOId($event)" ></app-lco-search>
            </div>
          
        </div> -->
        <div class="row" *ngIf="!isEdit">
          <label>Search Distributor</label>
          <input type="text" autocomplete="off" id="lcoCode" class="custom__field" formControlName="lcoCode" (input)="getSubscriberId($event)" />
          <p (click)="clearInput()" id="clearInput">Clear</p>
          <div class="search__param" style="overflow: auto;"  *ngIf="openSearch">
            <app-distributor-search  [searchText]="searchText" (userDetail)="getUser($event)" > </app-distributor-search>
          </div>
        </div>

        <!-- <div class="row" *ngIf="isEdit">
          <label>Search Distributor</label>
          <input type="text" autocomplete="off" id="lcoCode" class="custom__field" formControlName="lcoCode"/>
        </div> -->

        <div class="row" *ngIf="distributorSelected">
          <label>LCOs</label>
          <ng-multiselect-dropdown [placeholder]="'select LCOs'" [settings]="dropdownSettings" [data]="lcoList"
            class="custom__field"
            [(ngModel)]="selectedIDsList"
            [ngModelOptions]="{standalone: true}"
            (onSelect)="onItemSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelect)="onItemDeSelect($event)"
            (onDeSelectAll)="onDeSelectAll()">
        </ng-multiselect-dropdown>
        </div>

        <div class="row" *ngIf="isEdit">
          <label>LCOs</label>
          <input type="text" autocomplete="off" id="lcoCode" class="custom__field" [(ngModel)]="lcoIdEdit" [ngModelOptions]="{standalone: true}" disabled />

          <!-- <ng-multiselect-dropdown [placeholder]="'select LCOs'" [settings]="dropdownSettings" [data]="lcoList"
            class="custom__field"
            [(ngModel)]="selectedIDsList"
            [ngModelOptions]="{standalone: true}"
            (onSelect)="onItemSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelect)="onItemDeSelect($event)"
            (onDeSelectAll)="onDeSelectAll()">
        </ng-multiselect-dropdown> -->
        </div>
        <div class="row" *ngIf="distributorSelected && selectedList.length>0 && !isEdit">
          <label for="">Position</label>
          <select [(ngModel)]="positionValue" class="custom__field" (change)="nameSelected()"  [ngModelOptions]="{standalone: true}">
            <!-- <option value="selected" disabled selected>-- SELECT --</option> -->
            <option value="home-banner">Home Screen - Banner</option>
            <option value="home-overlay">Channel - Overlay</option>
            <option value="splash-screen">Splash - Screen</option>
            <option value="app-icon">App - Icon</option>
          </select>
          <!-- <select name="" id=""  (change)="selectedPosition($event)" class="custom__field"> 
                        <option value="selected" disabled selected> -- SELECT -- </option>
            <option value="home-banner">Home Screen - Banner</option>
            <option value="home-overlay">Channel - Overlay</option>
          </select> -->
        </div>

        <div class="row" *ngIf="isEdit">
          <label for="">Position</label>
          <select [(ngModel)]="positionValue" class="custom__field" (change)="nameSelected()"  [ngModelOptions]="{standalone: true}">
            <option value="home-banner">Home Screen - Banner</option>
            <option value="home-overlay">Channel - Overlay</option>
            <option value="splash-screen">Splash - Screen</option>
            <option value="app-icon">App - Icon</option>
          </select>
          
        </div>
        <div class="row" *ngIf="distributorSelected && selectedList.length>0 && !isEdit">
          <label for="">Upload</label>
          <input #myInput type="file" accept=".jpeg, .jpg, .png" class="custom__field" (input)="uploadAds($event)" *ngIf="positionValue!='splash-screen'"/>
          <input #myInput type="file"  accept="video/mp4"  class="custom__field" (input)="uploadAds($event)" *ngIf="positionValue=='splash-screen'"/>
        </div>
        <div class="row" *ngIf="isEdit">
          <label for="">Upload</label>
          <input #myInput type="file" accept=".jpeg, .jpg, .png" class="custom__field" (input)="uploadAds($event)" *ngIf="positionValue!='splash-screen'"/>
          <input #myInput type="file"  accept="video/mp4"  class="custom__field" (input)="uploadAds($event)" *ngIf="positionValue=='splash-screen'"/>
        </div>
        <div class="row" *ngIf="message">
          <b>{{message}}</b>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="preview" *ngIf="showPreview && !isEdit">
  <div class="preview_tv_home" >
    <img src="../../../assets/tv_mockup.png" alt="">
    <div class="overlay_home">
      <img [src]="imageURL" alt="" *ngIf="positionValue == adList[0].name">
      <img [src]="adList[0].url" alt="" *ngIf="positionValue != adList[0].name">
    </div>
    <span *ngIf="positionValue == adList[0].name" >{{adList[0].name}}</span>
  </div>
  <div class="preview_tv_channel">
    <img src="../../../assets/tv_mockup.png" alt="">
    <div class="overlay_channel">
      <img [src]="positionValue == adList[1].name?imageURL: adList[1].url" alt="">
    </div>
    <span>{{adList[1].name}}</span>
  </div>
</div>



<div class="preview" *ngIf="showPreview && !isEdit">
  <div class="preview_tv_home" >
    <img src="../../../assets/tv_mockup.png" alt="">
    <div class="appicon_channel">
      <img [src]="imageURL" alt="" *ngIf="positionValue == adList[2].name">
      <img [src]="adList[2].url" alt="" *ngIf="positionValue != adList[2].name">
    </div>
    <span *ngIf="positionValue == adList[2].name" >{{adList[0].name}}</span>
  </div>
  <div class="preview_tv_home" >
    <img src="../../../assets/tv_mockup.png" alt="">
    <div class="splashscreen_modal">
      <img [src]="imageURL" alt="" *ngIf="positionValue == adList[3].name">
      <video controls autoplay [src]="adList[0].url" alt="" *ngIf="positionValue != adList[3].name"></video>
    </div>
    <span *ngIf="positionValue == adList[0].name" >{{adList[0].name}}</span>
  </div>
</div>


<div class="preview" *ngIf="isEdit">
  <div class="preview_tv_home">
    <img src="../../../assets/tv_mockup.png" alt="">
    <div class="overlay_home">
      <img [src]="homebannerList.url" alt="">
    </div>
    <span>{{homebannerList.NAME}}</span>
  </div>
  <div class="preview_tv_channel">
    <img src="../../../assets/tv_mockup.png" alt="">
    <div class="overlay_channel">
      <img [src]="homeoverlayList.url" alt="">
    </div>
    <span>{{homeoverlayList.NAME}}</span>
  </div>
</div>

<div class="preview" *ngIf="isEdit">
  <div class="preview_tv_home">
    <img src="../../../assets/tv_mockup.png" alt="">
    <div class="appicon_channel">
      <img [src]="appIconList.url" alt="">
    </div>
    <span>{{appIconList.NAME}}</span>
  </div>
  <div class="preview_tv_channel">
    <img src="../../../assets/tv_mockup.png" alt="">
    <div class="splashscreen_modal">
      <video controls autoplay src="{{splashScreenList.url}}" alt=""  width="100%"></video>
    </div>
    <span>{{splashScreenList.NAME}}</span>
  </div>
</div>