<div class="cms__content">
    <app-reports-header></app-reports-header>
    <div class="top__heading">
      <h3>Subscriber Report</h3>
      <a [routerLink]="['/reports/custom-reports']"
        ><button class="default__button float-right">Back</button></a
      >
    </div>
    <div class="clearfix"></div>
    <div class="filter-download_container">
        <div class="filter_container">
          <div class="label-container">
            <label class="report_selection_label"> Broadcaster: </label>
          </div>
            
                <select class="custom__field report_selection" [(ngModel)]="selectedBroadcasterId" (change)='fetchData()'>
                  <option value="0">ALL</option>
                  <option *ngFor="let broadcaster of broadcasterList" [ngValue]="broadcaster.id">
                      {{ broadcaster.name }}
                  </option>
                </select>
        </div>
        <div class="download__report col-md-6">
          <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
            PDF
            <img src="./assets/pdf.svg" alt="download" />
          </button>
          <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
            Excel
            <img src="./assets/excel.svg" alt="download" />
          </button>
          <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
            CSV
            <img src="./assets/csv.svg" alt="download" /> </button>
        </div>
      </div>
    
      <!-- <div class="row"> 
        <div class="col-md-6 report-generated-fix">
          <p>
            Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
          </p>
        </div>
        
      </div> -->
  
    <div class="third_box">
      <div class="col-12 col-md-6 report">
        <p>
          Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
        </p>
      </div>
      <div style="display: flex; gap:20px" >
        <div class="table__right" style="padding: 5% 0%">
          <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
        </div>
        <!-- <div class="search__container">
          <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
          <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
        </div> -->
      </div>
    </div>
  
    <div class="col-12 list__table">
      <div class="row scroll">
        <table class="custom__iptv__table" #reportTable id="reportTable">
          <thead>
            <tr>
              <th>Quality</th>
              <th>FTA</th>
              <th>Pay</th>
              <th>Total</th>

            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngIf="reportList.length ==0" >
              <td style="text-align: center !important;" colspan="11">
                <app-custom-spinner></app-custom-spinner>
                </td>
            </tr> -->
            <tr *ngIf="reportList.length == 0 && loadingFlag">
              <td style="text-align: center !important" colspan="4">
                <app-custom-spinner></app-custom-spinner>
              </td>
            </tr>
            <tr *ngIf="!loadingFlag && reportList.length == 0">
              <td style="text-align: center !important" colspan="4">
                {{ isLoad }}
              </td>
            </tr>
            <tr>
                <td *ngIf="!loadingFlag&& reportList.length != 0">SD</td>
                <td>{{reportList['SDFTAChannelCount']}}</td>
                <td>{{reportList['SDPaidChannelCount']}}</td>
                <td>{{reportList['totalSDChannelCount']}}</td>
                </tr>
            <tr>
                <td *ngIf="!loadingFlag&& reportList.length != 0">HD</td>
                <td>{{reportList['HDFTAChannelCount']}}</td>
                <td>{{reportList['HDPaidChannelCount']}}</td>
                <td>{{reportList['totalHDChannelCount']}}</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td>Total</td>
                <td>{{reportList['totalChannelCount']}}</td>
            </tr>
                    

             
            <tr *ngIf="!loadingFlag && reportList.length === []">
              <td style="text-align: center !important" colspan="4">
                No Records.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  