import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from '../constants/global-constants';
import { ConfigService } from './config.service';
import { SubjectService } from './subject.service';
import { map } from 'rxjs/operators';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isLogin = false;
  // userSub = new BehaviorSubject<User>(null);
  clearTimeout: any;
  userMenuData: any;
  amount :any = 0;

  constructor(
    private http: HttpClient,
    // private httpwrapper: HttpClientWrapperService,
    private router: Router,
    private subject: SubjectService,
    private config: ConfigService,
    
  ) {}

  

  login(body: any): any {
    let headers = new HttpHeaders().set('UserType', this.config.json.userType);
    let url =
      (this.config.json.apiEndpointRdbms as string).replace('api', 'auth') +
      'login';
    this.http.post(url, body, { headers: headers }).subscribe(
      (res: any) => {
        console.log(res)
        if(res.statusCode==200){
          // this.httpC.alertShown= false;
          if (res.data.userType === this.config.json.userType) {
            console.log("coming here")
            this.afterLogin(res);
          } else {
            alert(
              `${res.data.userType} can't access ${this.config.json.userType} console`
            );
          }
        }
        else{
          // console.log()
          alert(res.message)
        }
        
      },
      (err: HttpErrorResponse) => {
        console.log(err.error.status)
        if (err.error.status == 401) {
          alert('Invalid Username/Password');
        }
        this.subject.setAccessSubject(false);
        this.isLogin = false;
        localStorage.setItem('STATE', 'false');
        localStorage.setItem('ROLE', '');
      }
    );
  }



  afterLogin(res) {
    console.log(res.statusCode )
    if (res.statusCode !== 200) {
      alert(res.message);
      return;
    }
    console.log(res);
    let data = res.data;
    this.userMenuData = data.menus;
    this.isLogin = true;
    localStorage.setItem('STATE', 'true');
    localStorage.setItem('TOKEN', data.token);
    localStorage.setItem('profileInfo', JSON.stringify(data.currentUser));
    localStorage.setItem('user', data.currentUser.name);
    // localStorage.setItem('menus',JSON.stringify(data.menus));
    console.log(data.menus)
    window.localStorage.setItem('id', btoa(data.currentUser.id));
    if ((data.userType as string).toLocaleUpperCase() == 'LCO') {
      window.localStorage.setItem(
        'lco',
        btoa(JSON.stringify(data.currentUser))
      );
      window.localStorage.setItem('menus', btoa(JSON.stringify(data.menus)));
      localStorage.setItem('ROLE', GlobalConstants.LCO);
    }
    if ((data.userType as string).toLocaleUpperCase() == 'MSO') {
      // MSO
      window.localStorage.setItem(
        'mso',
        btoa(JSON.stringify(data.currentUser))
      );
      window.localStorage.setItem('menus', btoa(JSON.stringify(data.menus)));
      window.localStorage.setItem(
        'aclGroupId',
        btoa(data.currentUser.aclGroupId)
      );
      localStorage.setItem('ROLE', GlobalConstants.MSO);
    }
    if ((data.userType as string).toLocaleUpperCase() == 'DISTRIBUTOR') {
      window.localStorage.setItem(
        'distributor',
        btoa(JSON.stringify(data.currentUser))
      );
      window.localStorage.setItem('menus', btoa(JSON.stringify(data.menus)));
      localStorage.setItem('ROLE', GlobalConstants.Distributor);
    }
    if ((data.userType as string).toLocaleUpperCase() == 'AFFILIATOR') {
      window.localStorage.setItem(
        'affiliator',
        btoa(JSON.stringify(data.currentUser))
      );
      window.localStorage.setItem('menus', btoa(JSON.stringify(data.menus)));
      localStorage.setItem('ROLE', GlobalConstants.Affiliator);
    }

    this.subject.setAccessSubject(true);
    this.router.navigate(['dashboard']);
  }

  autoLogout(expirationDate: number) {
    // //// console.log(expirationDate);
    this.clearTimeout = setTimeout(() => {
      this.logout();
    }, expirationDate);
  }

  private refreshSubject = new Subject<void>();
  refresh$ = this.refreshSubject.asObservable();

  triggerRefresh() {
    this.refreshSubject.next();
  }




  logout(): any {
    this.subject.setAccessSubject(false);
    this.isLogin = false;
    let colorPalette = localStorage.getItem('chosenPalette');
    let arrToRemove = [
      'profileInfo',
      'id',
      'aclGroupId',
      'user',
      'mso',
      'TOKEN',
      'menus',
    ];
    for (let ele of arrToRemove) {
      localStorage.removeItem(ele);
    }
    localStorage.setItem('STATE', 'false');
    localStorage.setItem('ROLE', '');
    setTimeout(() => {
      localStorage.setItem('chosenPalette', colorPalette);
    }, 100);
  }

  isLoggedIn(): any {
    const loggedIn = localStorage.getItem('STATE');
    if (loggedIn === 'true') {
      return true;
    } else {
      return false;
    }
  }

  getRole(): string {
    return localStorage.getItem('ROLE');
  }

  isLCO(): boolean {
    if (this.getRole() === 'LCO') {
      return true;
    }
    return false;
  }

  isMSO() {
    if (this.getRole() === 'MSO') {
      return true;
    }
    return false;
  }

isAffiliator(){
  if (this.getRole() === 'Affiliator') {
    return true;
  }
  return false;
}

  isDistributor(): boolean {
    if (this.getRole() === 'Distributor') {
      return true;
    }
    return false;
  }

  getUserData(): any {
    const lco = atob(window.localStorage.getItem('lco'));
    return JSON.parse(lco);
  }

  getDistributorData(): any {
    const lco = atob(window.localStorage.getItem('distributor'));
    return JSON.parse(lco);
  }

  getToken(): string {
    return localStorage.getItem('TOKEN');
  }

  getLcoId(): string {
    return atob(localStorage.getItem('id'));
  }

  getMenus(): any {
    // window.localStorage.setItem('menus', btoa(JSON.stringify(data.menus)));
    return atob(localStorage.getItem('menus'));
  }
}
