import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { environment } from 'src/environments/environment';
import { ReportTraiDataService } from 'src/app/report-trai/plugins/report-trai-data.service';
import { SubscriberSearchType } from 'src/app/subscription/subscriber-search/subscriber-search';

@Component({
  selector: 'app-logs-product-subscription-desubscription',
  templateUrl: './logs-product-subscription-desubscription.component.html',
  styleUrls: ['./logs-product-subscription-desubscription.component.scss','../../report-trai/report-filters.scss']
})
export class LogsProductSubscriptionDesubscriptionComponent implements OnInit {

  paginationObj = new PaginationObj();

  loadingFlag = false;


  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  reportList = [];
  selectedLco = 0;
  from: any = '0000-00-00';
  to: any = '0000-00-00';

  bouquetList = [];

  subscriberList=[];
  
    selectedSubscriberId :any=0
    selectedBouquetId:any =0;
  sortByProperties = {
    subscriberName: 'subscriberName',
    bouquetName: 'bouquetName',
    processType: 'processType',
    macId: 'macId',
    stbId: 'stbId',
    status:'status',
    transactionDate:'transactionDate',
    startDate:'startDate',
    endDate:'endDate',
    subscriberId:'subscriberId',
    bouquetId:'bouquetId'
  };

  currentDate = new Date();
  statusValue :any= 2

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchBouquetList();
    this.fetchSubscriberList();
    this.fetchData()
    
  }

  searchText!: string;
  openSearch = false;
  getSubscriberId(e: Event) {
    this.openSearch = false;
    this.searchText = (e.target as HTMLInputElement).value;
    if(this.searchText.length >= 3){
      setTimeout(() => {
        this.openSearch = true;
      }, 500);
    }
  }

  
  subId: string = '';

  getUser(e: SubscriberSearchType) {
    let inputBox = document.getElementById('searchSubCode') as HTMLInputElement;
    inputBox.value = `${e.subscriberCode}, ${e.subscriberName}`;
    this.selectedSubscriberId=e.subscriberId
    this.fetchData();
    this.subId = e.subscriberId;
    this.openSearch = false;
    this.searchText = undefined;
  }

  clearInput() {
    let inputBox = document.getElementById('searchSubCode') as HTMLInputElement;
    inputBox.value = ''
    this.fetchData()
  }

  fetchBouquetList(): void {
    this.report.getBouquetList().subscribe(data => {
      this.bouquetList = data.data;
    });
  }

  fetchSubscriberList(): void {
    const url = this.config.json.apiEndpointRdbms + 'reports/getSubscriberListReport';
     this.http.get(url).subscribe(
      data=>{
        this.subscriberList = data.data;
      }
     );
  }

  isLoad = 'Choose Date';

  onSearch(){
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  sortByItem(item: string): void {
    console.log(item)
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchData();
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchData();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }
  handleFetchPage(data): void {
    this.reportList =[]
    this.reportList = data.data;
    if (this.reportList.length != 0) {
      this.loadingFlag = false;
    } else {
      this.loadingFlag = false;
      this.isLoad = 'No Records';
    }
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }

  pageNumber
  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.pageNumber = metadata.pageNumber
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  fetchData(): void { 
    this.loadingFlag= true
    this.reportList = []
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
    let url = PaginationUtilities.urlBuilder('logs/productSubscriptionDesubscriptionLogs', this.paginationObj);
    url = url + "&subscriptionStatus=" + this.statusValue + "&bouquetId="+this.selectedBouquetId +"&subscriberId="+this.selectedSubscriberId
    if(this.from!="0000-00-00"&& this.to!="0000-00-00"){
      url = url+ "&fromDate="+this.from+"&toDate="+this.to;
    }
    this.http.get(url).subscribe(
        (data) => {
          this.handleFetchPage(data);
        },
        () => {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      );
  }

  lcoSelect(): void {
    this.from = this.calendar.getDate()['startDate']
    this.to = this.calendar.getDate()['endDate']
    this.fetchData();
  }

  dateString;
  eve;
  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      // // console.log(ev.startDate.date(), ev.startDate.month(), ev.startDate.year())
      // // console.log(ev.endDate.date(), ev.endDate.month(), ev.endDate.year())
      this.eve = ev;
      this.dateString =
        ev.startDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.startDate.month()) +
        '-' +
        ev.startDate.year() +
        ' to ' +
        ev.endDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.endDate.month()) +
        '-' +
        ev.endDate.year();
      this.from = ev.startDate.format('YYYY-MM-DD');
      this.to = ev.endDate.format('YYYY-MM-DD');
      console.log(this.from)
      this.fetchData();
      this.loadingFlag = true;
      this.isLoad = 'Loading';
      this.calendar.setDate(ev);
    }
  }

  downloadTableExcel(): void {
    //// console.log("hello")
    let headers = [];
    let name = 'Prodcut Subscription Desubscription Logs';
    this.doc.exportTableToExcelwithImage(
      this.reportTable,
      name,
      'prodcut_subscription_desubscription_logs',
      undefined,
      undefined,
      'Report Date : ',
      this.dateString
    );
  }

  downloadTablePdf(): void {
    if (
      this.reportList == null ||
      this.reportList == undefined ||
      this.reportList?.length == 0
    ) {
      alert('No Data!');
      return;
    }
    if (!this.formatDate(this.from)) {
      this.pdf.generatePdfHtml(
        '#reportTable',
        'prodcut_subscription_desubscription_logs',
        `Till now    Source Type : SMS`
      );
      return;
    }
    this.pdf.generatePdfHtml(
      '#reportTable',
      'prodcut_subscription_desubscription_logs',
      `From Date: ${this.formatDate(this.from)}     To Date: ${this.formatDate(
        this.to
      )},   Source Type : SMS`
    );
  }

  formatDate(ele) {
    if (ele == undefined) {
      return false;
    }
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,

      useBom: true,
      // noDownload: true,
      headers: [
        'Sl No',
        'Transaction Date',
        'Subscriber Id',
        'Subscriber Name',
        'STB Id',
        'MAC Id',
        'Bouquet Id',
        'Bouquet Name',
        'Subscription Status',
        'Start Date','End Date'
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slNo: i,
        date:line.transactionDate,
        subscriberId:line.subscriberId,
        subscriberName: line.subscriberName,
        stbid:line.stbId,
        mac: line.macId,
        bouquetId:line.bouquetId,
        bouquetName: line.bouquetName,
        processType: line.processType,
        startDate: line.startDate,
        endDate:line.endDate,
      };
      data.push(csvLine);
      i += 1;
    });

    new ngxCsv(data, 'prodcut_subscription_desubscription_logs', options);
  }

}

