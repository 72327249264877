import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListLeadComponent } from './list-lead/list-lead.component';
import { CreateLeadComponent } from './create-lead/create-lead.component';


const routes: Routes = [
  { path: '', redirectTo: 'list' },
  { path: 'list', component: ListLeadComponent },
  { path: ':id', component: CreateLeadComponent },
  { path: 'create', component: CreateLeadComponent },
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeadRoutingModule { }
