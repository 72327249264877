import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { PluginsModule } from '../plugins/plugins.module';
import { SharedModule } from '../shared/shared.module';
import { FingerprintReportComponent } from './fingerprint-report/fingerprint-report.component';
import { OsdReportComponent } from './osd-report/osd-report.component';
import { PackageAgeingReportComponent } from './package-ageing-report/package-ageing-report.component';
import { ReportTraiDataService } from './plugins/report-trai-data.service';
import { ReportStbActiveCountComponent } from './report-stb-active-count/report-stb-active-count.component';
import { ReportStbDeployedComponent } from './report-stb-deployed/report-stb-deployed.component';
import { ReportStbFaultyComponent } from './report-stb-faulty/report-stb-faulty.component';
import { ReportStbInactiveCountComponent } from './report-stb-inactive-count/report-stb-inactive-count.component';
import { ReportStbLcoStockComponent } from './report-stb-lco-stock/report-stb-lco-stock.component';
import { ReportStbStatusComponent } from './report-stb-status/report-stb-status.component';
import { ReportStbWarehouseStockComponent } from './report-stb-warehouse-stock/report-stb-warehouse-stock.component';
import { ReportSubscriberDataComponent } from './report-subscriber-data/report-subscriber-data.component';
import { ReportSubscriberStatusComponent } from './report-subscriber-status/report-subscriber-status.component';
import { ReportSubscriptionComponent } from './report-subscription/report-subscription.component';
import { ReportSyncCasComponent } from './report-sync-cas/report-sync-cas.component';
import { ReportTraiRoutingModule } from './report-trai-routing.module';
import { SubscriberAuthorizationReportComponent } from './subscriber-authorization-report/subscriber-authorization-report.component';
import { SubscriberPackagesHistoryComponent } from './subscriber-packages-history/subscriber-packages-history.component';
import { SubscriberPackagesReportComponent } from './subscriber-packages-report/subscriber-packages-report.component';
import { TraiHomeComponent } from './trai-home/trai-home.component';
import { ReportStbLocationWiseComponent } from './report-stb-location-wise/report-stb-location-wise.component';
import { ReportBouquetSubscriptionCountComponent } from './report-bouquet-subscription-count/report-bouquet-subscription-count.component';
import { ReportBouquetwiseChannelComponent } from './report-bouquetwise-channel/report-bouquetwise-channel.component';
import { ReportBouquetSubscriptionComponent } from './report-bouquet-subscription/report-bouquet-subscription.component';
import { ReportChannelSubscriptionComponent } from './report-channel-subscription/report-channel-subscription.component';
import { ReportBroadcasterChannelWiseComponent } from './report-broadcaster-channel-wise/report-broadcaster-channel-wise.component';
import { ReportStbActiveListComponent } from './report-stb-active-list/report-stb-active-list.component';
import { ReportStbInactiveListComponent } from './report-stb-inactive-list/report-stb-inactive-list.component';
import { ReportStbBlacklistListComponent } from './report-stb-blacklist-list/report-stb-blacklist-list.component';
import { ReportSubscribersListComponent } from './report-subscribers-list/report-subscribers-list.component';
import { ReportChannelListComponent } from './report-channel-list/report-channel-list.component';
import { ReportProductCompositionComponent } from './report-product-composition/report-product-composition.component';
import { ReportTodaysExpiryComponent } from './report-todays-expiry/report-todays-expiry.component';
import { ReportNextFifteenDaysExpiryComponent } from './report-next-fifteen-days-expiry/report-next-fifteen-days-expiry.component';
import { ReportBroadcasterWeekWiseComponent } from './report-broadcaster-week-wise/report-broadcaster-week-wise.component';
import { ReportSubscriberDeviceReplacementComponent } from './report-subscriber-device-replacement/report-subscriber-device-replacement.component';
import { ReportOrderHistoryActiveComponent } from './report-order-history-active/report-order-history-active.component';
import { ReportOrderHistoryInactiveComponent } from './report-order-history-inactive/report-order-history-inactive.component';
import { ReportStatewiseSubscriberComponent } from './report-statewise-subscriber/report-statewise-subscriber.component';
import { ReportSubscriberwiseSubscriptionActiveComponent } from './report-subscriberwise-subscription-active/report-subscriberwise-subscription-active.component';
import { ReportSubscriberwiseSubscriptionInactiveComponent } from './report-subscriberwise-subscription-inactive/report-subscriberwise-subscription-inactive.component';
import { ReportSubscriberDetailsComponent } from './report-subscriber-details/report-subscriber-details.component';
import { ReportSubwiseActiveInactiveLDTComponent } from './report-subwise-active-inactive-ldt/report-subwise-active-inactive-ldt.component';
import { ReportPackageAgeingInactiveComponent } from './report-package-ageing-inactive/report-package-ageing-inactive.component';
import { ReportNextSevenDaysExpiryComponent } from './report-next-seven-days-expiry/report-next-seven-days-expiry.component';
import { ReportBoquetModificationComponent } from './report-boquet-modification/report-boquet-modification.component';
import { ReportLcoBillingComponent } from './report-lco-billing/report-lco-billing.component';
import { SuspendedReports } from './suspended-reports/suspended-reports.componet';
import { LcoModule } from '../lco/lco.module';
import { DistributorModule } from '../distributor/distributor.module';
import { ReportStbInventoryComponent } from './report-stb-inventory/report-stb-inventory.component';
import { UniversalReportComponent } from './universal-report/universal-report.component';
import { ReportStbReconciliationCountComponent } from './report-stb-reconciliation-count/report-stb-reconciliation-count.component';
import { ReportSusbscriberReconciliationCountComponent } from './report-susbscriber-reconciliation-count/report-susbscriber-reconciliation-count.component';
import { ReportChannelReconciliationCountComponent } from './report-channel-reconciliation-count/report-channel-reconciliation-count.component';
import { ReportBoquetReconciliationCountComponent } from './report-boquet-reconciliation-count/report-boquet-reconciliation-count.component';
import { ReportSubscriptionReconciliationCountComponent } from './report-subscription-reconciliation-count/report-subscription-reconciliation-count.component';
import { ReportStbListComponent } from './report-stb-list/report-stb-list.component';
import { ReportStbCountComponent } from './report-stb-count/report-stb-count.component';
import { ReportProducrSubscriptionStatusComponent } from './report-producr-subscription-status/report-producr-subscription-status.component';
import { ReportMsrComponent } from './report-msr/report-msr.component';
import { ReportPackageAgeingActiveInactiveComponent } from './report-package-ageing-active-inactive/report-package-ageing-active-inactive.component';
import { ReportStbInventoryNewComponent } from './report-stb-inventory-new/report-stb-inventory-new.component';
import { ReportChannelListNewComponent } from './report-channel-list-new/report-channel-list-new.component';
import { ReportSubscriberListNewComponent } from './report-subscriber-list-new/report-subscriber-list-new.component';
import { ReportHomeNewComponent } from './report-home-new/report-home-new.component';
import { ReportStbMappingComponent } from './report-stb-mapping/report-stb-mapping.component';


@NgModule({
  declarations: [
    SubscriberAuthorizationReportComponent,
    SubscriberPackagesReportComponent,
    PackageAgeingReportComponent,
    OsdReportComponent,
    FingerprintReportComponent,
    SubscriberPackagesHistoryComponent,
    TraiHomeComponent,
    ReportStbFaultyComponent,
    ReportStbStatusComponent,
    ReportStbWarehouseStockComponent,
    ReportStbLcoStockComponent,
    ReportStbDeployedComponent,
    ReportSubscriberStatusComponent,
    ReportSubscriptionComponent,
    ReportSubscriberDataComponent,
    ReportSyncCasComponent,
    ReportStbActiveCountComponent,
    ReportStbInactiveCountComponent,
    ReportStbLocationWiseComponent,
    ReportBouquetSubscriptionCountComponent,
    ReportBouquetwiseChannelComponent,
    ReportBouquetSubscriptionComponent,
    ReportChannelSubscriptionComponent,
    ReportBroadcasterChannelWiseComponent,
    ReportStbActiveListComponent,
    ReportStbInactiveListComponent,
    ReportStbBlacklistListComponent,
    ReportSubscribersListComponent,
    ReportChannelListComponent,
    ReportProductCompositionComponent,
    ReportTodaysExpiryComponent,
    ReportNextFifteenDaysExpiryComponent,
    ReportBroadcasterWeekWiseComponent,
    ReportSubscriberDeviceReplacementComponent,
    ReportOrderHistoryActiveComponent,
    ReportOrderHistoryInactiveComponent,
    ReportStatewiseSubscriberComponent,
    ReportSubscriberwiseSubscriptionActiveComponent,
    ReportSubscriberwiseSubscriptionInactiveComponent,
    ReportSubscriberDetailsComponent,
    ReportSubwiseActiveInactiveLDTComponent,
    ReportPackageAgeingInactiveComponent,
    ReportNextSevenDaysExpiryComponent,
    ReportBoquetModificationComponent,
    ReportStbInactiveCountComponent,
    ReportLcoBillingComponent,
    SuspendedReports,
    ReportStbInventoryComponent,
    UniversalReportComponent,
    ReportStbReconciliationCountComponent,
    ReportSusbscriberReconciliationCountComponent,
    ReportChannelReconciliationCountComponent,
    ReportBoquetReconciliationCountComponent,
    ReportSubscriptionReconciliationCountComponent,
    ReportStbListComponent,
    ReportStbCountComponent,
    ReportProducrSubscriptionStatusComponent,
    ReportMsrComponent,
    ReportPackageAgeingActiveInactiveComponent,
    ReportStbInventoryNewComponent,
    ReportChannelListNewComponent,
    ReportSubscriberListNewComponent,
    ReportHomeNewComponent,
    ReportStbMappingComponent,
  ],
  imports: [
    CommonModule,
    ReportTraiRoutingModule,
    SharedModule,
    FormsModule,
    NgxDaterangepickerMd,
    PluginsModule,
    LcoModule,
    DistributorModule,
  ],
  exports: [],
  providers: [ReportTraiDataService],
})
export class ReportTraiModule {}
