import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateLcoComponent } from 'src/app/lco/create-lco/create-lco.component';
import { AclGroupInfo } from 'src/app/models/acl-group/acl-group-info';
import { UserInfo } from 'src/app/models/user/user-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-create-distributor',
  templateUrl: './create-distributor.component.html',
  styleUrls: ['./create-distributor.component.scss'],
})
export class CreateDistributorComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClientWrapperService,
    private store: StoreService,
    private config: ConfigService
  ) { }

  isEdit = false;
  id;

  newPassword = true;
  confirmPassword = true;
  isPasswordSame = true;
  password;
  updateUserData;
  distributorForm: FormGroup;
  isConfirmPasswordError = false;
  showPasswordField = true;
  showConfirmPasswordField = true;
  location = [];
  isMobileNumberInvalid = false;
  isSelfRegistered = false;
  selfRegisteredUser:UserInfo
  AclGroupList: AclGroupInfo[] = []

  @Output() distributorDetails = new EventEmitter<DistributorType>();
  @Input() distributor!: any;

  passwordStringMatch(): boolean {
    var myRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;
    if (myRegex.test(this.distributorForm.get('password').value)) {
      this.distributorForm.get('password').setErrors(null)
      return true
    } else {
      this.distributorForm.get('password').setErrors({ pattern: false })
      return false
    }
  }

  fetchAclList() {
    const url = this.config.json.apiEndpointRdbms + 'aclgroup/list?userType=distributor';
    this.http.get(url).subscribe(
      data=>{
        this.AclGroupList=data.data
      }
    )
  }

  ngOnInit(): void {
    this.initForm();
    this.locationList();
    this.fetchAclList();
    this.route.params.subscribe((res) => {
      if (res.id!==undefined) {
        this.id = res.id;
        this.updateUser(res.id);
        this.isEdit = true;
        this.distributorForm.get('password').clearValidators();
        this.distributorForm.get('confirmPassword').clearValidators();
        this.distributorForm.get('confirmPassword').updateValueAndValidity();
        this.distributorForm.get('password').updateValueAndValidity();
      }
    })
    const id = this.route.url['_value'][0].path
    console.log(id)
    if(id=='selfcreate'){
      this.isSelfRegistered= true
      this.isEdit = false
      let id1=JSON.parse(localStorage.getItem('userDetails'))
      id1= id1.id
        this.selfRegisteredFlow(id1)
    }
  }

  fetchLocationforSelfRegistered(id){
    let url= this.config.json.apiEndpointRdbms +'leadGeneration/location/'+id
    this.http.get(url).subscribe(
      (data)=>{
        this.distributorForm.get('locationId').setValue(data.data.id)
      }
    )
  }

  selfRegisteredFlow(id){
    let url=this.config.json.apiEndpointRdbms+'leadGeneration/'+id
    this.http.get(url).subscribe(
      (data)=>{
        this.selfRegisteredUser = data.data
        this.isSelfRegistered = true;
        this.updateSelfregisteredForm();
        this.fetchLocationforSelfRegistered(id);
        this.locationList();
        this.disableFormControls();
      }
    )
  }


  updateUser(id) {
    let url = this.config.json.apiEndpointRdbms + 'distributor/' + id;
    this.http.get(url).subscribe({
      next: (res) => {
        this.updateUserData = res.data;
        this.updateForm(res.data);
      },
    });
  }

  get passwordValidity() {
    return this.distributorForm.get('password') as FormControl
  }
  updateSelfregisteredForm(){
    this.distributorForm.get('name').setValue(this.selfRegisteredUser.contactPersonName); 
    this.distributorForm.get('mobileNumber').setValue(this.selfRegisteredUser.mobileNumber);
    this.distributorForm.get('email').setValue(this.selfRegisteredUser.email);
    this.distributorForm.get('address').setValue(this.selfRegisteredUser.address);
    this.distributorForm.get('password').setValue(this.selfRegisteredUser.password);
    this.distributorForm.get('confirmPassword').setValue(this.selfRegisteredUser.password);
  }

  disableFormControls(){
    this.distributorForm.get('name').disable();
    this.distributorForm.get('mobileNumber').disable();
    this.distributorForm.get('email').disable();
    this.distributorForm.get('address').disable();
    this.distributorForm.get('password').disable();
    this.distributorForm.get('confirmPassword').disable(); 
    this.distributorForm.get('locationId').disable();
  }
  enableFormControls(){
    this.distributorForm.get('name').enable();
    this.distributorForm.get('mobileNumber').enable();
    this.distributorForm.get('email').enable();
    this.distributorForm.get('address').enable();
    this.distributorForm.get('password').enable();
    this.distributorForm.get('confirmPassword').enable(); 
    this.distributorForm.get('locationId').enable();
  }
  updateForm(data) {
    this.distributorForm.get('name').patchValue(data.name);
    this.distributorForm.get('mobileNumber').patchValue(data.mobileNumber);
    this.distributorForm.get('email').patchValue(data.email);
    this.distributorForm.get('status').patchValue(data.status);
    this.distributorForm.get('locationId').patchValue(data.locationId);
    this.distributorForm.get('address').patchValue(data.address);
    this.distributorForm.get('percentage').patchValue(data.percentage);
    this.distributorForm.get('password').patchValue(data.password);
    this.distributorForm.get('confirmPassword').patchValue(data.confirmPassword);
    this.distributorForm.get('aclGroupId').setValue(data.aclGroupId);
    this.password = data.password;
  }

  initForm() {
    this.distributorForm = new FormGroup(
      {
        name: new FormControl('', [Validators.required]),
        mobileNumber: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        status: new FormControl('1', [Validators.required]),
        locationId: new FormControl('', [Validators.required]),
        address: new FormControl('', [Validators.required]),
        percentage: new FormControl(0),
        password: new FormControl('', [Validators.required]),
        confirmPassword: new FormControl('', [Validators.required]),
        aclGroupId: new FormControl(''),
      },
      { validators: this.passwordMatchValidator }
    );
    if (this.distributor) {
      this.updateForm(this.distributor);
      // this.updateUser(this.distributor);
    }
  }


  passwordMatchValidator: ValidatorFn = (control: AbstractControl) => {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');
    if (!password || !confirmPassword) {
      return null;
    }
    if (password.touched) {
      console.log(password.value == confirmPassword.value);
      if (password.value == confirmPassword.value) {
        this.isConfirmPasswordError = false;
      } else {
        this.isConfirmPasswordError = true;
      }
    }
    return password.value === confirmPassword.value
      ? null
      : this.isEdit
        ? null
        : { mismatch: true };
  };


  passwordChanges() {
    this.distributorForm
      .get('confirmPassword')
      .valueChanges.subscribe((value: string) => {
        if (
          this.distributorForm.get('password').value ==
          this.distributorForm.get('confirmPassword').value
        ) {
          this.distributorForm.get('password').setErrors({ mismatch: true });
        } else {
          this.distributorForm.get('password').setErrors(null);
        }
      });
    // this.distributorForm.get('confirmPassword').valueChanges.subscribe((value: string) => {
    //   this.distributorForm.get('password').patchValue(value);
    // });
  }

  formCalculated() {
    return this.distributorForm.invalid;
  }


  locationList() {
    this.location =[];
    let fetchLocationList = () => {
      const url = this.config.json.apiEndpointRdbms + 'location/getLocations';
      return this.http.get(url);
    }
    fetchLocationList().subscribe({
      next: (res: any) => {
        this.location = res;
      },
    });
  }


  checkConfirmPassword() {
    let pass = (document.getElementById('password') as HTMLInputElement).value;
    let passC = (document.getElementById('passwordC') as HTMLInputElement)
      .value;

    if (pass === passC) {
      this.isPasswordSame = true;
      this.password = pass;
    } else {
      this.isPasswordSame = false;
    }
  }

  checkMobileNumberFormat(e: Event) {
    let value = (e.target as HTMLInputElement).value;
    let phoneRegex: RegExp = /^[6-9]\d{9}$/;
    if (phoneRegex.test(value)) {
      console.log('pass');
      this.isMobileNumberInvalid = false;
      this.distributorForm.get('mobileNumber').setErrors(null);
    } else {
      this.isMobileNumberInvalid = true;
      this.distributorForm.get('mobileNumber').setErrors({ required: true });
    }
  }

  update() {
    let user = JSON.parse(JSON.stringify(this.distributorForm.value));
    user['password'] = this.updateUserData.password;
    const url = this.config.json.apiEndpointRdbms + 'distributor/update';
    user = {
      ...this.updateUserData,
      ...user,
      percentage: 0,
    };
    console.log(user);
    this.distributorDetails.emit(user);
  }

  getFormData() {
    const url = this.config.json.apiEndpointRdbms + 'distributor/create';
    this.enableFormControls()
    let body = JSON.parse(JSON.stringify(this.distributorForm.value));
    console.log(body)
    if(this.isSelfRegistered==false){
      body['password'] = this.password;
    }

    this.distributorDetails.emit(body);
  }
}

export type DistributorType = {
  name: string;
  mobileNumber: string;
  email: string;
  status: string;
  locationId: string;
  address: string;
  percentage: number;
  password: string;
  aclGroupId:number;
};
