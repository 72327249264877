<div class="cms__content" (click)="clickOutside()">
  <div class="top__heading">
    <h3>{{isEdit?'Edit':'Create'}} Channel</h3>
    <a [routerLink]="['/content/channel/list']"
      ><button type="button" class="default__button float-right">
        Back
      </button></a
    >
  </div>
  <div class="clearfix"></div>
  <form action="" [formGroup]="channelForm" (ngSubmit)="persist()">
    <div class="col-12 form__style">
      <div class="row">
        <div class="col-12 col-md-6 search_bar">
          <label>Name</label>
          <input
            type="text"
            class="custom__field"
            (input)="getName($event)"
            maxlength="50"
            formControlName="name"
            [readonly]="isValueSelected"
          />
          <span
            *ngIf="isValueSelected"
            (click)="channelCleared()"
            id="clearNameField"
            >Clear</span
          >
          <div class="search_result" id="searchResult">
            <ng-container *ngFor="let item of channelList">
              <p (click)="channelClicked(item)">{{item.channel_name}}</p>
            </ng-container>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Number</label>
          <input
            type="number"
            class="custom__field"
            maxlength="10"
            formControlName="number"
            [ngClass]="{'error__field': !channelNumber.valid && channelNumber.touched}"
            min="0"
            
          />
          <div [hidden]="!channelNumber.touched">
            <span class="error__msg" *ngIf="channelNumber.hasError('min')"
              >number should be greater than 0</span
            >
            <span class="error__msg" *ngIf="channelNumber.errors?.duplicate"
              >number is assigned to another channel</span
            >
          </div>
        </div>

        <div class="col-12">
          <label>Description</label>
          <textarea
            class="custom__field"
            formControlName="description"
          ></textarea>
        </div>

        <div class="col-12 col-md-6">
          <label>Unicast HLS URL</label>
          <input
            type="text"
            class="custom__field"
            formControlName="hlsUrl"
            [ngClass]="{'error__field': !hlsUrl.valid && hlsUrl.touched}"
          />
          <div [hidden]="!hlsUrl.touched">
            <span class="error__msg" *ngIf="hlsUrl.hasError('required')"
              >URL is required.</span
            >
            <span class="error__msg" *ngIf="hlsUrl.hasError('pattern')"
              >Link should start with http:// or udp://</span
            >
          </div>
          <div [hidden]="channelForm?.errors?.atLeastOneField">
            <span
              class="error__msg"
              *ngIf="channelForm?.errors?.atLeastOneField"
              >At least one of these Field should be entered</span
            >
          </div>
        </div>
        <div class="col-12 col-md-6">
          <label>Unicast DASH URL</label>
          <input
            type="text"
            class="custom__field"
            formControlName="dashUrl"
            [ngClass]="{'error__field': !dashUrl.valid && dashUrl.touched}"
          />
          <div [hidden]="!dashUrl.touched">
            <span class="error__msg" *ngIf="dashUrl.hasError('required')"
              >URL is required.</span
            >
            <span class="error__msg" *ngIf="dashUrl.hasError('pattern')"
              >Link should start with http:// or udp://</span
            >
          </div>
          <div [hidden]="channelForm?.errors?.atLeastOneField">
            <span
              class="error__msg"
              *ngIf="channelForm?.errors?.atLeastOneField"
              >At least one of these Field should be entered</span
            >
          </div>
        </div>
        <div class="col-12 col-md-6">
          <label>Multicast URL</label>
          <input
            type="text"
            class="custom__field"
            formControlName="multiCastUrl"
            [ngClass]="{'error__field': !multiCastUrl.valid && multiCastUrl.touched}"
          />
          <div [hidden]="!multiCastUrl.touched">
            <span class="error__msg" *ngIf="multiCastUrl.hasError('required')"
              >URL is required.</span
            >
            <span class="error__msg" *ngIf="multiCastUrl.hasError('pattern')"
              >Link should start with http:// or udp://</span
            >
          </div>
          <div [hidden]="channelForm?.errors?.atLeastOneField">
            <span
              class="error__msg"
              *ngIf="channelForm?.errors?.atLeastOneField"
              >At least one of these Field should be entered</span
            >
          </div>
        </div>
<!--
        <div class="col-12 col-md-6">
          <label>Channel ID</label>
          <input
            type="text"
            class="custom__field"
            formControlName="channelId"
            [ngClass]="{'error__field': !channelId.valid && channelId.touched}"
          />
          <div [hidden]="!channelId.touched">
            <span class="error__msg" *ngIf="channelId.errors?.duplicate"
              >ID is assigned to another channel</span
            >
          </div>
        </div> -->

        <div class="col-12 col-md-6">
          <label>Status</label>
          <select class="custom__field" formControlName="status">
            <option  disabled value="null"
              >--Select--</option
            >
            <option value="1" selected >Active</option>
            <option value="0">Inactive</option>
          </select>
        </div>

        <div class="col-12 col-md-6">
          <label>Quality</label>
          <select class="custom__field" formControlName="quality">
            <option [selected]="true" selected="true" value="null"
              >--Select--</option
            >

            <option value="0">SD</option>
            <option value="1">HD</option>
            <option value="2">FHD</option>
          </select>
        </div>

        <div class="col-12 col-md-6">
          <label>Type</label>
          <select class="custom__field" formControlName="type">
            <option [selected]="true" selected="true" value="null" disabled>--Select--</option>
            <option value="0">Free</option>
            <option value="1">Paid</option>
          </select>
        </div>

        <div class="col-12 col-md-6">
          <label class="special__class" *ngIf="type.value==0">Price (per month)</label>
          <label *ngIf="type.value==1">Price (per month)</label>
          <input *ngIf="type.value==0"
            type="number"
            class="custom__field"
            formControlName="price"
            [ngClass]="{'error__field': !price.valid && price.touched}"
             disabled aria-disabled="true"
          />
          <input *ngIf="type.value==1"
            type="number"
            class="custom__field"
            formControlName="price"
            [ngClass]="{'error__field': !price.valid && price.touched}"
          />
          <div [hidden]="!price.touched">
            <span class="error__msg" *ngIf="price.hasError('required')"
              >Please enter price</span
            >
            <span class="error__msg" *ngIf="price.hasError('min')"
              >Should be greater than 0</span
            >
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Language</label>
          <input type="text" autocomplete="off" class="custom__field" formControlName="language" />
        </div>

        <div class="col-12 col-md-6">
          <label>Genre</label>
          <input type="text" autocomplete="off" class="custom__field" formControlName="genre" />
        </div>

        <div class="col-12 col-md-6">
          <label>Protocol</label>
          <input type="text" autocomplete="off" class="custom__field" formControlName="protocol" />
        </div>

        <div class="col-12 col-md-6">
          <label>Broadcaster</label>
          <select class="custom__field" formControlName="broadcasterId">
            <option [selected]="true" selected value="null">--Select--</option>
            <option *ngFor="let bc of broadcasterList" [ngValue]="bc.id"
              >{{bc.name}}</option
            >
          </select>
        </div>
<!--
        <div class="col-12">
          <label>Categories</label>
          <ng-multiselect-dropdown
            [placeholder]="'select categories'"
            [settings]="dropdownSettings"
            [data]="dropdownCategoryList"
            [(ngModel)]="selectedCategoryList"
            [ngModelOptions]="{standalone: true}"
            class="custom__field"
          >
          </ng-multiselect-dropdown>
        </div> -->

        <div class="col-12">
          <label>Packages</label>
          <ng-multiselect-dropdown
            [placeholder]="'select packages'"
            [settings]="dropdownSettings"
            [data]="dropdownPackageList"
            [(ngModel)]="selectedPackageList"
            [ngModelOptions]="{standalone: true}"
            class="custom__field"
          >
          </ng-multiselect-dropdown>
        </div>

        <!-- <div class="col-12">
          <div class="col-12 transcoder__settings">
            <div class="row">
              <div class="col-12 col-md-6">
                <label class="not__required">Enable Transcoder</label>
                <label class="switch">
                  <input type="checkbox" [(ngModel)]="isTrancoderActive" [ngModelOptions]="{standalone: true}">
                  <span class="slider round"></span>
                </label><br />
                <span *ngIf="isTrancoderActive" class="safe__label">Active</span>
              </div>
              <div class="col-12 col-md-6">
                <span *ngIf="!isTrancoderActive" class="danger__label">Inactive</span>
                <label *ngIf="isTrancoderActive">Transcoder Profiles</label>
                <select *ngIf="isTrancoderActive" class="custom__field" [ngModel]="channel.transcoderProfileId"
                  (ngModelChange)="onProfileChange($event)" [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let profile of profileList" [ngValue]="profile.id">{{profile.name}}</option>
                </select>
              </div>
            </div>
            <div *ngIf="isTrancoderActive" class="col-12 transcoder__settings__inner">
              <label class="not__required">Transcoder URLs</label>
              <div class="overflow__url">
                <ul>
                  <li *ngFor="let preset of selectedProfile.presets">{{preset.name}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="col-12">
          <label>Logo</label>
          <input (change)="onLogoPicked($event)" type="file" class="custom__field" />
          <span class="error__msg" *ngIf="!channel.logo && !imageObj">Please set a logo</span>
          <img *ngIf="channel.logo" src={{channel.logo}} alt="Channel Logo" class="image--logo">
        </div> -->

        <!-- <div class="col-12 col-md-6 catSel">
          <label>Category</label>
          <input *ngIf="!isEdit" type="text" class="custom__field" disabled value="Unassigned" />
          <label *ngIf="!isEdit" class="info__label">Assign/Edit channel category in Category section</label>
          <span class="info__span" *ngIf="isEdit">To view/change Channel category, please visit category section</span>
        </div> -->

        <div class="col-12">
          <!-- <button type="button" [routerLink]="['/channel/list']" class="default__button">Cancel</button> -->
          <button class="cta float-right" [disabled]="channelForm.invalid||!isChannelSelected || saveClicked ">
            Save
          </button>
        <div style="display : flex; gap : 20px;" >
          <p style="font-weight: bold;" >Alacarte-Pack</p>
          <label class="switch not__required" >
            <input type="checkbox" id="alacartePack" >
            <span class=" slider round"></span>
          </label>
        </div>
        </div>
      </div>
    </div>
  </form>
</div>
