<div class="cms__content">
  <div class="top__heading sticky">
    <h3>Wallet Management</h3>
  </div>

  <div class="col-12 list__summary">
    <div class="row">
      <!-- Actual cards for category -->
      <div class="col-12">
        <div class="row">
          <div class="col-6 col-md-3" *msoAccess="">
            <a [routerLink]="['add-lco-funds']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">Wallet</label>
                <div class="card__txt">Add LCO Funds</div>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-3" *msoAccess="">
            <a [routerLink]="['add-distributor-funds']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">Wallet</label>
                <div class="card__txt">Add Distributor Funds</div>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-3" *distributorAccess>
            <a [routerLink]="['add-lco-funds']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">Wallet</label>
                <div class="card__txt">Add LCO Funds</div>
              </div>
            </a>
          </div>

          <!-- <div class="col-6 col-md-3" *msoAccess="">
            <a [routerLink]="['receive-lco-funds']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">Wallet</label>
                <div class="card__txt">Receive LCO Funds</div>
              </div>
            </a>
          </div> -->

          <!-- <div class="col-6 col-md-3" *distributorAccess>
            <a [routerLink]="['add-subscriber-funds']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">Wallet</label>
                <div class="card__txt">Add Subscriber Funds</div>
              </div>
            </a>
          </div> -->

          <div class="col-6 col-md-3" *ngIf="!isNotMSO" >
            <a [routerLink]="['transaction-history']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">Wallet</label>
                <div class="card__txt"> Online Transaction History</div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3" *lcoAccess>
            <a [routerLink]="['lco-wallet-recharge']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">Wallet</label>
                <div class="card__txt">Wallet Recharge</div>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-3" *distributorAccess="">
            <a [routerLink]="['/profile/wallet/distributor-wallet-recharge']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">Wallet</label>
                <div class="card__txt">Wallet Recharge</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
