<div class="cms__content">
  <app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>MSR Report</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>
  <div class="row row-top">
    <div class="date_filter">
      <div class="div_tabs" *ngIf="dateSelector">
        <button (click)="dateTypeSelectors(1)" >Month</button>
        <button (click)="dateTypeSelectors(2)" >Random Date</button>
      </div>
      <div class="div_tabs2" *ngIf="showMonth" >
       <div class="date_box">
        <label for="month">Select Month:</label>
        <div class="date_box_orig">
          <input class="custom__field mar_zeroed" type="month" id="month"  value="selectedDate" (change)="monthType($event)">
        </div>
       </div>
        <button (click)="dateTypeSelectors(0)" >X</button>
      </div>
      <div class="div_tabs2" *ngIf="showDateRange" >
        <label for="dateRange">
          Select Date :
        </label>
        <div class="date_box_orig">
          <app-date-picker id="mar_zeroeds" (dateChange)="dateChange($event)" ></app-date-picker>
        </div>
        <button id="app_date_pick_button" (click)="dateTypeSelectors(0)" >X</button>
      </div>
    </div>
      <div class="col-6 col-md-3 report_selection_block"></div>
      <div class="col-6 col-md-3 report_selection_block"></div>
      <div  class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
          <label class="report_selection_label"> State: </label>
          <select class="custom__field report_selection" [(ngModel)]="statusValue" (change)='fetchData()'>
            <option value="All">ALL</option>
              <option *ngFor="let item of stateList" value="{{item}}">{{item}}</option>
            </select>
      </div>

      <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
          <label class="report_selection_label"> Distributor: </label>
              <select class="custom__field report_selection" [(ngModel)]="selectedDistributorId" (change)='fetchData()'>
                <option value="0">ALL</option>
                <option *ngFor="let distributor of distributorList" [ngValue]="distributor.id">
                    {{ distributor.name }}
                </option>
              </select>
        </div>
        <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
          <label class="report_selection_label"> Broadcaster: </label>
              <select class="custom__field report_selection" [(ngModel)]="selectedBroadcasterId" (change)='fetchData()'>
                <option value="0">ALL</option>
                <option *ngFor="let broadcaster of broadcasterList" [ngValue]="broadcaster.id">
                    {{ broadcaster.name }}
                </option>
              </select>
        </div>
  
      <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
        <label class="report_selection_label"> Lco: </label>
            <select class="custom__field report_selection" [(ngModel)]="selectedLcoId" (change)='fetchData()'>
              <option value="0">ALL</option>
              <option *ngFor="let lco of lcoList" [ngValue]="lco.id">
                  {{ lco.name }}
              </option>
            </select>
      </div>

      <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
        <label class="report_selection_label" style="margin-right: 1%;"> Bouquet Type: </label>
        <select class="custom__field report_selection" [(ngModel)]="selectedBouquetType" (change)='fetchData()'>
            <option value = "0" >ALL</option>
            <option value="BASE_PACK">Base Pack</option>
            <option value="ADD_ON_PACK">Add-On Pack</option>
            <option value="ALACARETE_PACK">Alacarte Pack</option>
        </select>
      </div>

      
    </div>
  
    <!-- <div class="col-12 report">
      <p>Report generated on : {{ currentDate | date : "longDate" }}</p>
    </div> -->


  <!-- <div class="filter-download_container">
    <div class="filter_container">
      <div class="label-container">
        <label for="">Select Date :</label>
      </div>
      <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
      <div class="div label-container">
        <label class="report_selection_label">Status:</label>
      </div>
      <select class="custom__field report_selection" id="statusChangeApi"  [(ngModel)]="statusValue" (change)="fetchData()" >
        <option value="All">ALL</option>
        <option value="Active">Active</option>
        <option value="Inactive">Inactive</option>
        <option value="Terminated">Terminated</option>
        <option value="Suspended">Suspended</option>
        <option value="Blacklisted">Blacklisted</option>
      </select>
    </div>
  </div> -->

  <div class="row"> 
    <div class="col-md-6 report-generated-fix">
      <p>
        Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
      </p>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
        CSV
        <img src="./assets/csv.svg" alt="download" /> </button>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row overflow">
      <div class="custom__table__filter">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <!-- -->
        <div class="col-12 col-md-6 table-search">
          <div class="table__right">
            <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> record{{reportList.length == 1?
              '' : 's'}}</p>
            <!-- <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}} -->
            <!-- </p> -->
          </div>
          <div class="search__container">
            <input type="text" class="custom__field" id="searchKeyword" (keydown.enter)="onSearch()" [(ngModel)]="paginationObj.searchKeyword" />
            <button (click)="onSearch()" (keydown.enter)="onSearch()">
              <img src="./assets/search.png" alt="search" />
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="row scroll "> -->
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <!-- <th>Subscriber ID</th> -->
            <th>Sl No.</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.bouquet_id)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.bouquet_id}">Bouquet Id</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.bouquetName)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.bouquetName}">Bouquet Name</th>
 
            <th class="label__align" (click)="sortByItem(sortByProperties.duration)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.duration}">Duration</th>
            <th>Channels List</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.subscriberCountAt7)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.subscriberCountAt7}">Subscriber Count at 7</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.subscriberCountAt14)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.subscriberCountAt14}">Subscriber Count at 14</th>  
            <th class="label__align" (click)="sortByItem(sortByProperties.subscriberCountAt21)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.subscriberCountAt21}">Subscriber Count at 21</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.subscriberCountAt28)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.subscriberCountAt28}">Subscriber Count at 28</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.subscriberCountAtEndOfTheMonth)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.subscriberCountAtEndOfTheMonth}">Average Subscriptions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList?.length ==0 && !loadingFlag">
            <td style="text-align: center !important;" colspan="10">{{isLoad}}</td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important;" colspan="10">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <ng-template #showList>
            <tr *ngFor="let report of reportList, let j = index">
              <!-- <td>{{report.subscriber_id}}</td> -->
              <td>{{j+1}}</td>
              <td>{{report.bouquet_id == 'null' || report.bouquet_id == null || (report.bouquet_id ||
                report.bouquet_id) == '' ? '---' : (report.bouquet_id || report.bouquet_id)}}</td>
              <td>{{(report.bouquetName || report.bouquetName ) == 'null' || (report.bouquetName ||
                report.bouquetName ) == '' || (report.bouquetName || report.bouquetName ) == null ? '---' :
                (report.bouquetName || report.bouquetName )}}</td>
                <td>{{report.duration  == null? '---' :report.duration }}</td>
              <td>{{(report.channelList|| report.channelList) == 'null' || (report.channelList || report.channelList) == '' ||
                (report.channelList || report.channelList) == null ? '---' : (report.channelList || report.channelList)}}</td>
             
                <td>{{report.subscriberCountAt7== null ? '---' : report.subscriberCountAt7}}</td>
                <td>{{report.subscriberCountAt14== null ? '---' : report.subscriberCountAt14}}</td>  
                <td>{{report.subscriberCountAt21== null ? '---' : report.subscriberCountAt21}}</td>
                <td>{{report.subscriberCountAt28== null ? '---' : report.subscriberCountAt28}}</td>  
                <td>{{report.subscriberCountAtEndOfTheMonth== null ? '---' : report.subscriberCountAtEndOfTheMonth}}</td>

            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>