<div class="cms__content">
    <div class="top__heading">
      <h3>Lead Subscriber</h3>
      <a [routerLink]="['/manage/lead/create']"><button class="default__button float-right" *ngIf="menuAccess">+ Add New</button></a>
    </div>
  
    <div class="row row-top">
      <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
        <label class="report_selection_label"> LCO: </label>
        <select class="custom__field report_selection" [(ngModel)]="lcoId" (change)='fetchPage()'>
          <option value="0">ALL</option>
          <option *ngFor="let lco of lcoList" [ngValue]="lco.id">
              {{ lco.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-12 list__table">
      <div class="row overflow">
        <div class="custom__table__filter">
          <span>
            Show
            <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
              <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">
                {{ size }}
              </option>
            </select>
            Entries
          </span>
          <div class="table__right">
            <p class="table__summary">
              Total <span>{{ paginationObj.totalElements }}</span> records
            </p>
            <div class="search__container">
              <input type="text" autocomplete="off" class="custom__field" [(ngModel)]="paginationObj.searchKeyword"
                (keyup.enter)="$event.preventDefault(); onSearch()" />
              <button (click)="onSearch()">
                <img src="./assets/search.png" alt="search" />
              </button>
            </div>
          </div>
        </div>
        <div class="tableBox">
        <table class="custom__iptv__table">
          <thead>
            <tr>
              <th>Sl No.</th>
              <th (click)="sortByItem(sortByProperties.firstName)" [ngClass]="{
                  selected: paginationObj.sortBy === sortByProperties.firstName
                }">
                Name
              </th>
              <th (click)="sortByItem(sortByProperties.email)" [ngClass]="{
                  selected: paginationObj.sortBy === sortByProperties.email
                }">
                Email
              </th>
              <th (click)="sortByItem(sortByProperties.mobile)" [ngClass]="{
                  selected: paginationObj.sortBy === sortByProperties.mobile
                }">
               Mobile
              </th>
              <th (click)="sortByItem(sortByProperties.mobile)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.mobile
              }">
             Gender
            </th>
              <!-- <th (click)="sortByItem(sortByProperties.tp)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.tp}">
                Transfer Price (%)
              </th> -->
              <th (click)="sortByItem(sortByProperties.installationAddress)" [ngClass]="{
                  selected: paginationObj.sortBy === sortByProperties.installationAddress
                }">
                Installation Address
              </th>
              <th (click)="sortByItem(sortByProperties.billingAddress)" [ngClass]="{
                  selected: paginationObj.sortBy === sortByProperties.billingAddress
                }">
                Billing Address
              </th>
              <th class="label__align" (click)="sortByItem(sortByProperties.stateCode)" [ngClass]="{
                  selected: paginationObj.sortBy === sortByProperties.stateCode
                }">
                State
              </th>
              <th class="label__align" (click)="sortByItem(sortByProperties.districtCode)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.districtCode
              }">
              District
            </th>
            <th class="label__align" (click)="sortByItem(sortByProperties.districtCode)" [ngClass]="{
              selected: paginationObj.sortBy === sortByProperties.districtCode
            }">
            Pin Code
          </th>
          <th class="label__align" (click)="sortByItem(sortByProperties.districtCode)" [ngClass]="{
            selected: paginationObj.sortBy === sortByProperties.districtCode
          }">
          Duration
        </th>
        <th class="label__align" (click)="sortByItem(sortByProperties.districtCode)" [ngClass]="{
          selected: paginationObj.sortBy === sortByProperties.districtCode
        }">
        Lco Id
      </th>
      <th class="label__align" (click)="sortByItem(sortByProperties.districtCode)" [ngClass]="{
        selected: paginationObj.sortBy === sortByProperties.districtCode
      }">
      Bouquet Id
    </th>
              <th>
                Created On
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="loadingFlag; else showList">
              <td class="loading" colspan="9">
                <app-custom-spinner></app-custom-spinner>
              </td>
            </tr>
            <tr *ngIf="!loadingFlag && leadList.length===0">
              <td style="text-align: center; " colspan="9">
                No Data
              </td>
            </tr>
            <ng-template #showList>
              <tr *ngFor="let lead of leadList; let j = index">
                <td>{{ (pageNumber - 1) * pageSize + (j + 1) }}</td>
                <td>{{ lead.firstName ? lead.firstName+' '+lead.lastName : "---" }}</td>
                <td>{{ lead.email ? lead.email : "---" }}</td>
                <td>{{lead.mobile? lead.mobile : '---'}}</td>
                <td>{{lead.gender==1?'Female':'Male'}}</td>
                <td>{{ lead.installationAddress ? lead.installationAddress : "---" }}</td>
                <td>{{ lead.billingAddress ? lead.billingAddress : "---" }}</td>
                <td>{{ lead.stateCode?lead.stateCode:"---"}}</td>
                <td>{{ lead.districtCode?lead.stateCode:"---"}}</td>
                <td>{{lead.pincode?lead.pincode:"---"}}</td>
                <td>{{lead.duration?lead.duration:"---"}}</td>
                <td>{{lead.lcoId?lead.lcoId:"---"}}</td>
                <td>{{lead.bouquetId?lead.bouquetId:"---"}}</td>
                <td>
                  {{ lead.createdDate | date : "dd-MM-yyyy HH:mm:ss" }}
                  <div class="action__items">
                    <button class="cta" *ngIf="lead.subscriber==false" (click)="createLead(lead.id)">Create</button>
                    <!-- <button *ngIf="!isDistributor && menuAccess==true" (click)="edit(lead)">Edit</button>
                    <ng-container *ngIf="menuAccess==true"><button *msoAccess="true"  (click)="login(lead)">
                      Login
                    </button></ng-container> -->
                    <!-- <button *msoAccess="true" (click)="SetBouquet(admin)">
                      Set Bouquet
                    </button> -->
                    <!-- <button class="danger"
                      (click)="updateStatus(admin)">{{admin.status===1?'Deactivate':'Activate'}}</button> -->
                  </div>
                </td>
              </tr>

            </ng-template>
          </tbody>
        </table>
        </div>
        <div class="custom__pagination">
          <ul>
            <li>
              <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
                <img src="./assets/firstPage.png" />
              </button>
            </li>
            <li>
              <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
                <img src="./assets/previousPage.png" />
              </button>
            </li>
            <li>
              <span>Page {{ paginationObj.pageNumber }} of
                {{ paginationObj.totalPages }}</span>
            </li>
            <li>
              <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
                <img src="./assets/nextPage.png" />
              </button>
            </li>
            <li>
              <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
                <img src="./assets/lastPage.png" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
