<div class="cms__content">
	<app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>STB Blacklisted List Report</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <div class="filter__group col-12 col-md-6">
      <label for="month">Till Date</label>
        <input class="custom__field" type="date" id="month"  value="selectedDate" (change)="dateChangeTillDate($event)">
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
        CSV
        <img src="./assets/csv.svg" alt="download" />      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 report-generated-fix">
      <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
    </div>
    <div class="col-12 col-md-6 table-search">
      <div class="table__right" style="gap: 10px;" >
        <p class="table__summary" style="margin: 0;" >Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
        <div class="search__container">
          <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
          <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Subscriber ID</th>
            <th>Subscriber Code</th>
            <th>Subscriber Name</th>
            <th>Partner Name</th>
            <th>STB MAC</th>
            <th>Blacklisted Date</th>
          </tr>
        </thead>
        <tbody>

         <tr *ngIf="reportList.length ==0 && isLoading" >
          <td colspan="7" style="text-align: center;" >{{isLoad}}</td>
         </tr>
         <tr *ngIf="reportList.length ==0 && !isLoading" >
          <td colspan="7" style="text-align: center;" ><app-custom-spinner></app-custom-spinner></td>
         </tr>
            <tr *ngFor="let report of reportList, let j = index">
              <td>{{j+1}}</td>
              <td>{{report.subscriberId?report.subscriberId:'---'}}</td>
              <td>{{(report.subscriberCode || report.subscriber_code) == null || (report.subscriberCode || report.subscriber_code) == 'null' || (report.subscriberCode || report.subscriber_code) == '' ? '---' : (report.subscriberCode || report.subscriber_code)}}</td>
              <td>{{(report.subscriberName || report.subscriber_name) == null || (report.subscriberName || report.subscriber_name) == 'null' || (report.subscriberName || report.subscriber_name) == ''? '---' : (report.subscriberName || report.subscriber_name)}}</td>
              <td>{{(report.lcoName || report.lco_name) == null || (report.lcoName || report.lco_name) == 'null' || (report.lcoName || report.lco_name) == '' ? '---' : (report.lcoName || report.lco_name)}}</td>
              <td>{{(report.ethernetMacAddress || report.ethernet_mac_address || report.ethernetMac || report.ethernet_mac) == null || (report.ethernetMacAddress || report.ethernet_mac_address || report.ethernetMac || report.ethernet_mac) == 'null' || (report.ethernetMacAddress || report.ethernet_mac_address || report.ethernetMac || report.ethernet_mac) =='' ? '---' : (report.ethernetMacAddress || report.ethernet_mac_address || report.ethernetMac || report.ethernet_mac) }}</td>
              <td>{{(report.blacklistedDate || report.blacklisted_date) == null || (report.blacklistedDate || report.blacklisted_date) =='null' || (report.blacklistedDate || report.blacklisted_date) == ''? '---' : (report.blacklistedDate || report.blacklisted_date)  | date: 'dd-MM-yy HH:mm:ss':'UTC'}}</td>
            </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
