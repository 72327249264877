<div class="cms__content">
  <div class="top__heading">
    <h3>Transaction {{isInLogs ? 'Logs' : 'History'}}</h3>
    <a [routerLink]="[isInLogs ? '/logs' : '/accounts/wallet/']"><button
        class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <div class="col-md-6" *ngIf="user==true">
      <label for="selectUserType">Select User Type</label>
      <select class="custom__field" name="selectUserType" id="selectUserType" (change)="selectUserType($event)">
        <option value="All">All</option>
        <option value="lco">LCO</option>
        <option value="distributor">Distributor</option>
        <option value="user">User</option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6" *ngIf="userType!='All'">
      <label for="" *ngIf="userType=='lco'">Search LCO</label>
      <label for="" *ngIf="userType=='user'">Search User</label>
      <label for="" *ngIf="userType=='distributor'">Search Distributor</label>
      <input type="text" class="custom__field" id="lcoId" (input)="getSearchText($event)">
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 overflow" *ngIf="isSearch && userType=='lco'" style="margin-top: 10px">
      <app-lco-search [searchText]="searchText" (userDetail)="getLCOId($event)"></app-lco-search>
    </div>
    <div class="col-md-6 overflow" *ngIf="isSearch && userType=='distributor'" style="margin-top: 10px">
      <app-distributor-search  [searchText]="searchText" (userDetail)="getLCOId($event)" > </app-distributor-search>
    </div>
    <div class="col-md-6 overflow" *ngIf="isSearch && userType=='user'" style="margin-top: 10px">
      <app-subscriber-search [searchText]="searchText" (userDetail)="getLCOId($event)"></app-subscriber-search>
    </div>
  </div>

  <div class="row">
    <div class="filter__group col-12 col-md-6">
      <label id="select_date">Select Date :</label>
      <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
    </div>
    <div class="download__report col-md-6" >
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download Excel">
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>
  </div>
  <div class="custom__table__filter">
    <span>
      Show
      <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
        <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">
          {{ size }}
        </option>
      </select>
      Entries
    </span>
    <div class="table__right">
      <p class="table__summary">
        Total <span>{{ paginationObj.totalElements }}</span> records
      </p>
      <div class="search__container">
        <input type="text" autocomplete="off" class="custom__field" id="searchKeyword"
          [(ngModel)]="paginationObj.searchKeyword" (keyup.enter)="searchKeyWord()" />
        <button (click)="searchKeyWord()">
          <img src="./assets/search.png" alt="search" />
        </button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <ng-container *ngIf="isInLogs">
              <th>Code</th>
              <th>Remarks</th>
              <th>Type</th>
              <th>Transaction ID</th>
              <th>Amount</th>
              <th>Opening Balance</th>
              <th>Closing Balance</th>
            </ng-container>
            <ng-container *ngIf="!isInLogs">
              <th>User Code</th>
              <th>User Type</th>
              <th>Name</th>
              <th>Mobile Number</th>
              <th>Email</th>
              <th>Amount</th>
              <th>Payment Mode</th>
              <th>Transaction ID</th>
              <th>Easepay ID</th>
              <th>Bank Reference ID</th>
              <th>Status</th>
            </ng-container>
            <th>Date</th>
            <th *ngIf="!isInLogs">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList?.length == 0 && !loadingFlag">
            <td style="text-align: center !important" colspan="14">
              {{ isLoad }}
            </td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important" colspan="14">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <ng-template #showList>
            <tr *ngFor="let report of reportList; let j = index">
              <td>
                {{
                (paginationObj.pageNumber - 1) * paginationObj.pageSize +
                (j + 1)
                }}
              </td>
              <ng-container *ngIf="isInLogs">
                <td>{{ report.subscriberCode }}</td>
                <td>{{ report.remarks }}</td>
                <td>{{ report.trnType }}</td>
                <td>{{ report.slno }}</td>
                <td>{{ report.amount |currency:'INR'}}</td>
                <td>{{ report.openingBalance?(report.openingBalance|currency:'INR'):'---'}}</td>
                <td>{{ report.closingBalance?(report.closingBalance|currency:'INR'):'---'}}</td>
                <td>{{ report.datee }}</td>
              </ng-container>
              <ng-container *ngIf="!isInLogs">
                <td>{{ report.userCode? report.userCode : '--' }}</td>
                <td>{{ report.userType ? report.userType : '--' }}</td>
                <!-- <td>{{ report.name ? report.name : '--' }}</td> -->
                <td>{{(report.name || report.NAME) == 'null' || (report.name || report.NAME) == null || (report.name ||
                  report.NAME) == '' ? '---' : (report.name || report.NAME)}}</td>

                <td>{{ report.mobileNumber ? report.mobileNumber : '--' }}</td>
                <td>{{ report.email ? report.email : '--' }}</td>
                <td>{{ report.amount ? report.amount : '--' }}</td>
                <td>{{ report.paymentMode ? report.paymentMode : '--' }}</td>
                <td>{{ report.transactionId ? report.transactionId : '--' }}</td>
                <td>{{ report.easePayId ? report.easePayId : '--' }}</td>
                <td>{{ report.bankReferenceNumber ? report.bankReferenceNumber : '--' }}</td>
                <td>{{ report.paymentStatus ? report.paymentStatus: '--' }}</td>
                <td>{{ report.createdDate ? report.createdDate : '--' }}</td>
              </ng-container>
              <td *ngIf="!isInLogs">
                <button class="recheck" (click)="recheck(report)">
                  <span class="material-symbols-rounded"> refresh </span>Recheck
                </button>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>

      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disablePreviousBtn" (click)="pageDecrease()">
              <img src="./assets/previousPage.png" />
            </button>
          </li>
          <li>
            <span>Page {{ paginationObj.pageNumber }} of
              {{ paginationObj.totalPages }}</span>
          </li>
          <li>
            <button [disabled]="disableNextBtn" (click)="pageIncrease()">
              <img src="./assets/nextPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>