import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BroadcasterInfo } from 'src/app/models/broadcaster/broadcaster-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-report-broadcaster-week-wise',
  templateUrl: './report-broadcaster-week-wise.component.html',
  styleUrls: ['./report-broadcaster-week-wise.component.scss'],
})
export class ReportBroadcasterWeekWiseComponent implements OnInit {
  reportList = [];
  bouquetType = 0;
  broadcasterId: number = 0;
  dateChoosed = false;

  broadcasterList: BroadcasterInfo[] = [];

  selectedDate: any = new Date();

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config: ConfigService,
    private http: HttpClientWrapperService
  ) { }

  ngOnInit(): void {
    this.selectedDate = this.selectedDate.toISOString().split('T')[0];
    this.selectedDate = this.selectedDate.substring(0, 7);
    this.fetchLocationList();
    //// console.log(this.reportList)
    let today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    let body = {
      'fromDate': '2018-01-01',
      'toDate': formattedDate,
      'broadcasterId': 0,
      'searchKeyword': ''
    };
    this.fetchData(body)
  }

  fetchLocationList(): void {
    this.report.getBroadcasterList().subscribe((data) => {
      this.broadcasterList = data;
      this.broadcasterId = this.broadcasterList[0].id;
      // this.fetchData()
    });
  }

  resetReportList(){
    this.reportList = [];
    this.dateChoosed = false;
    this.isLoading = false
}

  showMonth = false;
  showDateRange = false;
  dateSelector = true;
  tableDisplay = true;
  dateTypeSelectors(num) {
    if (num == 0) {
      this.dateSelector = true;
      this.showMonth = false;
      this.showDateRange = false;
      this.tableDisplay = true;
      this.resetReportList()
    }
    if (num == 1) {
      this.dateSelector = false;
      this.showMonth = true;
      this.showDateRange = false;
      this.tableDisplay = true;
    }
    if (num == 2) {
      this.dateSelector = false;
      this.showDateRange = true;
      this.showMonth = false;
      this.tableDisplay = false;
    }
  }

  isDateSelected = false;
  body = {};
  eve;
  reportDate = 'From Start to Now';
  dateChangeRange(ev) {
    this.dateChoosed = true;
    this.body = {};
    this.reportList = [];
    this.isDateSelected = true;
    if (this.isBroadcasterSelected) {
      this.eve = ev;
      let startDate = ev.startDate.format('YYYY-MM-DD');
      let endDate = ev.startDate.format('YYYY-MM-DD');
      this.body['fromDate'] = startDate;
      this.body['toDate'] = endDate;
      this.body['broadcasterId'] = this.broadcasterId;
      this.reportDate =
        ev.startDate.format('DD-MM-YYYY') +
        ' to ' +
        ev.endDate.format('DD-MM-YYYY');
      this.fetchData(this.body);
    } else {
      this.isLoad = 'Select broadcaster';
    }
  }

  dateChangeMonth(ev) {
    this.dateChoosed = true;
    this.body = {};
    this.reportList = [];
    this.isDateSelected = true;

    // // console.log(ev.target.value)
    if (this.isBroadcasterSelected) {
      // // console.log(this.broadcasterId)
      this.selectedDate = this.body['monthYear'] = ev.target.value + '-01';
      let dateObj = new Date(this.selectedDate);
      this.reportDate =
        dateObj.getDate() +
        '/' +
        this.doc.monthNameCalculator(dateObj.getMonth()) +
        '/' +
        dateObj.getFullYear();
      this.body['broadcasterId'] = this.broadcasterId;
      // // console.log(this.body)
      this.fetchData(this.body);
      this.isLoading = true;
    } else {
      this.isLoad = 'Select Date';
    }
  }

  isBroadcasterSelected = true;
  broadcasterSelected() {
    // this.isBroadcasterSelected=true
    this.reportList = [];
    if (this.isDateSelected) {
      // // console.log(this.body);
      this.fetchData(this.body);
      this.isLoading = true;
    } else {
      this.isLoad = 'Select Date';
    }
  }

  isLoad = 'Loading';
  isLoading = false;
  fetchData(body?: any): void {
    // // console.log(body)
    this.broadCasterNameSetter(this.broadcasterId);
    this.isLoading = true;
    this.reportList = [];
    body['searchKeyword'] = this.searchWord ? this.searchWord : '';
    this.body['broadcasterId'] = this.broadcasterId
    this.report
      .getBroadcasterWiseChannelsWeekwiseReport(body)
      .subscribe((data) => {
        this.reportList = data.data;
        this.isLoading = false;
        data.data.length ? null : this.isLoad = 'No records'
      });
    // if(this.broadcasterId == undefined){
    //   this.isLoad = 'Choose Broadcaster'
    // }
    // if(this.selectedDate == undefined){
    //   this.isLoad = 'Choose Date'
    // }
    // //// console.log(this.broadcasterId, this.selectedDate)
    // if (this.selectedDate && this.broadcasterId) {
    //   const body = {
    //     "monthYear": this.selectedDate + '-01',
    //     broadcasterId: this.broadcasterId,
    //     "productType" : 0
    //   }
    //   this.isLoading = true
    //   // this.report.getBroadcasterWiseChannelsReportMonthly(body).subscribe(data => {
    //   this.report.getBroadcasterWiseChannelsWeekwiseReport(body).subscribe(data => {
    //     //// console.log(data)
    //     this.reportList = data.data;
    //     this.isLoading = false
    //   });
    // }
  }

  broadCasterName;
  broadCasterNameSetter(broadcasterId) {
    for (let ele of this.broadcasterList) {
      if (ele.id == broadcasterId) {
        this.broadCasterName = ele.name;
      }
    }
    broadcasterId == 0
      ? (this.broadCasterName = 'All')
      : (this.broadCasterName = this.broadCasterName);
  }

  downloadTableExcel(): void {
    let name = 'Broadcaster Bouquet Wise Weekly Report';
    this.doc.exportTableToExcelwithImage(
      this.reportTable,
      name,
      'broadcaster_week_wise_report',
      undefined,
      undefined,
      'Report Month :',
      this.reportDate,
      'Broadcaster :',
      this.broadCasterName
    );
  }

  downloadTablePdf(): void {
    const tempDate = new Date(this.selectedDate);
    const monthYear =
      tempDate.toLocaleString('default', { month: 'long' }) +
      ' ' +
      tempDate.getFullYear();
    this.pdf.generatePdfHtml(
      '#reportTable',
      'Broadcaster_Bouquet_Wise_Weekly_Report',
      `Month: ${this.reportDate ? this.reportDate : monthYear},  Source Type : SMS`
    );
  }
  // Broadcaster ID: ${this.broadcasterId}

  downloadTableCSV(): void {
    const monthHeaders = [
      'Sl No',
      'Bouquet ID',
      'Bouquet Name',
      'Channels',
      'Subscriptions on 7th',
      'Subscriptions on 14th',
      'Subscriptions on 21st',
      'Subscriptions on 28th',
      'Average Subscription',
    ];
    const randomHeaders = [
      'Sl No',
      'Bouquet Name',
      'Channels',
      'Subscriber Count',
    ];

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      // headers: ["Sl No","Bouquet Name","Channels","Subscriptions on 7th","Subscriptions on 14th","Subscriptions on 21st","Subscriptions on 28th","Average Subscription"]
      headers: this.tableDisplay ? monthHeaders : randomHeaders,
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLineMonth = {
        slno: i,
        bouquet_id:line.bouquet_id,
        bouquet_name: line.bouquet_name,
        channel_names_list: line.channel_names_list,
        subscriberCountAt7: line.subscriberCountAt7,
        subscriberCountAt14: line.subscriberCountAt14,
        subscriberCountAt21: line.subscriberCountAt21,
        subscriberCountAt28: line.subscriberCountAt28,
        averageSubscription:
          (line.subscriberCountAt7 +
            line.subscriberCountAt14 +
            line.subscriberCountAt21 +
            line.subscriberCountAt28) /
          4,
      };

      let csvLineRandom = {
        slno: i,
        bouquet_name: line.bouquet_name,
        channel_names_list: line.channel_names_list,
        subscriberCount: line.subscriberCount,
      };

      let csvLine = this.tableDisplay ? csvLineMonth : csvLineRandom;

      i += 1;
      data.push(csvLine);
    });

    new ngxCsv(data, 'Broadcaster_Bouquet_Wise_Weekly_Report', options);
  }

  searchWord
  searchKeyword() {
    // // console.log(this.selectedDate);
    let value = (document.getElementById('searchKeyword') as HTMLInputElement)
      .value;
    this.searchWord = value
    let url =
      this.config.json.apiEndpointRdbms +
      'reports/getBroadcasterWiseChannelsWeekwiseReport';
    let body = {};
    if (this.showDateRange) {
      body = {
        fromDate: this.eve.startDate.format('YYYY-MM-DD'),
        toDate: this.eve.endDate.format('YYYY-MM-DD'),
        searchKeyword: value,
        broadcasterId: +this.broadcasterId,
      };
    }
    if (this.showMonth) {
      body = {
        monthYear: this.selectedDate,
        searchKeyword: value,
        broadcasterId: +this.broadcasterId,
      };
    }
    if(!this.showMonth&&!this.showDateRange){
      let day = new Date()
      let toDate = `${day.getFullYear()}-${String(day.getMonth()+1).padStart(2,'0')}-${String(day.getDate()).padStart(2,'0')}`
      body = {
        fromDate: '2018-01-01',
        toDate: toDate,
        searchKeyword: value,
        broadcasterId: +this.broadcasterId,
      };
    }
    // // console.log(body)
    this.http.post(url, body).subscribe((data) => {
      this.reportList = data.data;
      this.isLoading = false;
    });
  }
}
