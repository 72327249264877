import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageInfo } from 'jspdf';
import { AdminInfo } from 'src/app/models/admin/admin-info';
import { MSOInfo } from 'src/app/models/mso/mso-info';
import { PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-lcm',
  templateUrl: './list-lcm.component.html',
  styleUrls: ['./list-lcm.component.scss']
})
export class ListLcmComponent implements OnInit {

  paginationObj = new PaginationObj();

  loadingFlag = false;

  msoList: MSOInfo[] = [];

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  sortByProperties = {
    lcoId: 'lcoId',
    lcoName: 'lcoName',
    distributorName: 'distributorName',
    channelLCN: 'channelLCN',
    channelName: 'channelName',
    createdBy:'createdBy'
  };

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }
  menus
  menuAccess = false

  ngOnInit(): void {
    this.fetchPage();
    this.menus=JSON.parse(atob(localStorage.getItem('menus')))
    this.checkMenuAccess()
  }
  
  checkMenuAccess(){
    for(let i of this.menus){
      if(i.name=='Ad Manager')
      {
        this.menuAccess=(i.access==0?false:true);
        console.log(this.menuAccess)
      }
    }
    console.log(this.menuAccess)
  }

  onSearch(){
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }

  pageSize
  isError = {
    errType : "Loading",
    bool : true
  }
  fetchPage(): void {
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
    const url = PaginationUtilities.urlBuilder('landingChannelSetting/list', this.paginationObj);
    // const url = this.config.json.apiEndpointRdbms + 'mso/getMSOs/' + this.paginationObj.pageNumber + '?pageSize=' + this.paginationObj.pageSize + '&sortBy=' + this.paginationObj.sortBy + '&sortOrder=' + this.paginationObj.sortOrder + '&searchKeyword=' + this.paginationObj.searchKeyword
    this.loadingFlag = true;
    this.http.get(url).subscribe({
      next : (data) =>{
        this.loadingFlag = false;
        this.pageSize = this.paginationObj.pageSize
        this.handleFetchPage(data);
        
      },
      error : () =>{

      }
    })
  }

  EditDetails(mso){
    this.router.navigate(['/settings/landing-channel-management/edit/', mso.lcoId]);
  }

  AdsDetails;
  viewModal = false;
  homeoverlayList = []
  homebannerList = []
  splashScreenList = []
  appIconList = []

  
  handleFetchPage(data): void {
    this.msoList = data.data;
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }

  pageNumber
  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.pageNumber = metadata.pageNumber
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchPage();
  }





}
