<div class="cms__content">
  <div class="top__heading">
    <h3>Add Subscription</h3>
    <button [routerLink]="['/manage/subscriber/list']">Back</button>
  </div> 
  <form [formGroup]="addSubscriptionForm">
    <div class="input_field">
      <div class="row" *ngIf="!isEdit">
        <div class="col-12" >
          <label for="">Search subscriber</label>
          <input class="custom__field" id="searchSubCode" autocomplete="off" type="text"
            formControlName="subscriberCode" (input)="getSubscriberId($event)" >
          <p id="clearBTN" (click)="clearInput()">Clear</p>
          <div class="search_param" *ngIf="openSearch">
            <app-subscriber-search [searchText]="searchText" (userDetail)="getUser($event)"></app-subscriber-search>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="isEdit">
      <div class="col-12">
        <label for="">Subscriber</label>
        <input class="custom__field" autocomplete="off" type="text" formControlName="subscriberIdEdit" readonly>
        <!-- <select name="" id="" class="custom__field" formControlName="stbId">
          <option value="" selected disabled>--SELECT--</option>
          <option *ngFor="let stb of stbList" [value]="stb.id">{{'Mac Address: '+stb.ethernetMac+", STB ID: "+stb.id+", Device Type: "+stb.deviceType}}
          </option>
        </select> -->
      </div>
    </div>
    <div class="row" *ngIf="!isEdit">
      <div class="col-12">
        <label for="">Select STB</label>
        <select name="" id="" class="custom__field" formControlName="stbId">
          <option value="" selected disabled>--SELECT--</option>
          <option *ngFor="let stb of stbList" [value]="stb.id">{{'Mac Address: '+stb.ethernetMac+", STB ID: "+stb.id+", Device Type: "+stb.deviceType}}
          </option>
        </select>
      </div>
    </div>

    <div class="row" *ngIf="isEdit">
      <div class="col-12">
        <label for="">STB</label>
        <select name="" id="" class="custom__field" formControlName="stbId" disabled>
          <option value="" selected disabled>--SELECT--</option>
          <option *ngFor="let stb of stbList" [value]="stb.id">{{'Mac Address: '+stb.ethernetMac+", STB ID: "+stb.id+", Device Type: "+stb.deviceType}}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label for=""  >Duration</label> 
       <div style="float: right; display: flex;justify-content: center;align-items: center;gap: 10px;"><label class="not__required" >In Days</label> <input  style="float: right;" type="checkbox" id="days" (change)="changeForDays($event)" ></div> 
        <input class="custom__field"  type="text"  oninput="this.value = this.value.replace(/[^0-9]/g, '')" [placeholder]="daysChecked ? 'Duration in Days': 'Duration in Months'" id="duration1" formControlName="duration1"  (change)="changeDaysSub()">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label for="">Assign Packages</label>
        <multi-select [controlName]="'bouquetId'" [parentFormName]="addSubscriptionForm" [multiSelectOptions]="packages"
          (selectedObjects)="packageSelected($event)"> </multi-select>
      </div>
    </div>
    
    <input type="hidden" formControlName="endDate">
    <input type="hidden" formControlName="duration">
  </form>
  
  <div class="row">
    <div class="col-12">
      <button class="cta float-right" [disabled]="addSubscriptionForm.invalid"
        (click)="addSubscriptionForm.valid ? save() : null">Save</button>
    </div>
  </div>
  <div class="col-12" *ngIf="selectedPackages.length != 0">
    <table class="custom__iptv__table selected_table">
      <thead>
        <tr>
          <th class="no__sort">Sl no</th>
          <th class="no__sort">Package Name</th>
          <th class="no__sort">Start Date</th>
          <th class="no__sort">End Date</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let package of selectedPackages; let i = index">
          <tr>
            <td>{{i+1}}</td>
            <td>{{package.name}}</td>
            <td>{{package.validFrom}}</td>
            <td>{{package.validTo}}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
