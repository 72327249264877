import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListLcmComponent } from './list-lcm/list-lcm.component';
import { CreateLcmComponent } from './create-lcm/create-lcm.component';


const routes: Routes = [
  {
    path: 'list',
    component: ListLcmComponent
  },
  {
    path: 'create',
    component: CreateLcmComponent
  },
  {
    path: 'edit/:id',
    component: CreateLcmComponent
  },
  {
    path: '',
    redirectTo: 'list',
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingChannelManagementRoutingModule { }