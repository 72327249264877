<div class="cms__content">
  <app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Subscription Logs</h3>
    <a [routerLink]="['/logs']"
      ><button class="default__button float-right">Back</button></a
    >
  </div>
  <div class="row">
    <div class="filter__group col-12 col-md-6 input_field">
      <div class="input_field">
        <input
          type="text"
          autocomplete="off"
          class="custom__field"
          id="searchSubCode"
          placeholder="Search Subscriber"
          (input)="getSubscriberId($event)"
        />
        <p id="clearBTN" (click)="clearInput()">Clear</p>
      </div>
      <div class="search_param" style="margin-right: 12px" *ngIf="openSearch">
        <app-subscriber-search
          [searchText]="searchText"
          (userDetail)="getUser($event)"
        >
        </app-subscriber-search>
      </div>
    </div>
    <div class="download__report col-md-6">
      <button
        class="default__button"
        (click)="downloadTablePdf()"
        title="Download PDF"
      >
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableExcel()"
        title="Download Excel"
      >
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableCSV()"
        title="Download Excel"
      >
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>
  </div>

  <div class="col-12 report">
    <p>
      Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
    </p>
  </div>
  <div class="custom__table__filter">
    <span>
      Show
      <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
        <option
          *ngFor="let size of paginationObj.pageSizeOptions"
          [ngValue]="size"
        >
          {{ size }}
        </option>
      </select>
      Entries
    </span>
    <div class="table__right">
      <p class="table__summary">
        Total <span>{{ paginationObj.totalElements }}</span> records
      </p>
      <div class="search__container">
        <input
          type="text"
          autocomplete="off"
          class="custom__field"
          id="searchKeyword"
          [(ngModel)]="paginationObj.searchKeyword"
          (keyup.enter)="searchKeyWord()"
        />
        <button (click)="searchKeyWord()">
          <img src="./assets/search.png" alt="search" />
        </button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Subscriber Code</th>
            <th>Name</th>
            <th>STB</th>
            <th>MAC ID</th>
            <th>Bouquet Name</th>
            <th>Process Type</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList.length == 0 && !loadingFlag">
            <td style="text-align: center !important" colspan="8">
              {{ isLoad }}
            </td>
          </tr>
          <tr *ngIf="loadingFlag">
            <td style="text-align: center !important" colspan="8">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <!-- <ng-template #showList> -->
          <tr *ngFor="let report of reportList; let i = index">
            <td>{{(paginationObj.pageNumber - 1) * paginationObj.pageSize +(i + 1)}}</td>
            <td>{{ report.subscriberCode ? report.subscriberCode : "---" }}</td>
            <td>{{ report.subscriberName ? report.subscriberName : "---" }}</td>
            <td>{{ report.macId ? report.macId : "---" }}</td>
            <td>{{ report.stbId ? report.stbId : "---" }}</td>
            <td>{{ report.bouquetName ? report.bouquetName : "---" }}</td>
            <td>{{ report.processType ? report.processType : "---" }}</td>
            <td>
              {{
                report.transactionDate | date : "dd-MM-yyyy HH:mm:ss" : "UTC"
              }}
            </td>
          </tr>
          <!-- </ng-template> -->
        </tbody>
      </table>

      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disablePreviousBtn" (click)="pageDecrease()">
              <img src="./assets/previousPage.png" />
            </button>
          </li>
          <li>
            <span
              >Page {{ paginationObj.pageNumber }} of
              {{ paginationObj.totalPages }}</span
            >
          </li>
          <li>
            <button [disabled]="disableNextBtn" (click)="pageIncrease()">
              <img src="./assets/nextPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
