import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DistributorSearchType } from 'src/app/distributor/distributor-search/distributor-search.component';
import { LcoSearchType } from 'src/app/lco/lco-search/lco-search';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-ad-manager',
  templateUrl: './ad-manager.component.html',
  styleUrls: ['./ad-manager.component.scss']
})


export class AdManagerComponent implements OnInit {

  @ViewChild('myInput') myInputVariable: ElementRef;

  constructor(
    private http: HttpClientWrapperService,
    private config: ConfigService,
    private router: ActivatedRoute,
  ) { }
  user
  openSearch = false
  positionSelected = false
  positionValue = 'home-banner'
  message = null as string
  lcoId
  lcoSelected = false
  distributorSelected = false;
  distributorId
  searchText!: string
  isSearch = false
  lcoCode = new FormControl()
  lcoList
  adList
  dropdownList = [];
  selectedList = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    textField: 'name',
  };
  paginationObj = new PaginationObj();

  ngOnInit(): void {
    if (this.config.json.userType == 'MSO') {
      this.user = true
    }
    else {
      this.user = false
    }
    this.getPathParams();

  }
  isEdit = false
  lcoIdEdit
  getPathParams() {
    const id = this.router.snapshot.paramMap.get('id');
    this.lcoIdEdit = id
    console.log(this.lcoIdEdit)
    if (id != null || id != undefined) {
      this.isEdit = true;
      this.selectedList.push(this.lcoIdEdit)
      this.fetchAdsData(id);
    } else {
      this.isEdit = false;
      this.fetchAdList();

    }
  }

  homeoverlayList = []
  homebannerList = []
  splashScreenList = []
  appIconList = []

  fetchAdsData(lcoId) {
    let url = `${this.config.json.apiEndpoint}ad/lcoAds?lcoId=` + lcoId;
    this.http.get(url).subscribe({
      next: (res: any) => {
        this.adList = res.data;
        for (let i of this.adList) {
          if (i.NAME == 'home-banner') {
            this.homebannerList = i
          }
          else if (i.NAME == 'home-overlay') {
            this.homeoverlayList = i
          }
          else if (i.NAME == 'splash-screen') {
            this.splashScreenList = i
          }
          else if (i.NAME == 'app-icon') {
            this.appIconList = i
          }
        }
      }
    })
  }

  getSubscriberId(e: Event) {
    console.log(e)
    this.openSearch = false
    this.searchText = (e.target as HTMLInputElement).value
    if (this.searchText.length >= 3) {
      setTimeout(() => {
        this.openSearch = true
      }, 500);
    }
  }


  clearInput() {
    let input = document.getElementById('lcoCode') as HTMLInputElement
    input.value = ""
    this.searchText = "",
      this.openSearch = false
    this.distributorSelected = false;
    this.selectedList = [];
    this.showPreview = false
    // this.distributorFundsForm.reset()
  }

  selectedPosition(e: Event) {
    this.positionSelected = true
    this.positionValue = (e.target as HTMLSelectElement).value
  }


  getSearchText(e: Event) {
    this.isSearch = false
    this.searchText = (e.target as HTMLInputElement).value
    if (this.searchText.length >= 3) {
      setTimeout(() => {
        this.isSearch = true
      }, 300);
    }
  }

  getUser(e: DistributorSearchType) {
    let inputBox = document.getElementById('lcoCode') as HTMLInputElement;
    console.log(inputBox)
    if ((e as any) == '') {
      this.openSearch = false;
      this.searchText = undefined;
      // this.distributorFundsForm.get('lcoCode').setValue(null);
      inputBox.value = '';
      return;
    }
    // this.distributorFundsForm.get('lcoCode').setValue(e.distributorCode);
    inputBox.value = `${e.distributorCode}, ${e.name}`;
    this.distributorId = e.id
    this.openSearch = false;
    this.searchText = undefined;
    this.searchText = undefined;
    this.fetchLCO(this.distributorId)
  }

  getLCOId(e: LcoSearchType) {
    let inp = document.getElementById('lcoId') as HTMLInputElement
    inp.value = `${e.lcoName}: ${e.lcoCode}`
    this.lcoId = e.lcoId
    this.isSearch = false
  }


  selectedIdsString
  imageURL = ''
  showPreview = false


  nameSelected() {
    this.positionSelected = true;
    this.imageURL = ''
    this.showPreview = false
    console.log(this.positionValue)


  }

  uploadAds(e: Event) {
    // let url = ''
    // if (this.user == true) {
    //   url = this.config.json.apiEndpointRdbms + `ad/insert?name=${this.positionValue}`
    // }
    // else {
    //   url = this.config.json.apiEndpointRdbms + `ad/insert/${this.lcoId}?name=${this.positionValue}`
    // }

    // let file = (e.target as HTMLInputElement).files[0]
    // let formData = new FormData()
    // formData.append('image', file)
    // this.http.post(url, formData).pipe().subscribe({
    //   next: (res) => {
    //     this.positionValue = "selected"
    //     this.positionSelected = false
    //     this.myInputVariable.nativeElement.value = "";
    //     alert("Upload Succesful")

    //   },
    //   error: (err) => {
    //     alert("Something went wrong")
    //   },
    //   complete: () => {
    //     formData = new FormData()
    //     this.fetchAdList()
    //   }
    // })

    let url = ''
    let file = (e.target as HTMLInputElement).files[0]
    if (file.type != 'video/mp4' && this.positionValue == 'splash-screen') {
      alert('Only MP4 files are allowed!');
      return;
    } else {
      let formData = new FormData()
      formData.append('image', file)
      console.log(file)
      this.selectedIdsString = this.selectedList.join(',');
      if (this.user == true) {
        url = this.config.json.apiEndpointRdbms + `ad/uploadAdsByLcoWise?name=${this.positionValue}&lcoIds=${this.selectedIdsString}`
      }
      else {
        url = this.config.json.apiEndpointRdbms + `ad/uploadAdsByLcoWise/${this.lcoId}?image=${file}`
      }

      // let file = (e.target as HTMLInputElement).files[0]
      // let formData = new FormData()
      // formData.append('image', file)
      // let body={
      //   name:this.positionValue,
      //   "lcoIds":this.selectedList
      // }
      this.http.post(url, formData).pipe().subscribe({
        next: (res) => {
          if (res.statusCode == 200) {
            // this.positionValue = "selected"
            alert("Upload Succesful")
            if (!this.isEdit) {
              this.positionSelected = false
              this.myInputVariable.nativeElement.value = "";

              this.showPreview = true
              this.imageURL = res.data.url
            }
            else {
              this.fetchAdsData(this.lcoIdEdit)
            }
            console.log(this.imageURL)
          }
          else {
            alert("Something went wrong")
          }


        },
        error: (err) => {
          alert("Something went wrong")
        },
        complete: () => {
          formData = new FormData()
          this.fetchAdList()
        }
      })
    }
  }


  fetchLCO(id) {
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url;

    url = PaginationUtilities.urlBuilder(
      'lco/getLCOsByDistributor',
      this.paginationObj
    );
    // url += `&distributorId=${this.auth.getLcoId()}`;
    url += `&distributorId=` + id;
    this.http.get(url).subscribe(
      (res) => {
        this.selectedIDsList = []
        this.selectedList = []
        this.lcoList = res.data
        this.distributorSelected = true
        this.showPreview = false
        this.lcoList = this.lcoList.map((channel) => {
          return {
            id: channel.id,
            name: channel.name,
          };
        });

      }
    )
  }
  selectedIDsList = []
  onItemSelect(item: any) {
    console.log
    this.selectedList.push(item.id);
    console.log(this.selectedList);
  }

  onSelectAll(items: any) {
    this.selectedList = items.map((item: any) => item.id);
    console.log(this.selectedList);
  }

  onItemDeSelect(item: any) {
    this.selectedList = this.selectedList.filter(id => id !== item.id);
    console.log(this.selectedList);
  }

  onDeSelectAll() {
    this.selectedList = [];
    console.log(this.selectedList);
  }


  fetchAdList() {
    if (this.user == true) {
      this.http.get(this.config.json.apiEndpoint + 'ad/list').subscribe({
        next: (res) => {
          this.adList = res.data
          console.log(this.adList)
        }
      })
    }
    else {
      let id = atob(localStorage.getItem('id'));
      this.lcoId = id
      this.http.get(this.config.json.apiEndpoint + 'ad/list/' + this.lcoId).subscribe({
        next: (res) => {
          this.adList = res.data
        }
      })
    }
  }

}
