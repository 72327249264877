import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MultiSelectType } from 'src/app/plugins/multiselect/multiselect.component';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { AuthService } from 'src/app/services/auth.service';
import { PathNames } from 'src/app/constants/path-names';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-swap-stb',
  templateUrl: './swap-stb.component.html',
  styleUrls: ['./swap-stb.component.scss']
})
export class SwapStbComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getPathParam()
  }

  swapSubscriptionForm: FormGroup;
  showDisablePopup = false;

  initForm() {
    this.swapSubscriptionForm = this.fb.group({
      subscriberId:['', [Validators.required]],
      fromStbId: ['', [Validators.required]],
      toStbId: ['', [Validators.required]],
    });
  }

  id

  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.id = id;
    if (id && id !== PathNames.create) {
      this.fetchRecord(id);
    } else {
      // this.createFlow();
    }
  }

  subscriberData

  fetchRecord(id: string) {
    const url =
      this.config.json.apiEndpointRdbms + 'subscriber/getSubscriber/' + id;
    this.http.get(url).subscribe(
      (res)=>{
        this.subscriberData = res
        this.updateForm()
        this.getSTBList()
      }
    );
  }

  updateForm(){
    let data=this.subscriberData.subscriberCode+','+this.subscriberData.firstName;
    this.subscriberId.setValue(data)
  }


  isSubscriberIdSelected = false;

  // getUser(e) {
  //   let input = document.getElementById('searchSubCode') as HTMLInputElement;
  //   this.swapSubscriptionForm.get('subscriberCode').setValue(e.subscriberCode);
  //   this.subscriberId = e.subscriberId;
  //   this.openSearch = false;
  //   this.getSTBList();
  //   this.searchText = undefined;
  //   input.value = `${e.subscriberCode}, ${e.subscriberName}`;
  // }

  isDropDown = true;
  fromstbList;
  tostbList
  lcoId;
  fromStbSelected = false;

  getSTBList() {
    const url =
      this.config.json.apiEndpointRdbms +
      'stb/getSTBBySubscriberId/' +
      this.id;
    this.http.get(url).subscribe({
      next: (res) => {
        this.fromstbList = res;
        this.tostbList = res;
        this.lcoId = res[0].lcoId;
        // this.getPackageList();
      },
    });
  }

  getToStb(){
    console.log("calling")
    this.fromStbSelected= true
    // this.tostbList
    this.fromStbSubscriptionList=[]
    this.toStbSubscriptionList=[]
    this.tostbList = this.fromstbList
    this.toStbId.setValue('')
    this.tostbList = this.tostbList.filter(i => i.id != this.fromStbId.value);
  }

  enableSaveButton = false
  fromStbSubscriptionList
  toStbSubscriptionList

  validateSelectedStb(){
    const url=  this.config.json.apiEndpointRdbms + 'stb/validateSwapStb';
    let body={
      "subscriberId": this.id,
      "fromStbId": +(this.fromStbId.value),
      "toStbId": +(this.toStbId.value)
    }
    this.http.post(url,body).subscribe(
      (res)=>{
        if(res.data.isError==false){
          this.enableSaveButton = true
          this.fromStbSubscriptionList = res.data.fromStbSubscriptionList
          this.toStbSubscriptionList=res.data.toStbSubscriptionList
        }
        else{
          this.enableSaveButton= false
          alert(res.data.errorMessage)
        }
      }
    )
  }

  save() {
    // if(confirm("")){
    const url = this.config.json.apiEndpointRdbms + 'stb/swapStb';
    let body={
      "subscriberId": this.id,
      "fromStbId": +(this.fromStbId.value),
      "toStbId": +(this.toStbId.value)
    }
    this.http.post(url, body).subscribe(
       (res) => {
        if (res.statusCode == '200') {
           this.router.navigate(['/manage/subscriber'])
          return;
        }else{
          alert(res.message);
        }
        // this.clearInput();
      },
    );
  // }
  }

  get fromStbId(): FormControl {
    return this.swapSubscriptionForm.get('fromStbId') as FormControl;
  }

  get toStbId(): FormControl {
    return this.swapSubscriptionForm.get('toStbId') as FormControl;
  }

  get subscriberId(): FormControl {
    return this.swapSubscriptionForm.get('subscriberId') as FormControl;
  }
}
