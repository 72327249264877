<div class="cms__content">
    <div class="top__heading">
      <h3>
        {{ isEdit ? "Edit" : "Create" }} Affliator <span class="current-item"></span>
      </h3>
      <a [routerLink]="['/manage/affiliator']">
        <button class="default__button float-right">Back</button></a
      >
    </div>
  
    <form action="" [formGroup]="affliatorForm" (ngSubmit)="persist()">
      <div class="col-12 form__style">
        <div class="row">
          <div class="col-12 col-md-6">
            <label>Name</label>
            <input type="text" autocomplete="off" class="custom__field" formControlName="name" />
          </div>
  
          <div class="col-12 col-md-6">
            <label>Mobile</label>
            <input
              type="text"
              class="custom__field"
              placeholder="format: +91......"
              formControlName="mobile"
              oninput="this.value = this.value.replace(/[^0-9]/g, '')"
              maxlength="10"
              pattern="^\d{10}$"
              (input)="numValidation($event)"
              [ngClass]="{ error__field: !mobile.valid && mobile.touched }"
            />
            <div [hidden]="!mobile.touched">
              <span class="error__msg" *ngIf="mobile.hasError('required')"
                >Please enter 10 digit mobile number</span
              >
              <span class="error__msg" *ngIf="isNumError"
                >Number is invalid</span
              >
              <span class="error__msg" *ngIf="mobile.errors?.duplicate"
                >Number is taken</span
              >
            </div>
          </div>
  
          <div class="col-12 col-md-6">
            <label>Landline</label>
            <input
              type="text"
              class="custom__field"
              placeholder="format: +91......"
              formControlName="landline"
              oninput="this.value = this.value.replace(/[^0-9]/g, '')"
              pattern="^\d{10}$"
              maxlength="10"
              [ngClass]="{ error__field: !landline.valid && landline.touched }"
            />
            <div [hidden]="!landline.touched">
              <span class="error__msg" *ngIf="landline.hasError('required')"
                >Please enter landline number</span
              >
              <span class="error__msg" *ngIf="landline.hasError('pattern')"
                >Number is invalid</span
              >
              <span class="error__msg" *ngIf="landline.errors?.duplicate"
                >Number is taken</span
              >
            </div>
          </div>
  
          <div class="col-12 col-md-6">
            <label>Email</label>
            <input
              type="email"
              class="custom__field"
              placeholder="format: abc@xyz"
              formControlName="email"
              [ngClass]="{ error__field: !email.valid && email.touched }"
            />
            <div [hidden]="!email.touched">
              <span class="error__msg" *ngIf="email.hasError('required')"
                >Please enter email</span
              >
              <span class="error__msg" *ngIf="email.hasError('email')"
                >Email is invalid</span
              >
              <span class="error__msg" *ngIf="email.errors?.duplicate"
                >Email is taken</span
              >
            </div>
          </div>
  
         
  
          <div *ngIf="!isEdit" class="col-12 col-md-6 password-field_show-hide">
            <label>New Password</label>
            <input
              [type]="showPassword ? 'text' : 'password'"
              class="custom__field"
              formControlName="password"
              [ngClass]="{
                error__field:
                  !password.valid && password.touched && !passwordStringMatch()
              }"
            />
            <img (click)="showPassword = !showPassword" [src]="!showPassword ? '/assets/icons/eye.svg' : '/assets/icons/eye-no.svg'" alt="Show Password">
            <br />
            <div [hidden]="!password.touched">
              <span
                [ngStyle]="style"
                class="error__msg"
                *ngIf="password.hasError('required')"
                >Required</span
              >
              <span
                [ngStyle]="style"
                class="error__msg"
                *ngIf="!passwordStringMatch()"
              >
                Must contain at least one lowercase letter, one uppercase letter,
                one numeric digit, and one special character and 8 digits long
              </span>
              <span
                [ngStyle]="style"
                class="error__msg"
                *ngIf="password.hasError('minlength')"
                >Should be atleast 8 characters long</span
              >
            </div>
          </div>
  
          <div *ngIf="!isEdit" class="col-12 col-md-6 password-field_show-hide">
            <label>Confirm Password</label>
            <input
              [type]="showConfirmPassword? 'text' : 'password'"
              class="custom__field"
              formControlName="confirmPassword"
              [ngClass]="{ error__field: !cpassword.valid && cpassword.touched }"
            />
            <img (click)="showConfirmPassword = !showConfirmPassword" [src]="!showConfirmPassword ? '/assets/icons/eye.svg' : '/assets/icons/eye-no.svg'"alt="Show Password">
            <div [hidden]="!cpassword.touched">
              <span class="error__msg" *ngIf="affliatorForm.errors?.mismatch"
                >Passwords do not match</span
              >
            </div>
          </div>


          <div class="col-12 col-md-6">
            <label>Status</label>
            <select class="custom__field" formControlName="status">
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
          </div>
  
          <div class="col-12 col-md-6">
            <label>ACL Group</label>
            <select class="custom__field" formControlName="aclGroupId">
              <option *ngFor="let aclGroup of AclGroupList" [value]="aclGroup.id">
                {{ aclGroup.name }}
              </option>
            </select>
          </div>
  
          <div class="col-12">
            <label>Address</label>
            <textarea class="custom__field" formControlName="address"></textarea>
          </div>
        </div>
        <button class="cta float-right" [disabled]="!affliatorForm.valid">Save</button>
      </div>
    </form>
  </div>
  