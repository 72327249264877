import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-add-packages',
  templateUrl: './add-packages.component.html',
  styleUrls: ['./add-packages.component.scss'],
})
export class AddPackagesComponent implements OnInit {
  @Output() selectedPackageList = new EventEmitter();
  @Output() goBack = new EventEmitter();
  @Input() selectedPackage!: any;
  @Input() selectedPackagesForUpdate!: any; 
  constructor(
    private config: ConfigService,
    private http: HttpClientWrapperService
  ) {}

  ngOnInit(): void {
    
    if(this.selectedPackage!=undefined){
      this.selectedPackageArray = this.selectedPackage
    }
    this.getBouquet();
    
  }
  checkSelectAllState() {
    this.isSelectAll = this.packagesList.every(packages => packages.checked);
  }
  isSelectAll = false;
  changeIsSelectAll(){
    this.isSelectAll = !this.isSelectAll;
  }

  packagesList;
  packType ='All'

  callCheckPackages(){
    for (let ele of this.packagesList) {
      for (let item of this.selectedPackageArray) {
        if (item.id == ele.id) {
          ele.checked = true;
        }
      }
    }
  }

  
  getBouquet() {
    const url = `${this.config.json.apiEndpoint}bouquet/getBouquetList?packType=`+this.packType;
    this.http.get(url).subscribe({
      next: (res) => {
        console.log(res);
        for (let ele of res.data) {
          ele['checked'] = false;
        }
        this.packagesList = res.data;
        if(this.selectedPackageArray.length>0){
          this.callCheckPackages()
        }
        if (this.selectedPackagesForUpdate) {
          console.log('selFrUpdt', this.selectedPackagesForUpdate);
          this.selectedPackagesUpdate();
        }
        if (this.selectedPackage) {
          console.log('selFrPkg', this.selectedPackagesForUpdate);
          this.updateSelectedPackages();
        }
        this.checkSelectAllState()
        if (this.isSearch == true) {
          console.log('Selected ' + this.selectedIdsFromSearch);

          for (let ele of this.packagesList) {
            for (let item of this.selectedIdsFromSearch) {
              if (ele.id == item) {
                console.log(ele.id)
                ele.checked = true;
              }
            }
          }
          this.isSearch = false;
        }
      },
    });
  }

  selectedPackagesUpdate() {
    for (let ele of this.packagesList) {
      for (let item of this.selectedPackagesForUpdate) {
        if (item.id == ele.id) {
          ele.checked = true;
          ele['share'] = item.share;
        }
      }
    }
    console.log('pkglist', this.packagesList);
  }

  updateSelectedPackages() {
    for (let ele of this.packagesList) {
      for (let item of this.selectedPackage) {
        if (item.id == ele.id) {
          ele.checked = item.checked;
        }
      }
    }
  }
  selectAll() {
    this.isSelectAll = true;
    for (let i of this.packagesList) {
      i.checked = true;
      this.selectedPackageArray.push(i);
    }
  }

  unselectAll() {
    this.isSelectAll = false;
    for (let i of this.packagesList) {
      i.checked = false;
      const index = this.selectedPackageArray.findIndex((ch) => ch.id === i.id);
      index !== -1 && this.selectedPackageArray.splice(index, 1);
    }
  }
  selectPackage(selectedPackage: any, e: Event) {
    let check = (e.target as HTMLInputElement).checked;
    for (let ele of this.packagesList) {
      if (selectedPackage.id == ele.id) {
        if (check) {
          ele.checked = true;
          this.selectedPackageArray.push(ele)
        } else {
          console.log(this.selectedPackageArray)
          ele.checked = false;
          let index=0;
          for(let ele of this.selectedPackageArray){
            if(selectedPackage.id == ele.id){
              this.selectedPackageArray.splice(index,1)
            }
            index=index+1
          }
          console.log(this.selectedPackageArray)
        }
      }
    }
    this.checkSelectAllState();
  }

  selectedPackageArray = [];

  selectPackagesToSetPrice() {
    for (let ele of this.packagesList) {
      if (ele.checked) {
        let present = false
        for(let item of this.selectedPackageArray){
          if(ele.id==item.id){
            present=true
          }
        }
        if(present==false){
          this.selectedPackageArray.push(ele);
        }
      }
    }
    this.selectedPackageList.emit(this.selectedPackageArray);
  }

  copyOfPackagesList;
  isSearch = false;
  
  search() {
    for (let ele of this.packagesList) {
      if (ele.checked) {
        let present = false
        for(let item of this.selectedPackageArray){
          if(ele.id==item.id){
            present=true
          }
        }
        if(present==false){
          this.selectedPackageArray.push(ele);
        }
      }
    }
    let value = (document.getElementById('searchbox') as HTMLInputElement).value;
    if (value == '') {
      this.cancel();
      return;
    }
    let url = `${this.config.json.apiEndpoint}bouquet/getBouquetSearch?search=${value}`+'&packType='+this.packType;
    this.http.get(url).subscribe({
      next: (res) => {
        this.copyOfPackagesList = JSON.parse(JSON.stringify(this.packagesList));
        this.packagesList = res.data;
        this.isSearch = true;
        for(let ele of this.packagesList){
          for(let item of this.selectedPackageArray){
            if (ele.id == item.id) {
              ele.checked = true;
            }
          }
        }
        if (this.isSearch == true) {
          for (let ele of this.packagesList) {
            for (let item of this.selectedIdsFromSearch) {
              if (ele.id == item) {
                ele.checked = true;
              }
            }
          }
        }
      },
    });
  }

  selectedIdsFromSearch: string[] = [];
  cancel() {
    // this.packagesList = this.copyOfPackagesList;
    for (let ele of this.packagesList) {
      if (ele.checked) {
        this.selectedIdsFromSearch.push(ele.id);
        console.log(ele.id);
      }
    }

    const searchInput: HTMLInputElement | null = document.querySelector('#searchbox');
    searchInput.value = "";

    // for(let ele of this.copyOfPackagesList){
    //   for(let item of this.selectedIdsFromSearch){
    //     if(ele.id == item){
    //       ele.checked = true;
    //     }
    //   }
    // }
    // this.packagesList = JSON.parse(JSON.stringify(this.copyOfPackagesList))
    // this.isSearch = false;

    this.getBouquet();

    // console.log("Selected " + this.selectedIdsFromSearch)

    // for(let ele of this.packagesList){
    //   console.log("First Item:" + ele.id);
    //   for(let item of this.selectedIdsFromSearch){
    //     console.log("Second Item"+ item)
    //     if(ele.id == item){
    //       console.log("Voila")
    //       ele.checked = true;
    //     }
    //   }
    // }
    // this.isSearch=false;
  }

  back() {
    this.goBack.emit(true);
  }
}
