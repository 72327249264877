<div class="cms__content">
	<div class="top__heading">
    <app-reports-header></app-reports-header>
		<h3>STB Partner Stock Report</h3>
		<a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
	</div>
	<div class="clearfix"></div>

	<div class="row">
		<div class="filter__group col-12 col-md-6">
			<app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
		</div>

		<div class="download__report col-md-6">
			<button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
				PDF
				<img src="./assets/pdf.svg" alt="download" />
			</button>
			<button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
				Excel
				<img src="./assets/excel.svg" alt="download" />
			</button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
				CSV
        <img src="./assets/csv.svg" alt="download" />			</button>
		</div>


		<div class="col-12 col-md-6 report_selection_block">
			<label class="report_selection_label">Select LCO</label>
			<select class="custom__field report_selection" [(ngModel)]="selectedLcoId" (ngModelChange)="lcoSelect()">
				<option [ngValue] = 0 >ALL</option>
				<option *ngFor="let lco of lcoList" [ngValue]='lco.id'>{{lco.name}}</option>
			</select>
		</div>
	</div>

	<div class="col-12 col-md-6">
		<p>Report generated on : {{currentDate | date:'dd-MM-yy HH:mm:ss'}}</p>
	  </div>

	<div class="col-12 list__table">
		<div class="row">
			<table class="custom__iptv__table" #reportTable id="reportTable">
				<thead>
					<tr>
            <th>Sl No.</th>
						<th>Date</th>
						<th>Fresh</th>
						<th>Assigned</th>
						<th>Unassigned</th>
					</tr>
				</thead>
				<tbody>
          <tr *ngIf="reportList.length ==0 || reportList.length== null && !loadingFlag" >
            <td style="text-align: center !important;" colspan="5" >{{isLoad}}</td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important;" colspan="5">
            <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <ng-template #showList>
					<tr *ngFor="let report of reportList, let j = index">
            <td>{{j+1}}</td>
						<td>{{report.createdDate | date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
						<td>{{report.freshSTBs}}</td>
						<td>{{report.assignedSTBs}}</td>
						<td>{{report.unAssignedSTBs}}</td>
					</tr>
        </ng-template>
				</tbody>
			</table>
		</div>
	</div>
</div>
