import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-stb-count',
  templateUrl: './report-stb-count.component.html',
  styleUrls: ['./report-stb-count.component.scss', '../report-filters.scss']
})
export class ReportStbCountComponent implements OnInit {

  paginationObj = new PaginationObj();

  loadingFlag = false;



  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  reportList = [];
  selectedLco = 0;
  from: any = '0000-00-00';
  to: any = '0000-00-00';

  lcoList: LCOInfo[] = [];

  currentDate = new Date();
  lcoValue: any = 0

  sortByProperties = {
    lcoCode: 'lcoCode',
    lcoName: 'lcoName',
    stbActiveCount: 'stbActiveCount',
    stbInactiveCount: 'stbInactiveCount',
    stbBlacklistedCount: 'stbBlacklistedCount',
    stbSuspenedCount: 'stbSuspenedCount',
    stbTerminatedCount: 'stbTerminatedCount'
  };


  totalActiveCount = 0
  totalBlacklistedCount = 0
  totalInactiveCount = 0
  totalSuspendedCount = 0
  totalTerminatedCount = 0

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchLcoList();
    this.fetchData()
  }

  fetchLcoList(): void {
    this.report.getLCOList().subscribe((data) => {
      this.lcoList = data.data;
    });
  }

  isLoad = 'Choose Date';

  sortByItem(item: string): void {
    console.log(item)
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchData();
  }

  handlePaginationBtnDisableStatuses(): void {
    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchData();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  getCounts(data) {
    this.totalActiveCount = 0
    this.totalBlacklistedCount = 0
    this.totalInactiveCount = 0
    this.totalSuspendedCount = 0
    this.totalTerminatedCount = 0
    for (let stb of data) {
      this.totalActiveCount = this.totalActiveCount + stb.stbActiveCount
      this.totalBlacklistedCount = this.totalBlacklistedCount + stb.stbBlacklistedCount
      this.totalInactiveCount = this.totalInactiveCount + stb.stbInactiveCount
      this.totalSuspendedCount = this.totalSuspendedCount + stb.stbSuspenedCount
      this.totalTerminatedCount = this.totalTerminatedCount + stb.stbTerminatedCount

    }
    if (isNaN(this.totalTerminatedCount)) {
      this.totalTerminatedCount = 0;
    }
    if (isNaN(this.totalSuspendedCount)) {
      this.totalSuspendedCount = 0;
    }
    if (isNaN(this.totalInactiveCount)) {
      this.totalInactiveCount = 0;
    }
    if (isNaN(this.totalBlacklistedCount)) {
      this.totalBlacklistedCount = 0;
    }
    if (isNaN(this.totalActiveCount)) {
      this.totalActiveCount = 0;
    }

  }

  handleFetchPage(data): void {
    this.reportList = data.data;
    this.getCounts(this.reportList)
    if (this.reportList.length != 0) {
      this.loadingFlag = false;
    } else {
      this.loadingFlag = false;
      this.isLoad = 'No Records';
    }
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }

  pageNumber
  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.pageNumber = metadata.pageNumber
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  fetchData(): void {
    this.loadingFlag = true
    this.reportList = []
    if (this.lcoValue == 0) {
      this.selectedLco = 0
    }
    else {
      this.selectedLco = this.lcoValue.id
    }
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
    let url = PaginationUtilities.urlBuilder('reports/stbCountReport', this.paginationObj);
    url = url + "&lcoId=" + this.selectedLco
    if (this.from != "0000-00-00" && this.to != "0000-00-00") {
      url = url + "&fromDate=" + this.from + "&toDate=" + this.to;
    }
    this.http.get(url).subscribe(
      (data) => {
        console.log(data)
        this.handleFetchPage(data);
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }

  lcoSelect(): void {
    this.from = this.calendar.getDate()['startDate']
    this.to = this.calendar.getDate()['endDate']
    this.fetchData();
  }

  dateString;
  eve;
  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      // // console.log(ev.startDate.date(), ev.startDate.month(), ev.startDate.year())
      // // console.log(ev.endDate.date(), ev.endDate.month(), ev.endDate.year())
      this.eve = ev;
      this.dateString =
        ev.startDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.startDate.month()) +
        '-' +
        ev.startDate.year() +
        ' to ' +
        ev.endDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.endDate.month()) +
        '-' +
        ev.endDate.year();
      this.from = ev.startDate.format('YYYY-MM-DD');
      this.to = ev.endDate.format('YYYY-MM-DD');
      console.log(this.from)
      this.fetchData();
      this.loadingFlag = true;
      this.isLoad = 'Loading';
      this.calendar.setDate(ev);
    }
  }

  downloadTableExcel(): void {
    //// console.log("hello")
    let info = '';
    let suffix = '';
    let headers = [];
    let filters = `From Date: ${this.from} , To Date: ${this.to} ,  Source Type : SMS`
    let name = 'STB Count Report';
    if (this.selectedLco != 0) {
      let LCOName = '';
      for (let i of this.lcoList) {
        if (i.id == this.selectedLco) {
          LCOName = i.name;
        }
      }
      info = info + 'LCO:' + LCOName;
      suffix = suffix + LCOName;
    }
    if (suffix != '') {
      if (this.from == "0000-00-00" && this.to == "0000-00-00") {
        this.doc.exportTableToExcelwithImage(
          this.reportTable,
          name,
          'stb_count_report_' + `${suffix}`,
          undefined,
          undefined,
          'Partner : ',
          this.selectedLco.toString()
        );
      }
      else {
        this.doc.exportTableToExcelwithImage(
          this.reportTable,
          name,
          'stb_count_report_' + `${suffix}`,
          undefined,
          undefined,
          'Filters : ',
          filters
        );
      }
    }
    else {
      if (this.from == "0000-00-00" && this.to == "0000-00-00") {
        this.doc.exportTableToExcelwithImage(
          this.reportTable,
          name,
          'stb_count_report',
          undefined,
          undefined,
          'Partner : ',
          this.selectedLco.toString()
        );
      }
      else {
        this.doc.exportTableToExcelwithImage(
          this.reportTable,
          name,
          'stb_count_report',
          undefined,
          undefined,
          'Filters : ',
          filters
        );
      }
    }
  }

  downloadTablePdf(): void {
    let info = '';
    let suffix = '';
    if (this.selectedLco != 0) {
      let LCOName = '';
      for (let i of this.lcoList) {
        if (i.id == this.selectedLco) {
          LCOName = i.name;
        }
      }
      info = info + 'LCO:' + LCOName;
      suffix = suffix + LCOName;
    }
    if (
      this.reportList == null ||
      this.reportList == undefined ||
      this.reportList?.length == 0
    ) {
      alert('No Data!');
      return;
    }
    if (suffix != '') {
      if (this.from == "0000-00-00" && this.to == "0000-00-00") {
        this.pdf.generatePdfHtml('#print', 'stb_count_report_' + `${suffix}`,
          `Till now  Source Type : SMS `, null, null, 'a4');
        return;
      }

      this.pdf.generatePdfHtml('#print', 'stb_count_report',
        `From Date: ${this.from} , To Date: ${this.to}, Source Type : SMS`, null, null, 'a4');
    }
    else {
      if (this.from == "0000-00-00" && this.to == "0000-00-00") {
        this.pdf.generatePdfHtml('#print', 'stb_count_report_' + `${suffix}`,
          `Till now  Source Type : SMS `, null, null, 'a4');
        return;
      }

      this.pdf.generatePdfHtml('#print', 'stb_count_report',
        `From Date: ${this.from} , To Date: ${this.to}, Source Type : SMS`, null, null, 'a4');
    }
  }


  formatDate(ele) {
    if (ele == undefined) {
      return false;
    }
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: [
        'Sl No',
        'LCO Code',
        'LCO Name',
        'Active Count',
        'Inactive Count',
        'Blacklisted Count',
        'Suspended Count',
        'Terminated Count',
      ],

    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slNo: i,
        partnercode: line.lcoCode,
        partnerName: line.lcoName,
        active: line.stbActiveCount,
        inactive: line.stbInactiveCount,
        blacklist: line.stbBlacklistedCount,
        suspended: line.stbSuspenedCount,
        terminated: line.stbTerminatedCount,
      };
      data.push(csvLine);
      i += 1;

    });
    let newLine = {
      slNo: '',
      partnercode: '',
      partnerName: 'Total',
      active: this.totalActiveCount,
      inactive: this.totalInactiveCount,
      blacklist: this.totalBlacklistedCount,
      suspended: this.totalSuspendedCount,
      terminated: this.totalTerminatedCount,
    };

    data.push(newLine);

    new ngxCsv(data, 'stb_count_report', options);
  }

  onSearch() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }
}

