<div class="cms__content">
  <app-reports-header></app-reports-header>

 <div class="top__heading">
   <h3>Subscriber Wise Inactive Subscription</h3>
   <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
 </div>
 <div class="clearfix"></div>

 <div class="row row-top">
  <div class="date_filter">
    <div class="div_tabs" *ngIf="dateSelector">
      <button (click)="dateTypeSelectors(1)" >Till Date</button>
      <button (click)="dateTypeSelectors(2)" >Random Date</button>
    </div>
    <div class="div_tabs2" *ngIf="showMonth" >
     <div class="date_box">
      <label for="month">Select Date:</label>
      <div class="date_box_orig">
        <input class="custom__field mar_zeroed" type="date" id="month"  value="selectedDate" (change)="dateChangeMonth($event)">
      </div>
     </div>
      <button (click)="dateTypeSelectors(0)" >X</button>
    </div>
    <div class="div_tabs2" *ngIf="showDateRange" >
      <label for="dateRange">
        Select Date :
      </label>
      <div class="date_box_orig">
        <app-date-picker id="mar_zeroeds" (dateChange)="dateChangeRange($event)" ></app-date-picker>
      </div>
      <button id="app_date_pick_button" (click)="dateTypeSelectors(0)" >X</button>
    </div>
  </div>
   <div class="download__report col-md-6">
     <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
       PDF
       <img src="./assets/pdf.svg" alt="download" />
     </button>
     <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
       Excel
       <img src="./assets/excel.svg" alt="download" />
     </button>
     <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
           CSV
           <img src="./assets/csv.svg" alt="download" />        </button>
           <button class="default__button" (click)="downloadHistoricReport()" title="Download PDF">
            Historical Report
            <img src="./assets/download.svg" alt="download" />
          </button>
   </div>
 </div>

 <!-- <div class="col-12 col-md-6 report_selection_block">
   <label class="report_selection_label">Select Broadcaster</label>
   <select class="custom__field report_selection" [(ngModel)]="broadcasterId" (ngModelChange)='fetchData()'> -->
     <!-- Changed ngValue binding from lco.id to lco.email -->
     <!-- <option value = 0 >ALL</option> -->
     <!-- <option *ngFor="let lco of broadcasterList" [ngValue]='lco.id'>
       {{lco.name}}
     </option>
   </select>
 </div> -->

 <div class="third_box">
  <div class="col-12 col-md-6 report">
    <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
  </div>
  <div style="display: flex; gap:20px" >
    <div class="table__right">
      <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
    </div>
    <div class="search__container">
      <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
      <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
    </div>
  </div>
</div>

 <div class="col-12 list__table">
   <div class="row scroll">
     <table class="custom__iptv__table" #reportTable id="reportTable">
       <thead>
         <tr>
           <!-- <th>Date</th> -->
           <th>Sl no</th>
           <th>SUbscriber ID</th>
           <th>Subscriber Code</th>
           <th>STB ID</th>
           <th>MAC ID</th>
           <th>Subscriber Name</th>
           <th>District</th>
           <th>State</th>
           <th>Subscriber Status</th>
           <th>Mobile</th>
           <th>Bouquet ID</th>
           <th>Price Package</th>
           <th>Subscription Type</th>
           <th>Subscription Status</th>
           <th>Start Date</th>
           <th> End Date</th>
           <!-- <th>Transaction Date</th> -->
           <!-- <th>Country</th> -->
           <!-- <th>Report Generation Time</th> -->
           <!-- <th>Report Generation Time</th> -->
           <!-- <th>Total Subscriptions</th> -->
         </tr>
       </thead>
       <tbody>
        <tr *ngIf="reportList.length ==0 && loadingFlag" >
          <td style="text-align: center !important;" colspan="14">
            <app-custom-spinner></app-custom-spinner>
            </td>
        </tr>
        <tr *ngIf="!loadingFlag && reportList.length ==0" >
          <td style="text-align: center !important;" colspan="14">{{isLoad}}</td>
        </tr>

          <!-- <ng-template #showList> -->
            <tr *ngFor="let report of reportList, let j = index">
              <td>{{j+1}}</td>
              <td>{{report.subscriber_id}}</td>
              <td>{{report.subscriber_code}}</td>
              <td>{{report.stb_id}}</td>
              <td>{{report.ethernet_mac_address}}</td>
              <td>{{report.subscriber_name}}</td>
              <td>{{report.city}}</td>
              <td>{{report.state}}</td>
              <td>{{report.subscriber_status==1?'Active':'Inactive'}}</td>
              <td>{{report.mobile_no}}</td>
              <td>{{report.bouquet_id}}</td>
              <td>{{report.price_package_name}}</td>
              <td >{{report.subscription_type}}</td>
              <td>{{report.subscription_status==1?'Active':'Inactive'}}</td>
              <td style=" white-space: nowrap;"> {{report.transactionDate}}</td>
            <td style=" white-space: nowrap;">{{report.end_date}}</td>
              
              <!-- <td>{{report.country}}</td> -->
              <!-- <td>{{report.transactionDate | date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td> -->
              <!-- <td>{{report.price_package_name}}</td> -->
              <!-- <td>{{report.subscription_type'}}</td> -->
              <!-- <td style="overflow-x: scrroll;" >{{report.transactionDate}}</td> -->
              <!-- <td>{{report.channelName}}</td>
              <td>{{report.channelNo}}</td>
              <td>{{report.channelId}}</td>
              <td>{{report.subscriptionType}}</td>
              <td>{{report.subscriberCountAt7}}</td>
              <td>{{report.subscriberCountAt14}}</td>
              <td>{{report.subscriberCountAt21}}</td>
              <td>{{report.subscriberCountAt28}}</td>
              <td>{{(report.subscriberCountAt7+report.subscriberCountAt14+
                report.subscriberCountAt21+report.subscriberCountAt28)/4}}</td> -->
            </tr>
          <!-- </ng-template> -->
        </tbody>
      </table>
    </div>
  </div>
</div>


<div class="modal_box" *ngIf="viewModal">
  <div class="modal_box_inner">
    <!-- <h3>LCO Details</h3> -->
    <div class="parent">
      <!-- <p class="div1"></p> -->
      <div class="div1">
        <div class="row row-top">
          <div class="date_filter">
            <div class="div_tabsmodal" *ngIf="dateSelectormodal">
              <button (click)="dateTypeSelectorsmodal(1)" >Month</button>
              <button (click)="dateTypeSelectorsmodal(2)" >Year</button>
            </div>
            <div class="div_tabs2modal" *ngIf="showMonthmodal" >
             <div class="date_box">
              <label for="month">Select Month:</label>
              <div class="date_box_orig">
                <input class="custom__field mar_zeroed" type="month" id="month"  value="selectedDate" (change)="MonthFilter($event)">
              </div>
             </div>
              <button (click)="dateTypeSelectorsmodal(0)" >X</button>
            </div>
            <div class="div_tabs2modal" *ngIf="showDateRangemodal" >
              <div class="date_box">
                <label for="month">Select Year:</label>
                <div class="date_box_orig">
                  <select name="" id="yearCalc" (change)="yearFilter()" class="custom__field">
                    <option *ngFor="let item of yearsArray" value="{{ item }}">
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
              <button (click)="dateTypeSelectorsmodal(0)">X</button>
            </div>
          </div>
         
        </div>
      </div>
        <!-- </div> -->
      <!-- <p class="div3">Report Type</p>
      <p class="div4">
        <select class="custom__field" [(ngModel)]="reportType">
          <option disabled>None</option>
          <option value="Active_stb_report"> Active STB Report</option>
          <option value="Inactive_stb_report"> Inactive STB Report</option>
        </select>
      </p> -->
    </div>
   
   
    <button class="float-left" (click)="dateTypeSelectors(0);viewModal = !viewModal;this.reportType='';this.yearValue='';"> Close </button>
    <button class="cta float-right" (click)="generateReport()" [disabled]="reportType==''||yearValue==''"> Generate </button>
  </div>
</div>
