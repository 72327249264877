<section>
  <div class="flex">
    <div class="col1">

      <div class="filter__channel">
        <label class="not__required">Select Channels</label>
        <div class="filter__category">
          <div class="search__container">
            <input type="text" autocomplete="off" class="custom__field" [(ngModel)]="paginationObj.searchKeyword"
              (keyup.enter)="$event.preventDefault(); fetchPage()" />
            <button type="button"><img src="./assets/search.png" alt="search" (click)="fetchPage()" /></button>
          </div>
          <div class="filter__wrapper">
            <label class="not__required">Select All in this page</label>
            <label class="switch">
              <input type="checkbox" [(ngModel)]="selectAll" (ngModelChange)="selectAllToggle()">
              <span class=" slider round"></span>
            </label>
          </div>
          <!-- <div class="filter__wrapper">
            <label class="not__required">Show thumbnails</label>
            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="filter__wrapper">
            <label class="not__required">Show only Paid Channels</label>
            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="filter__wrapper">
            <label class="not__required">Sort by most viewed</label>
            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="filter__wrapper">
            <label class="not__required">Sort by most viewed</label>
            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div> -->
          <div class="filter__wrapper">
            <label for="pageSize" id="label-pageSize" class="not__required">Per page</label>
            <select name="" id="pageSize" class="custom__field" (change)="pageSizeChangeThroughSelect($event)" >
              <option value="null" selected disabled >--SELECT--</option>
              <option *ngFor="let item of paginationObj.pageSizeOptions" [value]="item">{{item}} channels</option>
            </select>
          </div>
        </div>

      </div>


    </div>

    <div class="col2">
      <div class="channel__selector">
        <div class="channel__card" *ngFor="let channel of channelList" (click)="channelGridClick(channel)"
          [ngClass]="{'selected__channel': channel.checked}">
          <div class="alt__card">
            <img [src]="prefix+channel.logo" onerror="this.src='assets/ImagewithoutLogo.png'" />
          </div>
          {{channel.name}}
        </div>
      </div>
      <div class="loader" *ngIf="loadingFlag">
        <app-custom-spinner></app-custom-spinner>
      </div>
      <div class="custom__pagination">
        <ul>
          <li>
            <button type="button" [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button type="button" [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button type="button" [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button type="button" [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>


    </div>

    <div class="col3">
      <div class="filter__channel">
        <label class="not__required">Selected Channels</label>
        <ul>
          <li *ngFor="let select of selectedList; let i = index" (click)="removeFromSelectedList(select, i)">
            {{select.name}}
          </li>
        </ul>
        <div class="filter__summary">
          Selected <span>{{selectedList.length}}</span> channels
        </div>
      </div>


    </div>
  </div>
</section>
