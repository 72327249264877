import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  PaginationObj,
  PaginationUtilities,
} from 'src/app/models/pagination/pagination-info';
import { SubscriberInfo } from 'src/app/models/subscriber/subscriber-info';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';
import {
  PageMetadata,
  PageInfo,
  SortOrder,
} from 'src/app/models/pagination/page-info';
import { delay } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { ngxCsv } from 'ngx-csv';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportTraiDataService } from 'src/app/report-trai/plugins/report-trai-data.service';

@Component({
  selector: 'app-list-subcriber',
  templateUrl: './list-subcriber.component.html',
  styleUrls: ['./list-subcriber.component.scss'],
})
export class ListSubcriberComponent implements OnInit {
  paginationObj = new PaginationObj();

  loadingFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  totalPages = 0;
  //Subscriber counts
  SubscriberActiveCount: number;
  SubscriberInActiveCount: number;
  TotalSubscriberCount: number;
  sortByProperties = {
    name: 'firstName',
    email: 'email',
    phone: 'phone',
    id: 'id',
    date: 'createdDate',
    status: 'status',
  };
  showSearch = false

  isLCO = false;
  subscriberList = [];

  deactivateCount = 0;

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private auth: AuthService,
    private config: ConfigService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private report: ReportTraiDataService,
  ) { }

  isDistributor = false;
  metadata;
  isLoad: string;
  menus
  menuAccess = false
  subscritionMenuAccess = false
  mailAccess = false
  osdAccess = false
  fingerprintAccess = false


  ngOnInit(): void {
    this.checkIfLCO();
    this.fetchPage();
    this.fetchSubscriberCounts();
    this.isLoad = 'Loading . . .';
    this.isDistributor = this.auth.isDistributor();
    this.menus = JSON.parse(atob(localStorage.getItem('menus')))
    this.checkMenuAccess()
  }

  checkMenuAccess() {
    for (let i of this.menus) {
      if (i.name == 'Subscriber') {
        this.menuAccess = (i.access == 0 ? false : true);
      }
      else if (i.name == 'OSD') {
        this.osdAccess = (i.access == 0 ? false : true);
      }
      else if (i.name == 'Fingerprint') {
        this.fingerprintAccess = (i.access == 0 ? false : true);
      }
      else if (i.name == 'Mail') {
        this.mailAccess = (i.access == 0 ? false : true);
      }
      else if(i.name == 'Subscription'){
        this.subscritionMenuAccess =  (i.access == 0 ? false : true);
      }
    }
    console.log(this.menuAccess)
  }

  fetchSubscriberCounts() {
    // // console.log(atob(localStorage.getItem('id')))
    let role = localStorage.getItem('ROLE');
    let url;
    if (role == 'MSO') {
      url =
        this.config.json.apiEndpointRdbms +
        'subscriber/getSubscriberTotalCountAndActiveCount/0';
    } else if (role == 'LCO') {
      let lcoId = atob(localStorage.getItem('id'));
      url =
        this.config.json.apiEndpointRdbms +
        'subscriber/getSubscriberTotalCountAndActiveCount/' +
        lcoId;
    } else if (this.auth.isDistributor()) {
      url =
        this.config.json.apiEndpointRdbms +
        'subscriber/getSubscriberTotalCountAndActiveCountByDistributor/' +
        this.auth.getLcoId();
    }
    this.http.get(url).subscribe((res) => {
      // // console.log(res)
      this.SubscriberActiveCount = res.data.activeSubscriberCount;
      this.SubscriberInActiveCount = res.data.inactiveSubscriberCount;
      this.TotalSubscriberCount = res.data.totalSubscriberCount;
    });
  }
  pageSize = 10;
  pageNumber = 1;

  checkIfLCO() {
    this.auth.isLCO() ? (this.isLCO = true) : (this.isLCO = false);
  }

  pageIncrease() {
    let obj = JSON.parse(localStorage.getItem('profileInfo'));
    let id = obj.id;
    console.log('Page Number' + this.pageNumber);
    console.log('Total Pages' + this.paginationObj.totalPages);
    if (this.pageNumber < this.paginationObj.totalPages) {
      this.pageNumber++;
      console.log(this.pageNumber);
      // const url = `http://192.168.10.9:7080/admin/api/subscriber/getSubscribers/${this.pageNumber}?pageSize=${this.pageSize}&sortBy=createdDate&sortOrder=DESC&searchKeyword=`
      // const url = this.config.json.apiEndpointRdbms+`subscriber/getSubscribers/${this.pageNumber}?pageSize=${this.pageSize}&sortBy=createdDate&sortOrder=DESC&searchKeyword=`
      let url = '';
      if (this.auth.isLCO())
        url =
          this.config.json.apiEndpointRdbms +
          'subscriber/getSubscribersByLCOId/' +
          this.paginationObj.pageNumber +
          '?pageSize=' +
          this.paginationObj.pageSize +
          '&lcoId=' +
          id +
          '&searchKeyword=';
      // const url = `http://192.168.10.9:7080/admin/api/subscriber/getSubscribers/${this.pageNumber}?pageSize=${this.pageSize}&sortBy=createdDate&sortOrder=DESC&searchKeyword=`
      else
        url =
          this.config.json.apiEndpointRdbms +
          `subscriber/getSubscribers/${this.pageNumber}?pageSize=${this.pageSize}&sortBy=createdDate&sortOrder=DESC&searchKeyword=`;

      this.http.get(url).subscribe((res) => {
        //// console.log(res)
        this.subscriberList = res['data'];
      });
    }
  }
  pageDecrease() {
    let obj = JSON.parse(localStorage.getItem('profileInfo'));
    let id = obj.id;
    if (this.pageNumber > 1) {
      this.pageNumber--;
      // const url = `http://192.168.10.9:7080/admin/api/subscriber/getSubscribers/${this.pageNumber}?pageSize=${this.pageSize}&sortBy=createdDate&sortOrder=DESC&searchKeyword=`
      // const url = this.config.json.apiEndpointRdbms+`subscriber/getSubscribers/${this.pageNumber}?pageSize=${this.pageSize}&sortBy=createdDate&sortOrder=DESC&searchKeyword=`
      let url = '';
      if (this.auth.isLCO())
        url =
          this.config.json.apiEndpointRdbms +
          'subscriber/getSubscribersByLCOId/' +
          this.paginationObj.pageNumber +
          '?pageSize=' +
          this.paginationObj.pageSize +
          '&lcoId=' +
          id +
          '&searchKeyword=';
      // const url = `http://192.168.10.9:7080/admin/api/subscriber/getSubscribers/${this.pageNumber}?pageSize=${this.pageSize}&sortBy=createdDate&sortOrder=DESC&searchKeyword=`
      else
        url =
          this.config.json.apiEndpointRdbms +
          `subscriber/getSubscribers/${this.pageNumber}?pageSize=${this.pageSize}&sortBy=createdDate&sortOrder=DESC&searchKeyword=`;

      this.http.get(url).subscribe((res) => {
        //// console.log(res)
        this.subscriberList = res['data'];
      });
    }
  }

  pageSizeChanger() {
    let obj = JSON.parse(localStorage.getItem('profileInfo'));
    let id = obj.id;
    var num = document.getElementById('pagesizesubmit') as HTMLInputElement;
    var numberina = parseInt(num.value);
    this.pageSize = numberina;
    let url = '';
    if (this.auth.isLCO())
      url =
        this.config.json.apiEndpointRdbms +
        'subscriber/getSubscribersByLCOId/' +
        this.paginationObj.pageNumber +
        '?pageSize=' +
        this.paginationObj.pageSize +
        '&lcoId=' +
        id +
        '&searchKeyword=';
    // const url = `http://192.168.10.9:7080/admin/api/subscriber/getSubscribers/${this.pageNumber}?pageSize=${this.pageSize}&sortBy=createdDate&sortOrder=DESC&searchKeyword=`
    else
      url =
        this.config.json.apiEndpointRdbms +
        `subscriber/getSubscribers/${this.pageNumber}?pageSize=${this.pageSize}&sortBy=createdDate&sortOrder=DESC&searchKeyword=`;
    this.http.get(url).subscribe((res) => {
      //// console.log(res)
      this.subscriberList = res['data'];
      this.paginationObj.totalPages = res.metadata.totalPageCount;
    });
  }

  statUS = ['Inactive', 'Active', 'Blacklisted', 'New', 'Terminated'];

  fetchPage(): void {
    if (this.auth.isMSO()) {
      this.fetchmso();
    }
    if (this.auth.isDistributor()) {
      this.fetchDistributor();
    }
    if (this.auth.isLCO()) {
      this.fetchLco();
    }
    if(this.auth.isAffiliator()){
      this.fetchAffiliator()
    }
  }


  fetchAffiliator() {
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url = PaginationUtilities.urlBuilder(
      'subscriber/getSubscribersByAffiliatorId',
      this.paginationObj
    );
    url += `&affiliatorId=${this.auth.getLcoId()}`;
    this.http.get(url).subscribe((res) => {
      this.handleFetchPage(res);
    });
  }

  fetchDistributor() {
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url = PaginationUtilities.urlBuilder(
      'subscriber/getSubscribersByDistributorId',
      this.paginationObj
    );
    url += `&distributorId=${this.auth.getLcoId()}`;
    this.http.get(url).subscribe((res) => {
      this.handleFetchPage(res);
    });
  }

  fetchmso(): void {
    // const url = PaginationUtilities.urlBuilderRdbms('subscriber/getSubscribers', this.paginationObj);
    const url =
      this.config.json.apiEndpointRdbms +
      'subscriber/getSubscribers/' +
      this.paginationObj.pageNumber +
      '?pageSize=' +
      this.paginationObj.pageSize +
      '&sortBy=' +
      this.paginationObj.sortBy +
      '&sortOrder=' +
      this.paginationObj.sortOrder +
      '&searchKeyword=' +
      this.paginationObj.searchKeyword;
    this.loadingFlag = true;

    this.http
      .get(url)
      .pipe(delay(250))
      .subscribe((data: PageInfo<SubscriberInfo>) => {
        // // console.log(data)
        this.loadingFlag = false;
        this.handleFetchPage(data);
      });
  }

  fetchLco(): void {
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url = PaginationUtilities.urlBuilder(
      'subscriber/getSubscribersByLCOId',
      this.paginationObj
    );
    url += `&lcoId=${this.auth.getLcoId()}`;
    this.http.get(url).subscribe((res) => {
      this.handleFetchPage(res);
    });
  }

  subscriberDetails: SubscriberDetails;
  viewModal = false;
  searchType
  searchText
  searchTextSelected = false;
  ViewSearchModel = false;
  searchSubscriberData


  addSubscription(subscriber,stb){
    console.log(stb.id)
    localStorage.setItem("stbId",btoa(stb.stb_id))
    this.router.navigateByUrl("subscription/add-subscription/"+ subscriber);
  }


  removeSubscription(subscriber,stb){
    localStorage.setItem("stbId",btoa(stb.stb_id))
    this.router.navigateByUrl("subscription/remove-subscription/"+ subscriber);
  }

  searchEnabled
  // closeSearch() {
  //   this.searchTextSelected = false;
  //   this.searchEnabled = false; 
  //   this.searchText = '';
  //   setTimeout(() => {
  //     this.searchEnabled = true; 
  //   }, 500);
  // }


  renewSubscription(subscriber,stb){
    localStorage.setItem("stbId",btoa(stb.stb_id))
    this.router.navigateByUrl("subscription/renew-subscription/"+ subscriber);
  }

  fetchSearchPage() {
    let url = this.config.json.apiEndpoint + 'subscriber/getUserDetails?searchType=' + this.searchType + '&searchText=' +
      this.searchText + '&userType=' + this.config.json.userType.toLowerCase() + '&userId=' + atob(localStorage.getItem('id'))
    this.http.get(url).subscribe(
      (res) => {
        if (res.statusCode == 200) {
          this.searchText='';
          this.searchType='';
          this.searchTextSelected=false;
          this.showSearch=false
          this.ViewSearchModel= true
          this.searchSubscriberData=res.data
          
        }
        else {
          alert(res.message)
        }
      }
    )
  }

  viewSubscriberDetails(subDetails: any) {
    let subscriberId = subDetails.subscriberId,
      url = `${this.config.json.apiEndpoint}subscriber/getSubscriberDocument`;
    this.http.post(url, { fromDate: '', toDate: '', subscriberId: subscriberId })
      .subscribe({
        next: (res: any) => {
          this.subscriberDetails = res.data as SubscriberDetails;
          this.viewModal = true;
        },
      });
  }

  swapStb(subDetails: any) {
    let subscriberId = subDetails.subscriberId
    this.router.navigateByUrl('manage/subscriber/swap-stb/' + subscriberId)
  }

  handleFetchPage(data: PageInfo<SubscriberInfo>): void {
    this.subscriberList = data.data;
    // // console.log("Subscriber List: ", this.subscriberList)
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
    this.countSubscriber();
  }

  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.pageSize = metadata.pageSize;
    this.paginationObj.pageNumber = metadata.pageNumber;
  }

  handlePaginationBtnDisableStatuses(): void {
    console.log(this.paginationObj);

    this.disableFirstBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;
    this.disablePreviousBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;

    // Right section
    this.disableNextBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
    this.disableLastBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  openImageInNewTab(str: string) {
    window.open(str);
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder =
      this.paginationObj.sortOrder === SortOrder.DESC
        ? SortOrder.ASC
        : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record: SubscriberInfo): void {
    // // console.log(record)
    // this.router.navigate(['subscriber', record["subscriber_id"]]);
    this.router.navigate(['/manage/subscriber', record['subscriberId']]);
  }

  sendMail(record: SubscriberInfo): void {
    this.router.navigate(['/communication/mail', 'create'], {
      queryParams: {
        subscriberId: record['subscriberId'],
      },
    });
  }

  sendOSD(record: SubscriberInfo): void {
    this.router.navigate(['/communication/osd', 'create'], {
      queryParams: {
        subscriberId: record.subscriberId,
      },
    });
  }

  sendFingerprint(record: SubscriberInfo): void {
    this.router.navigate(['/communication/fingerprint'], {
      queryParams: {
        subscriberId: record.subscriberId,
      },
    });
  }

  updateStatus(record: SubscriberInfo): void {
    record.status === 1 ? this.deactivate(record) : this.activate(record);
  }

  deactivate(record: SubscriberInfo): void {
    const url =
      this.config.json.apiEndpoint +
      'subscriber/deactivateAccount/' +
      record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 0;
      this.countSubscriber();
      window.alert('Account deactivated');
    });
  }

  activate(record: SubscriberInfo): void {
    const url =
      this.config.json.apiEndpoint + 'subscriber/activateAccount/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 1;
      this.countSubscriber();
      window.alert('Account activated');
    });
  }

  countSubscriber(): void {
    this.deactivateCount = this.subscriberList.filter(
      (x) => x.status === 0
    ).length;
  }

  copyToClipboard(subscriber: SubscriberInfo): void {
    const textArea = document.createElement('textarea');
    textArea.value = subscriber.id + '';
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    const ele = document.getElementById(subscriber.id + '') as HTMLElement;
    ele.innerText = 'Copied';
    setTimeout(() => {
      ele.innerText = subscriber.id + '';
    }, 500);
  }

  sendMailAll(): void {
    this.router.navigate(['/communication/mail', 'create'], {
      queryParams: {
        all: true,
      },
    });
  }

  sendOSDAll(): void {
    this.router.navigate(['/communication/osd', 'create'], {
      queryParams: {
        all: true,
      },
    });
  }

  searchKeyword() {
    let value = (document.getElementById('searchKeyword') as HTMLInputElement)
      .value;
      this.paginationObj.searchKeyword=value
    if (this.auth.isLCO()) {
      let obj = JSON.parse(localStorage.getItem('profileInfo'));
      let id = obj.id;
      let url =
        this.config.json.apiEndpointRdbms +
        'subscriber/getSubscribersByLCOId/1' +
        '?pageSize=' +
        this.paginationObj.pageSize +
        '&lcoId=' +
        id +
        '&searchKeyword=' +
        value;
      this.http.get(url).subscribe(
        (data) => {
          // // console.log(data)
          this.loadingFlag = false;
          this.subscriberList = data.data;
          // this.handleFetchPage(data);
        },
        (error) => {
          // //// console.log(error)
        }
      );
    } else {
      let url =
        this.config.json.apiEndpointRdbms +
        'subscriber/getSubscribers/1' +
        '?pageSize=' +
        this.paginationObj.pageSize +
        '&sortBy=' +
        this.paginationObj.sortBy +
        '&sortOrder=' +
        this.paginationObj.sortOrder +
        '&searchKeyword=' +
        value;
      this.loadingFlag = true;

      this.http
        .get(url)
        .pipe(delay(250))
        .subscribe((data: PageInfo<SubscriberInfo>) => {
          // // console.log(data)
          this.loadingFlag = false;
          this.handleFetchPage(data);
        });
    }
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: [        "Sl NO",     "subscriber ID",   "Subscriber Code",        "LCO Code",
        "Name",        "Phone Number",        "Packages",        "MAC Address",        "Status",        "STB ID",
        "Location",        "Created Date"]}

    let data = []
    let i = 1
    this.subscriberList.forEach(line => {
      let csvLine = {
        slno : i,
        id:line.subscriberId,
        service_asset_id: line.id,
        resource_code: line.subscriberCode,
        resource_name: line.lcoCode,
        channel_status: line.name,
        package_code: line.mobileNumber,
        bouquet_id: line.packages,
        package_name: line.ethernetMacAddress,
        transaction_date: line.status === 0 ?'InActive':'Active',
        stbId:line.stbId,
        locality:line.locality+line.city+line.district+ line.stateName,
        createdDate:line.createdDate
      }
      data.push(csvLine);
    });
    new ngxCsv(data, 'subscriber_list', options);
}


downloadTableExcel(): void {
  let name = 'Subscriber List ';
 
    this.doc.exportTableToExcelwithImageswithoutbuttonnames(
      this.reportTable,
      name,
      'subscriber_list' ,
      undefined,
      undefined,
    );
  }

downloadTablePdf(): void {
  if (
    this.subscriberList == null ||
    this.subscriberList == undefined ||
    this.subscriberList?.length == 0
  ) {
    alert('No Data!');
    return;
  }
  const reportTable = document.querySelector('#reportTable') as HTMLElement;

  if (!reportTable) {
    alert('Report table not found!');
    return;
  }
  const originalDisplay = reportTable.style.display;
  reportTable.style.display = 'table'; 

  this.pdf.generatePdfHtml(
    '#reportTable',
    'subscriber_list',
    `Source type : SMS`
  );

  reportTable.style.display = originalDisplay;

  
}


}

export type SubscriberDetails = {
  doc: any[];
  subscription: {
    ethernet_mac_address: string;
    stb_id: string;
    bouquetName: string;
  }[];
};
