<div class="cms__content">
  <app-reports-header></app-reports-header>
  <div class="top__heading">
    <h3>Subscribers List Report</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row row-top">
    <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
      <label class="report_selection_label">Status</label>
      <select class="custom__field report_selection" [(ngModel)]="statusValue" id="statusChangeApi"
        (change)="fetchData()">
        <option value="5">ALL</option>
        <option value="1">Active</option>
        <option value="0">Inactive</option>
        <option value="4">Terminated</option>
        <option value="3">New</option>
        <option value="2">Blacklisted</option>
      </select>
    </div>

    <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
      <label class="report_selection_label">Select LCO</label>
      <select class="custom__field report_selection" id="statusChangeApi" [(ngModel)]="lcoValue" (change)="fetchData()">
        <option value="0">ALL</option>
        <option *ngFor="let lco of lcoList" [ngValue]="lco.id">
          {{ lco.name }}
        </option>
      </select>
    </div>

   
  </div>
  

  <div class="third_box">
    <div class="col-12 col-md-6 report">
      <p>
        Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
      </p>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>
   
  </div>

  <div class="col-12 list__table">
    <div class="row">
      <div class="custom__table__filter">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <!-- -->
        <div class="col-12 col-md-6 table-search">
          <div class="table__right">
            <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> record{{reportList.length == 1?
              '' : 's'}}</p>
            <!-- <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}} -->
            <!-- </p> -->
          </div>
          <div class="search__container">
            <input type="text" class="custom__field" id="searchKeyword" (keydown.enter)="onSearch()"
              [(ngModel)]="paginationObj.searchKeyword" />
            <button (click)="onSearch()" (keydown.enter)="onSearch()">
              <img src="./assets/search.png" alt="search" />
            </button>
          </div>
        </div>
      </div>
      <div class="scroll">
      <table class="custom__iptv__table" #reportTable id="print">
        <thead>
          <tr>
            <th class="label__align">Sl no</th>
            <th class="label__align">Created Date</th>
            <th class="label__align">Subscriber ID</th>
            <th class="label__align">Name</th>
            <th class="label__align">LCO Code</th>
            <th class="label__align">City</th>
            <th class="label__align">Area</th>
            <th class="label__align">State Name</th>
            <th class="label__align">Phone Number</th>
            <th class="label__align">Bouquets</th>
            <th class="label__align">Connection Count</th>
            <th class="label__align">Status</th>
            <th class="label__align">STB ID</th>

            <!-- <th class="label__align" (click)="sortByItem(sortByProperties.createdDate)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.createdDate}">Created Date</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.subscriberId)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.subscriberId}">Subscriber ID</th>
            
            <th class="label__align" (click)="sortByItem(sortByProperties.subscriberName)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.subscriberName}">Name</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.city)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.city}">City</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.locality)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.locality}">Area</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.state)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.state}">State Name</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.mobileNumber)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.mobileNumber}">Phone Number</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.packages)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.packages}">Bouquets</th>
              <th>Connection Count</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.status)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.status}">Status</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.stb_id)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.stb_id}">STB ID</th> -->
          </tr>
        </thead>
        <tbody>
          <!-- <tr *ngIf="reportList.length ==0" >
              <td style="text-align: center !important;" colspan="11">
                <app-custom-spinner></app-custom-spinner>
                </td>
            </tr> -->
          <tr *ngIf="reportList.length == 0 && loadingFlag">
            <td style="text-align: center !important" colspan="13">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <tr *ngIf="!loadingFlag && reportList.length == 0">
            <td style="text-align: center !important" colspan="13">
              {{ isLoad }}
            </td>
          </tr>
          <tr *ngFor="let report of reportList; let j = index">
            <td>{{ j + 1 }}</td>
            <td>{{report.createdDate?(report.createdDate| date:'dd-MM-yyyy hh:mm:ss'):"---"}}</td>
            <td>{{ report.subscriberId ? report.subscriberId : "---" }}</td>
            <td style="text-wrap: nowrap; word-break: keep-all;">
              {{
              report.subscriberName || report.subscriberName
              ? report.subscriberName || report.subscriberName
              : "---"
              }}
            </td>
            <td>{{report.lcoCode?report.lcoCode:'---'}}</td>
            <!-- <td>{{ report.cafNumber ? report.cafNumber : "---" }}</td> -->
            <td>{{ report.city ? report.city : "---" }}</td>
            <td>{{report.locality? report.locality:"---"}}</td>
            <td>{{ report.state ? report.state : "---" }}</td>
            <td>{{ report.mobileNumber ? report.mobileNumber : "---" }}</td>

            <td style="overflow-wrap: break-word;">{{ report.packages ? report.packages : "No Subscription" }}</td>
            <td>{{ report.stbIds ? (report.stbIds.split(',').length): '0' }}</td>
            <td>{{report.status == 0? "Inactive"
              : report.status == 1
              ? "Active"
              : report.status == 2
              ? "Blacklisted"
              : report.status == 3
              ? "New"
              : "Terminated"
              }}
            </td>
            <!-- <td style="word-wrap: break-word; word-break: break-all;">
              {{
              report.ethernetMacAddress ? report.ethernetMacAddress : "---"
              }}
            </td> -->
            <td>{{report.stbIds?report.stbIds:"---"}}</td>
          </tr>
          <tr *ngIf="!loadingFlag && reportList.length === []">
            <td style="text-align: center !important" colspan="11">
              No Records.
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>