import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { CategoryInfo } from 'src/app/models/category/category-info';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-category',
  templateUrl: './list-category.component.html',
  styleUrls: ['./list-category.component.scss']
})
export class ListCategoryComponent implements OnInit {

  paginationObj = new PaginationObj();

  loadingFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  sortByProperties = {
    name: 'name',
    desc: 'description',
    date: 'createdDate',
    status: 'status'
  };
  menus
  menuAccess = false


  categoryList: CategoryInfo[] = [];

  activeCategoryCount = 0;
  //category counts
  CategoryActiveCount: number;
  CategoryInActiveCount: number;
  TotalCategoryCount: number;

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchPage();
    this.fetchCategoryCount();
    this.menus=JSON.parse(atob(localStorage.getItem('menus')))
    this.checkMenuAccess()
  }

  checkMenuAccess(){
    for(let i of this.menus){
      if(i.name=='Category')
      {
        this.menuAccess=(i.access==0?false:true);
        console.log(this.menuAccess)
      }
    }
    console.log(this.menuAccess)
  }

  fetchCategoryCount() {
    const url = this.config.json.apiEndpointRdbms + 'category/getCategoriesTotalCountAndActiveCount'
    this.http.get(url).subscribe((res) => {
      // // console.log(res)
      this.CategoryActiveCount = res.data.activeCategoryCount
      this.CategoryInActiveCount = res.data.inactiveCategoryCount
      this.TotalCategoryCount = res.data.categoryTotalCount


    });
  }

  onSearch(){
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }

  fetchPage(): void {
    // const url = PaginationUtilities.urlBuilderRdbms('category/getCategories', this.paginationObj);
    const url = this.config.json.apiEndpointRdbms + 'category/getCategories/' + this.paginationObj.pageNumber + '?pageSize=' + this.paginationObj.pageSize + '&sortBy=' + this.paginationObj.sortBy + '&sortOrder=' + this.paginationObj.sortOrder + '&searchKeyword=' + this.paginationObj.searchKeyword
    this.loadingFlag = true;

    this.http.get(url).pipe(delay(500)).subscribe((data: PageInfo<CategoryInfo>) => {
      this.loadingFlag = false;
      this.handleFetchPage(data);
    });
  }

  handleFetchPage(data: PageInfo<CategoryInfo>): void {
    this.categoryList = data.data;
    // //// console.log(this.categoryList)
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
    this.countCategory();
  }

  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record: CategoryInfo): void {
    this.router.navigate(['/content/category', record.id]);
  }


  // updateStatus(record: CategoryInfo): void {
  //   record.status === 1 ? this.deactivate(record) : this.activate(record);
  // }

  deactivate(record: CategoryInfo): void {
    const url = this.config.json.apiEndpoint + 'category/deactivate/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 0;
      this.countCategory();
      window.alert('Deactivated');
    });
  }

  activate(record: CategoryInfo): void {
    const url = this.config.json.apiEndpoint + 'category/activate/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 1;
      this.countCategory();
      window.alert('Activated');
    });
  }

  countCategory(): void {
    this.activeCategoryCount = this.categoryList.filter(cat => cat.status).length;
  }

  delete(category: CategoryInfo): void {
    if(!confirm("Are you sure you want to delete")){
      return
    }
    const url = this.config.json.apiEndpointRdbms + 'category/deleteCategory/' + category.id;
    this.http.delete(url).subscribe(data => {
      // //// console.log(data)
      this.fetchPage();
      window.alert('Deleted!');
    });
  }

}
