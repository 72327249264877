<div class="cms__content">
  <app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>STB Logs</h3>
    <a [routerLink]="['/logs']"><button class="default__button float-right">Back</button></a>
  </div>

  <div class="row">
    <div class="filter__group col-12 col-md-6">
      <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>


    <!-- <div class="col-12 col-md-6 report_selection_block">
            <label class="report_selection_label">Select STB MAC Address</label>
            <select class="custom__field report_selection" [(ngModel)]="selectedStbMacAddress" (change)='changeMacAddress()'>
                <option *ngFor="let mac of macAddressList" [ngValue]='mac.id'>{{mac.name}}</option>
            </select>
        </div> -->

  </div>

  <div class="col-12 report">
    <p>Report generated on : {{currentDate | date:'dd-MM-yy HH:mm:ss'}}</p>
  </div>
  <div class="custom__table__filter">
    <span>
      Show
      <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
        <option
          *ngFor="let size of paginationObj.pageSizeOptions"
          [ngValue]="size"
        >
          {{ size }}
        </option>
      </select>
      Entries
    </span>
    <div class="table__right">
      <p class="table__summary">
        Total <span>{{ paginationObj.totalElements }}</span> records
      </p>
      <div class="search__container">
        <input
          type="text"
          autocomplete="off"
          class="custom__field"
          id="searchKeyword"
          [(ngModel)]="paginationObj.searchKeyword"
          (keyup.enter)="searchKeyWord()"
        />
        <button (click)="searchKeyWord()">
          <img src="./assets/search.png" alt="search" />
        </button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <!-- <th>STB ID</th> -->
            <th>Sl No.</th>
            <th>Subscriber Code</th>
            <th>STB Mac address</th>
            <th>Command</th>
            <!-- <th>STB Level</th>
            <th>Status</th> -->
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList.length ==0 && !loadingFlag" >
            <td style="text-align: center !important;" colspan="12" >{{isLoad}}</td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important;" colspan="12">
            <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <ng-template #showList>
            <tr *ngFor="let report of reportList, let j= index">
              <!-- <td>{{report.stbId? report.stbId : '---'}}</td> -->
               <td>{{(paginationObj.pageNumber - 1) * paginationObj.pageSize +(j + 1)}}</td>
              <td>{{report.subscriberCode? report.subscriberCode : '---' }}</td>
              <td>{{report.ethernetMac? report.ethernetMac : '---'}}</td>
              <td>{{report.description ? report.description:'---'}}</td>
              <!-- <td>{{report.stbLevel? report.stbLevel : '---'}}</td> -->
              <!-- <td>{{ report.STATUS == 0 ? 'Inactive' : report.STATUS == 1 ? 'Active' : 'Terminated' }}</td> -->
              <!-- <td>{{report.commandStb === 0 ? "Active" : "Inactive"}}</td> -->
              <td>{{report.createdDate ?report.createdDate:'---' }}</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
    <div class="custom__pagination">
      <ul>
        <li>
          <button [disabled]="disableFirstBtn" (click)="pageFirstBtnClick()">
            <img src="./assets/firstPage.png" />
          </button>
        </li>
        <li>
          <button [disabled]="disablePreviousBtn" (click)="pageDecrease()">
            <img src="./assets/previousPage.png" />
          </button>
        </li>
        <li>
          <span
            >Page {{ paginationObj.pageNumber }} of
            {{ paginationObj.totalPages }}</span
          >
        </li>
        <li>
          <button [disabled]="disableNextBtn" (click)="pageIncrease()">
            <img src="./assets/nextPage.png" />
          </button>
        </li>
        <li>
          <button [disabled]="disableLastBtn" (click)="pageLastBtnClick()">
            <img src="./assets/lastPage.png" />
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
