<div class="cms__content">
	<app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Channels List</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <!-- <div class="filter-download_container">
    <div class="filter_container">
      <div class="label-container">
        <label class="">Select Broadcaster:</label>
      </div>
      <select class="custom__field report_selection" [(ngModel)]="broadcasterId" id="broadcasterselected" (change)='fetchData()'>
        <option value="0">ALL</option>
        <option *ngFor="let lco of broadcasterList" value={{lco.id}} >
          {{lco.name}}
        </option>
      </select>
      <div class="label-container">
        <label for="">Select Channel Type :</label>
      </div>
      <select class="custom__field report_selection" id="statusChangeApi"  [(ngModel)]="statusValue" (change)="fetchData()" >
        <option value="2">ALL</option>
        <option value="1">Active</option>
        <option value="0">Inactive</option>
      </select>
      <div class="div label-container">
        <label class="report_selection_label">Status:</label>
      </div>
      <select class="custom__field report_selection" id="statusChangeApi"  [(ngModel)]="channelType" (change)="fetchData()" >
        <option value="2">ALL</option>
        <option value="1">Paid</option>
        <option value="0">Free</option>
      </select>
      
    </div>
  </div> -->
  <div class="row row-top">
    <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
      <label class="report_selection_label"> Broadcaster : </label>
      <select class="custom__field report_selection" [(ngModel)]="broadcasterId" id="broadcasterselected" (change)='fetchData()'>
        <option value="0">ALL</option>
        <option *ngFor="let broadcaster of broadcasterList" value={{broadcaster.id}} >
          {{broadcaster.name}}
        </option>
      </select>
    </div>

    <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
      <label class="report_selection_label" style="margin-right: 1%;"> Channel Type : </label>
      <select class="custom__field report_selection" id="statusChangeApi"  [(ngModel)]="channelType" (change)="fetchData()" >
        <option value="2">ALL</option>
        <option value="1">Paid</option>
        <option value="0">Free</option>
      </select>
    </div>

    <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
      <label class="report_selection_label" style="margin-right: 1%;"> Status : </label>
      <select class="custom__field report_selection" id="statusChangeApi"  [(ngModel)]="statusValue" (change)="fetchData()" >
        <option value="2">ALL</option>
        <option value="1">Active</option>
        <option value="0">Inactive</option>
      </select>
    </div>
  </div>
  
  <div class="row">
    <div class="col-12 col-md-6 report">
      <p>Report generated on : {{currentDate | date:'dd-MM-yy HH:mm:ss'}}</p>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
				CSV
				<img src="./assets/csv.svg" alt="download" />
			</button>
    </div>
  </div>
  <div class="third_box">
    
  <div class="col-12 list__table">
    <div class="row overflow">
      <div class="custom__table__filter">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <!-- -->
        <div class="col-12 col-md-6 table-search">
          <div class="table__right">
            <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> record{{reportList.length == 1?
              '' : 's'}}</p>
            <!-- <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}} -->
            <!-- </p> -->
          </div>
          <div class="search__container">
            <input type="text" class="custom__field" id="searchKeyword" (keydown.enter)="onSearch()" [(ngModel)]="paginationObj.searchKeyword" />
            <button (click)="onSearch()" (keydown.enter)="onSearch()">
              <img src="./assets/search.png" alt="search" />
            </button>
          </div>
        </div>
      </div>
      <table class="custom__iptv__table" #reportTable id="print">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.channelId)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.channelId}">Channel ID</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.channelName)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.channelName}">Channel Name</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.broadcasterName)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.broadcasterName}">Broadcaster Name</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.channelLCN)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.channelLCN}">Channel LCN</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.channelStatus)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.channelStatus}">Status</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.channelType)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.channelType}">Channel Type</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.language)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.language}">Language</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.genre)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.genre}">Genre</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.description)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.description}">Description</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.createdDate)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.createdDate}">Created Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="channelReportList.length ==0" >
            <td colspan="11" style="text-align: center;" >
              No Data
              <!-- <app-custom-spinner></app-custom-spinner> -->
            </td>
          </tr>
            <tr *ngFor="let report of channelReportList, let j=index">
              <td>{{j+1}}</td>
              <td>{{(report.channel_id || report.channelId) == null || (report.channel_id || report.channelId) == 'null' || (report.channel_id || report.channelId) == ''? '---' : (report.channel_id || report.channelId) }}</td>
              <td>{{(report.channelName) == null || (report.channelName) == 'null' || (report.channelName) == ''? '---' : (report.channelName|| report['channelName'])}}</td>
              <td>{{(report.broadcasterName || report['broadcaster_name']) == null || (report.broadcasterName || report['broadcaster_name']) == 'null' || (report.broadcasterName || report['broadcaster_name']) == ''? '---' : (report.broadcasterName || report['broadcaster_name'])}}</td>
              <td>{{(report.channelLCN || report.channelLCN) == null || (report.channelLCN || report.channelLCN) == 'null' || (report.channelLCN || report.channelLCN) == ''? '---' : (report.channelLCN || report.channelLCN)}}</td>
              <td>{{report.channelStatus?report.channelStatus:"---"}}</td>
              <td>{{(report.channelType || report['channelType']) == null || (report.channelType || report['channelType']) == 'null' || (report.channelType || report['channelType']) == '' ? '---' : (report.channelType || report['channelType'])}}</td>
              <td>{{(report.language || report['LANGUAGE']) == null || (report.language || report['LANGUAGE']) == 'null' || (report.language || report['LANGUAGE']) == '' ? '---' : (report.language || report['LANGUAGE'])}}</td>
              <td>{{(report.genre || report['GENERE']) == null || (report.genre || report['GENERE']) == 'null' || (report.genre || report['GENERE']) == '' ? '---' : (report.genre || report['GENERE'])}}</td>
              <td>{{(report.description || report['DESCRIPTION']) == null || (report.description || report['DESCRIPTION']) == 'null' || (report.description || report['DESCRIPTION']) == '' ? '---' : (report.description || report['DESCRIPTION']) }}</td>
              <td>{{(report.createdDate || report.createdDate) == null || (report.createdDate || report.createdDate) == 'null' || (report.createdDate || report.createdDate) == ''? '---' : (report.createdDate || report.createdDate) | date: 'dd-MM-yy HH:mm:ss' : 'UTC' }}</td>
            </tr>
        </tbody>
      </table>

      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>
