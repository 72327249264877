<div class="cms__content">
    <app-reports-header></app-reports-header>
  
    <div class="top__heading">
      <h3>Channel Modification Logs</h3>
      <a [routerLink]="['/logs']"><button class="default__button float-right">Back</button></a>
    </div>
    <div class="clearfix"></div>
  
    <!-- <div class="row">
      <div class="filter__group col-12 col-md-6">
        <label id="select_date">Select Date :</label>
        <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
      </div>
      <div class="download__report col-md-6">
        <button
          class="default__button"
          (click)="downloadTablePdf()"
          title="Download PDF"
        >
          PDF
          <img src="./assets/pdf.svg" alt="download" />
        </button>
        <button
          class="default__button"
          (click)="downloadTableExcel()"
          title="Download Excel"
        >
          Excel
          <img src="./assets/excel.svg" alt="download" />
        </button>
        <button
          class="default__button"
          (click)="downloadTableCSV()"
          title="Download Excel"
        >
          CSV
          <img src="./assets/csv.svg" alt="download" />
        </button>
      </div>
      <div class="col-12 col-md-6 report_selection_block">
        <label class="report_selection_label" style="width: 10rem; padding: 0"
          >Select Broadcaster :</label
        >
        <select
          class="custom__field report_selection"
          (change)="listItem($event)"
        >
          <option value="0">All</option>
          <option *ngFor="let bc of broadcasterList" value="{{ bc.id }}">
            {{ bc.name }}
          </option>
        </select>
      </div>
    </div> -->
  
    <div class="row row-top">
      <div class="date_filter">
        <div class="div_tabs" *ngIf="dateSelector">
          <!-- <button (click)="dateTypeSelectors(1)">Till Date</button> -->
          <button (click)="dateTypeSelectors(2)">Random Date</button>
          <button (click)="dateTypeSelectors(3)">Year</button>
        </div>
        <div class="div_tabs2" *ngIf="showMonth">
          <div class="date_box">
            <label for="month">Till date:</label>
            <div class="date_box_orig">
              <input class="custom__field mar_zeroed" type="date" id="month" value="selectedDate"
                (change)="dateChangeMonth($event)" />
            </div>
          </div>
          <button (click)="dateTypeSelectors(0)">×</button>
        </div>
        <div class="div_tabs2" *ngIf="showDateRange">
          <label for="dateRange"> Select Date : </label>
          <div class="date_box_orig date_box_orig2">
            <app-date-picker id="mar_zeroeds" (dateChange)="dateChangeRange($event)"></app-date-picker>
          </div>
          <button id="app_date_pick_button" (click)="dateTypeSelectors(0)">
            ×
          </button>
        </div>
        <div class="div_tabs2" *ngIf="showYearSelector">
          <label for=""> Select Year </label>
          <select name="" id="yearCalc" (change)="yearFilter()">
            <option *ngFor="let item of yearsArray" value="{{ item }}">
              {{ item }}
            </option>
          </select>
          <button (click)="dateTypeSelectors(0)">×</button>
        </div>
      </div>
      <div class="download__report col-md-6">
        <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
          PDF
          <img src="./assets/pdf.svg" alt="download" />
        </button>
        <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
          Excel
          <img src="./assets/excel.svg" alt="download" />
        </button>
        <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
          CSV
          <img src="./assets/csv.svg" alt="download" />
        </button>
      </div>
  
  
  
  
      <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
        <label class="report_selection_label"> Type: </label>
        <select class="custom__field report_selection" [(ngModel)]="selectBouquetType" (change)='fetchData()'>
          <option value="All">ALL</option>
          <option value="Created">Created</option>
          <option value="Modified">Modified</option>
        </select>
      </div>
  
      <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
        <label class="report_selection_label" style="margin-right: 1%;"> Broadcaster: </label>
        <select class="custom__field report_selection" (change)="listItem($event)">
          <option value="0">All</option>
          <option *ngFor="let bc of broadcasterList" value="{{ bc.id }}">
            {{ bc.name }}
          </option>
        </select>
      </div>
  
  
    </div>
  
    <!-- <div class="col-12 report">
      <p>Report generated on : {{ currentDate | date : "longDate" }}</p>
    </div> -->
  
    <div class="col-12 report">
      <p>
        Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
      </p>
    </div>
  
    <div class="custom__table__filter">
      <span>
        Show
        <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
          <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">
            {{ size }}
          </option>
        </select>
        Entries
      </span>
      <div class="table__right">
        <p class="table__summary">
          Total <span>{{ paginationObj.totalElements }}</span> records
        </p>
        <div class="search__container">
          <input type="text" autocomplete="off" class="custom__field" id="searchKeyword"
            [(ngModel)]="paginationObj.searchKeyword" (keyup.enter)="searchKeyWord()" />
          <button (click)="searchKeyWord()">
            <img src="./assets/search.png" alt="search" />
          </button>
        </div>
      </div>
    </div>
  
    <div class="col-12 list__table">
      <div class="row scroll">
        <table class="custom__iptv__table" #reportTable id="reportTable">
          <thead>
            <tr>
              <th (click)="sortByItem(sortByProperties.category)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.category}">Sl No.</th>
              <th (click)="sortByItem(sortByProperties.broadcasterName)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.broadcasterName}">Broadcaster Name</th>
                <th (click)="sortByItem(sortByProperties.channelId)" [ngClass]="{
                  selected: paginationObj.sortBy === sortByProperties.channelId}">Channel ID</th>
              <th (click)="sortByItem(sortByProperties.channelName)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.channelName}">Channel Name</th>
             
              <th (click)="sortByItem(sortByProperties.channelNo)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.channelNo}">Channel Number</th>
              <th (click)="sortByItem(sortByProperties.ACTION)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.ACTION}">Action</th>
              <th (click)="sortByItem(sortByProperties.fromDate)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.fromDate}">Transaction Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="reportList.length == 0 && !loadingFlag">
              <td style="text-align: center !important" colspan="12">
                {{ isLoad }}
              </td>
            </tr>
            <tr *ngIf="loadingFlag; else showList">
              <td style="text-align: center !important" colspan="12">
                <app-custom-spinner></app-custom-spinner>
              </td>
            </tr>
            <ng-template #showList>
              <tr *ngFor="let report of reportList; let i = index">
                <td>{{(paginationObj.pageNumber - 1) * paginationObj.pageSize +(i + 1)}}</td>
                <td> {{ report.broadcasterName ? report.broadcasterName : "---" }}</td>
                <td>{{ report.channelId ? report.channelId : "---" }}</td>
                <td>{{ report.channelName ? report.channelName : "---" }}</td>
                <td>{{ report.channelNo ? report.channelNo : "---" }}</td>
                <td>
                  {{
                  report.action || report.ACTION
                  ? report.action || report.ACTION
                  : "---"
                  }}
                </td>
                <td>
                  {{ report.fromDate | date : "dd-MM-yyyy HH:mm:ss" : "UTC" }}
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
  
        <div class="custom__pagination">
          <ul>
            <li>
              <button [disabled]="disableFirstBtn" (click)="pageFirstBtnClick()">
                <img src="./assets/firstPage.png" />
              </button>
            </li>
            <li>
              <button [disabled]="disablePreviousBtn" (click)="pageDecrease()">
                <img src="./assets/previousPage.png" />
              </button>
            </li>
            <li>
              <span>Page {{ paginationObj.pageNumber }} of
                {{ paginationObj.totalPages }}</span>
            </li>
            <li>
              <button [disabled]="disableNextBtn" (click)="pageIncrease()">
                <img src="./assets/nextPage.png" />
              </button>
            </li>
            <li>
              <button [disabled]="disableLastBtn" (click)="pageLastBtnClick()">
                <img src="./assets/lastPage.png" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>