// LCO
const lcoMainMenu = [
  'Plugins',
  'Content',
  'Reports',
  'Communication',
  'Batch Jobs',
];
const lcoSubMenu = [
  'MSO',
  'Distributor',
  'LCO',
  'Location',
  'Distributor',
  'General Settings',
  'Access Control',
  'Ad Manager',
  'Form Request',
  'OTA Manager',
];

//MSO
const msoMainMenu = ['Plugins'];
const msoSubMenu = ['Bouquet Management'];

//Distributor
const distributorMainMenu = [
  'Plugins',
  'Content',
  'Reports',
  'Communication',
  'Batch Jobs',
  'Subscription',
];
const distributorSubMenu = [
  'MSO',
  'Subscriber Groups',
  'Distributor',
  'General Settings',
  'Access Control',
  'Ad Manager',
  'OTA Manager',
  'Invoice',
  'Form Request'
];

export default {
  lcoMainMenu,
  lcoSubMenu,
  msoMainMenu,
  msoSubMenu,
  distributorMainMenu,
  distributorSubMenu,
};

/* 
{
  'lcoMainMenu': [ 'Plugins', 'Content', 'Reports', 'Communication', 'Batch Jobs' ],
  'lcoSubMenu': [
    'MSO',
    'Distributor',
    'LCO',
    'Location',
    'Distributor',
    'General Settings',
    'Access Control',
    'Ad Manager',
    'OTA Manager'
  ],
  'msoMainMenu': [ 'Plugins' ],
  'msoSubMenu': [ 'Bouquet Management' ],
  'distributorMainMenu': [
    'Plugins',
    'Content',
    'Reports',
    'Communication',
    'Batch Jobs',
    'Subscription'
  ],
  'distributorSubMenu': [
    'MSO',
    'Location',
    'Distributor',
    'General Settings',
    'Access Control',
    'Ad Manager',
    'OTA Manager'
  ]
}
*/