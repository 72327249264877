<div class="cms__content">
  <app-reports-header></app-reports-header>
  <div class="top__heading">

    <h3>STB Location Wise Report</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <div class="filter__group col-12 col-md-6">
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
        CSV
        <img src="./assets/csv.svg" alt="download" /> </button>
    </div>
  </div>

  <div class="form__style">
    <div class="row">
      <div class="col-12 col-md-6 report_selection_block">
        <label class="report_selection_label">Select Location</label>
        <select class="custom__field report_selection" [(ngModel)]="selectedLocation" (change)='lcoSelect()' id="lco">
          <!-- Changed ngValue binding from lco.id to lco.email -->
          <option value='0'>ALL</option>
          <option *ngFor="let lco of locationList" [ngValue]='lco.city + "-"+lco.state+"-" +lco.pincode' value="{{lco}}">
            {{lco.city}} - {{lco.state}} [ {{lco.pincode}} ]
          </option>
        </select>
      </div>
      <div class="col-md-6 report_selection_block">
        <label class="report_selection_label">Subscriber Status</label>
        <select class="custom__field report_selection" id="status" (change)="statusChange()">
          <option value="5">ALL</option>
          <option value="0">Inactive</option>
          <option value="1">Active</option>
          <option value="2">Blacklist</option>
          <option value="3">New</option>
          <option value="4">Terminated</option>
        </select>
      </div>
    </div>
  </div>

  <!-- report gen and search -->
  <div class="row">
    <div class="col-12 col-md-6">
      <p>
        Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
      </p>
    </div>
    <div class="col-12 col-md-6 table-search">
      <div class="table__right">
        <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
      </div>
      <div class="search__container">
        <input
          type="text"
          class="custom__field"
          id="searchKeyword"
          (keydown.enter)="searchKeyword()"
        />
        <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()">
          <img src="./assets/search.png" alt="search" />
        </button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="print">
        <thead>
          <tr>
            <!-- <th>Date</th> -->
            <th>Sl No.</th>
            <th>Subscriber Name</th>
            <th>STB MAC</th>
            <th>Subscriber Code</th>
            <th>Mobile Number</th>
            <!-- <th>STB Serial No</th> -->
            <!-- <th>Locality</th> -->
            <th>City</th>
            <th>Area</th>
            <th>Subscriber Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList.length ==0 && !loadingFlag">
            <td style="text-align: center !important;" colspan="8">{{isLoad}}</td>
          </tr>
          <tr *ngIf="loadingFlag">
            <td style="text-align: center !important;" colspan="8">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <tr *ngFor="let report of reportList, let j = index">
            <td>{{j+1}}</td>
            <!-- <td>{{report.firstName + ' ' + report.lastName}}</td> -->
            <td>{{(report.fullName || report.full_name) == 'null' || (report.fullName || report.full_name) == null ||
              (report.fullName || report.full_name) == '' ? '---' : (report.fullName || report.full_name)}}</td>
            <td>{{(report.ethernetMac || report.ethernet_mac_address) == 'null' || (report.ethernetMac ||
              report.ethernet_mac_address) == null || (report.ethernetMac || report.ethernet_mac_address) == '' ? '---'
              : (report.ethernetMac || report.ethernet_mac_address) }}</td>
            <td>{{(report.subscriberCode || report.subscriber_code) == 'null' || (report.subscriberCode ||
              report.subscriber_code) == null || (report.subscriberCode || report.subscriber_code) == ''? '---' :
              (report.subscriberCode || report.subscriber_code)}}</td>
            <td>{{(report.mobileNumber || report.mobile_number) == null || (report.mobileNumber || report.mobile_number)
              == 'null' || (report.mobileNumber || report.mobile_number) == '' ? "---" : (report.mobileNumber ||
              report.mobile_number)}}</td>
            <!-- <td>{{report.serialNo}}</td> -->
            <!-- <td>{{report.locality}}</td> -->
            <td>{{(report.city || report['CITY']) == null || (report.city || report['CITY']) == 'null' || (report.city
              || report['CITY']) == ''? "---" : (report.city || report['CITY'])}}</td>
            <td>{{(report.locality || report['LOCALITY']) == null || (report.locality || report['LOCALITY']) == 'null' || (report.locality
              || report['LOCALITY']) == ''? "---" : (report.locality || report['LOCALITY'])}}</td>
            <td>{{ getStatusValue(report.subscriber_status)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>