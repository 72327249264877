<div class="cms__content">
    <div class="top__heading">
        <h3>Swap Stb For Subscriber</h3>
        <a [routerLink]="['/manage/subscriber/list']"><button type="button" class="default__button float-right">
                Back
            </button></a>
    </div>
    <form action="" [formGroup]="swapSubscriptionForm">
        <div class="col-12 form__style">
            <div class="row">
                <div class="col-12">
                    <label for="">subscriber</label>
                    <input class="custom__field" autocomplete="off" type="text" formControlName="subscriberId" readonly>
                </div>
               
                <div class="col-12">
                    <label for="">Select From STB</label>
                    <select name="" id="" class="custom__field" formControlName="fromStbId" (change)="getToStb()">
                        <option value="" selected disabled>--SELECT--</option>
                        <option *ngFor="let stb of fromstbList" [value]="stb.id">{{'Mac Address: '+stb.ethernetMac+", STB
                            ID: "+stb.id+", Device Type: "+stb.deviceType}}
                        </option>
                    </select>
                </div>



                <div class="col-12" *ngIf="fromStbSelected">
                    <label for="">Select to STB</label>
                    <select name="" id="" class="custom__field" formControlName="toStbId" (change)="validateSelectedStb()">
                        <option value="" selected disabled>--SELECT--</option>
                        <option *ngFor="let stb of tostbList" [value]="stb.id">{{'Mac Address: '+stb.ethernetMac+", STB
                            ID: "+stb.id+", Device Type: "+stb.deviceType}}
                        </option>
                    </select>
                </div>
                <div class="col-12" *ngIf="enableSaveButton && fromStbSubscriptionList.length>0">
                  <h5><b>From Stb Subscription Details</b></h5>
                  <div class="tableBox">
                  <table class="custom__iptv__table selected_table">
                    <thead>
                      <tr>
                        <th class="no__sort">Sl no</th>
                        <th class="no__sort">Stb ID</th>
                        <th class="no__sort">Stb Name</th>
                        <th class="no__sort">Pack Type</th>
                        <th class="no__sort">Bouquet ID</th>
                        <th class="no__sort">Subscriber ID</th>
                        <th class="no__sort">Mac address</th>
                        <th class="no__sort">Start Date</th>
                        <th class="no__sort">End Date</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let stb of fromStbSubscriptionList; let i = index">
                        <tr>
                          <td>{{i+1}}</td>
                          <td>{{stb.stbId?stb.stbId:'---'}}</td>
                          <td>{{stb.name?stb.name:'---'}}</td>
                          <td>{{stb.packType?stb.packType:'---'}}</td>
                          <td>{{stb.bouquetId?stb.bouquetId:'---'}}</td>
                           <td>{{stb.subscriberId?stb.subscriberId:'---'}}</td>
                          <td>{{stb.ethernetMac?stb.ethernetMac:'---'}}</td>
                          <td>{{stb.startDate?stb.startDate:'---'}}</td>
                          <td>{{stb.endDate?stb.endDate:'---'}}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                  </div>
                </div>
          
                <div class="col-12" *ngIf="enableSaveButton && toStbSubscriptionList.length>0">
                  <h5><b>To Stb Subscription Details</b></h5>
                  <div class="tableBox">
                  <table class="custom__iptv__table selected_table">
                    <thead>
                      <tr>
                        <th class="no__sort">Sl no</th>
                        <th class="no__sort">Stb ID</th>
                        <th class="no__sort">Stb Name</th>
                        <th class="no__sort">Pack Type</th>
                        <th class="no__sort">Bouquet ID</th>
                        <th class="no__sort">Subscriber ID</th>
                        <th class="no__sort">Mac address</th>
                        <th class="no__sort">Start Date</th>
                        <th class="no__sort">End Date</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let stb of toStbSubscriptionList; let i = index">
                        <tr>
                          <td>{{i+1}}</td>
                          <td>{{stb.stbId?stb.stbId:'---'}}</td>
                          <td>{{stb.name?stb.name:'---'}}</td>
                          <td>{{stb.packType?stb.packType:'---'}}</td>
                          <td>{{stb.bouquetId?stb.bouquetId:'---'}}</td>
                           <td>{{stb.subscriberId?stb.subscriberId:'---'}}</td>
                          <td>{{stb.ethernetMac?stb.ethernetMac:'---'}}</td>
                          <td>{{stb.startDate?stb.startDate:'---'}}</td>
                          <td>{{stb.endDate?stb.endDate:'---'}}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                  </div>
                </div>

                <div class="col-12">
                    <button class="cta float-right" [disabled]="enableSaveButton==false"
                        (click)="swapSubscriptionForm.valid ?  showDisablePopup=!showDisablePopup: null">Save</button>
                </div>
            </div>
        </div>
    </form>
   
</div>


<div class="modal_box"  style="top:3vh" *ngIf="showDisablePopup">
  <div class="modal_box_inner">
    <div class="custom_iptv_table">
      <h6 class="warning"><b> Warning: </b> Device Transfer in Progress<br/>
        You are about to transfer your subscription to a new Set-Top Box (STB). Once the transfer is complete, the old STB will no longer function, and all services will be deactivated on that device.
        If you are sure you want to proceed, please confirm your action</h6>
        <div class="buttongroup">
        <button class="" (click)="showDisablePopup = !showDisablePopup"> Cancel </button>
        <button class="cta" (click)="save()"> Confirm </button>
      </div>
      
    </div>
   
  </div>
</div>