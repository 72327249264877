import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-affiliator',
  templateUrl: './list-affiliator.component.html',
  styleUrls: ['./list-affiliator.component.scss']
})
export class ListAffiliatorComponent implements OnInit {

  paginationObj = new PaginationObj();

  loadingFlag = false;

  affiliatorList= [];

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  activatedCount = 0;
 
  sortByProperties = {
    name: 'firstName',
    email: 'email',
    mobile: 'mobile',
    date: 'createdDate',
    status: 'status'
  };
  menus
  menuAccess = false
  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchPage();
    this.menus = JSON.parse(atob(localStorage.getItem('menus')))
    this.checkMenuAccess()
  }
  onSearch() {
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }

  checkMenuAccess() {
    for (let i of this.menus) {
      if (i.name == 'Affiliator') {
        this.menuAccess = (i.access == 0 ? false : true);
        console.log(this.menuAccess)
      }
    }
  }

  pageSize
  isError = {
    errType: "Loading",
    bool: true
  }
  pageNumber

  fetchPage(): void {
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
    const url = PaginationUtilities.urlBuilder('affiliator/list', this.paginationObj);
    this.loadingFlag = true;
    this.http.get(url).subscribe(
      (data) => {
        this.loadingFlag = false;
        this.pageSize = this.paginationObj.pageSize
        this.handleFetchPage(data);
      },
      (error) => {
        console.log(error)
      }
    )
  }


  handleFetchPage(data): void {
    this.affiliatorList = data.data;
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }


  handlePaginationMetadata(metadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.pageNumber = metadata.pageNumber
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record): void {
    console.log("call")
    this.router.navigate(['manage/affiliator', record.id]);
  }


}
