import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ngxCsv } from 'ngx-csv';
import { delay } from 'rxjs/operators';
import { AdminInfo } from 'src/app/models/admin/admin-info';
import { DistributorInfo } from 'src/app/models/distributor/distributor_info';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import {
  PageInfo,
  PageMetadata,
  SortOrder,
} from 'src/app/models/pagination/page-info';
import {
  PaginationObj,
  PaginationUtilities,
} from 'src/app/models/pagination/pagination-info';
import { ReportTraiDataService } from 'src/app/report-trai/plugins/report-trai-data.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-distributor',
  templateUrl: './distributor.component.html',
  styleUrls: ['./distributor.component.scss'],
})
export class DistributorComponent implements OnInit {
  paginationObj = new PaginationObj();

  loadingFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  LcoActiveCount: number;
  TotalLcoCount: number;
  sortByProperties = {
    name: 'name',
    email: 'email',
    mobile: 'mobileNumber',
    date: 'createdDate',
    status: 'status',
    cp: 'contactPerson',
    tp: 'transferPrice',
  };

  lcoList = [];

  activatedCount = 0;
  user

  menus
  menuAccess = false

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService,
    public auth:AuthService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private report: ReportTraiDataService,
  ) {}

  ngOnInit(): void {
    if(this.config.json.userType=='MSO'){
      this.user=true
    }
    else{
      this.user=false
    }
    this.fetchPage();
    this.menus=JSON.parse(atob(localStorage.getItem('menus')))
    this.checkMenuAccess()
    // this.fetchLcoCount();
  }
  distributrDetails: DistributorInfo;
  viewModal = false;

  viewDistributorDetails(distributorDetails: any) {
    let distributorId = distributorDetails.id,
      url = `${this.config.json.apiEndpoint}distributor/distributorDetails/`+distributorId;
    this.http.get(url).subscribe({
        next: (res: any) => {
          this.distributrDetails = res.data;
          this.viewModal = true;
        },
      });
  }

  checkMenuAccess(){
    for(let i of this.menus){
      if(i.name=='Distributor')
      {
        this.menuAccess=(i.access==0?false:true);
        console.log(this.menuAccess)
      }
    }
    console.log(this.menuAccess)
  }

  onSearch() {
    this.paginationObj.searchKeyword = (
      document.getElementById('searchValue') as HTMLInputElement
    ).value;
    this.fetchPage();
  }


  login(userDetail) {
    // console.log( JSON.parse(localStorage.getItem('profileInfo') as string).email);
    let body = {
      username: userDetail.email,
      loggedIn: JSON.parse(localStorage.getItem('profileInfo') as string).email,
    };
    let openUrl =
      this.config.json.adminLoginasDistributor + 'userdistributor/' + btoa(JSON.stringify(body));
      console.log(openUrl)
    window.open(openUrl, '_blank');
  }


  fetchPage(): void {
    if(this.auth.isAffiliator())
    {
      PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
      let url = PaginationUtilities.urlBuilder(
        'distributor/getDistributorByAffiliator',
        this.paginationObj
      );
      url=url+'&affiliatorId='+this.auth.getLcoId()
      this.loadingFlag = true;
      this.http
        .get(url)
        .pipe(delay(250))
        .subscribe((data: PageInfo<LCOInfo>) => {
          this.loadingFlag = false;
          this.handleFetchPage(data);
          this.handlePaginationMetadata(data.metadata);
        });
    }
    else{
      PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
      let url = PaginationUtilities.urlBuilder(
        'distributor/list',
        this.paginationObj
      );
      this.loadingFlag = true;
      this.http
        .get(url)
        .pipe(delay(250))
        .subscribe((data: PageInfo<LCOInfo>) => {
          this.loadingFlag = false;
          this.handleFetchPage(data);
          this.handlePaginationMetadata(data.metadata);
        });
    }
   
  }

  handleFetchPage(data): void {
    this.lcoList = data.data;

    // this.handlePaginationMetadata(data.metadata);
    // this.handlePaginationBtnDisableStatuses();
    // this.countAdmin();
  }

  pageSize;
  pageNumber;
  handlePaginationMetadata(metadata: PageMetadata): void {
    console.log(metadata);
    console.log(this.paginationObj);

    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
    this.pageSize = metadata.pageSize;
    this.paginationObj.pageSize = metadata.pageSize;
    this.pageNumber = metadata.pageNumber;
    this.paginationObj.pageNumber = metadata.pageNumber;
  }

  handlePaginationBtnDisableStatuses(): void {
    console.log(this.paginationObj.pageNumber);

    // Left section
    this.disableFirstBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;
    this.disablePreviousBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;

    // Right section
    this.disableNextBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
    this.disableLastBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
  }

  pageSizeChange(e: Event): void {
    this.paginationObj = {
      ...this.paginationObj,
      pageSize: +((e.target as HTMLSelectElement).value)
    }
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    console.log(this.paginationObj.pageNumber);

    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    console.log(this.paginationObj.pageNumber);

    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    console.log(this.paginationObj.pageNumber);

    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder =
      this.paginationObj.sortOrder === SortOrder.DESC
        ? SortOrder.ASC
        : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record: AdminInfo): void {
    this.router.navigate(['/manage/distributor/edit', record.id]);
    // alert('record not editable!')
  }

  countAdmin(): void {
    this.activatedCount = this.lcoList.filter((x) => x.status === 1).length;
    // //// console.log(this.lcoList)
  }

  updateStatus(record: AdminInfo): void {
    record.status === 1 ? this.deactivate(record) : this.activate(record);
  }

  deactivate(record: AdminInfo): void {
    const url =
      this.config.json.apiEndpointRdbms +
      'admin/deactivateAccount/' +
      record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 0;
      this.countAdmin();
      window.alert('Account deactivated');
    });
  }

  activate(record: AdminInfo): void {
    const url =
      this.config.json.apiEndpointRdbms + 'admin/activateAccount/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 1;
      this.countAdmin();
      window.alert('Account activated');
    });
  }


  // downloadTableExcel(): void {
  //   let name = 'Distributor List ';
  //     this.doc.exportTableToExcelwithImage(
  //       this.reportTable,
  //       name,
  //       'distributor_list' ,
  //       undefined,
  //       undefined,
  //     );
  //   }
  


  async downloadTableExcel(): Promise<void> {
    const data = [];
    let i = 1;
    this.lcoList.forEach(line => {
      const excelRow = {
        slno : i,
        'Distributor Id': line.id,
        'Distributor Code': line.distributorCode,
       'Distributor Name': line.name,
        Mobile: line.mobile,
        Email: line.email,
        Status: line.status === 0 ?'InActive':'Active',
        createdDate:line.createdDate
      };
      data.push(excelRow);
      i++;
    });
    let blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let workbook: ExcelProper.Workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('My Sheet', {
      properties: {
        defaultColWidth: 30
      }
    });
    let ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    let ab = XLSX.utils.sheet_to_json(ws)
    let currentDate = new Date();
    var row = worksheet.getRow(1);
    row.height = 100, 20
 
      let row2 = worksheet.getRow(2)
      
        let row3 = worksheet.getRow(3)
        row3.getCell(1).value = "Report generated on :"
        row3.getCell(2).value = currentDate.getDate() + "-" + this.doc.monthNameCalculator(currentDate.getMonth()) + "-" + currentDate.getFullYear() + " [" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds() + "]"
        let row4 = worksheet.getRow(4)
        row4.getCell(1).value = 'Source type :'
        row4.getCell(2).value = "SMS"
      
    let a
    let nameRow = worksheet.addRow(['Distributor List'])
    worksheet.mergeCells('A1:D1')
    a = (ab.length === 1) ? Object.keys(ab[0]) : Object.keys(ab[1])
    let headerRow = worksheet.addRow(a)
    nameRow.eachCell((cell, number) => {
      cell.font = { bold: true, size: 14 }
    })
    headerRow.eachCell((cell, number) => {
      cell.font = { bold: true }
    })
    for (let x1 of ab) {
      let x2 = Object.keys(x1);
      let temp = []
      a = x2
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }
    var myBase64Image = "/assets/logos/esto_header_1.png";
    const response = await fetch(myBase64Image); const buffer12 = await response.arrayBuffer();
    var imageId2 = workbook.addImage({
      buffer: buffer12,
      extension: 'png',
    });
    worksheet['!cols'] = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 40 }, { width: 20 }, { width: 20 }]
    worksheet.addImage(imageId2, 'A1:C1');
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: blobType });
      FileSaver.saveAs(blob, 'lco_list.xlsx');
    });
  
  }
  
    pdfDown=false
  downloadTablePdf(): void {
      this.pdfDown=true
  if (
    this.lcoList == null ||
    this.lcoList == undefined ||
    this.lcoList?.length == 0
  ) {
    alert('No Data!');
    this.pdfDown = false
    return;
  }
  this.pdf.generatePdfHtml(
    '#reportTable',
    'distributor_list',
    `Source type : SMS`
  );

  setTimeout(() => {
    this.pdfDown = false
  }, 3000);
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No.","Distributor ID","Distributor Code","Name",,"Mobile","Email"
      ,"Status","Created On"]
    };
    let data = []
    let i = 1
    this.lcoList.forEach(line => {
      let csvLine = {
        slno : i,
        service_asset_id: line.id,
        resource_code: line.distributorCode,
        resource_name: line.name,
        bouquet_id: line.mobile,
        package_name: line.email,
        transaction_date: line.status === 0 ?'InActive':'Active',
        createdDate:line.createdDate
      }
      data.push(csvLine);
    });
    new ngxCsv(data, 'distributor_list', options);
}


}
