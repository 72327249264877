<div class="cms__content" [ngStyle]="{'filter': showSidePanel ? 'blur(1.5px)' : 'blur(0px)' }">
  <form [formGroup]="fingerprintConfigurationForm" (ngSubmit)="persist()">
    <div class="top__heading">
      <h3>Fingerprint</h3>
      <!-- <button class="cta float-right">Save</button> -->
      <!-- <button class="cta float-right" [disabled]="!fingerprintConfigurationForm.valid">Save</button> -->
    </div>

    <div class="col-12 setting__content">
      <div class="row">

        <div class="col-12">
          <div class="row">

            <div class="col-12 col-md-6">

              <div class="col-12 section__title">
                <h5>EMM Fingerprint</h5>
              </div>

              <div class="col-12 card form__style" formGroupName="fingerprintEmm">
                <div class="row">

                  <div class="col-12 col-md-4">
                    <label>Fingerprint Display Type</label>
                    <select class="custom__field" formControlName="displayType">
                      <option [ngValue]="0">Overt</option>
                      <option [ngValue]="1">Covert</option>
                    </select>
                    <button class="send-now_btn" type="button" (click)="sendFingerprint(0)">Send Now</button>
                  </div>

                  <div class="col-12 col-md-8" *ngIf="emmOvertFlag" formGroupName="fingerprintEmmOvert">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">

                          <div class="col-12">
                            <label>Expiry</label>
                            <input type="datetime-local" class="custom__field" formControlName="expiry">
                          </div>

                          <div class="col-12">
                            <label>Repeat</label>
                            <select class="custom__field" formControlName="repeat">
                              <option [ngValue]="0">No</option>
                              <option [ngValue]="1">Yes</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Location Type</label>
                            <select class="custom__field" formControlName="location">
                              <option [ngValue]="0">Random</option>
                              <option [ngValue]="1">Fixed</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Font Style</label>
                            <select class="custom__field" formControlName="font_style">
                              <option [ngValue]="0">Roboto</option>
                              <option [ngValue]="1">Manrope</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Font Size</label>
                            <select class="custom__field" formControlName="font_size">
                              <option [ngValue]="0">Extra Small</option>
                              <option [ngValue]="1">Small</option>
                              <option [ngValue]="2">Normal</option>
                              <option [ngValue]="3">Large</option>
                              <option [ngValue]="4">Extra Large</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Font Colour</label>
                            <select class="custom__field" formControlName="font_color">
                              <option [ngValue]="0">White</option>
                              <option [ngValue]="1">Black</option>
                              <option [ngValue]="2">Red</option>
                              <option [ngValue]="3">Green</option>
                              <option [ngValue]="4">Blue</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Background Colour</label>
                            <select class="custom__field" formControlName="bg_color">
                              <option [ngValue]="0">Black</option>
                              <option [ngValue]="1">White</option>
                              <option [ngValue]="2">Red</option>
                              <option [ngValue]="3">Green</option>
                              <option [ngValue]="4">Blue</option>
                              <option [ngValue]="5">Transparent</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Content Identifier</label>
                            <select class="custom__field" formControlName="content_identifier">
                              <option [ngValue]="0">STB MAC</option>
                              <option [ngValue]="1">Date and Timestamp</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-12">
                            <label>Display Duration (in ms)</label>
                            <input type="number" class="custom__field" formControlName="repeat_duration">
                          </div>

                          <div class="col-12">
                            <label>Interval (in ms)</label>
                            <input type="number" class="custom__field" formControlName="repeat_interval">
                          </div>

                          <div class="col-12">
                            <label>Top (in %)</label>
                            <input type="number" class="custom__field" formControlName="top" placeholder="0">
                          </div>

                          <div class="col-12">
                            <label>Left (in %)</label>
                            <input type="number" class="custom__field" formControlName="left" placeholder="0">
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>

                  <div class="col-12 col-md-8" *ngIf="!emmOvertFlag" formGroupName="fingerprintEmmCovert">
                    <div class="row">

                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-12">
                            <label>Expiry</label>
                            <input type="datetime-local" class="custom__field" formControlName="expiry">
                          </div>

                          <div class="col-12">
                            <label>Repeat</label>
                            <select class="custom__field" formControlName="repeat">
                              <option [ngValue]="0">No</option>
                              <option [ngValue]="1">Yes</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Content Identifier</label>
                            <select class="custom__field" formControlName="content_identifier">
                              <option [ngValue]="0">STB MAC</option>
                              <option [ngValue]="1">Date and Timestamp</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Covert Type</label>
                            <select class="custom__field" formControlName="covert_type">
                              <option [ngValue]="0">Static</option>
                              <option [ngValue]="1">Flashing</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Covert Pattern</label>
                            <select class="custom__field" formControlName="covert_pattern">
                              <option [ngValue]="0">Dots</option>
                              <option [ngValue]="1">Bars</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-12">
                            <label>Display Duration (in ms)</label>
                            <input type="number" class="custom__field" formControlName="repeat_duration">
                          </div>
                          <div class="col-12">
                            <label>Interval (in ms)</label>
                            <input type="number" class="custom__field" formControlName="repeat_interval">
                          </div>
                          </div>
                          </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div class="col-12 col-md-6">
              <div class="col-12 section__title">
                <h5>ECM Fingerprint</h5>
              </div>

              <div class="col-12 card form__style" formGroupName="fingerprintEcm">
                <div class="row">

                  <div class="col-12 col-md-4">
                    <label>Fingerprint Display Type</label>
                    <select class="custom__field" formControlName="displayType">
                      <option [ngValue]="0">Overt</option>
                      <option [ngValue]="1">Covert</option>
                    </select>
                    <button class="send-now_btn" type="button" (click)="sendFingerprint(1)">Send Now</button>
                  </div>

                  <div class="col-12 col-md-8" *ngIf="ecmOvertFlag" formGroupName="fingerprintEcmOvert">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <!-- <div class="col-12">
                            <label>Display Duration (in ms)</label>
                            <input type="number" class="custom__field" formControlName="duration">
                          </div> -->

                          <div class="col-12">
                            <label>Expiry</label>
                            <input type="datetime-local" class="custom__field" formControlName="expiry">
                          </div>

                          <div class="col-12">
                            <label>Repeat</label>
                            <select class="custom__field" formControlName="repeat">
                              <option [ngValue]="0">No</option>
                              <option [ngValue]="1">Yes</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Location Type</label>
                            <select class="custom__field" formControlName="location">
                              <option [ngValue]="0">Random</option>
                              <option [ngValue]="1">Fixed</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Font Style</label>
                            <select class="custom__field" formControlName="font_style">
                              <option [ngValue]="0">Roboto</option>
                              <option [ngValue]="1">Manrope</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Font Size</label>
                            <select class="custom__field" formControlName="font_size">
                              <option [ngValue]="0">Extra Small</option>
                              <option [ngValue]="1">Small</option>
                              <option [ngValue]="2">Normal</option>
                              <option [ngValue]="3">Large</option>
                              <option [ngValue]="4">Extra Large</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Font Colour</label>
                            <select class="custom__field" formControlName="font_color">
                              <option [ngValue]="0">White</option>
                              <option [ngValue]="1">Black</option>
                              <option [ngValue]="2">Red</option>
                              <option [ngValue]="3">Green</option>
                              <option [ngValue]="4">Blue</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Background Colour</label>
                            <select class="custom__field" formControlName="bg_color">
                              <option [ngValue]="0">Black</option>
                              <option [ngValue]="1">White</option>
                              <option [ngValue]="2">Red</option>
                              <option [ngValue]="3">Green</option>
                              <option [ngValue]="4">Blue</option>
                              <option [ngValue]="5">Transparent</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Content Identifier</label>
                            <select class="custom__field" formControlName="content_identifier">
                              <option [ngValue]="0">STB MAC</option>
                              <option [ngValue]="1">Date and Timestamp</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">

                          <div class="col-12">
                            <label>Display Duration (in ms)</label>
                            <input type="number" class="custom__field" formControlName="repeat_duration">
                          </div>

                          <div class="col-12">
                            <label>Interval (in ms)</label>
                            <input type="number" class="custom__field" formControlName="repeat_interval">
                          </div>

                          <div class="col-12">
                            <label>Top (in %)</label>
                            <input type="number" class="custom__field" formControlName="top" placeholder="0">
                          </div>

                          <div class="col-12">
                            <label>Left (in %)</label>
                            <input type="number" class="custom__field" formControlName="left" placeholder="0">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-8" *ngIf="!ecmOvertFlag" formGroupName="fingerprintEcmCovert">
                    <div class="row">

                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-12">
                            <label>Expiry</label>
                            <input type="datetime-local" class="custom__field" formControlName="expiry">
                          </div>

                          <div class="col-12">
                            <label>Repeat</label>
                            <select class="custom__field" formControlName="repeat">
                              <option [ngValue]="0">No</option>
                              <option [ngValue]="1">Yes</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Content Identifier</label>
                            <select class="custom__field" formControlName="content_identifier">
                              <option [ngValue]="0">STB MAC</option>
                              <option [ngValue]="1">Date and Timestamp</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Covert Type</label>
                            <select class="custom__field" formControlName="covert_type">
                              <option [ngValue]="0">Static</option>
                              <option [ngValue]="1">Flashing</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label>Covert Pattern</label>
                            <select class="custom__field" formControlName="covert_pattern">
                              <option [ngValue]="0">Dots</option>
                              <option [ngValue]="1">Bars</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-12">
                            <label>Display Duration (in ms)</label>
                            <input type="number" class="custom__field" formControlName="repeat_duration">
                          </div>

                          <div class="col-12">
                            <label>Interval (in ms)</label>
                            <input type="number" class="custom__field" formControlName="repeat_interval">
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <!-- <button type="button" (click)="sendFingerprint(1)">Send Now</button> -->
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- <div class="overlay__content" *ngIf="showSidePanel"></div> -->
</div>

<div class="side__panel-wrapper" *ngIf="showSidePanel" >
<div class="side__slider" [ngStyle]="{'right': showSidePanel ? '0px' : '0' }">
  <div class="side__slider--content">

    <div class="fp_type">
      <form [formGroup]="fingerprintSendForm" (keydown.enter)="$event.preventDefault()" >
        <button type="button" class="close__button" (click)="closeSidePanel()"><img src="./assets/close-line.svg"
            alt="close button" /></button>
        <h5 style="text-transform: capitalize;">{{sidepanelHeading===0?'EMM':'ECM'}} Fingerprint</h5>

        <div *ngIf="sidepanelHeading===0">

          <div class="send__all">
            <label>To</label>
            <div class="send__type">

              <div class="send__all--content">
                <input type="radio" formControlName="subscriberTargetType" [value]=1 />
                <label>Send to Group</label>
              </div>
              <div class="send__all--content">
                <input type="radio" formControlName="subscriberTargetType" [value]=2 />
                <label>Send to All</label>
              </div>
              <div class="send__all--content">
                <input type="radio" formControlName="subscriberTargetType" [value]=0 />
                <label>Send to Individual</label>
              </div>
              <div class="send__all--content">
                <input type="radio" formControlName="subscriberTargetType" [value]=3 />
                <label>Send to LCO</label>
              </div>
            </div>
          </div>

          <div class="col-12 search__subscriber" *ngIf="fpSubscriberTarget===0">

            <!-- <input type="number" class="custom__field" placeholder="Subscriber Code" formControlName="subscriberId" />
            -->
            <!-- <div class="search__container">
              <input type="text" autocomplete="off" class="custom__field" [(ngModel)]="paginationObjSubscriber.searchKeyword"
                [ngModelOptions]="{standalone: true}" (keyup.enter)="$event.preventDefault(); searchSubscriber()" />
              <button type="button" (click)="searchSubscriber()"><img src="./assets/search.png" alt="search" /></button>
            </div>
            <div class="search__radio">
            
            </div> -->
            <!-- <div class="search__results">
              <table class="custom__iptv__table">
                <thead>
                  <tr>
                    <th></th>
                    <th class="caps">{{fpSubscriberFilter.value}}</th>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngIf="subLoadingFlag; else showSubList">
                    <td class="loading" colspan="2">
                      <app-custom-spinner></app-custom-spinner>
                    </td>
                  </tr>

                  <ng-template #showSubList>
                    <tr *ngFor="let sub of subscriberList">
                      <td><input type="checkbox" [checked]="sub.checked" (change)="subscriberCheckbox(sub)" /></td>
                      <td>{{ fpSubscriberFilter.value==="SubscriberCode"? sub.subscriberCode:
                        sub[fpSubscriberFilter.value] }}</td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div> -->
            <!-- <div class="col-12 noPadding sub__accordion">
              <h6>Search By Subscriber Code</h6>
              <img src="./assets/nextPage.png" class="down__arrow"
                [ngClass]="{up__arrow : subscriberSearch, down__arrow: (!subscriberSearch)}" />
            </div> -->

            <div class="search__inner">

              <div class="search__container">
                <input type="text" autocomplete="off" class="custom__field" [(ngModel)]="paginationObjSubscriber.searchKeyword"
                  [ngModelOptions]="{standalone: true}" (keyup.enter)="$event.preventDefault(); searchSubscriber()" />
                <button type="button" (click)="searchSubscriber()"><img src="./assets/search.png"
                    alt="search" /></button>
              </div>

              <div class="search__radio">
                <input type="radio" id="search__name" [value]="'name'" formControlName="subscriberFilter">
                <label class="not__required">Name</label>
                <input type="radio" id="search__email" [value]="'email'" formControlName="subscriberFilter">
                <label class="not__required">Email</label>
                <input type="radio" id="search__number" [value]="'mobile'" formControlName="subscriberFilter">
                <label class="not__required">Mobile</label>
                <input type="radio" id="search__number" [value]="'landline'" formControlName="subscriberFilter">
                <label class="not__required">Landline</label>
                <input type="radio" id="search__name" [value]="'SubscriberCode'" formControlName="subscriberFilter">
                <label class="not__required">Subscriber Code</label>
              </div>

              <div class="search__results">
                <table class="custom__iptv__table">
                  <thead>
                    <tr>
                      <th></th>
                      <th class="caps">{{fpSubscriberFilter.value}}</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr *ngIf="subLoadingFlag; else showSubList">
                      <td class="loading" colspan="2">
                        <app-custom-spinner></app-custom-spinner>
                      </td>
                    </tr>

                    <ng-template #showSubList>
                      <tr *ngFor="let sub of subscriberList">
                        <td><input type="checkbox" [checked]="sub.checked" (change)="subscriberCheckbox(sub)" /></td>
                        <!-- <td>{{
                          fpSubscriberFilter.value==="SubscriberCode"? sub.subscriberCode:
                          sub[fpSubscriberFilter.value]
                          }}</td> -->
                        <!-- nested Ternary Operator -->
                        <td id="subTable" >{{fpSubscriberFilter.value === "SubscriberCode" ?
                        sub.subscriberCode == null ? 'No data': sub.subscriberCode :
                        sub[fpSubscriberFilter.value] || (fpSubscriberFilter.value==="name" || fpSubscriberFilter.value==="Name" || fpSubscriberFilter.value==="NAME")?
                        (sub.name ? sub.name : sub.NAME ? sub.NAME : sub.Name ? sub.Name : "No Data") :
                        sub[fpSubscriberFilter.value] || fpSubscriberFilter.value==="email"? sub.emailId == null ? 'Nodata' :
                        sub.emailId : sub[fpSubscriberFilter.value] || fpSubscriberFilter.value==="mobile"? sub.mobileNumber == null? 'No Mobile Number' :
                        sub.mobileNumber == "" ? 'No data' : sub.mobileNumber :
                        sub[fpSubscriberFilter.value] || fpSubscriberFilter.value === "landline"? sub.landline == null ?'No Landline' :
                        sub.landline : sub[fpSubscriberFilter.value]}}</td>
                      </tr>
                    </ng-template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="col-12 search__subscriber" *ngIf="fpSubscriberTarget===1">
            <h6>Select Subscriber Group</h6>
            <hr>
            <select name="subGroup" class="custom__field" formControlName="groupId"
              (change)="getSubscriberListBySubscriberGroupId()">
              <option hidden value="" disabled selected>Select Group</option>
              <option *ngFor="let group of subscriberGroupList" [value]="group.id">{{group.name}}</option>
            </select>
          </div>

          <div class="col-12 search__subscriber" *ngIf="fpSubscriberTarget===3" >
            <label for="">Search LCO</label>
            <input type="text" class="custom__field" id="lcoId" (input)="getSearchText($event)" >
            <div class="overflow" *ngIf="isSearch" style="margin-top: 10px" >
              <app-lco-search [searchText]="searchText" (userDetail)="getLCOId($event)" ></app-lco-search>
            </div>
          </div>
        </div>

        <div *ngIf="sidepanelHeading===1">
          <!-- <label>Channel ID</label>

          <input type="number" class="custom__field" placeholder="Channel id" formControlName="channelIds" /> -->

          <div class="col-12 search__subscriber">
            <div class="col-12 noPadding sub__accordion">
              <h6>Search for Channels</h6>
              <!-- <img src="./assets/nextPage.png" class="down__arrow"
                [ngClass]="{up__arrow : chSearch, down__arrow: (!chSearch)}" /> -->
            </div>
            <div class="search__inner">
              <div class="search__radio">
                <input type="radio" id="search__ch__channelid" [value]="'channelId'" formControlName="channelFilter">
                <label class="not__required">Channel ID</label>
                <input type="radio" id="search__ch__name" [value]="'Name'" formControlName="channelFilter">
                <label class="not__required">Name</label>
                <input type="radio" id="search__ch__number" [value]="'Number'" formControlName="channelFilter">
                <label class="not__required">Number</label>
              </div>
              <div class="search__container">
                <input type="text" autocomplete="off" class="custom__field" [(ngModel)]="paginationObjChannel.searchKeyword"
                  [ngModelOptions]="{standalone: true}" (keyup.enter)="$event.preventDefault(); searchChannel()" />
                <button type="button" (click)="searchChannel()"><img src="./assets/search.png" alt="search" /></button>
              </div>
              <div class="search__results">
                <table class="custom__iptv__table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{{fpChannelFilter.value}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="chLoadingFlag; else showChList">
                      <td class="loading" colspan="2">
                        <app-custom-spinner></app-custom-spinner>
                      </td>
                    </tr>

                    <ng-template #showChList>
                      <tr *ngFor="let ch of channelList">
                        <td><input type="checkbox" [checked]="ch.checked" (change)="channelCheckbox(ch)" /></td>
                        <!-- <td>{{fpChannelFilter.value === 'Name' ?ch.name:ch.number}}</td> -->
                        <td>{{ fpChannelFilter.value==="Name"?ch.name == null ? 'No data':
                          ch.name:
                          ch[fpChannelFilter.value] ||
                          fpChannelFilter.value==="Number"?ch.channel_no == null ? 'No data':ch.channel_no:
                          ch[fpChannelFilter.value] || fpChannelFilter.value==="channelId"?ch.channel_id == null ? 'No
                          data':ch.channel_id:
                          ch[fpChannelFilter.value] }}</td>
                      </tr>
                    </ng-template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <hr />

        </div>
        <ng-container *ngIf="sidepanelHeading===0 && fpSubscriberTarget===0" >
          <label>Select STB ID</label>
          <multi-select [controlName]="'stbIds'" [parentFormName]="fingerprintSendForm" [multiSelectOptions]="stbIds" ></multi-select>
          </ng-container>
        <div style="margin-top: 10px;">
          <button type="button" (click)="closeSidePanel()">Cancel</button>
          <button type="submit" class="cta float-right" [disabled]="!fingerprintSendForm.valid" (click)="fingerprintSendForm.valid? fpPersist() : null" >Send</button>
          </div>
      </form>
    </div>

  </div>
</div>
</div>
