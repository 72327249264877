import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { AdminInfo } from 'src/app/models/admin/admin-info';
import { LeadInfo } from 'src/app/models/lead/lead-info';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { ReportTraiDataService } from 'src/app/report-trai/plugins/report-trai-data.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-lead',
  templateUrl: './list-lead.component.html',
  styleUrls: ['./list-lead.component.scss']
})
export class ListLeadComponent implements OnInit {
  paginationObj = new PaginationObj();
  loadingFlag = false;
  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  LcoActiveCount: number;
  TotalLcoCount: number;
  sortByProperties = {
    firstName: 'firstName',
    email: 'email',
    mobile: 'mobile',
    date: 'createdDate',
    installationAddress: 'installationAddress',
    billingAddress: 'billingAddress',
    stateCode: 'stateCode',
    districtCode: 'districtCode'
  };

  lcoId='0'

  leadList: LeadInfo[] = [];
  menus
  menuAccess = false

  activatedCount = 0;
  lcoList

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService,
    private httpService: HttpClient,
    private auth: AuthService,
    private report: ReportTraiDataService
  ) { }

  isDistributor = false;

  ngOnInit(): void {
    this.fetchLcoList()
    this.fetchPage();
    this.isDistributor = this.auth.isDistributor();
    this.menus=JSON.parse(atob(localStorage.getItem('menus')))
    this.checkMenuAccess()
  }

  onSearch() {
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }


  fetchLcoList(): void {
    this.report.getLCOList().subscribe((data) => {
      this.lcoList = data.data;
    });
  }
  checkMenuAccess(){
    for(let i of this.menus){
      if(i.name=='Lead')
      {
        this.menuAccess=(i.access==0?false:true);
        console.log(this.menuAccess)
      }
    }
    console.log(this.menuAccess)
  }

  SetBouquet(admin) {
    this.router.navigateByUrl('manage/lco/set-bouquet/' + admin.id)
  }

  LeadDetails: LeadInfo;
  viewModal = false;

  viewLCODetails(lcoDetails: any) {
    let lcoId = lcoDetails.id,
      url = `${this.config.json.apiEndpoint}lco/lcoDetails/` + lcoId;
    this.http.get(url).subscribe({
      next: (res: any) => {
        this.LeadDetails = res.data;
        this.viewModal = true;
      },
    });
  }
  createLead(id){
    let url=''
    url=this.config.json.apiEndpoint+'subscriber/pushLeadToSubscriber?subscriberLeadId='+id
    this.http.post(url,{}).subscribe(
      (res)=>{
        if(res.statusCode==200){
          alert(res.message)
        }else{
          alert("Failed "+res.message)
        }
      },
      (err)=>{
        alert(err) 
      }
    )
  }

  fetchPage(): void {
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url;
    // url = PaginationUtilities.urlBuilder('subscriber/lead/list',this.paginationObj);
    
    url=this.config.json.apiEndpoint+'subscriber/lead/list/'+this.paginationObj.pageNumber+'?pageSize=' + this.paginationObj.pageSize;
    
    // url=this.config.json.apiEndpoint+'subscriber/lead/list/1?lcoId=2'
    if(this.lcoId!='0'){
      url=url+'&lcoId='+this.lcoId;
    }
    // url=url+'&lcoId=2'
    this.loadingFlag = true;
    this.http.get(url).pipe(delay(250)).subscribe((data: PageInfo<LeadInfo>) => {
        this.loadingFlag = false;
        this.handleFetchPage(data);
      });
  }

  handleFetchPage(data: PageInfo<LeadInfo>): void {
    this.leadList = data.data;
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }

  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
    this.pageSize = metadata.pageSize;
    this.pageNumber = metadata.pageNumber;
  }

  pageSize;
  pageNumber;
  handlePaginationBtnDisableStatuses(): void {
    // Left section
    this.disableFirstBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;
    this.disablePreviousBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;

    // Right section
    this.disableNextBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
    this.disableLastBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    // this.paginationObj.sortBy = item;
    // this.paginationObj.sortOrder =
    //   this.paginationObj.sortOrder === SortOrder.DESC
    //     ? SortOrder.ASC
    //     : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record: AdminInfo): void {
    this.router.navigate(['/manage/lead', record.id]);
  }







  // login(userDetail) {
  //   // console.log( JSON.parse(localStorage.getItem('profileInfo') as string).email);
  //   let body = {
  //     username: userDetail.email,
  //     loggedIn: JSON.parse(localStorage.getItem('profileInfo') as string).email,
  //   };
  //   let openUrl =
  //     this.config.json.adminLogin + 'user/' + btoa(JSON.stringify(body));
  //   window.open(openUrl, '_blank');
  // }


}
