import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListLeadComponent } from './list-lead/list-lead.component';
import { CreateLeadComponent } from './create-lead/create-lead.component';
import { SharedModule } from '../shared/shared.module';
import { LeadRoutingModule } from './lead-routing.module';



@NgModule({
  declarations: [
    ListLeadComponent,
    CreateLeadComponent
  ],
  imports: [
    CommonModule,
    SharedModule,LeadRoutingModule
  ]
})
export class LeadModule { }
