<h6 class="onlyPrint">OnSMS</h6>
<div class="report__header">
  <div class="dpo">
    <label>DPO</label>
    <div class="img__container">
      <!-- <img src="assets/logos/logo-Elxire.png" alt="elxire"> -->
      <!-- <img src="assets/logos/VBC.png" alt="VBC"> -->
      <!-- <img src="assets/logos/Fiberx-Android.png" alt="fiberx"> -->
      <!-- <img src="assets/logos/pioneer.png" alt="pioneer"> -->
      <!-- <img src="assets/logos/onnet.png" alt="onnet"> -->
      <img src="assets/logos/esto_logo.png" alt="airfiber" />
    </div>

    <!-- <h5>Pioneer Digital Tv PVT. LTD</h5>
    <h6>5-9-12, Old Ghasmandi,
       Khairtabad, Hyderabad,
       Telangana 500004</h6>
    <h6>Phone: 095739 12345</h6> -->

    <!-- <h5>WI-BRO SOLUTIONS PVT LTD</h5>
    <h6>
      516, 5th Floor Usha Kiran Building, Azadpur Commercial Complex New Delhi
      110033
    </h6>
    <h6>Phone: 88606969, 9871361211</h6> -->
    <h5>Esto </h5>
    <h6>
      Sliet Road, Longowal
    </h6>
    <h6>
     Sai Road  - Baddi
    </h6>
    <h6>Phone: +91 8350813508</h6>

    <!-- <h5>FIBERX NETWORKS PVT. LTD</h5>
    <h6>54 16 7 4B, Plot no. 7, Road no. 1,
        2nd floor, Maheena Enclave central excise colony,
        Vijayawada, AP-520007</h6>
    <h6>Phone: 095739 12345</h6> -->

    <!-- <h5>VIZAG BROADCASTING
      COMPANY PVT. LTD, (VBC)</h5>
    <h6>
      #47 -10-20, Flat No. 401, 4th floor,
      Dwaraka Plaza, Dwaraka Nagar,
      Visakhpatanam - 530016</h6>
    <h6>Phone: (0891) 6677-123, 6677-124</h6> -->
    <!-- <h5>ELXIRE IT Services Pvt. Ltd.</h5>
    <h6>Shop No. 74, Sector-7, HUDA market,
        Faridabad - 121006, Haryana, India
    </h6> -->
    <!-- <h6>SCF-65, Sector-7 HUDA Market,
        Faridabad - 121006
    </h6> -->
    <!-- <h6>E-mail: info@elxireit.in</h6> -->
  </div>
  <div class="cas">
    <label>SMS Vendor</label>
    <div class="img__container">
      <img src="assets/logos/onnet.png" alt="VBC" />
    </div>

    <h5>Onnet Systems India Pvt. Ltd.</h5>
    <h6>
      Unit 702, 7th floor, Brigade Rubix, 20,HMT Watch Factory Main Road, Peenya
      1st Stage, Bengaluru, Karnataka 560013
    </h6>
    <h6>Phone: +91 802 9724 142 / 52</h6>
  </div>
</div>
<div class="onlyPrint"><p>Source Type : SMS</p></div>
