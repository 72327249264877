import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { SubscriberInfo } from 'src/app/models/subscriber/subscriber-info';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-subscriber-list-new',
  templateUrl: './report-subscriber-list-new.component.html',
  styleUrls: ['./report-subscriber-list-new.component.scss','../report-filters.scss'],
})
export class ReportSubscriberListNewComponent implements OnInit {
  paginationObj = new PaginationObj();

  loadingFlag = false;


  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  reportList = [];
  selectedLco = 0;
  from: any = '0000-00-00';
  to: any = '0000-00-00';

  lcoList: LCOInfo[] = [];

  currentDate = new Date();
  statusValue = "5"
  lcoValue="0"
  sortByProperties = {
    subscriberName: 'subscriberName',
    subscriberCode: 'subscriberCode',
    createdDate: 'createdDate',
    city: 'city',
    state: 'state',
    stbId: 'stbId',
    mobileNumber:'mobileNumber',
    status:'status',
    packages:'packages',
    ethernetMacAddress:'ethernetMacAddress',
    subscriberId:'subscriberId',
    stb_id:'stb_id',
    locality:'locality'
  };

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchLcoList();
    this.fetchData()
  }

  fetchLcoList(): void {
    this.report.getLCOList().subscribe((data) => {
      this.lcoList = data.data;
    });
  }
  sortByItem(item: string): void {
    console.log(item)
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchData();
  }
  isLoad = 'Choose Date';

  onSearch(){
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchData();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }
  handleFetchPage(data): void {
    this.reportList = data.data;
    if (this.reportList.length != 0) {
      this.loadingFlag = false;
    } else {
      this.loadingFlag = false;
      this.isLoad = 'No Records';
    }
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }

  pageNumber
  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.pageNumber = metadata.pageNumber
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  fetchData(): void { 
    this.loadingFlag = true
    this.reportList=[]
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
    let url = PaginationUtilities.urlBuilder('reports/subscriberListReport', this.paginationObj);
    url = url + "&status=" + this.statusValue +"&lcoId="+this.lcoValue
    // if(this.from!="0000-00-00"&& this.to!="0000-00-00"){
    //   url = url+ "&fromDate="+this.from+"&toDate="+this.to;
    // }
    this.http.get(url).subscribe(
        (data) => {
          this.handleFetchPage(data);
        },
        () => {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      );
  }

  // fetchLcoList(): void {
  //   this.report.getLCOList().subscribe((data) => {
  //     this.lcoList = data.data;
  //   });
  // }

  lcoSelect(): void {
    this.from = this.calendar.getDate()['startDate']
    this.to = this.calendar.getDate()['endDate']
    this.fetchData();
  }

  dateString;
  eve;
  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      // // console.log(ev.startDate.date(), ev.startDate.month(), ev.startDate.year())
      // // console.log(ev.endDate.date(), ev.endDate.month(), ev.endDate.year())
      this.eve = ev;
      this.dateString =
        ev.startDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.startDate.month()) +
        '-' +
        ev.startDate.year() +
        ' to ' +
        ev.endDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.endDate.month()) +
        '-' +
        ev.endDate.year();
      this.from = ev.startDate.format('YYYY-MM-DD');
      this.to = ev.endDate.format('YYYY-MM-DD');
      console.log(this.from)
      this.fetchData();
      this.loadingFlag = true;
      this.isLoad = 'Loading';
      this.calendar.setDate(ev);
    }
  }

  
  downloadTableExcel(): void {
    let status = [...this.statUS, 'ALL'],
      filterName = status[this.statusValue];
    let name = 'Subscriber list Report';
    this.doc.exportTableToExcelwithImage(
      this.reportTable,
      name,
      'subscribers_list_report',
      undefined,
      undefined,
      'Status',
      filterName
    );
  }
  statUS = ['Inactive', 'Active', 'Blacklisted', 'New', 'Terminated'];

  downloadTablePdf(): void {
    let status = [...this.statUS, 'ALL'],
      filterName = status[this.statusValue];
      this.pdf.generatePDFForLongTable("Subscribers List Report", `Status: ${filterName} Source type : SMS`,1800)
  }
  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: [
        'Sl No',
        'Created Date',
        'Subscriber ID',
        'Name',
        'City',
        'Area',
        'State Name',
        'Phone Number',
        'Connection Count',
        'Bouquets',
        'Status',
        // 'Mac Address',
        'STB ID'
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((report) => {
      let csvLine = {
        slNo: i,
        createdDate:report.createdDate,
        subscriberCode: report.subscriberId,
        Name: report.subscriberName,
        lcoCode:report.lcoCode,
        city: report.city ? report.city : 'No Data',
        locality:report.locality,
        stateName: report.state ? report.state : 'No Data',
        mobileNumber: report.mobileNumber,
        count:report.stbIds.split(',').length,
        packages: report.packages,
        status: report.status,
        // ethernetMacAddress: report.ethernetMacAddress,
        stbIds:report.stbIds
      };
      i += 1;
      data.push(csvLine);
    });

    new ngxCsv(data, 'subscriber_list_report', options);
  }


  formatDate(ele) {
    if (ele == undefined) {
      return false;
    }
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

}
