import { Component, OnInit } from '@angular/core';
import { OsdInfo } from 'src/app/models/osd/osd-info';
import { environment } from 'src/environments/environment';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { Router } from '@angular/router';
import {
  PaginationObj,
  PaginationUtilities,
} from 'src/app/models/pagination/pagination-info';
import {
  SortOrder,
  PageMetadata,
  PageInfo,
} from 'src/app/models/pagination/page-info';
import { delay } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-osd-list',
  templateUrl: './osd-list.component.html',
  styleUrls: ['./osd-list.component.scss'],
})
export class OsdListComponent implements OnInit {
  paginationObj = new PaginationObj();

  loadingFlag = false;
  errorFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  sortByProperties = {
    subId: 'subscriberId',
    udate: 'updatedDate',
    cdate: 'createdDate',
    status: 'revoke',
  };

  

  osdList: OsdInfo[] = [];
  menus
  menuAccess = false

  constructor(
    private http: HttpClientWrapperService,
    private router: Router,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    this.fetchPage();
    this.menus=JSON.parse(atob(localStorage.getItem('menus')))
    this.checkMenuAccess()
  }
  
  checkMenuAccess(){
    for(let i of this.menus){
      if(i.name=='OSD')
      {
        this.menuAccess=(i.access==0?false:true);
        console.log(this.menuAccess)
      }
    }
    console.log(this.menuAccess)
  }

  onSearch() {
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }
  showAll: boolean[] = [];

  showAllValues(index: number): void {
    this.showAll[index] = !this.showAll[index];
  }

  fetchPage(): void {
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms);
    const url = PaginationUtilities.urlBuilder('osd/list', this.paginationObj);
    this.loadingFlag = true;

    this.http
      .get(url)
      .subscribe(
        (data: PageInfo<OsdInfo>) => {
          this.loadingFlag = false;
          this.handleFetchPage(data);
        },
        (error) => {
          this.loadingFlag = false;
          this.errorFlag = true;
        }
      );
  }

  handleFetchPage(data: PageInfo<OsdInfo>): void {
    this.osdList = data.data;

    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }

  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  handlePaginationBtnDisableStatuses(): void {
    // Left section
    this.disableFirstBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;
    this.disablePreviousBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;

    // Right section
    this.disableNextBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
    this.disableLastBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder =
      this.paginationObj.sortOrder === SortOrder.DESC
        ? SortOrder.ASC
        : SortOrder.DESC;
    this.fetchPage();
  }

  updateStatus(osd: OsdInfo, index: number): void {
    osd.revoke ? this.activateOsd(osd, index) : this.revokeOsd(osd, index);
  }

  revokeOsd(osd: OsdInfo, index: number): void {
    const url = this.config.json.apiEndpointRdbms + 'osd/revoke/' + osd.id;
    this.http.post(url, {}).subscribe((data: OsdInfo) => {
      this.osdList[index] = data;
      window.alert('OSD Revoked');
    });
  }

  activateOsd(osd: OsdInfo, index: number): void {
    const url = this.config.json.apiEndpointRdbms + 'osd/activateOsd/' + osd.id;
    this.http.post(url, {}).subscribe((data: OsdInfo) => {
      this.osdList[index] = data;
      window.alert('OSD Activated');
    });
  }

  deleteOsd(osd: OsdInfo, index: number): void {
    const url = this.config.json.apiEndpointRdbms + 'osd/' + osd.id;
    this.http.delete(url).subscribe(() => {
      window.alert('Deleted Message');
      this.osdList.splice(index, 1);
    });
  }

  updateOsd(osd: OsdInfo): void {
    this.router.navigate(['/manage/osd', osd.id]);
  }
}
