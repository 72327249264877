import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DistributorType } from '../create-distributor/create-distributor.component';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
@Component({
  selector: 'app-distributor-create-parent',
  templateUrl: './distributor-create-parent.component.html',
  styleUrls: ['./distributor-create-parent.component.scss'],
})
export class DistributorCreateParentComponent implements OnInit {

  id: string = null;
  switchPage: 'create' | "addPackages" |"setPackagePrice" | "review" = 'create';
  isEdit = false;
  completeStatusForWizard = 1;
  selectedPackagesFromAddPackages!: any;
  selectedPackagesFromUpdate: any[] = [];
  reviewData;
  basicDetails: DistributorType;
  isSelfRegistered
  userDetails 

  constructor(
    private route: ActivatedRoute,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private router: Router
  ) {}

  ngOnInit(): void {
    
    this.route.params.subscribe((res) => {
      if (res.id) {
        this.isEdit = true;
      }
    });
    const id = this.route.url['_value'][0].path
    if(id=='selfcreate'){
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'))
      this.isSelfRegistered= true
      this.isEdit = false
    }
  }

  
  
  basicDetailsComplete(e: DistributorType) {
    if ((e as any).hasOwnProperty('bouquets')) {
      this.selectedBouquets((e as any)['bouquets']);
    }
    this.basicDetails = e;
    this.switchPage = 'addPackages';
    this.completeStatusForWizard += 1;
  }


  clickonBack(){
    if(this.isSelfRegistered == true){
      this.router.navigateByUrl('manage/form-request')
    }
    else{
      this.router.navigateByUrl('manage/distributor')
    }
  }
 
  addPackagesComplete(e) {
    console.log("call")
    this.selectedPackagesFromAddPackages = e;
    this.switchPage = 'setPackagePrice';
    this.completeStatusForWizard += 1;
  }

  // addPackagesCompleteAlacartee(e) {
  //   console.log("call2")
  //   this.selectedPackagesFromAddPackages = e;
  //   this.switchPage = 'setPackagePrice';
  //   this.completeStatusForWizard += 1;
  // }


  selectedBouquets(idArray: any[]) {
    for (let ele of Object.keys(idArray)) {
      this.selectedPackagesFromUpdate.push({ id: ele, share: idArray[ele] });
    }
  }

  setPackagePrices(e) {
    console.log(e)
    this.reviewData = {
      basicDetails: this.basicDetails,
      selectedPackages: e,
    };
    this.switchPage = 'review';
    this.completeStatusForWizard += 1;
  }
  
  goBackTo(page: 'create' | "addPackages" | "setPackagePrice" | "review") {
    this.completeStatusForWizard -= 1;
    this.switchPage = page;
  }

  submit() {
    let arrayOfObjects = [];
    for (let ele of this.reviewData.selectedPackages) {
      arrayOfObjects.push(ele.priceUpdate);
    }
    const bouquets = arrayOfObjects.reduce((acc, obj) => {
      const id = Object.keys(obj)[0];
      acc[id] = obj[id];
      return acc;
    }, {});
    let details = {
      name: this.basicDetails.name,
      email: this.basicDetails.email,
      mobileNumber: this.basicDetails.mobileNumber,
      status: this.basicDetails.status,
      locationId: this.basicDetails.locationId,
      address: this.basicDetails.address,
      password: this.basicDetails.password,
      aclGroupId:this.basicDetails.aclGroupId,
      bouquets,
    };
    let url;
    if (this.isEdit) {
      url = this.config.json.apiEndpointRdbms + 'distributor/update';
      details['id'] = (this.basicDetails as any).id;
      this.http.put(url, details).subscribe(
         (res) => {
          if (res.statusCode == 200){
            this.router.navigate(['/manage/distributor/list']);
          }
          else{
            alert(res.message)
          }
        },
      );
    } else {
      url = this.config.json.apiEndpointRdbms + 'distributor/create';
      this.http.post(url, details).subscribe(
        (res) => {
          if (res.statusCode == 200){
            if(this.isSelfRegistered==true){
              this.updateapprovalStatus();
            }else{
            this.router.navigate(['/manage/distributor/list']);
            }
          }
          else{
            alert(res.message)
          }
        },
      );
    }
  }

  formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  updateapprovalStatus(){
   this.userDetails.approvalStatus = 1;
   this.userDetails.approvedDate= new Date()
   this.userDetails.password = this.basicDetails.password
   let url=this.config.json.apiEndpoint+'leadGeneration/'+this.userDetails.id
   this.http.put(url,this.userDetails).subscribe(
    (data)=>{
      if(data.statusCode==200){
        localStorage.removeItem('userDetails')
        this.router.navigateByUrl('manage/form-request')
      }
    },
    (err)=>{
      console.log(err)
    }
   )
  }
}
