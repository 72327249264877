import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-report-msr',
  templateUrl: './report-msr.component.html',
  styleUrls: ['./report-msr.component.scss', '../report-filters.scss']
})
export class ReportMsrComponent implements OnInit {
  paginationObj = new PaginationObj();

  loadingFlag = false;

  dateChoosed = false;
  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  reportList = [];
  selectedLco = 0;
  from: any = '0000-00-00';
  to: any = '0000-00-00';

  lcoList: LCOInfo[] = [];
  bouquetList = []
  broadcasterList = [];
  distributorList = [];


  body = {
    fromDate: '',
    toDate: '',
    tillDate: '',
  };

  stateList = [];
  sortByProperties = {
    subscriberName: 'subscriberName',
    bouquet_id: 'bouquet_id',
    bouquetName: 'bouquetName',
    subscriberCount: 'subscriberCount',
    stbId: 'stbId',
    status: 'status',
    duration: 'duration',
    subscriberCountAtEndOfTheMonth: 'subscriberCountAtEndOfTheMonth',
    subscriberCountAt21: 'subscriberCountAt21',
    subscriberCountAt28: 'subscriberCountAt28',
    subscriberCountAt14: 'subscriberCountAt14',
    subscriberCountAt7: 'subscriberCountAt7'
  };

  currentDate = new Date();
  statusValue = "All"

  selectedLcoId = "0"
  selectedDistributorId = "0"
  selectedBouquetType = "0"
  selectedBroadcasterId = "0"

  showMonth = false;
  showDateRange = false;
  showYearSelector = false;
  dateSelector = true;
  isDateSelected = false;
  eve;
  reportDate =
    this.reportList.length == 0 ? 'Not Selected' : 'From Start to Now';
  isLoading = false;

  yearsArray = [];
  isLoad = 'Choose Date';
  pageNumber
  dateString;
  selectedDate: any = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.selectedDate = this.selectedDate.toISOString().split('T')[0]
    this.selectedDate = this.selectedDate.substring(0, 7)
    this.fetchLcoList();
    this.fetchBouquetList();
    this.fetchLocationList();
    this.fetchBroadcasterList()
    this.fetchDistributorList();
    this.fetchData()
  }
  fetchDistributorList(): void {
    let url =
      this.config.json.apiEndpointRdbms + `distributor/search?searchKeyword=`;
    this.http.get(url).subscribe((data) => {
      this.distributorList = data.data;
    });
  }

  fetchLocationList(): void {
    let url =
      this.config.json.apiEndpointRdbms + `location/getLocationDropdown`;
    this.http.get(url).subscribe((data) => {
      this.stateList = data.data.locationList;
      // // console.log(this.stateList)
    });
  }

  yearsArrayCalculator() {
    this.yearsArray = [];
    let currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 2019; i--) {
      this.yearsArray.push(i);
    }
  }

  yearFilter() {
    this.isLoading = true;
    this.reportList = [];
    let year = (document.getElementById('yearCalc') as HTMLSelectElement).value;
    this.reportDate = year;
    let fromDate = `${year}-01-01`;
    let toDate = `${year}-12-31`;
    this.body = {
      ...this.body,
      fromDate,
      toDate,
    };
    this.fetchData();
  }


  dateTypeSelectors(num) {
    if (num == 0) {
      this.showYearSelector = false;
      this.dateSelector = true;
      this.showMonth = false;
      this.showDateRange = false;
      this.body = {
        fromDate: '',
        toDate: '',
        tillDate: '',
      }
      this.fetchData();
    }
    if (num == 1) {
      this.showYearSelector = false;
      this.dateSelector = false;
      this.showMonth = true;
      this.showDateRange = false;
    }
    if (num == 2) {
      this.showYearSelector = false;
      this.dateSelector = false;
      this.showDateRange = true;
      this.showMonth = false;
    }
    if (num == 3) {
      this.showYearSelector = true;
      this.dateSelector = false;
      this.showDateRange = false;
      this.showMonth = false;
    }
  }

  monthType(e) {
    this.dateChoosed = true;
    this.selectedDate = ''
    this.selectedDate += e.target.value
    this.fetchData()
  }


  dateChangeRange(ev) {
    let startDate = ev.startDate.format('YYYY-MM-DD');
    let endDate = ev.endDate.format('YYYY-MM-DD');
    this.body = {
      fromDate: startDate,
      toDate: endDate,
      tillDate: '',
    };
    this.reportDate = this.body.fromDate + ' to ' + this.body.toDate;
    this.fetchData();
  }

  dateChangeMonth(ev) {
    let dateobj = new Date(ev.target.value);
    let tillDate =
      dateobj.getFullYear() +
      '-' +
      String(dateobj.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(dateobj.getDate()).padStart(2, '0');
    this.body = {
      fromDate: '',
      toDate: '',
      tillDate: tillDate,
    };
    this.reportDate = this.body.tillDate;
    this.fetchData();
  }

  fetchLcoList(): void {
    this.report.getLCOList().subscribe((data) => {
      this.lcoList = data.data;
    });
  }

  fetchBouquetList(): void {
    this.report.getBouquetList().subscribe(data => {
      this.bouquetList = data.data;
    });
  }

  fetchBroadcasterList(): void {
    this.report.getBroadcasterList().subscribe(data => {
      this.broadcasterList = data;
    });
  }

  onSearch() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  sortByItem(item: string): void {
    console.log(item)
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchData();
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchData();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  handleFetchPage(data): void {
    this.reportList = []
    this.reportList = data.data;
    if (this.reportList.length != 0) {
      this.loadingFlag = false;
    } else {
      this.loadingFlag = false;
      this.isLoad = 'No Records';
    }
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }


  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.pageNumber = metadata.pageNumber
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  // fetchData(): void {
  //   this.loadingFlag=true;
  //   this.reportList = []
  //   PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
  //   let url = PaginationUtilities.urlBuilder('reports/monthlySubscription', this.paginationObj);
  //   url = url + "&state=" + this.statusValue + "&bouquetType=" + this.selectedBouquetType +
  //     "&distributorId=" + this.selectedDistributorId + "&broadcasterId=" + this.selectedBroadcasterId +
  //     "&lcoId=" + this.selectedLcoId
  //   if (this.from != "0000-00-00" && this.to != "0000-00-00") {
  //     url = url + "&fromDate=" + this.from + "&toDate=" + this.to;
  //   }
  //   else if (this.selectedDate != '') {
  //     url = url + "&monthYear=" + this.selectedDate + '-01'
  //   }
  //   this.http.get(url).subscribe(
  //     (data) => {
  //       this.handleFetchPage(data);
  //     },
  //     () => {
  //       this.loadingFlag = false;
  //       this.isLoad = 'No Records';
  //     }
  //   );
  // }

  private apiSubscription: Subscription | null = null;
  fetchData(): void {
    // Cancel the previous API call if it's still running
    if (this.apiSubscription) {
      this.apiSubscription.unsubscribe();
    }

    this.loadingFlag = true;
    this.reportList = [];
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms);

    let url = PaginationUtilities.urlBuilder('reports/monthlySubscription', this.paginationObj);
    url = url + "&state=" + this.statusValue + "&bouquetType=" + this.selectedBouquetType +
      "&distributorId=" + this.selectedDistributorId + "&broadcasterId=" + this.selectedBroadcasterId +
      "&lcoId=" + this.selectedLcoId;

    if (this.from !== "0000-00-00" && this.to !== "0000-00-00") {
      url = url + "&fromDate=" + this.from + "&toDate=" + this.to;
    } else if (this.selectedDate !== '') {
      url = url + "&monthYear=" + this.selectedDate + '-01';
    }

    // Start a new API call
    this.apiSubscription = this.http.get(url).subscribe(
      (data) => {
        this.handleFetchPage(data);
        this.loadingFlag = false;
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }
  lcoSelect(): void {
    this.from = this.calendar.getDate()['startDate']
    this.to = this.calendar.getDate()['endDate']
    this.fetchData();
  }


  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      // // console.log(ev.startDate.date(), ev.startDate.month(), ev.startDate.year())
      // // console.log(ev.endDate.date(), ev.endDate.month(), ev.endDate.year())
      this.eve = ev;
      this.dateString =
        ev.startDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.startDate.month()) +
        '-' +
        ev.startDate.year() +
        ' to ' +
        ev.endDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.endDate.month()) +
        '-' +
        ev.endDate.year();
      this.from = ev.startDate.format('YYYY-MM-DD');
      this.to = ev.endDate.format('YYYY-MM-DD');
      console.log(this.from)
      this.fetchData();
      this.loadingFlag = true;
      this.isLoad = 'Loading';
      this.calendar.setDate(ev);
    }
  }

  downloadTableExcel(): void {
    //// console.log("hello")
    let info = '';
    let suffix = '';
    let headers = [];
    let name = 'Monthly Subscription Report';
    const sanitizeAndFormatName = (name: string): string => {
      return name.replace(/\s+/g, '').substring(0, 10) + '_';
    };

    if (this.selectedBouquetType != '0') {
      info = info + 'Bouquet Type: ' + this.selectedBouquetType;
      suffix = suffix + sanitizeAndFormatName(this.selectedBouquetType);
    }

    if (this.selectedDistributorId != "0") {
      let distributorName = '';
      for (let i of this.distributorList) {
        if (i.id == this.selectedDistributorId) {
          distributorName = i.name;
          break;
        }
      }
      info = info + 'Distributor:' + distributorName;
      // suffix = suffix + sanitizeAndFormatName(distributorName);
    }

    
    if (this.from != "0000-00-00" && this.to != "0000-00-00") {
      info = info + this.from + "-" + this.to;
      suffix = suffix + this.from + "-" + this.to + "_";
    } else if (this.selectedDate != '') {
      const date = new Date(this.selectedDate);
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();
      info = info + this.selectedDate;
      suffix = suffix + month + '-' + year + "_";
    }
    if (this.selectedBroadcasterId != "0") {
      let broadcasterName = '';
      for (let i of this.broadcasterList) {
        if (i.id == this.selectedBroadcasterId) {
          broadcasterName = i.name;
        }
      }
      info = info + 'Broadcaster:' + broadcasterName;
      suffix = suffix + broadcasterName+'_';
    }

    if (this.selectedLcoId != "0") {
      let LCOName = '';
      for (let i of this.lcoList) {
        if (i.id.toString() == this.selectedLcoId) {
          LCOName = i.name;
        }
      }
      info = info + 'LCO:' + LCOName;
      // suffix = suffix + sanitizeAndFormatName(LCOName);
    }
    
    if (this.statusValue != 'All') {
      info = info + 'State: ' + this.statusValue;
      suffix = suffix + sanitizeAndFormatName(this.statusValue);
    }
    if (suffix.endsWith('_')) {
      suffix = suffix.slice(0, -1);
    }

    this.doc.exportTableToExcelwithImage(
      this.reportTable,
      name,
      'MSR_report_' + `${suffix}`,
    );
  }

  downloadTablePdf(): void {
    let info = '';
    let suffix = '';

    if (
      this.reportList == null ||
      this.reportList == undefined ||
      this.reportList?.length == 0
    ) {
      alert('No Data!');
      return;
    }

    const sanitizeAndFormatName = (name: string): string => {
      return name.replace(/\s+/g, '').substring(0, 10) + '_';
    };




    if (this.selectedBouquetType != '0') {
      info = info + 'Bouquet Type: ' + this.selectedBouquetType;
      // suffix = suffix + sanitizeAndFormatName(this.selectedBouquetType);
    }

    if (this.selectedDistributorId != "0") {
      let distributorName = '';
      for (let i of this.distributorList) {
        if (i.id == this.selectedDistributorId) {
          distributorName = i.name;
          break;
        }
      }
      info = info + 'Distributor:' + distributorName;
      // suffix = suffix + sanitizeAndFormatName(distributorName);
    }

   

    if (this.from != "0000-00-00" && this.to != "0000-00-00") {
      info = info + this.from + "-" + this.to;
      // suffix = suffix + this.from + "-" + this.to+"_";
    } else if (this.selectedDate != '') {
      const date = new Date(this.selectedDate);
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();
      info = info + this.selectedDate;
      suffix = suffix + month + '-' + year + "_";
    }
    if (this.selectedBroadcasterId != "0") {
      let broadcasterName = '';
      for (let i of this.broadcasterList) {
        if (i.id == this.selectedBroadcasterId) {
          broadcasterName = i.name;
        }
      }
      info = info + 'Broadcaster:' + broadcasterName;
      suffix = suffix + broadcasterName + "_";
    }
    if (this.selectedLcoId != "0") {
      let LCOName = '';
      for (let i of this.lcoList) {
        if (i.id.toString() == this.selectedLcoId) {
          LCOName = i.name;
        }
      }
      info = info + 'LCO:' + LCOName;
      // suffix = suffix + sanitizeAndFormatName(LCOName);
    }
    if (this.statusValue != 'All') {
      info = info + 'State: ' + this.statusValue;
      suffix = suffix + sanitizeAndFormatName(this.statusValue);
    }
    if (suffix.endsWith('_')) {
      suffix = suffix.slice(0, -1);
    }

    this.pdf.generatePdfHtml(
      '#reportTable',
      'MSR_report_' + `${suffix}`,
      `Till now    Source Type : SMS ${info}`, null, null, 'a3'
    );
  }


  // downloadTablePdf(): void {
  //  let  info = ''
  //  let suffix=''

  //   if (
  //     this.reportList == null ||
  //     this.reportList == undefined ||
  //     this.reportList?.length == 0
  //   ) {
  //     alert('No Data!');
  //     return;
  //   }
  //   if(this.statusValue!='0'){
  //     info= info+ 'State: '+this.statusValue
  //     suffix=suffix+this.statusValue
  //   }
  //   if(this.selectedBouquetType!='0'){
  //     info= info+ 'Bouquet Type: '+this.selectedBouquetType
  //     suffix=suffix+this.selectedBouquetType
  //   }
  //   if(this.selectedDistributorId!="0"){
  //     let distributorName=''
  //     for(let i of this.distributorList){
  //       if(i.id==this.selectedDistributorId){
  //         distributorName = i.name
  //         break;
  //       }
  //     }
  //     info = info+  'Distributor:' + distributorName
  //     suffix=suffix+distributorName
  //   }
  //   if(this.selectedBroadcasterId!="0"){
  //     let broadcasterName=''
  //     for(let i of this.broadcasterList){
  //       if(i.id==this.selectedBroadcasterId){
  //         broadcasterName = i.name
  //       }
  //     }
  //     info = info+ 'Broadcaster:' + broadcasterName
  //     suffix=suffix+broadcasterName
  //   }
  //   if (this.from != "0000-00-00" && this.to != "0000-00-00") {
  //     info = info + this.from + "-" + this.to;
  //   }
  //   else if (this.selectedDate != '') {
  //     info = info + this.selectedDate 
  //   }
  //   if(this.selectedLcoId!="0"){
  //     let LCOName=''
  //     for(let i of this.broadcasterList){
  //       if(i.id==this.selectedBroadcasterId){
  //         LCOName = i.name
  //       }
  //     }
  //     info = info+ 'LCO:' + LCOName
  //     suffix=suffix+LCOName
  //   }
  //   this.pdf.generatePdfHtml(
  //     '#reportTable',
  //     'msr_report',
  //     `Till now    Source Type : SMS ${info}`, null, null, 'a3'
  //   );

  // }

  formatDate(ele) {
    if (ele == undefined) {
      return false;
    }
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: [
        'Sl No.',
        'Bouquet Id',
        'Bouquet Name',
        'Duration', 'Channels List',
        'Subscriber Count at 7',
        'Subscriber Count at 14 ',
        'Subscriber Count at 21',
        'Subscriber Count at 28',
        'Average Subscriptions'
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slNo: i,
        subName: line.subscriberName,
        bouquetName: line.bouquetName,
        duration: line.duration,
        channelList: line.channelList,
        subscriberCountAt7: line.subscriberCountAt7,
        subscriberCountAt14: line.subscriberCountAt14,
        subscriberCountAt21: line.subscriberCountAt21,
        subscriberCountAt28: line.subscriberCountAt28,
        subscriberCountAtEndOfTheMonth: line.subscriberCountAtEndOfTheMonth

      };
      data.push(csvLine);
      i += 1;
    });

    new ngxCsv(data, 'msr_report', options);
  }


}