<div class="cms__content">
  <app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Product Subscription and Desubscription logss</h3>
    <a [routerLink]="['/logs']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>



  <div class="row">
    <div class="filter__group col-12 col-md-6">
      <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
    </div>
  </div>

  <div class="row">
    <div class="filter__group col-12 col-md-6 input_field">
      <div class="input_field">
        <input
          type="text"
          autocomplete="off"
          class="custom__field"
          id="searchSubCode"
          placeholder="Search Subscriber"
          (input)="getSubscriberId($event)"
        />
        <p id="clearBTN" (click)="clearInput()">Clear</p>
      </div>
      <div class="search_param" style="margin-right: 12px" *ngIf="openSearch">
        <app-subscriber-search
          [searchText]="searchText"
          (userDetail)="getUser($event)"
        >
        </app-subscriber-search>
      </div>
    </div>
    </div>

  <div class="row row-top">
    <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
      <label class="report_selection_label"> Status: </label>
      <select class="custom__field report_selection" id="statusChangeApi" [(ngModel)]="statusValue"
        (change)="fetchData()">
        <option value="2">ALL</option>
        <option value="1">Active</option>
        <option value="0">Inactive</option>
      </select>
    </div>

    <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
      <label class="report_selection_label" style="margin-right: 1%;"> Bouquet: </label>
      <select class="custom__field report_selection" id="statusChangeApi" [(ngModel)]="selectedBouquetId"
        (change)="fetchData()">
        <option value="0">ALL</option>
        <option *ngFor="let bouquet of bouquetList" [ngValue]="bouquet.id">
          {{ bouquet.name }}
        </option>
      </select>
    </div>
    <!-- <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
      <label class="report_selection_label" style="margin-right: 1%;"> Subscriber: </label>
      <select class="custom__field report_selection" id="statusChangeApi" [(ngModel)]="selectedSubscriberId"
        (change)="fetchData()">
        <option value="0">ALL</option>
        <option *ngFor="let subs of subscriberList" [ngValue]="subs">
          {{ subs.name }}
        </option>
      </select>
    </div> -->

  </div>

  <div class="row">
    <div class="col-md-6 report-generated-fix">
      <p>
        Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
      </p>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
        CSV
        <img src="./assets/csv.svg" alt="download" /> </button>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row overflow">
      <div class="custom__table__filter">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <!-- -->
        <div class="col-12 col-md-6 table-search">
          <div class="table__right">
            <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> record{{reportList.length == 1?
              '' : 's'}}</p>
            <!-- <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}} -->
            <!-- </p> -->
          </div>
          <div class="search__container">
            <input type="text" class="custom__field" id="searchKeyword" (keydown.enter)="onSearch()"
              [(ngModel)]="paginationObj.searchKeyword" />
            <button (click)="onSearch()" (keydown.enter)="onSearch()">
              <img src="./assets/search.png" alt="search" />
            </button>
          </div>
        </div>
      </div>
      <div class="tableBox">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.transactionDate)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.transactionDate}">Transaction Date</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.subscriberId)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.subscriberId}">Subscriber ID</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.subscriberName)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.subscriberName}">Subscriber Name</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.stbId)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.stbId}">Stb ID</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.macId)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.macId}">MAC ID</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.bouquetId)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.bouquetId}">Bouquet ID</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.bouquetName)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.bouquetName}">Bouquet Name</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.processType)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.processType}">Subscription status</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.startDate)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.startDate}">Start Date</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.endDate)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.endDate}">End Date</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList?.length ==0 && !loadingFlag">
            <td style="text-align: center !important;" colspan="12">{{isLoad}}</td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important;" colspan="12">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <ng-template #showList>
            <tr *ngFor="let report of reportList, let j = index">
              <td>{{(paginationObj.pageNumber - 1) * paginationObj.pageSize +(j + 1)}}</td>
              <td>{{(report.transactionDate || report.transactionDate) == null ||(report.transactionDate ||
                report.transactionDate) == 'null' || (report.transactionDate || report.transactionDate) == ''? '---' :
                (report.transactionDate || report.transactionDate)| date: 'dd-MM-yyyy HH:mm:ss'}}</td>
              <td>{{report.subscriberId?report.subscriberId:'---'}}</td>
              <td>{{report.subscriberName == 'null' || report.subscriberName == null || (report.subscriberName ||
                report.subscriberName) == '' ? '---' : (report.subscriberName || report.subscriberName)}}</td>
              <td>{{report.stbId?report.stbId:'---'}}</td>

              <td>{{(report.macId || report.macId) == 'null' || (report.macId
                || report.macId) == null || (report.macId || report.macId) ==
                '' ? '---' : (report.macId || report.macId)}}</td>
              <td>{{report.bouquetId?report.bouquetId:'---'}}</td>
              <td>{{(report.bouquetName || report.bouquetName ) == 'null' || (report.bouquetName ||
                report.bouquetName ) == '' || (report.bouquetName || report.bouquetName ) == null ? '---' :
                (report.bouquetName || report.bouquetName )}}</td>
              <td>{{(report.processType || report.processType) == 'null' || (report.processType || report.processType)
                == '' ||
                (report.processType || report.processType) == null ? '---' : (report.processType ||
                report.processType)}}</td>
              <td>{{report.startDate?(report.startDate|date: 'dd-MM-yyyy HH:mm:ss'):'---'}}</td>
              <td>{{report.endDate?(report.endDate|date: 'dd-MM-yyyy HH:mm:ss'):'---'}}</td>

            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>