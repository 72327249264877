<div class="cms__content">
    <app-reports-header></app-reports-header>
  
    <div class="top__heading">
      <h3>Ads Logs</h3>
      <a [routerLink]="['/logs']"
        ><button class="default__button float-right">Back</button></a
      >
    </div>
  
    <div class="col-12 report">
      <p>
        Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
      </p>
      <div class="download__report col-md-6">
        <button
          class="default__button"
          (click)="downloadTablePdf()"
          title="Download PDF"
        >
          PDF
          <img src="./assets/pdf.svg" alt="download" />
        </button>
        <button
          class="default__button"
          (click)="downloadTableExcel()"
          title="Download Excel"
        >
          Excel
          <img src="./assets/excel.svg" alt="download" />
        </button>
        <button
          class="default__button"
          (click)="downloadTableCSV()"
          title="Download Excel"
        >
          CSV
          <img src="./assets/csv.svg" alt="download" />
        </button>
      </div>
    </div>
    <div class="custom__table__filter">
      <span>
        Show
        <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
          <option
            *ngFor="let size of paginationObj.pageSizeOptions"
            [ngValue]="size"
          >
            {{ size }}
          </option>
        </select>
        Entries
      </span>
      <div class="table__right">
        <p class="table__summary">
          Total <span>{{ paginationObj.totalElements }}</span> records
        </p>
        <div class="search__container">
          <input
            type="text"
            autocomplete="off"
            class="custom__field"
            id="searchKeyword"
            [(ngModel)]="paginationObj.searchKeyword"
            (keyup.enter)="searchKeyWord()"
          />
          <button (click)="searchKeyWord()">
            <img src="./assets/search.png" alt="search" />
          </button>
        </div>
      </div>
    </div>
  
    <div class="col-12 list__table">
      <div class="row scroll">
        <div class="tableBox">
        <table class="custom__iptv__table" #reportTable id="reportTable">
          <thead>
            <tr>
              <th>Sl No.</th>
              <th>Ad Name</th>
              <th>LCO Name</th>
              <th>Uploaded By</th>
              <th>Action</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="reportList.length == 0 && !loadingFlag">
              <td style="text-align: center !important" colspan="8">
                {{ isLoad }}
              </td>
            </tr>
            <tr *ngIf="loadingFlag">
              <td style="text-align: center !important" colspan="8">
                <app-custom-spinner></app-custom-spinner>
              </td>
            </tr>
            <!-- <ng-template #showList> -->
            <tr *ngFor="let report of reportList; let i = index">
              <td>{{(paginationObj.pageNumber - 1) * paginationObj.pageSize +(i + 1)}}</td>
              <td>{{ report.adName ? report.adName : "---" }}</td>
              <td>{{ report.lcoName ? report.lcoName : "---" }}</td>
              <!-- <td><img src="{{report.url}}" class="imgs"></td> -->
              <td>{{ report.uploadedBy ? report.uploadedBy : "---" }}</td>
              <td>{{ report.action ? report.action : "---" }}</td>
              <td>
                {{
                  report.createdDate | date : "dd-MM-yyyy HH:mm:ss" : "IST"
                }}
                <div class="action__items">
                  <button (click)="viewAdsDetails(report)">View</button>
                  <!-- <button class="danger"
                    (click)="updateStatus(admin)">{{admin.status===1?'Deactivate':'Activate'}}</button> -->
                </div>
              </td>
            </tr>
            <!-- </ng-template> -->
          </tbody>
        </table>
      </div>
        <div class="custom__pagination">
          <ul>
            <li>
              <button [disabled]="disableFirstBtn" (click)="pageFirstBtnClick()">
                <img src="./assets/firstPage.png" />
              </button>
            </li>
            <li>
              <button [disabled]="disablePreviousBtn" (click)="pageDecrease()">
                <img src="./assets/previousPage.png" />
              </button>
            </li>
            <li>
              <span
                >Page {{ paginationObj.pageNumber }} of
                {{ paginationObj.totalPages }}</span
              >
            </li>
            <li>
              <button [disabled]="disableNextBtn" (click)="pageIncrease()">
                <img src="./assets/nextPage.png" />
              </button>
            </li>
            <li>
              <button [disabled]="disableLastBtn" (click)="pageLastBtnClick()">
                <img src="./assets/lastPage.png" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  

  
<div class="modal_box" *ngIf="viewModal">
  <div class="modal_box_inner">
    <h3>Ads Details</h3>
   

    <div class="preview">
      <div class="preview_tv_home" *ngIf="adDetails.adName=='home-banner'">
    
        <img src="../../../assets/tv_mockup.png" alt="">
    
        <div class="overlay_home">
    
          <img [src]="adDetails.url" alt="">
        </div>
    
        <span>{{adDetails.adName}}</span>
        
    
      </div>
    
      <div class="preview_tv_channel" *ngIf="adDetails.adName=='home-overlay'">
    
        <img src="../../../assets/tv_mockup.png" alt="">
    
        <div class="overlay_channel">
    
          <img [src]="adDetails.url" alt="">
        </div>
    
        <span>{{adDetails.adName}}</span>
    
      </div>

      <div class="preview_tv_channel" *ngIf="adDetails.adName=='app-icon'">
    
        <img src="../../../assets/tv_mockup.png" alt="">
    
        <div class="appicon_channel">
    
          <img [src]="adDetails.url" alt="">
        </div>
    
        <span>{{adDetails.adName}}</span>
    
      </div>

      <div class="preview_tv_channel" *ngIf="adDetails.adName=='splash-screen'">
    
        <img src="../../../assets/tv_mockup.png" alt="">
    
        <div class="splashscreen_modal">
    
          <img [src]="adDetails.url" alt="">
        </div>
    
        <span>{{adDetails.adName}}</span>
    
      </div>
    
    </div>
   
    <button class="cta float-right" (click)="viewModal = !viewModal"> Close </button>
  </div>
</div>