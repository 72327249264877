import { LogsSubscriberSmsComponent } from './logs-subscriber-sms/logs-subscriber-sms.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogsProductCompositionChangeComponent } from './logs-product-composition-change/logs-product-composition-change.component';
import { LogsStbConnectionStatusComponent } from './logs-stb-connection-status/logs-stb-connection-status.component';
import { LogsUserActivityComponent } from './logs-user-activity/logs-user-activity.component';
import { LogsUserLoginComponent } from './logs-user-login/logs-user-login.component';
import { LogsBouquetStatusChangeComponent } from './logs-bouquet-status-change/logs-bouquet-status-change.component';
import { LogsChannelStatusChangeComponent } from './logs-channel-status-change/logs-channel-status-change.component';
import { LogsCompleteComponent } from './logs-complete/logs-complete.component';
import { LogsStbActivationDeactivationComponent } from './logs-stb-activation-deactivation/logs-stb-activation-deactivation.component';
import { LogsSubscriptionDesubscriptionComponent } from './logs-subscription-desubscription/logs-subscription-desubscription.component';
import { LogsBouquetModificationComponent } from './logs-bouquet-modification/logs-bouquet-modification.component';
import { LogsChannelModificationComponent } from './logs-channel-modification/logs-channel-modification.component';
import { LogHomeComponent } from './log-home/log-home.component';
import { LogsBatchComponent } from './logs-batch/logs-batch.component';
import { LogsSubscriptionComponent } from './logs-subscription/logs-subscription.component';
import { MSOLCOLoginHistoryComponent } from './msolcologin-history/msolcologin-history.component';
import { UserLoginHistoryComponent } from './user-login-history/user-login-history.component';
import { TransactionLogsComponent } from '../wallet/transaction-logs/transaction-logs.component';
import { LogsProductModificationComponent } from './logs-product-modification/logs-product-modification.component';
import { LogsCompletecategoriesComponent } from './logs-completecategories/logs-completecategories.component';
import { LogsProductSubscriptionDesubscriptionComponent } from './logs-product-subscription-desubscription/logs-product-subscription-desubscription.component';
import { LogsHomeNewComponent } from './logs-home-new/logs-home-new.component';
import { LogsStbActivationDeactivationNewComponent } from './logs-stb-activation-deactivation-new/logs-stb-activation-deactivation-new.component';
import { LogsChannelModificationNewComponent } from './logs-channel-modification-new/logs-channel-modification-new.component';
import { LogAdComponent } from './log-ad/log-ad.component';

const routes: Routes = [
  { path: 'logs-home-new', component: LogsHomeNewComponent },
  { path: 'logs-user-auth', component: LogsUserLoginComponent },
  { path: 'logs-user-activity', component: LogsUserActivityComponent },
  { path: 'logs-bouquet-status', component: LogsBouquetStatusChangeComponent },
  { path: 'logs-channel-status', component: LogsChannelStatusChangeComponent },
  // { path: 'logs-product-composition', component: LogsProductCompositionChangeComponent },
  { path: 'logs-stb-connection', component: LogsStbConnectionStatusComponent },
  {path : '', component : LogHomeComponent},
  { path: 'logs-complete', component: LogsCompleteComponent },
  { path: 'logs-stb', component: LogsStbActivationDeactivationComponent },
  { path: 'logs-subscription-desubscription', component: LogsSubscriptionDesubscriptionComponent },
  { path: 'logs-bouquet-modification', component: LogsBouquetModificationComponent },
  { path: 'logs-channel-modification', component: LogsChannelModificationComponent },
  { path: 'logs-batch', component: LogsBatchComponent },
  { path: 'logs-subscription', component: LogsSubscriptionComponent },
  { path: 'mso-lco-login-history', component: MSOLCOLoginHistoryComponent },
  { path: 'user-login-history', component: UserLoginHistoryComponent },
  { path: 'logs-transaction', component: TransactionLogsComponent },
  { path: 'subscriber-sms-logs', component: LogsSubscriberSmsComponent },
  { path: 'logs-product-modification', component: LogsProductModificationComponent },
  { path: 'logs-product-subscription-desubscription', component: LogsProductSubscriptionDesubscriptionComponent },
  { path: 'logs-stb-activation-deacivation', component: LogsStbActivationDeactivationNewComponent },
  {path:'logs-complete-categories',component:LogsCompletecategoriesComponent},
  {path:'logs-channel-modification-new',component:LogsChannelModificationNewComponent},
  {path:'logs-ads',component:LogAdComponent}


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LogsRoutingModule { }
