<div class="cms__content">
	<app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Broadcaster Ala-carte Channel Wise Weekly Report</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row row-top">
    <div class="date_filter">
      <div class="div_tabs" *ngIf="dateSelector">
        <button (click)="dateTypeSelectors(1)" >Month</button>
        <button (click)="dateTypeSelectors(2)" >Random Date</button>
      </div>
      <div class="div_tabs2" *ngIf="showMonth" >
       <div class="date_box">
        <label for="month">Select Month:</label>
        <div class="date_box_orig">
          <input class="custom__field mar_zeroed" type="month" id="month"  value="selectedDate" (change)="dateChanged($event)">
        </div>
       </div>
        <button (click)="dateTypeSelectors(0)" >X</button>
      </div>
      <div class="div_tabs2" *ngIf="showDateRange" >
        <label for="dateRange">
          Select Date :
        </label>
        <div class="date_box_orig">
          <app-date-picker id="mar_zeroeds" (dateChange)="dateChange($event)" ></app-date-picker>
        </div>
        <button id="app_date_pick_button" (click)="dateTypeSelectors(0)" >X</button>
      </div>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
				CSV
				<img src="./assets/csv.svg" alt="download" />
			</button>
    </div>
  </div>

  <div class="col-12 col-md-6 report_selection_block">
    <label class="report_selection_label">Select Broadcaster</label>
    <select class="custom__field report_selection" [(ngModel)]="broadcasterId" id="broadcasterselected" (change)='broadcasterSelected()' [disabled]="!dateChoosed">
      <!-- Changed ngValue binding from lco.id to lco.email -->
      <!-- <option value = 0 >ALL</option> -->
      <option *ngFor="let lco of broadcasterList" value={{lco.id}} >
        {{lco.name}}
      </option>
    </select>
  </div>
  <!-- <div class="col-12 col-md-6 report_selection_block">
    <label class="report_selection_label">Select Product</label>
    <select class="custom__field report_selection" (change)="selectedProductType()" id="productTypeSelection" >
      <option value="0" >All</option>
      <option value="0" >Base Pack</option>
      <option value="1" >Ala Carte</option>
      <option value="2" > Add On </option>
    </select>
  </div> -->
  <div class="third_box">
    <div class="col-12 col-md-6 report">
      <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
    </div>
    <div style="display: flex; gap:20px" >
      <div class="table__right">
        <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
      </div>
      <div class="search__container">
        <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
        <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <!-- <th>Date</th> -->
            <th>Sl No</th>
            <!-- <th>Channel ID</th> -->
            <th>Channel Name</th>
            <th *ngIf="!tableDisplay">Subscriber Count</th>
            <th *ngIf="tableDisplay" >NUMBER OF SUBSCRIBERS OF THE CHANNEL ON 7TH DAY OF MONTH</th>
            <th *ngIf="tableDisplay" >NUMBER OF SUBSCRIBERS OF THE CHANNEL ON 14TH DAY OF MONTH</th>
            <th *ngIf="tableDisplay" >NUMBER OF SUBSCRIBERS OF THE CHANNEL ON 21TH DAY OF MONTH</th>
            <th *ngIf="tableDisplay" >NUMBER OF SUBSCRIBERS OF THE CHANNEL ON 28TH DAY OF MONTH</th>
            <th *ngIf="tableDisplay" >MONTHLY SUBSCRIPTION OF THE CHANNELS</th>

            <!-- <th>Total Subscriptions</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="isLoading" >
            <td colspan="8" style="text-align: center;" *ngIf="isLoading" ><app-custom-spinner></app-custom-spinner></td>
            <!-- <td colspan="8" style="text-align: center;" *ngIf="!isLoading && reportList?.length == 0" >No records</td> -->
          </tr>
          <tr *ngIf="!dateChoosed" >
            <td colspan="8" style="text-align: center;" >Choose Date</td>
          </tr>
          <!-- <ng-template #showList> -->
            <tr *ngFor="let report of reportList, let j = index">
              <td>{{j+1}}</td>
              <!-- <td>{{report.channelId}}</td> -->
              <td>{{report.channelName}}</td>
              <!-- <td>{{report.channelNo}}</td> -->
              <!-- <td style="word-wrap: break-all">{{report.subscriptionType}}</td> -->
              <td *ngIf="!tableDisplay">{{report.subscriberCount}}</td>
              <td *ngIf="tableDisplay">{{report.subscriberCountAt7}}</td>
              <td *ngIf="tableDisplay" >{{report.subscriberCountAt14}}</td>
              <td *ngIf="tableDisplay" >{{report.subscriberCountAt21}}</td>
              <td *ngIf="tableDisplay" >{{report.subscriberCountAt28}}</td>
              <td *ngIf="tableDisplay" >{{(report.subscriberCountAt7+report.subscriberCountAt14+
                report.subscriberCountAt21+report.subscriberCountAt28)/4}}</td>
            </tr>
          <!-- </ng-template> -->
        </tbody>
      </table>
    </div>
  </div>
</div>
