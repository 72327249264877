import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';
import {
  PaginationObj,
  PaginationUtilities,
} from 'src/app/models/pagination/pagination-info';
import {
  SortOrder,
  PageMetadata,
  PageInfo,
} from 'src/app/models/pagination/page-info';
import { delay } from 'rxjs/operators';
import { LocationInfo } from 'src/app/models/location/location-info';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-list-location',
  templateUrl: './list-location.component.html',
  styleUrls: ['./list-location.component.scss'],
})
export class ListLocationComponent implements OnInit {
  paginationObj = new PaginationObj();

  loadingFlag = false;
  locationCount: number;
  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  sortByProperties = {
    country: 'country',
    state: 'state',
    cdate: 'createdDate',
    id: 'id',
    city: 'city',
    locality: 'locality',
    pin: 'pincode',
  };

  locationList: LocationInfo[] = [];
  menus
  menuAccess = false

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    this.fetchPage();
    this.menus=JSON.parse(atob(localStorage.getItem('menus')))
    this.checkMenuAccess()
  }

  onSearch() {
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }

  checkMenuAccess(){
    for(let i of this.menus){
      if(i.name=='Location')
      {
        this.menuAccess=(i.access==0?false:true);
        console.log(this.menuAccess)
      }
    }
    console.log(this.menuAccess)
  }

  fetchPage(): void {
    // const url = PaginationUtilities.urlBuilderRdbms('location/getLocations', this.paginationObj);
    const url =
      this.config.json.apiEndpointRdbms +
      'location/getLocations/' +
      this.paginationObj.pageNumber +
      '?pageSize=' +
      this.paginationObj.pageSize +
      '&sortBy=' +
      this.paginationObj.sortBy +
      '&sortOrder=' +
      this.paginationObj.sortOrder +
      '&searchKeyword=' +
      this.paginationObj.searchKeyword;
    this.loadingFlag = true;

    this.http
      .get(url)
      .pipe(delay(500))
      .subscribe((data: PageInfo<LocationInfo>) => {
        this.loadingFlag = false;
        this.handleFetchPage(data);
        this.locationCount = data.metadata.totalElementCount;
      });
  }

  handleFetchPage(data: PageInfo<LocationInfo>): void {
    this.locationList = data.data;

    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }

  pageSize;
  pageNumber;
  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
    this.pageSize = metadata.pageSize;
    this.pageNumber = metadata.pageNumber;
  }

  handlePaginationBtnDisableStatuses(): void {
    // Left section
    this.disableFirstBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;
    this.disablePreviousBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;

    // Right section
    this.disableNextBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
    this.disableLastBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder =
      this.paginationObj.sortOrder === SortOrder.DESC
        ? SortOrder.ASC
        : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record: LocationInfo): void {
    this.router.navigate(['/manage/location', record.id]);
  }

  // deleteItem(record: LocationInfo, index: number): void {
  //   const url = environment.apiEndpoint + 'location/deleteLocation/' + record.id;
  //   this.http.delete(url).subscribe(() => {
  //     window.alert('Deleted Location');
  //     this.fetchPage();
  //   });
  // }
}
