<div class="cms__content">
    <div class="top__heading">
      <h3>Ads</h3>
      <a [routerLink]="['/settings/ad-manager/ad-upload']"><button class="default__button float-right" *ngIf="menuAccess">+ Add New</button></a>
    </div>
  
    <div class="col-12 list__table">
      <div class="row overflow">
        <div class="custom__table__filter">
          <span>
            Show
            <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
              <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
            </select>
            Entries
          </span>
          <div class="table__right">
            <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> records</p>
            <div class="search__container">
              <input type="text" autocomplete="off" class="custom__field" [(ngModel)]="paginationObj.searchKeyword"
                (keyup.enter)="$event.preventDefault(); onSearch()" />
              <button (click)="onSearch()"><img src="./assets/search.png" alt="search" /></button>
            </div>
          </div>
        </div>
        <div class="tableBox">
        <table class="custom__iptv__table">
          <thead>
            <tr>
              <th>
                Sl No.
              </th>
              <th class="label__align" (click)="sortByItem(sortByProperties.lcoId)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.lcoId}">
                LCO ID
              </th>
              <th (click)="sortByItem(sortByProperties.lcoName)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.lcoName}">
                LCO Name
              </th>
  
  
              <th (click)="sortByItem(sortByProperties.distributorName)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.distributorName}">
                Distributor Name
              </th>
  
              <th class="label__align" (click)="sortByItem(sortByProperties.adNames)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.adNames}">
                Ad Name
              </th>
  
  
  
              <th class="label__align" (click)="sortByItem(sortByProperties.createdDate)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.createdDate}">
              
              Created Date </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="loadingFlag; else showList">
              <td class="loading" colspan="9">
                <app-custom-spinner></app-custom-spinner>
              </td>
              <!-- <div class="cont">
                <div class="ring"></div>
                <div class="ring"></div>
                <div class="ring"></div>
                <p>Loading..</p>
              </div> -->
            </tr>
  
            <ng-template #showList>
              <tr *ngFor="let mso of msoList, let j = index">
                <td>{{((pageNumber-1)*pageSize)+(j+1)}}</td>
                <td>{{mso.lcoId? mso.lcoId : '---'}}</td>
                <td>{{mso.lcoName? mso.lcoName : '---'}}</td>
                <td>{{mso.distributorName? mso.distributorName : '---'}}</td>
                <td>{{mso.adNames? mso.adNames : '---'}}</td>
                <td>
                  {{mso.createdDate | date: 'dd-MM-yyyy HH:mm:ss'}}
                  <div class="action__items">
                    <button (click)="viewAdsDetails(mso)">View</button>
                    <button (click)="editAdsDetails(mso)">Edit</button>
                    <!-- <button class="danger"
                      (click)="updateStatus(admin)">{{admin.status===1?'Deactivate':'Activate'}}</button> -->
                  </div>
                </td>
  
              </tr>
            </ng-template>
          </tbody>
        </table>
        </div>
        <div class="custom__pagination">
          <ul>
            <li>
              <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
                <img src="./assets/firstPage.png" /></button>
            </li>
            <li>
              <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
                <img src="./assets/previousPage.png" /></button>
            </li>
            <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
            <li>
              <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
                <img src="./assets/nextPage.png" /></button>
            </li>
            <li>
              <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
                <img src="./assets/lastPage.png" /></button>
            </li>
          </ul>
  
        </div>
      </div>
    </div>
  </div>

  
<div class="modal_box" *ngIf="viewModal">
    <div class="modal_box_inner">
      <h3>Ads Details</h3>
      <div class="preview">
        <div class="preview_tv_home">
          <img src="../../../assets/tv_mockup.png" alt="">
          <div class="overlay_home">
            <img [src]="homebannerList.url" alt="">
          </div>
          <span>{{homebannerList.NAME}}</span>
        </div>
        <div class="preview_tv_channel">
          <img src="../../../assets/tv_mockup.png" alt="">
          <div class="overlay_channel">
            <img [src]="homeoverlayList.url" alt="">
          </div>
          <span>{{homeoverlayList.NAME}}</span>
        </div>
      </div>

      <div class="preview">
        <div class="preview_tv_home">
          <img src="../../../assets/tv_mockup.png" alt="">
          <div class="appicon_channel">
            <img [src]="appIconList.url" alt="">
          </div>
          <span>{{appIconList.NAME}}</span>
        </div>
        <div class="preview_tv_channel">
          <img src="../../../assets/tv_mockup.png" alt="">
          <div class="splashscreen_modal">
            <video controls autoplay src="{{splashScreenList.url}}" alt=""  width="100%"></video>
          </div>
          <span>{{splashScreenList.NAME}}</span>
        </div>
      </div>
      <button class="cta float-right" (click)="viewModal = !viewModal"> Close </button>
    </div>
  </div>