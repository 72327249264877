import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import {
  PaginationObj, PaginationUtilities,
} from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ngxCsv } from 'ngx-csv';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';

@Component({
  selector: 'app-report-stb-inventory-new',
  templateUrl: './report-stb-inventory-new.component.html',
  styleUrls: ['./report-stb-inventory-new.component.scss', '../report-filters.scss']
})
export class ReportStbInventoryNewComponent implements OnInit {
  profileInfo = {};
  paginationObj = new PaginationObj();
  loadingFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  sortByProperties = {
    id: 'id',
    manufacturer: 'manufacturer',
    ethernetMac: 'ethernetMac',
    wifiMac: 'wifiMac',
    faultyStatus: 'faultyStatus',
    assignedFlag: 'assignedFlag',
    activationStatus: 'activationStatus',
    blacklistStatus:'blacklistStatus',
    createdDate:'createdDate',
    deviceType:'deviceType'
  };

  reportList = [];
  from;
  to;
  totalPages;
  searchKeywordW = '';
  currentDate = new Date();
  isLoad = 'Choose Date';
  pageSize = 10;
  pageNumber = 1;
  dateSelected = false;
  ev;
  reportDate;
  manufactrerList = []
  manufacturervalue: any = "All"
  statusValue: any = "All"

  body = {
    fromDate: '',
    toDate: '',
  };
  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private pdf: PdfGeneratorService
  ) { }

  ngOnInit(): void {

    this.paginationObj.pageNumber = 1;
    this.fetchData();
    this.fetchManufacturerList()
  }
  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchData();
  }
  fetchManufacturerList(){
    let url=this.config.json.apiEndpointRdbms+'stb/manufacturers'
    this.http.get(url).subscribe(
      data=>{
        this.manufactrerList=data.data
      }
    )
  }
  onSearch() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }
  fetchData() {
    this.loadingFlag = true
    this.reportList = []
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url = PaginationUtilities.urlBuilder('reports/stbInventoryReport', this.paginationObj);
    url = url + "&status=" + this.statusValue + "&manufacturer=" + this.manufacturervalue
    this.http.get(url).subscribe({
      next: (res) => {
        this.handlePageMetadata(res.metadata);
        this.reportList = res.data;
        if (res.data?.length == 0) {
          this.isLoad = 'No Data';
          this.loadingFlag = true;
        }
      },
      error: () => {
        this.loadingFlag = true;
        this.isLoad = 'Error Fetching Data';
      },
      complete: () => {
        this.loadingFlag = false;
        if (this.paginationObj.pageNumber == 1) {
          this.disableFirstBtn = true;
          this.disablePreviousBtn = true;
          this.disableLastBtn = false;
          this.disableNextBtn = false;
        } else if (
          this.paginationObj.pageNumber == this.paginationObj.totalPages
        ) {
          this.disableFirstBtn = false;
          this.disablePreviousBtn = false;
          this.disableLastBtn = true;
          this.disableNextBtn = true;
        } else {
          this.disableFirstBtn =
            this.disablePreviousBtn =
            this.disableNextBtn =
            this.disableLastBtn =
            false;
        }
        console.log(this.paginationObj);
      },
    });
  }

  handlePageMetadata(data: any) {
    this.paginationObj.pageNumber = data.pageNumber;
    this.paginationObj.pageSize = data.pageSize;
    this.paginationObj.totalElements = data.totalElementCount;
    this.paginationObj.totalPages = data.totalPageCount;
    this.paginationObj.searchKeyword = data.searchKeyword;
  }

  dateChange(eve: NgxDateRangeInfo) {
    if (
      eve.startDate == null ||
      eve.startDate == undefined ||
      eve.endDate == null ||
      eve.endDate == undefined
    ) {
      return;
    }
    this.body = {
      fromDate: eve.startDate.format('YYYY-MM-DD'),
      toDate: eve.endDate.format('YYYY-MM-DD'),
    };
    this.reportDate = `${this.body.fromDate} to ${this.body.toDate}`;
    this.fetchData();
  }

  downloadTableExcel(): void {
    let name = 'STB Inventory Report';
    let filters=`Source : SMS , Stb Type : ${this.statusValue} , Manufacturer : ${this.manufacturervalue}`
    this.doc.exportTableToExcelwithImage(
      this.reportTable,
      name,
      'stb_inventory_report',undefined,
      undefined,
      'Filters : ',
      filters
    );
  }

  downloadTablePdf(): void {
    let date = new Date();
    let reportGenerated = `${String(date.getDate()).padStart(2, '0')}-${String(
      date.getMonth() + 1
    ).padStart(2, '0')}-${date.getFullYear()}`;
    this.pdf.generatePdfHtml('#print',
      'stb_inventory_report',`Source : SMS , Stb Type : ${this.statusValue} , Manufacturer : ${this.manufacturervalue}`,
      
    );
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: [
        'SL No.',
            'STB ID',
            'Ethernet MAC',
            'WIFI MAC',
            'Manufacturer',
            'Faulty Status',
            'Assigned flag',
            'Activation status',
            'Blacklisted status',
            'Created Date',
        ,
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slno: i,
        id: line.id,
        ethernetMac: line.ethernetMac,
        wifiMac: line.wifiMac,
        manufacturer:line.manufacturer,
        faultyStatus: line.faultyStatus,
        assignedFlag: line.assignedFlag,
        activationStatus: line.activationStatus,
        blacklistStatus: line.blacklistStatus,
        createdDate: line.createdDate,
      };
      data.push(csvLine);
    });

    new ngxCsv(data, 'stb_inventory_report', options);
  }

  searchKeyWord() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageSizeChange() {
    this.fetchData();
  }

  pageFirstBtnClick() {
    this.disableFirstBtn = true;
    this.disablePreviousBtn = true;
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageLastBtnClick() {
    this.disableLastBtn = true;
    this.disableNextBtn = true;
    this.paginationObj.pageNumber = this.paginationObj.totalPages;
    this.fetchData();
  }

  pageIncrease() {
    if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableNextBtn = true;
      this.disableLastBtn = true;
    }
    this.paginationObj.pageNumber++;
    this.fetchData();
  }

  pageDecrease() {
    if (this.paginationObj.pageNumber == 1) {
      this.disablePreviousBtn = true;
      this.disableFirstBtn = true;
    }
    this.paginationObj.pageNumber--;
    this.fetchData();
  }
}

