import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LogsDataService } from 'src/app/logs/plugins/logs-data.service';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import {
  PaginationObj,
  PaginationUtilities,
} from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ngxCsv } from 'ngx-csv';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LcoSearchType } from 'src/app/lco/lco-search/lco-search';
import { DistributorSearchType } from 'src/app/distributor/distributor-search/distributor-search.component';
import { SubscriberSearchType } from 'src/app/subscription/subscriber-search/subscriber-search';
import * as XLSX from 'xlsx';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-transaction-logs',
  templateUrl: './transaction-logs.component.html',
  styleUrls: ['./transaction-logs.component.scss'],
})
export class TransactionLogsComponent implements OnInit {
  profileInfo = {};
  paginationObj = new PaginationObj();
  loadingFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  reportList = [];
  from;
  to;
  totalPages;
  searchKeywordW = '';
  currentDate = new Date();
  isLoad = 'Choose Date';
  pageSize = 10;
  pageNumber = 1;
  dateSelected = false;
  ev;
  reportDate;
  body = {
    fromDate: '',
    toDate: '',
  };
  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private pdf: PdfGeneratorService,
    private router: Router,
    private auth: AuthService
  ) { }

  isInLogs = false;
  user
  userId
  searchText!: string
  isSearch = false
  id = 0;
  userType = 'All'



  ngOnInit(): void {
    if (this.config.json.userType == 'MSO') {
      this.user = true
    }
    else {
      this.user = false
    }
    this.userId = JSON.parse(localStorage.getItem('profileInfo')).id
    this.isInLogs = this.router.url.includes('logs');
    this.body = {
      fromDate: '',
      toDate: '',
    };
    this.isLoad = 'Loading...';
    this.paginationObj.pageNumber = 1;
    this.fetchData(this.body);
  }


  getSearchText(e: Event) {
    this.isSearch = false
    this.searchText = (e.target as HTMLInputElement).value
    if (this.searchText.length >= 3) {
      setTimeout(() => {
        this.isSearch = true
      }, 300);
    }
  }

  getLCOId(e) {
    let inp = document.getElementById('lcoId') as HTMLInputElement
    if (this.userType == 'lco') {
      inp.value = `${e.lcoName}: ${e.lcoCode}`
      this.id = e.lcoId
    }
    else if (this.userType == 'distributor') {
      inp.value = `${e.name}: ${e.distributorCode}`
      this.id = e.id
    }
    else if (this.userType == 'user') {
      inp.value = `${e.subscriberName}: ${e.subscriberCode}`
      this.id = e.subscriberId
    }
    this.isSearch = false
    this.fetchData(this.body)
  }

  selectUserType(e: Event) {
    this.userType = (e.target as HTMLSelectElement).value;
    let inp = document.getElementById('lcoId') as HTMLInputElement
    if (inp != null) {
      inp.value = '';
    }
    this.body['accountType'] = this.userType
    this.body['subscriberId'] = this.id
    this.fetchData(this.body)
  }

  fetchData(body: { fromDate: string; toDate: string }) {
    if (this.user == true) {
      this.loadingFlag = true;
      PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
      let url = PaginationUtilities.urlBuilder(
        `wallet/${this.isInLogs ? 'transactions' : 'onlinePaymentHistory'}`,
        this.paginationObj
      );
      let subscription = {
        next: (res) => {
          this.handlePageMetadata(res.metadata);
          this.reportList = res.data;
          if (res.data?.length == 0) {
            this.isLoad = 'No Data';
            this.loadingFlag = true;
          }
        },
        error: () => {
          this.loadingFlag = true;
          this.isLoad = 'Error Fetching Data';
        },
        complete: () => {
          this.loadingFlag = false;
          if (this.paginationObj.pageNumber == 1) {
            this.disableFirstBtn = true;
            this.disablePreviousBtn = true;
            this.disableLastBtn = false;
            this.disableNextBtn = false;
          } else if (
            this.paginationObj.pageNumber == this.paginationObj.totalPages
          ) {
            this.disableFirstBtn = false;
            this.disablePreviousBtn = false;
            this.disableLastBtn = true;
            this.disableNextBtn = true;
          } else {
            this.disableFirstBtn =
              this.disablePreviousBtn =
              this.disableNextBtn =
              this.disableLastBtn =
              false;
          }
          console.log(this.paginationObj);
        },
      };
      if (!this.isInLogs) {
        url += `&userType=${this.userType}&fromDate=${body.fromDate}&toDate=${body.toDate}`;
        this.http.get(url).subscribe(subscription);
      } else {
        body['accountType'] = this.userType
        body['subscriberId'] = this.id
        this.http.post(url, body).subscribe(subscription);
      }
    }
    else {
      this.loadingFlag = true;
      PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
      let url = PaginationUtilities.urlBuilder(
        `wallet/${this.isInLogs ? 'transactionsForLcoOrDistributor' : 'onlinePaymentHistory'}`,
        this.paginationObj
      );
      let subscription = {
        next: (res) => {
          this.handlePageMetadata(res.metadata);
          this.reportList = res.data;
          if (res.data?.length == 0) {
            this.isLoad = 'No Data';
            this.loadingFlag = true;
          }
        },
        error: () => {
          this.loadingFlag = true;
          this.isLoad = 'Error Fetching Data';
        },
        complete: () => {
          this.loadingFlag = false;
          if (this.paginationObj.pageNumber == 1) {
            this.disableFirstBtn = true;
            this.disablePreviousBtn = true;
            this.disableLastBtn = false;
            this.disableNextBtn = false;
          } else if (
            this.paginationObj.pageNumber == this.paginationObj.totalPages
          ) {
            this.disableFirstBtn = false;
            this.disablePreviousBtn = false;
            this.disableLastBtn = true;
            this.disableNextBtn = true;
          } else {
            this.disableFirstBtn =
              this.disablePreviousBtn =
              this.disableNextBtn =
              this.disableLastBtn =
              false;
          }
          console.log(this.paginationObj);
        },
      };
      if (!this.isInLogs) {
        url += `&userType=${this.userType}&fromDate=${body.fromDate}&toDate=${body.toDate}`;
        this.http.get(url).subscribe(subscription);
      } else {
        body['userType'] = this.config.json.userType.toLowerCase()
        body['userId'] = this.userId
        this.http.post(url, body).subscribe(subscription);
      }
    }
  }

  recheck(report: any) {
    this.http.post(`${this.config.json.apiEndpoint}wallet/paymentOnSuccessOrFailure`, { txnid: report.transactionId }).subscribe({
      next: () => {
        this.fetchData(this.body)
      }
    })
  }

  handlePageMetadata(data: any) {
    this.paginationObj.pageNumber = data.pageNumber;
    this.paginationObj.pageSize = data.pageSize;
    this.paginationObj.totalElements = data.totalElementCount;
    this.paginationObj.totalPages = data.totalPageCount;
    this.paginationObj.searchKeyword = data.searchKeyword;
  }

  dateChange(eve: NgxDateRangeInfo) {
    if (
      eve.startDate == null ||
      eve.startDate == undefined ||
      eve.endDate == null ||
      eve.endDate == undefined
    ) {
      return;
    }
    this.body = {
      fromDate: eve.startDate.format('YYYY-MM-DD'),
      toDate: eve.endDate.format('YYYY-MM-DD'),
    };
    this.reportDate = `${this.body.fromDate} to ${this.body.toDate}`;
    this.fetchData(this.body);
  }

  async downloadTableExcel() {
    ////// console.log(this.reportTable)if

    if(this.isInLogs){
    let header = [];
    let name = 'Transaction Logs';
    this.doc.exportTableToExcelwithImage(
      this.reportTable,
      name,
      'transaction_logs',
      undefined,
      undefined,
      'Report Date',
      this.reportDate ? this.reportDate : 'Not Selected'
    );}
    else{
      const data = [];
      let i = 1;
      this.reportList.forEach(line => {
        const excelRow = {
          slno: i,
          userCode: line.userCode,
          userType: line.userType,
          name: line.name,
          mobileNumber: line.mobileNumber,
          email: line.email,
          amount: line.amount,
          paymentMode: line.paymentMode,
          transactionId: line.transactionId,
          easePayId: line.easePayId,
          bankReferenceNumber: line.bankReferenceNumber,
          paymentStatus: line.paymentStatus,
          createdDate: line.createdDate
        };
        data.push(excelRow);
        i++;
      });
      let blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let workbook: ExcelProper.Workbook = new Excel.Workbook();
      let worksheet = workbook.addWorksheet('My Sheet', {
        properties: {
          defaultColWidth: 30
        }
      });
      let ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
      let ab = XLSX.utils.sheet_to_json(ws)
      let currentDate = new Date();
      var row = worksheet.getRow(1);
      row.height = 100, 20
   
        let row2 = worksheet.getRow(2)
        
          let row3 = worksheet.getRow(3)
          row3.getCell(1).value = "Report generated on :"
          row3.getCell(2).value = currentDate.getDate() + "-" + this.doc.monthNameCalculator(currentDate.getMonth()) + "-" + currentDate.getFullYear() + " [" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds() + "]"
          let row4 = worksheet.getRow(4)
          row4.getCell(1).value = 'Source type :'
          row4.getCell(2).value = "SMS"
        
      let a
      let nameRow = worksheet.addRow(['Transaction logs'])
      worksheet.mergeCells('A1:D1')
      a = (ab.length === 1) ? Object.keys(ab[0]) : Object.keys(ab[1])
      let headerRow = worksheet.addRow(a)
      nameRow.eachCell((cell, number) => {
        cell.font = { bold: true, size: 14 }
      })
      headerRow.eachCell((cell, number) => {
        cell.font = { bold: true }
      })
      for (let x1 of ab) {
        let x2 = Object.keys(x1);
        let temp = []
        a = x2
        for (let y of x2) {
          temp.push(x1[y])
        }
        worksheet.addRow(temp)
      }
      var myBase64Image = "/assets/logos/esto_header_1.png";
      const response = await fetch(myBase64Image); const buffer12 = await response.arrayBuffer();
      var imageId2 = workbook.addImage({
        buffer: buffer12,
        extension: 'png',
      });
      worksheet['!cols'] = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 40 }, { width: 20 }, { width: 20 }]
      worksheet.addImage(imageId2, 'A1:C1');
      workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], { type: blobType });
        FileSaver.saveAs(blob, 'transaction_logs.xlsx');
      });
    }
    
  }

  downloadTablePdf(): void {
    // if (this.isInLogs) {/
    let date = new Date();
    let reportGenerated = `${String(date.getDate()).padStart(2, '0')}-${String(
      date.getMonth() + 1
    ).padStart(2, '0')}-${date.getFullYear()}`;
    this.pdf.generatePdfHtml(
      '#reportTable',
      'transaction_logs',
      `Report Date : ${this.reportDate ? this.reportDate : 'Not Selected'
      } Source Type : SMS   Report Generated On : ${reportGenerated}`
    );
    // else{

    // }
  }

  downloadTableCSV(): void {
    if (this.isInLogs) {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        useBom: true,
        headers: [
          'Slno',
          'Code',
          'Remarks',
          'Type',
          'Transaction ID',
          'Amount',
          'Opening Balance',
          'Closing Balance',
          'Date',
        ],
      };
      let data = [];
      let i = 1;
      this.reportList.forEach((line) => {
        let csvLine = {
          slno: i,
          subscriberCode: line.subscriberCode,
          remarks: line.remarks,
          trnType: line.trnType,
          slNo: line.slno,
          amount: line.amount,
          openingBalance: line.openingBalance,
          closingbal: line.closingBalance,
          date: line.datee,
        };
        data.push(csvLine);
      });

      new ngxCsv(data, 'Transaction_Logs', options);
    }
    else {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        useBom: true,
        headers: [
          'User Code',
          'User Type',
          'Name',
          'Mobile Numbe',
          'Email',
          'Amount',
          'Payment Mode',
          'Transaction ID',
          'Easepay ID',
          'Bank Reference ID',
          'Status', 'Date'
        ],
      };
      let data = [];
      let i = 1;
      this.reportList.forEach((line) => {
        let csvLine = {
          slno: i,
          userCode: line.userCode,
          userType: line.userType,
          name: line.name,
          mobileNumber: line.mobileNumber,
          email: line.email,
          amount: line.amount,
          paymentMode: line.paymentMode,
          transactionId: line.transactionId,
          easePayId: line.easePayId,
          bankReferenceNumber: line.bankReferenceNumber,
          paymentStatus: line.paymentStatus,
          createdDate: line.createdDate
        };
        data.push(csvLine);
      });

      new ngxCsv(data, 'Transaction_Logs', options);
    }

  }

  searchKeyWord() {
    this.paginationObj.pageNumber = 1;
    this.fetchData(this.body);
  }

  pageSizeChange() {
    this.fetchData(this.body);
  }

  pageFirstBtnClick() {
    this.disableFirstBtn = true;
    this.disablePreviousBtn = true;
    this.paginationObj.pageNumber = 1;
    this.fetchData(this.body);
  }

  pageLastBtnClick() {
    this.disableLastBtn = true;
    this.disableNextBtn = true;
    this.paginationObj.pageNumber = this.paginationObj.totalPages;
    this.fetchData(this.body);
  }

  pageIncrease() {
    if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableNextBtn = true;
      this.disableLastBtn = true;
    }
    this.paginationObj.pageNumber++;
    this.fetchData(this.body);
  }

  pageDecrease() {
    if (this.paginationObj.pageNumber == 1) {
      this.disablePreviousBtn = true;
      this.disableFirstBtn = true;
    }
    this.paginationObj.pageNumber--;
    this.fetchData(this.body);
  }
}
