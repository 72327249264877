<div class="cms__content">
    <app-reports-header></app-reports-header>
    <div class="top__heading">
        <h3>STB  Mapping Report</h3>
        <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
    </div>
    <div class="clearfix"></div>

    <div class="row">
        <div class="filter__group col-12 col-md-6">
            <input type="date" class="custom__field" [(ngModel)]="onDate" (change)='fetchData()'>
        <!-- <input type="text" autocomplete="off" ngxDaterangepickerMd [(ngModel)]="selected" placeholder="Choose date"
          (change)="dateChange($event)" [ranges]="ranges" [alwaysShowCalendars]="true" [customRangeDirection]="true"
          [locale]="locale" class="custom__field" /> -->
       </div> 
        <!-- <div class="filter__group col-12 col-md-6">
            <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
        </div> -->
        <div class="download__report col-md-6">
            <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
                PDF
                <img src="./assets/pdf.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
                Excel
                <img src="./assets/excel.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
                CSV
                <img src="./assets/csv.svg" alt="download" />
            </button>
           
        </div>
    </div>

    <div class="third_box">
        <div class="col-12 col-md-6 report">
            <p>
                Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
            </p>
        </div>
       
    </div>
    <div class="col-12 list__table">
        <div class="row scroll">
            <div class="tableBox">
            <table class="custom__iptv__table" #reportTable id="reportTable">
                <thead>
                    <tr>
                        <th>Sl No</th>
                        <th>Subscriber ID</th>
                        <th>Subscriber Name</th>
                        <th>STB ID</th>
                        <th>Ethernet Mac Address</th>
                        <th>Description</th>
                        <th>Date</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="reportList?.length == 0">
                        <td style="text-align: center" *ngIf="isLoading" colspan="7">
                            <app-custom-spinner></app-custom-spinner>
                        </td>
                        <td style="text-align: center" *ngIf="!isLoading" colspan="7">
                            {{ isLoad }}
                        </td>
                    </tr>
                    <tr *ngFor="let report of reportList; let j = index">
                        <td>
                            {{
                            (paginationObj.pageNumber - 1) * paginationObj.pageSize +
                            (j + 1)
                            }}
                          </td>
                    <td>{{report.subscriberId?report.subscriberId:'---'}}</td>
                    <td>{{report.subscriberName?report.subscriberName:'---'}}</td>
                    <td>{{report.stbId?report.stbId:'---'}}</td>
                    <td>{{report.ethernetMacAddress?report.ethernetMacAddress:'---'}}</td>
                    <td>{{report.description?report.description:'---'}}</td>
                    <td>{{report.createdDate}}</td>
                   
                </tr>

                </tbody>
            </table>
            </div>
            <div class="custom__pagination">
                <ul>
                  <li>
                    <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
                      <img src="./assets/firstPage.png" /></button>
                  </li>
                  <li>
                    <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
                      <img src="./assets/previousPage.png" /></button>
                  </li>
                  <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
                  <li>
                    <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
                      <img src="./assets/nextPage.png" /></button>
                  </li>
                  <li>
                    <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
                      <img src="./assets/lastPage.png" /></button>
                  </li>
                </ul>
        
              </div>
        </div>
    </div>
</div>

