<div class="cms__content">
  <div class="top__heading">
    <h3>{{isEdit ? "Update" : "Send"}} OSD</h3>
    <a [routerLink]="['/communication/osd']">
      <button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>
  <form action="" [formGroup]="osdForm" >
    <div class="row">
      <div class="col-12 col-md-6">
        <label for="">To</label>
        <select name="" id="" class="custom__field" (change)="selectSendTo($event)" >
          <option disabled selected > --SELECT--</option>
          <option value="all">All</option>
          <option value="lco">LCO</option>
          <option value="subscriber">Subscriber</option>
          <option value="subscribergroup">Subscriber Group</option>
        </select>
      </div>
      <div class="col-12 col-md-6">
        <label>Message</label>
        <select class="custom__field" formControlName="message" (change)="selectedMessage($event)" >
          <option *ngFor="let msg of messageList" [value]="msg.id">
            {{ msg.message }}
          </option>
        </select>
      </div>
    </div>
    <div class="row" [ngStyle]="searchSubscriberStyle"  >
      <div class="col-12">
        <div class="wrapper" >
          <label for="">Search Subscriber</label>
          <input style="display: none;" type="text" formControlName="subscriberId" >
          <input autocomplete="off" [readOnly]="isSendAllSelected" class="custom__field" list="to__addresses" name="to__address" id="to__address" (click)="showAllUser()"
             (input)="searchValue($event)" placeholder="Search subscriber" />
          <p (click)="clear()" >Clear</p>
        </div>
          <div class="search_param" *ngIf="openSearch" >
            <app-subscriber-search [searchText]="searchText" (userDetail)="getUserDetail($event)" ></app-subscriber-search>
          </div>
      </div>
      <div class="col-12">
        <label for="">Select STB ID</label>
        <multi-select [controlName]="'stbIds'" [parentFormName]="osdForm" [multiSelectOptions]="stbList" ></multi-select>
      </div>
    </div>
    <div class="row" [ngStyle]="searchLCOStyle"  >
      <div class="col-md-6">
        <div class="wrapper" >
          <label for="">Search LCO</label>
          <input style="display: none;" type="text" formControlName="lcoId" >
          <input autocomplete="off" [readOnly]="isSendAllSelected" class="custom__field" list="to__addresses" name="to__address" id="lcoId" (click)="showAllUser()"
             (input)="searchValue($event)" placeholder="Search LCO" />
          <p (click)="clear()" >Clear</p>
        </div>
          <div class="search_param" *ngIf="openSearch" >
            <app-lco-search [searchText]="searchText" (userDetail)="getLCODetail($event)" ></app-lco-search>
          </div>
      </div>
    </div>
    <div class="row" [ngStyle]="selectSubscriberGroupStyle" >
      <div class="col-12">
        <label for="">Select Subscriber Group</label>
        <select name="" id="" class="custom__field" (change)="getSubscriberIdsBySubscriberGroupId($event)" >
          <option hidden value="" disabled selected>Select Group</option>
              <option *ngFor="let group of subscriberGroupList" [value]="group.id">{{group.name}}</option>
        </select>
      </div>
    </div>
    <div class="col-12 form__style">
      <div class="row">
        <div class="col-12 col-md-6"  >
          <div class="send__all" style="display: none;" >
            <label>To</label>
            <div class="send__all--content">
              <input type="checkbox"
              formControlName="isSendAll"
              (change)="isSendAllSelected = ($event.target.checked)"
               />
              <label>Send to All</label>
            </div>
          </div>
         
        </div>
        <div class="col-12 additional__settings" formGroupName="osdConfiguration">
          <div class="row">
            <!-- <div class="col-12 col-md-3">
              <h6>Message Settings</h6>
            </div> -->

            <div class="col-12 col-md-3">
              <label>Start Time</label>
              <input type="datetime-local" class="custom__field" formControlName="startTime">
            </div>

            <div class="col-12 col-md-3">
              <label>Duration (in ms)</label>
              <input type="number" class="custom__field" formControlName="duration" />
            </div>

            <div class="col-12 col-md-3">
              <label>Content Identifier</label>
              <select class="custom__field" formControlName="contentIdentifier">
                <option [ngValue]="0">Without Fingerprint</option>
                <option [ngValue]="1">With Fingerprint</option>
              </select>
            </div>

            <div class="col-12 col-md-3">
              <label>Polarization</label>
              <select class="custom__field" formControlName="polarization">
                <!-- <option [ngValue]="0">Center</option> -->
                <option [ngValue]="0">Top</option>
                <option [ngValue]="1">Bottom</option>
                <!-- <option [ngValue]="1">Top Left</option>
                <option [ngValue]="2">Top Right</option>
                <option [ngValue]="3">Bottom Left</option>
                <option [ngValue]="4">Bottom Right</option> -->
              </select>
            </div>

            <div class="col-12 col-md-3">
              <label>Marque</label>
              <select class="custom__field" formControlName="marque">
                <option [ngValue]="0">Left-Right</option>
                <option [ngValue]="1">Right-Left</option>
              </select>
            </div>

            <div class="col-12 col-md-3">
              <label>Transparency</label>
              <input type="number" class="custom__field" formControlName="transparency"
                [ngClass]="{'error__field': !transparency.valid && transparency.touched}" />
              <div [hidden]="!transparency.touched">
                <span class="error__msg" *ngIf="transparency.hasError('min')">number should be greater than 20</span>
                <span class="error__msg" *ngIf="transparency.hasError('max')">number should be less than 80</span>
              </div>
            </div>

            <div class="col-12 col-md-3">
              <label>Font Size</label>
              <select class="custom__field" formControlName="fontSize">
                <option [ngValue]="0">Extra Small</option>
                <option [ngValue]="1">Small</option>
                <option [ngValue]="2">Normal</option>
                <option [ngValue]="3">Large</option>
                <option [ngValue]="4">Extra Large</option>
              </select>
            </div>

            <div class="col-12 col-md-3">
              <label>Font Style</label>
              <select class="custom__field" formControlName="fontStyle">
                <option [ngValue]="0">Roboto</option>
                <option [ngValue]="1">Manrope</option>
              </select>
            </div>

            <div class="col-12 col-md-3">
              <label>Font Colour</label>
              <select class="custom__field" formControlName="fontColour">
                <option [ngValue]="0">White</option>
                <option [ngValue]="1">Black</option>
                <option [ngValue]="2">Red</option>
                <option [ngValue]="3">Green</option>
                <option [ngValue]="4">Blue</option>
              </select>
            </div>

            <div class="col-12 col-md-3">
              <label>Background Colour</label>
              <select class="custom__field" formControlName="bgColour">
                <option [ngValue]="0">Black</option>
                <option [ngValue]="1">White</option>
                <option [ngValue]="2">Red</option>
                <option [ngValue]="3">Green</option>
                <option [ngValue]="4">Blue</option>
                <option [ngValue]="5">Transparent</option>
              </select>
            </div>

            <div class="col-12 col-md-3">
              <label>Forced</label>
              <select class="custom__field" formControlName="forced">
                <option [ngValue]="0">Not Forced</option>
                <option [ngValue]="1">Forced</option>
              </select>
            </div>
            <div class="col-12 col-md-3">
              <label>Scroll type</label>
              <select class="custom__field" formControlName="scrolling">
                <option [ngValue]="0">No</option>
                <option [ngValue]="1">Yes</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <button class="cta float-right" [disabled]="formValidity()" (click)="persist()" >
    {{ isEdit ? "Update" : "Create" }}
  </button>
</div>
