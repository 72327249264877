<div class="cms__content">
  <app-reports-header></app-reports-header>
  <div class="top__heading">
    <h3>Package Ageing Report</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row row-top">
    <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
      <label class="report_selection_label" style="margin-right: 1%;">Date: </label>
      <input type="date" class="custom__field" [(ngModel)]="onDate" (change)='fetchData()'>
    </div>

    <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
      <label class="report_selection_label" style="margin-right: 1%;">Bouquet Name: </label>
      <select class="custom__field report_selection" [(ngModel)]="selectedBouquetId" (change)='fetchData()'>
        <option value=0>ALL</option>
        <option *ngFor="let bq of bouquetList" [ngValue]='bq.id'>{{bq.name}}</option>
      </select>
    </div>

    <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
      <label class="report_selection_label" style="margin-right: 1%;">Bouquet Type: </label>
      <select class="custom__field report_selection" [(ngModel)]="selectBouquetType" (change)='fetchData()'>
        <option value="All">ALL</option>
        <option value="ADD_ON_PACK">ADD_ON_PACK</option>
        <option value="ALACARETE_PACK">ALACARETE_PACK</option>
        <option value="BASE_PACK">BASE_PACK</option>
      </select>
    </div>
  </div>

  <div class="third_box">
    <div class="col-12 col-md-6">
      <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>
  </div>


  <div class="col-12 list__table">
    <div class="custom__table__filter">
      <span>
        Show
        <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
          <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
        </select>
        Entries
      </span>
      <div class="col-12 col-md-6 table-search">
        <div class="table__right">
          <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> record{{reportList.length == 1?'' : 's'}}</p>
        </div>
        <div class="search__container">
          <input type="text" class="custom__field" id="searchKeyword" (keydown.enter)="onSearch()"
            [(ngModel)]="paginationObj.searchKeyword" />
          <button (click)="onSearch()" (keydown.enter)="onSearch()">
            <img src="./assets/search.png" alt="search" />
          </button>
        </div>
      </div>
    </div>
    <div class="row scroll">
      
      <table class="custom__iptv__table" #reportTable id="print">
        <thead>
          <tr>
            <th>Sl No</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.bouquetId)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.bouquetId}">Bouquet ID</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.bouquetName)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.bouquetName}">Bouquet Name</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.bouquetType)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.bouquetType}">Bouquet Type</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.a)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.a}">0-30</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.b)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.b}">31-60</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.c)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.c}">61-90</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.d)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.d}">91-120</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.e)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.e}">>120</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList.length ==0 && !loadingFlag">
            <td style="text-align: center !important;" colspan="9">{{isLoad}}</td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important;" colspan="9">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <ng-template #showList>
            <tr *ngFor="let report of reportList, let j=index">
              <td>{{j+1}}</td>
              <td>{{report.bouquetId}}</td>
              <td>{{report.bouquetName=='null' || report.bouquetName==null ? '---' : report.bouquetName }}</td>
              <td>{{report.bouquetType=='null' ||report.bouquetType==null ? '---' : report.bouquetType}}</td>
              <td>{{report["0-30"]=='null'? '---' : report["0-30"]}}</td>
              <td>{{report["31-60"] == 'null' ? '---' : report["31-60"]}}</td>
              <td>{{report["61-90"] ==null ? '---' : report["61-90"]}}</td>
              <td>{{report["91-120"] == null ? '---' : report["91-120"]}}</td>
              <td>{{report[">121"] == null ? '---' : report[">121"]}}</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>