import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-lead',
  templateUrl: './create-lead.component.html',
  styleUrls: ['./create-lead.component.scss']
})
export class CreateLeadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
