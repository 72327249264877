<div class="cms__content">
  <app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>STB Inactive List Report</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <div class="filter__group col-12 col-md-6">
      <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
        CSV
        <img src="./assets/csv.svg" alt="download" /> </button>
    </div>
  </div>


  <div class="row">
    <div class="col-md-6 report-generated-fix">
      <p>
        Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
      </p>
    </div>
    <div class="col-12 col-md-6 table-search">
      <div class="table__right">
        <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}
        </p>
      </div>
      <div class="search__container">
        <input type="text" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
        <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()">
          <img src="./assets/search.png" alt="search" />
        </button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <!-- <th>Date</th> -->
            <th>Sl No.</th>
            <th>Subscriber ID</th>
            <th>Subscriber Code</th>
            <th>Subscriber Name</th>
            <th>Partner Name</th>
            <th>MAC Address</th>
            <th>STB IDs</th>
            <th>Deactivation Date</th>
            <!-- <th>Inactive Date</th> -->
          </tr>
        </thead>
        <tbody>

          <tr *ngIf="reportList?.length ==0 && !loadingFlag">
            <td style="text-align: center !important;" colspan="8">{{isLoad}}</td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important;" colspan="8">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>

          <ng-template #showList>
            <tr *ngFor="let report of reportList, let j = index">
              <!-- <td>{{report.subscriber_id}}</td> -->
              <!-- <td>{{report.createdDate | date: 'dd-MM-yy'}}</td> -->
              <td>{{j+1}}</td>
              <td>{{(report.subscriber_id || report.subscriberId) == null || (report.subscriber_id ||
                report.subscriberId) == 'null' || (report.subscriber_id || report.subscriberId) == ''? '---' :
                (report.subscriber_id || report.subscriberId)}}</td>
              <td>{{(report.subscriber_code || report.subscriberCode) == null || (report.subscriber_code ||
                report.subscriberCode) == 'null' || (report.subscriber_code || report.subscriberCode) == ''? '---' :
                (report.subscriber_code || report.subscriberCode)}}</td>
              <td>{{(report.subscriberName || report.subscriber_name) == null || (report.subscriberName ||
                report.subscriber_name) == 'null' || (report.subscriberName || report.subscriber_name) == ''? '---' :
                (report.subscriberName || report.subscriber_name)}}</td>
              <td>{{(report.lco_name || report.lcoName) == null || (report.lco_name || report.lcoName) == 'null' ||
                (report.lco_name || report.lcoName) == ''? '---' : (report.lco_name || report.lcoName)}}</td>
              <td>{{(report.ethernet_mac_address || report.ethernetMacAddress) == null || (report.ethernet_mac_address
                || report.ethernetMacAddress) == 'null' || (report.ethernet_mac_address || report.ethernetMacAddress)
                =='' ? '---' : (report.ethernet_mac_address || report.ethernetMacAddress) }}</td>
                <td>{{report.stb_ids?report.stb_ids:'---'}}</td>
              <td>{{(report.activation_date || report.activationDate) == null || (report.activation_date ||
                report.activationDate) == 'null' || (report.activation_date || report.activationDate) == '' ? '---' :
                (report.activation_date || report.activationDate) | date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
              <!-- <td>{{report.deActivationDate | date: 'dd-MM-yy'}}</td> -->
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>
</div>