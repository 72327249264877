import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { AdminInfo } from 'src/app/models/admin/admin-info';
import { MSOInfo } from 'src/app/models/mso/mso-info';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { UserInfo } from 'src/app/models/user/user-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-selfregistered',
  templateUrl: './list-selfregistered.component.html',
  styleUrls: ['./list-selfregistered.component.scss']
})
export class ListSelfregisteredComponent implements OnInit {

  paginationObj = new PaginationObj();

  loadingFlag = false;

  userTypeList: UserInfo[] = [];

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  activatedCount = 0;
  MsoActiveCount: number;
  TotalMsoCount: number;
  sortByProperties = {
    name: 'contactPersonName',
    email: 'email',
    mobile: 'mobile',
    date: 'createdDate',
    status: 'status',
    id:'id',
    userType:'userType',
    companyName:'companyName'
  };

  lcoDetails
  LCODetails;
  userDetails;
  viewModal = false;
  viewPaymentModal=false;
  walletPayment=0
  onetimePayment=0

  view(userDetails:any){
    this.viewModal = true;
    this.LCODetails = userDetails
    this.viewPaymentModal=false;
  }

  processPaymentandContinue(){
    let body={
    "firstName": this.userDetails.companyName,
    "mobileNumber": this.userDetails.mobileNumber,
    "amount": +(this.walletPayment)+(+(this.onetimePayment)),
    "email": this.userDetails.email,
    "packageName": "PaymentTest",
    "subsId":this.userDetails.id,
    "accType":this.userDetails.userType,
    "oneTimePayment":this.onetimePayment,
    "walletRecharge":this.walletPayment
    }
    let url=this.config.json.apiEndpoint+'leadGeneration/sendPaymentRequest'
    this.http.post(url,body).subscribe(
      (res)=>{
        if(res.statusCode==200){
          this.viewPaymentModal = !this.viewPaymentModal
          this.onetimePayment=0
          this.walletPayment=0
          alert("Payment request successfully sent.")
          this.fetchPage();
        }
        else{
          // this.viewPaymentModal = !this.viewPaymentModal
          //  this.onetimePayment=''
          // this.walletPayment=''
          alert(res.message)
        }
      }
    )
  }
isEdit
amount

addAmount(){
  this.amount=this.onetimePayment+this.walletPayment
}

preventMinus(event: KeyboardEvent): void {
  console.log(event)
  if (event.key === '-' || event.key === 'e') {
    event.preventDefault(); // Prevent typing the minus sign or 'e' for scientific notation
  }
}

  processPayment(user){
    this.viewPaymentModal = true;
    this.userDetails = user
    this.isEdit=false
    this.onetimePayment= user.oneTimePayment
    this.walletPayment= user.walletRechargeAmount
    // this.amount=user.amount
    this.amount=this.onetimePayment+this.walletPayment
    if((this.onetimePayment==0 && this.walletPayment==0)||(this.onetimePayment==null&& this.walletPayment==null)){
      this.isEdit=false
    }
    else{
      this.isEdit=true
    }
    console.log(this.isEdit)
    if(user.paymentStatus==1){
      this.payStatus='success'
    }
    else{
      this.payStatus='failure'
    }
  }

  openImageInNewTab(str: string) {
    console.log(str)
    window.open(str);
  }

  checkMenuAccess(){
    for(let i of this.menus){
      if(i.name=='Form Request')
      {
        this.menuAccess=(i.access==0?false:true);
        console.log(this.menuAccess)
      }
    }
    console.log(this.menuAccess)
  }


  getFileExtension(filename: string): string {
    return filename.split('.').pop().toLowerCase();
  }

  isImageFile(filename: string): boolean {
    const extension = this.getFileExtension(filename);
    return extension === 'jpeg' || extension === 'jpg' || extension === 'png';
  }


  approve(lcoDetails){
    if(lcoDetails.userType=='distributor'){
      localStorage.setItem('userDetails',JSON.stringify(lcoDetails));
    }
    this.router.navigateByUrl(lcoDetails.userType=='distributor'?'manage/distributor/selfcreate':'manage/lco/selfcreate-'+lcoDetails.id)
  }


  viewLCODetails(lcoDetails: any) {
    let lcoId = lcoDetails.id,
      url = `${this.config.json.apiEndpoint}lco/lcoDetails/`+lcoId;
    this.http.get(url).subscribe({
        next: (res: any) => {
          this.LCODetails = res.data;
          this.viewModal = true;
        },
      });
  }

menus
menuAccess= false
payStatus=''
  pageSize
  isError = {
    errType : "Loading",
    bool : true
  }

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchPage();
    this.menus=JSON.parse(atob(localStorage.getItem('menus')))
    this.checkMenuAccess()
  }

  onSearch(){
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }


  fetchPage(): void {
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
    const url = PaginationUtilities.urlBuilder('leadGeneration/list', this.paginationObj);
    // const url = this.config.json.apiEndpointRdbms + 'mso/getMSOs/' + this.paginationObj.pageNumber + '?pageSize=' + this.paginationObj.pageSize + '&sortBy=' + this.paginationObj.sortBy + '&sortOrder=' + this.paginationObj.sortOrder + '&searchKeyword=' + this.paginationObj.searchKeyword
    this.loadingFlag = true;
    this.http.get(url).subscribe(
      (res)=>{
        this.loadingFlag = false;
        this.pageSize = this.paginationObj.pageSize
        this.handleFetchPage(res);
      },
     (error) =>{

      }
    )
  }
  
  handleFetchPage(data) {
    this.userTypeList = data.data;
    this.handlePaginationMetadata(data.metadata);
    // this.checkPaymentStatus()
    this.handlePaginationBtnDisableStatuses();
    this.countAdmin();
  }

  
  checkPaymentStatus(user){
      let url=this.config.json.apiEndpoint+'leadGeneration/checkPaymentStatus?id='+user.id
      this.http.get(url).subscribe(
        (res)=>{
         this.payStatus=res.data['paymentStatus'].toLowerCase()
         if(this.payStatus=='success'){
          this.isEdit=true
          alert("Click on continue to proceed further")
         }
         else{
          this.isEdit=false
          if(res.data['paymentStatus']=='failur'||res.data['paymentStatus']=='failure'){
            alert("The last transaction has failed")
          }
          else
          alert("The last transaction has "+res.data['paymentStatus'])
          // alert("Payment Link Expired or Failed.Please try again")
         }
        }
      )
  }

  pageNumber
  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.pageNumber = metadata.pageNumber
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record: AdminInfo): void {
    // //// console.log(record)
    this.router.navigate(['/manage/mso', record.id]);
  }

  countAdmin(): void {
    this.activatedCount = this.userTypeList.filter(x => x.status === 1).length;
  }

  updateStatus(record: AdminInfo): void {
    record.status === 1 ? this.deactivate(record) : this.activate(record);
  }

  deactivate(record: AdminInfo): void {
    const url = this.config.json.apiEndpointRdbms + 'admin/deactivateAccount/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 0;
      this.countAdmin();
      window.alert('Account deactivated');
    });
  }

  activate(record: AdminInfo): void {
    const url = this.config.json.apiEndpointRdbms + 'admin/activateAccount/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 1;
      this.countAdmin();
      window.alert('Account activated');
    });
  }

}
