<div class="top__navbar"  *ngIf="!isOnSeparatePage()">
  <div class="div1">IPTV <strong>SMS&nbsp;</strong>   v2.1</div>
  <div class="div2" (click)="gotoDashboard()">Dashboard</div>
  <div *ngIf="router.url.includes('dashboard')" style="display: flex;align-items: center;" (click)="refreshClicked()"><span class="material-symbols-rounded">refresh</span></div>
  <div class="div3" id="div4" >
    <ul class="main-menu">
      <ng-container *ngIf="isNotMSO && isNotAffiliator">
        <li class="main-menu-content" routerLink="/accounts/wallet" >
          <span style="width: 25px">
            <img src="assets/icons/wallet.svg" alt="" />
          </span>
          &nbsp;{{auth.amount }} &nbsp; INR
        </li>
      </ng-container>
      <ng-container *ngFor="let menu of menuArray; let i = index">
        <ng-container *ngIf="menu.id != 1">
          <li class="main-menu-content" [id]="'main-menu-id' + i">
            <span (click)="openMenu(i, menu)" class="main-menu-name" *ngIf="menu.name!='Dashboard'">{{
              menu.name
            }}</span>
            <ng-container *ngIf="menu.subMenu?.length != 0">
              <ul class="sub-menu" #subMenu [id]="'subMenu' + i">
                <ng-container
                  *ngFor="let subMenu of menu.subMenu; let j = index"
                >
                  <li
                    class="sub-menu-name"
                    (click)="openSubMenu(subMenu.name, i, j)"
                  >
                    {{ subMenu.name }}
                  </li>
                </ng-container>
              </ul>
            </ng-container>
          </li>
        </ng-container>
      </ng-container>
      <li
        class="main-menu-content hide__desktop"
        (click)="openProfile('profileSubMenuMobile')"
      >
        <span>Profile</span>
        <ul class="sub-menu" id="profileSubMenuMobile">
          <li class="sub-menu-name" routerLink="/profile" (click)="openHambuger()">My Profile</li>
          <li class="sub-menu-name">Log Out</li>
        </ul>
      </li>
    </ul>
  </div>
  <div class="div4">
    <div>
      <ul class="main-menu">
        <li class="main-menu-content">
          <img
            class="hide__mobile"
            src="./assets/profile.svg"
            (click)="openProfile('profileSubMenu')"
            id="profileImg"
          />
          <ul class="sub-menu" id="profileSubMenu">
            <li class="sub-menu-name" id="profileLi" routerLink="/profile">
              My Profile
            </li>
            <li class="sub-menu-name" (click)="logout()">Log Out</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div class="div5" (click)="openHambuger()">
    <span class="material-symbols-rounded"> menu </span>
  </div>
</div>
