<div class="cms__content">
    <app-reports-header></app-reports-header>

    <div class="top__heading">
        <h3>Product Subscription Status Report</h3>
        <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
    </div>
    <div class="clearfix"></div>

    <div class="row">
        <div class="filter__group col-12 col-md-6">
            <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
        </div>
        <div class="download__report col-md-6">
            <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
                PDF
                <img src="./assets/pdf.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
                Excel
                <img src="./assets/excel.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
                CSV
                <img src="./assets/csv.svg" alt="download" />
            </button>
        </div>
    </div>
    <div class="col-12 col-md-6 report_selection_block">
        <label class="report_selection_label">Status</label>
        <select class="custom__field report_selection" id="statusChangeApi" [(ngModel)]="statusValue"
            (change)="fetchData()">
            <option value="2">ALL</option>
            <option value="1">Active</option>
            <option value="0">Inactive</option>
        </select>
    </div>

    <div class="col-12 col-md-6 report_selection_block">
        <label class="report_selection_label">Select Bouquet</label>
        <select class="custom__field report_selection" id="statusChangeApi" [(ngModel)]="lcoValue"
            (change)="fetchData()">
            <option value="0">ALL</option>
            <option *ngFor="let bouquet of bouquetList" [ngValue]="bouquet">
                {{ bouquet.name }}
            </option>
        </select>
    </div>

    <div class="col-12 list__table">
        <div class="custom__table__filter">
            <span>
                Show
                <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
                    <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
                </select>
                Entries
            </span>
            <!-- -->
            <div class="col-12 col-md-6 table-search">
                <div class="table__right">
                    <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span>
                        record{{reportList.length == 1?
                        '' : 's'}}</p>
                    <!-- <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}} -->
                    <!-- </p> -->
                </div>
                <div class="search__container">
                    <input type="text" class="custom__field" id="searchKeyword" (keydown.enter)="onSearch()"  [(ngModel)]="paginationObj.searchKeyword" />
                    <button (click)="onSearch()" (keydown.enter)="onSearch()">
                        <img src="./assets/search.png" alt="search" />
                    </button>
                </div>
            </div>
        </div>
        <div class="row scroll">
           
            <!-- <div class="row scroll "> -->
            <table class="custom__iptv__table" #reportTable id="print">
                <thead>
                    <tr>
                        <th>Sl No.</th>
                        <th>Subscriber ID</th>
                        <!-- <th>STB ID</th> -->
                        <th>Subscriber Name</th>
                        <th>Bouquet Name</th>
                        <th>Bouquet ID</th>
                        <th>Subscriber Status</th>
                        <th>Mac address</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Bouquet Type</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="reportList?.length ==0 && !loadingFlag">
                        <td style="text-align: center !important;" colspan="10">{{isLoad}}</td>
                    </tr>
                    <tr *ngIf="loadingFlag; else showList">
                        <td style="text-align: center !important;" colspan="10">
                            <app-custom-spinner></app-custom-spinner>
                        </td>
                    </tr>
                    <ng-template #showList>
                        <tr *ngFor="let report of reportList, let j = index">
                            <td>{{j+1}}</td>
                            <td>{{report.subscriberId?report.subscriberId:'---'}}</td>
                            <td>{{report.subscriberName == 'null' || report.subscriberName == null ||
                                (report.subscriberName ||
                                report.subscriberName) == '' ? '---' : (report.subscriberName ||
                                report.subscriberName)}}</td>
                            <td>{{(report.bouquetName|| report.bouquetName) == 'null' || (report.bouquetName ||
                                report.bouquetName) == '' ||
                                (report.bouquetName || report.bouquetName) == null ? '---' : (report.bouquetName ||
                                report.bouquetName)}}</td>
                            <td>{{report.bouquetId?report.bouquetId:'---'}}</td>
                            <td>{{(report.subscriptionStatus || report.subscriptionStatus) == null
                                ||(report.subscriptionStatus ||
                                report.subscriptionStatus) == 'null' || (report.subscriptionStatus ||
                                report.subscriptionStatus) == ''? '---' :
                                (report.subscriptionStatus || report.subscriptionStatus)}}</td>
                            <td>{{report.ethernetMacAddress == 'null' || report.ethernetMacAddress == null ||
                                (report.ethernetMacAddress ||
                                report.ethernetMacAddress) == '' ? '---' : (report.ethernetMacAddress ||
                                report.ethernetMacAddress)}}</td>
                            <td>{{report.startDate?(report.startDate|  date: 'dd-MM-yyyy HH:mm:ss'):'---'}}</td>
                            <td>{{report.endDate?(report.endDate|  date: 'dd-MM-yyyy HH:mm:ss'):'---'}}</td>
                            <td>{{(report.bouquetType || report.bouquetType) == 'null' ||
                                (report.bouquetType
                                || report.bouquetType) == null || (report.bouquetType ||
                                report.bouquetType) ==
                                '' ? '---' : (report.bouquetType || report.bouquetType)}}</td>

                        </tr>
                    </ng-template>
                </tbody>
            </table>
            <div class="custom__pagination">
                <ul>
                    <li>
                        <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
                            <img src="./assets/firstPage.png" /></button>
                    </li>
                    <li>
                        <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
                            <img src="./assets/previousPage.png" /></button>
                    </li>
                    <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
                    <li>
                        <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
                            <img src="./assets/nextPage.png" /></button>
                    </li>
                    <li>
                        <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
                            <img src="./assets/lastPage.png" /></button>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</div>