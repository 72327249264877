import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { DashboardDataService } from './dashboard-service/dashboard-data.service';
// import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
// import { IgxDoughnutChartModule } from 'igniteui-angular-charts';
@NgModule({
  declarations: [DashboardHomeComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ChartsModule,
    // CanvasJSAngularChartsModule
  ],
  providers: [
    DashboardDataService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DashboardModule { }
