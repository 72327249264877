import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { AdminInfo } from 'src/app/models/admin/admin-info';
import { MSOInfo } from 'src/app/models/mso/mso-info';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-mso',
  templateUrl: './list-mso.component.html',
  styleUrls: ['./list-mso.component.scss']
})
export class ListMsoComponent implements OnInit {

  paginationObj = new PaginationObj();

  loadingFlag = false;

  msoList: MSOInfo[] = [];

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  activatedCount = 0;
  MsoActiveCount: number;
  TotalMsoCount: number;
  sortByProperties = {
    name: 'firstName',
    email: 'email',
    mobile: 'mobile',
    date: 'createdDate',
    status: 'status'
  };
  menus
  menuAccess = false
  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchPage();
    this.fetchMsoCount();
    this.menus=JSON.parse(atob(localStorage.getItem('menus')))
    this.checkMenuAccess()
  }

  onSearch(){
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }

  checkMenuAccess(){
    for(let i of this.menus){
      if(i.name=='MSO')
      {
        this.menuAccess=(i.access==0?false:true);
        console.log(this.menuAccess)
      }
    }
  }

  pageSize
  isError = {
    errType : "Loading",
    bool : true
  }
  fetchPage(): void {
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
    const url = PaginationUtilities.urlBuilder('mso/getMSOs', this.paginationObj);
    // const url = this.config.json.apiEndpointRdbms + 'mso/getMSOs/' + this.paginationObj.pageNumber + '?pageSize=' + this.paginationObj.pageSize + '&sortBy=' + this.paginationObj.sortBy + '&sortOrder=' + this.paginationObj.sortOrder + '&searchKeyword=' + this.paginationObj.searchKeyword
    this.loadingFlag = true;
    this.http.get(url).subscribe({
      next : (data: PageInfo<MSOInfo>) =>{
        this.loadingFlag = false;
        this.pageSize = this.paginationObj.pageSize
        this.handleFetchPage(data);
        
      },
      error : () =>{

      }
    })
  }
  fetchMsoCount() {
    const url = this.config.json.apiEndpointRdbms + 'mso/getMsoCount'
    this.http.get(url).subscribe((res) => {
      this.MsoActiveCount = res.data.active
      this.TotalMsoCount = res.data.count
    });
  }
  handleFetchPage(data: PageInfo<MSOInfo>): void {
    this.msoList = data.data;
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
    this.countAdmin();
  }

  pageNumber
  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.pageNumber = metadata.pageNumber
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record: AdminInfo): void {
    // //// console.log(record)
    this.router.navigate(['/manage/mso', record.id]);
  }

  countAdmin(): void {
    this.activatedCount = this.msoList.filter(x => x.status === 1).length;
  }

  updateStatus(record: AdminInfo): void {
    record.status === 1 ? this.deactivate(record) : this.activate(record);
  }

  deactivate(record: AdminInfo): void {
    const url = this.config.json.apiEndpointRdbms + 'admin/deactivateAccount/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 0;
      this.countAdmin();
      window.alert('Account deactivated');
    });
  }

  activate(record: AdminInfo): void {
    const url = this.config.json.apiEndpointRdbms + 'admin/activateAccount/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 1;
      this.countAdmin();
      window.alert('Account activated');
    });
  }

}
