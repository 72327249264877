import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { PackageInfo } from 'src/app/models/package/package-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { ConfigService } from 'src/app/services/config.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { LogsDataService } from '../plugins/logs-data.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import {  PaginationObj,  PaginationUtilities,} from 'src/app/models/pagination/pagination-info';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';

@Component({
  selector: 'app-logs-product-modification',
  templateUrl: './logs-product-modification.component.html',
  styleUrls: ['./logs-product-modification.component.scss']
})
export class LogsProductModificationComponent implements OnInit { reportList = [];
  bouquetList: PackageInfo[] = [];
  selectedBouquetId: number=0;;
  from: any;
  to: any;
  paginationObj = new PaginationObj();
  currentDate = new Date();
  selectBouquetType:any="All"

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config: ConfigService,
    private http: HttpClientWrapperService
  ) {}
  
  sortByProperties = {
    bouquetName: 'bouquetName',
    bouquetType: 'bouquetType',
    categoryName: 'categoryName',
    channelName: 'channelName',
    transactionDate: 'transactionDate',
    channelNumber: 'channelNumber',
    channelStatus: 'channelStatus',
    bouquetId:'bouquetId'  ,
    channelId:'channelId' 
  };


  body = {
    fromDate: '',
    toDate: '',
    tillDate: '',
  };

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchData();
  }

  ngOnInit(): void {
    this.yearsArrayCalculator();
    this.fetchBouquetList();
    this.fetchData();

  }

  yearsArray = [];
  yearsArrayCalculator() {
    this.yearsArray = [];
    let currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 2019; i--) {
      this.yearsArray.push(i);
    }
  }

  fetchData() {
    this.isLoading = true
    this.reportList =[]
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url = PaginationUtilities.urlBuilder(
      'logs/packageModification',
      this.paginationObj
    );
    url += `&fromDate=${this.body.fromDate}&toDate=${this.body.toDate}&onDate=${this.body.tillDate}`;
    url += "&bouquetId="+this.selectedBouquetId+"&action="+this.selectBouquetType
    this.http.get(url).subscribe(
      (data) => {
        this.reportList = data.data;
        this.handlePageMetadata(data.metadata);
        this.isLoading = false;
        if (data.data == null || []) {
          this.isLoad = 'No Records';
        }
      },
    (error)=>{
      this.isLoading = true
      this.isLoad = 'No Records';
    });
  }

  handlePageMetadata(data: any) {
    this.paginationObj.pageNumber = data.pageNumber;
    this.paginationObj.pageSize = data.pageSize;
    this.paginationObj.totalElements = data.totalElementCount;
    this.paginationObj.totalPages = data.totalPageCount;
    this.paginationObj.searchKeyword = data.searchKeyword;
    this.handleButtonStatus();
  }

  yearFilter() {
    this.isLoading = true;
    this.reportList = [];
    let year = (document.getElementById('yearCalc') as HTMLSelectElement).value;
    this.reportDate = year;
    let fromDate = `${year}-01-01`;
    let toDate = `${year}-12-31`;
    this.body = {
      ...this.body,
      fromDate,
      toDate,
    };
    this.fetchData();
  }

  fetchBouquetList(): void {
    this.report.getBouquetList().subscribe((data) => {
      // //// console.log("bouquet: ",)
      //// console.log(data.data)
      this.bouquetList = data.data;
    });
  }

  isLoad = '';
  isLoading = false;

  downloadTableExcel(): void {
    let header = [];
    let name = 'Bouquet Modification Logs';
    this.doc.exportTableToExcelwithImage(
      this.reportTable,
      name,
      'bouquet_modication_logs',
      undefined,
      undefined,
      'Report Date : ',
      this.reportDate + ` , Action : ${this.selectBouquetType}`
    );
  }

  downloadTablePdf(): void {
    // ,  Bouquet Id : ${this.selectedBouquetId}
    let date = new Date();
    let reportGenerated = `${String(date.getDate()).padStart(2, '0')}-${String(
      date.getMonth() + 1
    ).padStart(2, '0')}-${date.getFullYear()}`;
    this.pdf.generatePdfHtml(
      '#reportTable',
      'bouquet_modication_logs',
      `Report : ${
        this.reportDate ? this.reportDate : 'Not Selected'
      } , Source Type : SMS ,  Report Generated On : ${reportGenerated} , Action : ${this.selectBouquetType}  `
    );
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: [
        'Slno',
        'Channel ID',
        'Channel Name',
        'Action',
        'Bouquet Name',
        'Bouquet ID',
        'Transaction Date',
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slno: i,
        channelId: line.channelId,
        channelname: line.channelName,
        Action: line.channelStatus,
        bouquetName: line.bouquetName,
        bouquetId: line.bouquetId,
        transactionDate:line.transactionDate
      };
      data.push(csvLine);
    });

    new ngxCsv(data, 'bouquet_Modification_Logs', options);
  }

  showMonth = false;
  showDateRange = false;
  showYearSelector = false;
  dateSelector = true;
  dateTypeSelectors(num) {
    if (num == 0) {
      this.showYearSelector = false;
      this.dateSelector = true;
      this.showMonth = false;
      this.showDateRange = false;
      this.body ={
        fromDate: '',
        toDate: '',
        tillDate: '',
      }
      this.fetchData();
    }
    if (num == 1) {
      this.showYearSelector = false;
      this.dateSelector = false;
      this.showMonth = true;
      this.showDateRange = false;
    }
    if (num == 2) {
      this.showYearSelector = false;
      this.dateSelector = false;
      this.showDateRange = true;
      this.showMonth = false;
    }
    if (num == 3) {
      this.showYearSelector = true;
      this.dateSelector = false;
      this.showDateRange = false;
      this.showMonth = false;
    }
  }

  isDateSelected = false;
  eve;
  reportDate =
    this.reportList.length == 0 ? 'Not Selected' : 'From Start to Now';
  dateChangeRange(ev) {
    let startDate = ev.startDate.format('YYYY-MM-DD');
    let endDate = ev.endDate.format('YYYY-MM-DD');
    this.body = {
      fromDate: startDate,
      toDate: endDate,
      tillDate: '',
    };
    this.reportDate = this.body.fromDate + ' to ' + this.body.toDate;
    this.fetchData();
  }

  dateChangeMonth(ev) {
    let dateobj = new Date(ev.target.value);
    let tillDate =
      dateobj.getFullYear() +
      '-' +
      String(dateobj.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(dateobj.getDate()).padStart(2, '0');
    this.body = {
      fromDate: '',
      toDate: '',
      tillDate: tillDate,
    };
    this.reportDate = this.body.tillDate;
    this.fetchData();
  }

  disableFirstBtn = false;
  disablePreviousBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;

  handleButtonStatus() {
    if (this.paginationObj.pageNumber == 1) {
      this.disableFirstBtn = true;
      this.disablePreviousBtn = true;
      this.disableLastBtn = false;
      this.disableNextBtn = false;
    } else if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableFirstBtn = false;
      this.disablePreviousBtn = false;
      this.disableLastBtn = true;
      this.disableNextBtn = true;
    } else {
      this.disableFirstBtn =
        this.disablePreviousBtn =
        this.disableNextBtn =
        this.disableLastBtn =
          false;
    }
  }

  searchKeyWord() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageSizeChange() {
    this.fetchData();
  }

  pageFirstBtnClick() {
    this.disableFirstBtn = true;
    this.disablePreviousBtn = true;
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageLastBtnClick() {
    this.disableLastBtn = true;
    this.disableNextBtn = true;
    this.paginationObj.pageNumber = this.paginationObj.totalPages;
    this.fetchData();
  }

  pageIncrease() {
    if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableNextBtn = true;
      this.disableLastBtn = true;
    }
    this.paginationObj.pageNumber++;
    this.fetchData();
  }

  pageDecrease() {
    if (this.paginationObj.pageNumber == 1) {
      this.disablePreviousBtn = true;
      this.disableFirstBtn = true;
    }
    this.paginationObj.pageNumber--;
    this.fetchData();
  }
}
