<div class="cms__content">
  <div class="top__heading">
    <h3>Distributor</h3>
   
    <a [routerLink]="['/manage/distributor/create']"
    ><button class="default__button float-right" *ngIf="menuAccess">+ Add New</button></a
  >
      
   
    
  </div>
  <div class="clearfix"></div>
  <div class="col-12 list__summary">
    <div class="row">
      <!-- <div class="col-6 col-md-3">
        <div class="list__card">
          <span class="list__heading">Accounts</span>
          <span class="list__item">{{TotalLcoCount}}</span>
        </div>
      </div>
      <div class="col-6 col-md-3">
        <div class="list__card">
          <span class="list__heading">Activated Accounts</span>
          <span class="list__item">{{LcoActiveCount}}</span>
        </div>
      </div> -->

    
    </div>
  </div>
  <div class="col-12 list__table">
    <div class="row overflow">
 <div class="custom__table__filter">
        <span>
          Show
          <select (change)="pageSizeChange($event)"  >
            <option
              *ngFor="let size of paginationObj.pageSizeOptions"
              [ngValue]="size"
              [selected]="size == paginationObj.pageSize"
              >{{size}}</option
            >
          </select>
          Entries
        </span>
        <div class="table__right">
          <div class="download__report">
            <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
              PDF
              <img src="./assets/pdf.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
              Excel
              <img src="./assets/excel.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
              CSV
              <img src="./assets/csv.svg" alt="download" />
            </button>
        </div>
          <p class="table__summary">
            Total <span>{{paginationObj.totalElements}}</span> records
          </p>
          <div class="search__container">
            <input
              type="text"
              class="custom__field"
              id="searchValue"
              (keyup.enter)="onSearch()"
            />
            <button (click)="onSearch()">
              <img src="./assets/search.png" alt="search" />
            </button>
          </div>
        </div>
      </div>
      <div class="tableBox">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>
              Sl No.
            </th>
            <th>Distributor ID</th>
            <th>Distributor Code</th>
            <th
              (click)="sortByItem(sortByProperties.name)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.name}"
            >
              Name
            </th>
            <!-- <th
              (click)="sortByItem(sortByProperties.cp)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.cp}"
            >
              Contact Person
            </th>
            <th
              (click)="sortByItem(sortByProperties.tp)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.tp}"
            >
              Transfer Price (%)
            </th> -->
            <th
              (click)="sortByItem(sortByProperties.mobile)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.mobile}"
            >
              Mobile
            </th>
            <th
              (click)="sortByItem(sortByProperties.email)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.email}"
            >
              Email
            </th>
            <th
              class="label__align"
              (click)="sortByItem(sortByProperties.status)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.status}"
            >
              Status
            </th>
            <th
              (click)="sortByItem(sortByProperties.date)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.date}"
            >
              Created On
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="!loadingFlag&& lcoList.length == 0">
            <td colspan="9" style="text-align: center;">No Data</td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td class="loading" colspan="9">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <ng-template #showList>
            <tr *ngFor="let admin of lcoList, let j=index">
              <td>{{((paginationObj.pageNumber-1)*paginationObj.pageSize)+(j+1)}}</td>
              <td>{{admin.id}}</td>
              <td>{{admin.distributorCode? admin.distributorCode : '---'}}</td>
              <td>{{admin.name? admin.name : '---'}}</td>
              <!-- <td>{{admin.contactPerson? admin.contactPerson : '---'}}</td> -->
              <!-- <td>{{admin.transferPrice? admin.transferPrice : '---'}}</td> -->
              <td>{{admin.mobileNumber? admin.mobileNumber : '---'}}</td>
              <td>{{admin.email? admin.email : '---'}}</td>
              <td class="label__align">
                <span
                  [ngClass]="{'safe__label' : admin.status, 'danger__label' : !admin.status}"
                >
                  {{admin.status===0?'X':'&#10003;'}}
                </span>
              </td>
              <td>
                {{admin.createdDate | date: 'dd-MM-yyyy HH:mm:ss'}}
                <div class="action__items" *ngIf="pdfDown==false">
                  <button class="cta" (click)="viewDistributorDetails(admin)">View</button>
                  <button (click)="edit(admin)" *ngIf="menuAccess">Edit</button>
                  <ng-container *ngIf="menuAccess==true"><button *ngIf="user==true && menuAccess" (click)="login(admin)">
                    Login
                  </button></ng-container>
                  <!-- <button class="danger"
                      (click)="updateStatus(admin)">{{admin.status===1?'Deactivate':'Activate'}}</button> -->
                </div>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      </div>
      <div class="custom__pagination">
        <ul>
          <li>
            <button
              [disabled]="loadingFlag || disableFirstBtn"
              (click)="pageFirstBtnClick()"
            >
              <img src="./assets/firstPage.png" />
            </button>
          </li>
          <li>
            <button
              [disabled]="loadingFlag || disablePreviousBtn"
              (click)="pagePreviousBtnClick()"
            >
              <img src="./assets/previousPage.png" />
            </button>
          </li>
          <li>
            <span
              >Page {{paginationObj.pageNumber}} of
              {{paginationObj.totalPages}}</span
            >
          </li>
          <li>
            <button
              [disabled]="loadingFlag || disableNextBtn"
              (click)="pageNextBtnClick()"
            >
              <img src="./assets/nextPage.png" />
            </button>
          </li>
          <li>
            <button
              [disabled]="loadingFlag || disableLastBtn"
              (click)="pageLastBtnClick()"
            >
              <img src="./assets/lastPage.png" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="modal_box" *ngIf="viewModal">
  <div class="modal_box_inner">
    <h3>Distributor Details</h3>
    <div class="parent">
      <p class="div1">Distributor ID</p>
      <p class="div2">{{distributrDetails.distributorDetails.distributorId}}</p>
      <p class="div3">Distributor Name</p>
      <p class="div4">{{distributrDetails.distributorDetails.distributorName}}</p>
      <p class="div5">Distributer Code</p>
      <p class="div6">{{distributrDetails.distributorDetails.distributorCode}}</p>
      <p class="div7">Created Date</p>
      <p class="div8">{{distributrDetails.distributorDetails.createdDate | date}}</p>
      <p class="div9" style="text-align:start">LCO Count</p>
      <p class="div10">{{distributrDetails.distributorDetails.lcoCount}}</p>
      <p class="div11" style="text-align:start">Subscriber Count</p>
      <p class="div12">{{distributrDetails.distributorDetails.subscriberCount}}</p>
      <p class="div13" style="text-align:start">Wallet balance</p>
      <p class="div14">{{distributrDetails.distributorDetails.walletBalance | currency:'INR'}}</p>
      
    </div>
    <div class="custom_iptv_table" *ngIf="distributrDetails.last5Transaction.length>0">
      <h6>Last Transactions</h6>  
       <div class="table__height">

      <table class="custom__iptv__table">
        <tbody>
          <tr>
            <th>Sl No</th>
            <th>Amount</th>
            <th>Type</th>
            <th>Remarks</th>
          </tr>
          <ng-container *ngFor="let item of distributrDetails.last5Transaction;let i=index">
            <tr>
              <td>{{i+1}}</td>
              <td>{{item.amount| currency:'INR'}}</td>
              <td>{{item.type}}</td>
              <td>{{item.remarks }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table></div>
    </div>
    <div class="custom_iptv_table" *ngIf="distributrDetails.lcosDetails.length>0">
      <h6>LCO Details</h6>
      <div class="table__height">
      <table class="custom__iptv__table" >
        <tbody>
          <tr>
            <th>LCO Id</th>
            <th>LCO Name</th>
            <th>Subscriber Count</th>
            <th>GST Number</th>
            <th>Wallet Balance</th>
            <th>Created date</th>

          </tr>
          <ng-container *ngFor="let item of distributrDetails.lcosDetails">
            <tr>
              <td>{{item.lcoId}}</td>
              <td>{{item.lcoName}}</td>
              <td>{{item.subscriberCount}}</td>
              <td>{{item.gstNumber}}</td>
              <td>{{item.walletBalance | currency:'INR'}}</td>
              <td>{{item.createdDate}}</td>


            </tr>
          </ng-container>
        </tbody>
      </table>
      </div>
    </div>
    <div class="custom_iptv_table" *ngIf="distributrDetails.BouquetDetails.length>0">
      <h6>Bouquet Details</h6>
      <div class="table__height">
      <table class="custom__iptv__table">
        <tbody>
          <tr>
            <th>Bouquet Id</th>
            <th>Bouquet Name</th>
            <th>Bouquet Price</th>
            <th>Bouquet Type</th>
            <th>Distributor Share</th>
          </tr>
          <ng-container *ngFor="let item of distributrDetails.BouquetDetails">
            <tr>
              <td>{{item.bouquetId}}</td>
              <td>{{item.bouquetName}}</td>
              <td>{{item.bouquetPrice | currency:'INR'}}</td>
              <td>{{item.bouquetType}}</td>
              <td>{{item.distributorShare}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    </div>
    <button class="cta float-right" (click)="viewModal = !viewModal"> Close </button>
  </div>
</div>