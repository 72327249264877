<div class="cms__content">
    <div class="box">
        <div class="top__heading">
            <h3>Landing Channel Management</h3>
            <a [routerLink]="['/settings/landing-channel-management/']">
                <button class="default__button float-right">Back</button></a>
        </div>
        <form action="">
            <div class="col-12 form__style">
                <div class="row" *ngIf="!isEdit">
                    <label>Search Distributor</label>
                    <input type="text" autocomplete="off" id="lcoCode" class="custom__field" formControlName="lcoCode"
                        (input)="getSubscriberId($event)" />
                    <p (click)="clearInput()" id="clearInput">Clear</p>

                    <div class="search__param" style="overflow: auto;" *ngIf="openSearch">
                        <app-distributor-search [searchText]="searchText" (userDetail)="getUser($event)">
                        </app-distributor-search>
                    </div>
                </div>

                <div class="row" *ngIf="distributorSelected &&!isEdit">
                    <label>LCOs</label>
                    <ng-multiselect-dropdown [placeholder]="'select LCOs'" [settings]="dropdownSettings"
                        [data]="lcoList" class="custom__field" [(ngModel)]="selectedIDsList"
                        [ngModelOptions]="{standalone: true}" (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event)"
                        (onDeSelectAll)="onDeSelectAll()">
                    </ng-multiselect-dropdown>
                </div>

                <div class="row" *ngIf="isEdit">
                    <label>LCOs</label>
                    <input type="text" autocomplete="off" id="lcoCode" class="custom__field" [(ngModel)]="lcmData.lcoName" [ngModelOptions]="{standalone: true}" disabled />
                </div>
                <div class="row" *ngIf="distributorSelected && selectedList.length>0 || isEdit">
                    <label style="width:70%;">Select Channel</label>
                    <div class="search__container" style="width:30%;">
                        <input type="text" autocomplete="off" class="custom__field"  [(ngModel)]="searchQuery" 
                        [ngModelOptions]="{standalone: true}" placeholder="search channels..."
                          (keyup.enter)="$event.preventDefault(); filteredChannels()" />
                        <button (click)="filteredChannels()"><img src="./assets/search.png" alt="search" /></button>
                      </div>

                <div class="channel__class channel__selector_view" >
                        
                        <!-- <input  type="text" class="custom__field" /> -->
                    <div class="channel__card_view" *ngFor="let channel of filteredChannelData" (click)="selectChannel(channel)" [class.selected]="selectedChannel == channel.channelId">
                        LCN : {{channel.channelLCN}}
                        <div class="alt__card">
                            <img [src]="prefix+channel.logo" onerror="this.src='assets/ImagewithoutLogo.png'" />
                        </div>
                        {{channel.channelName}}
                    </div>
                </div>
                </div>

            </div>
        </form>
        <button
        class="cta float-right"
        [disabled]="selectedChannel==''||selectedList.length==0"
        (click)="persist()"
      >
        Submit
      </button>
    </div>
</div>