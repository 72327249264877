import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-channel',
  templateUrl: './list-channel.component.html',
  styleUrls: ['./list-channel.component.scss']
})
export class ListChannelComponent implements OnInit {

  paginationObj = new PaginationObj();

  loadingFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  sortByProperties = {
    name: 'name',
    number: 'number',
    channelId: 'channelId',
    desc: 'description',
    date: 'createdDate',
    status: 'status',
    url: 'url',
    language : 'language',
    categories  : 'categories',
    genre :'genre',
    price:'price'

  };

  channelList: ChannelInfo[] = [];

  activeChannelCount = 0;
  //channel counts
  ChannelActiveCount: number;
  ChannelInActiveCount: number;
  TotalChannelCount: number;
  menus
  menuAccess = false




  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private auth: AuthService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchPage();
    this.fetchChannelCount();
    this.menus=JSON.parse(atob(localStorage.getItem('menus')))
    this.checkMenuAccess()
  }
  checkMenuAccess(){
    for(let i of this.menus){
      if(i.name=='Channel')
      {
        this.menuAccess=(i.access==0?false:true);
        console.log(this.menuAccess)
      }
    }
    console.log(this.menuAccess)
  }

  onSearch(){
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }


  fetchPage(): void {
    this.auth.isLCO() ? this.fetchLco() : this.fetchmso();
  }
  fetchChannelCount() {
    const url = this.config.json.apiEndpointRdbms + 'channel/getChannelTotalCountAndActiveCount'
    this.http.get(url).subscribe((res) => {
      // // console.log(res)
      this.ChannelActiveCount = res.data.activeChannelCount
      this.ChannelInActiveCount = res.data.inactiveChannelCount
      this.TotalChannelCount = res.data.channelTotalCount


    });
  }
  fetchmso(): void {
    // const url = PaginationUtilities.urlBuilderRdbms('channel/getChannels', this.paginationObj);
    const url = this.config.json.apiEndpointRdbms + 'channel/getChannels/' + this.paginationObj.pageNumber + '?pageSize=' + this.paginationObj.pageSize + '&sortBy=' + this.paginationObj.sortBy + '&sortOrder=' + this.paginationObj.sortOrder + '&searchKeyword=' + this.paginationObj.searchKeyword
    this.loadingFlag = true;

    this.http.get(url).pipe(delay(250)).subscribe((data: PageInfo<ChannelInfo>) => {
      this.loadingFlag = false;
      this.handleFetchPage(data);
    });
  }

  fetchLco(): void {
    // const url = PaginationUtilities.urlBuilderRdbms('channel/getChannels', this.paginationObj);
    const url = this.config.json.apiEndpointRdbms + 'channel/getChannels/' + this.paginationObj.pageNumber + '?pageSize=' + this.paginationObj.pageSize + '&sortBy=' + this.paginationObj.sortBy + '&sortOrder=' + this.paginationObj.sortOrder + '&searchKeyword=' + this.paginationObj.searchKeyword

    // const url = PaginationUtilities.urlBuilderRdbms('channel/getChannelsByLCOId', this.paginationObj)
    //   + '&lcoId=' + this.auth.getLcoId();

    this.http.get(url).subscribe(data => {
      this.loadingFlag = false;
      this.handleFetchPage(data);
    });
  }

  handleFetchPage(data: PageInfo<ChannelInfo>): void {
    this.channelList = data.data;

    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
    this.countChannel();
  }

  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record: ChannelInfo): void {
    this.router.navigate(['/content/channel', record.id]);
  }

  // updateStatus(record: ChannelInfo): void {
  //   record.status === 1 ? this.deactivate(record) : this.activate(record);
  // }

  deactivate(record: ChannelInfo): void {
    const url = this.config.json.apiEndpoint + 'channel/deactivate/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 0;
      this.countChannel();
      window.alert('Deactivated');
    });
  }

  activate(record: ChannelInfo): void {
    const url = this.config.json.apiEndpoint + 'channel/activate/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 1;
      this.countChannel();
      window.alert('Activated');
    });
  }

  countChannel(): void {
    this.activeChannelCount = this.channelList.filter(ch => ch.status).length;
  }

}
