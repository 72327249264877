import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdManagerComponent } from './ad-manager/ad-manager.component';
import { AdManagerListComponent } from './ad-manager-list/ad-manager-list.component';

const routes: Routes = [
    {
        path: 'ad-upload',
        component: AdManagerComponent
    },
    
    {
        path: 'ad-list',
        component: AdManagerListComponent
    },
    {
        path: 'ad-upload/:id',
        component: AdManagerComponent
    },
    {
        path: '',
        redirectTo: 'ad-list'
    },
    {
        path: '**',
        component: AdManagerListComponent
    }
]
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdManagerRoutingModule { }