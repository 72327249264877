<div class="cms__content">
  <app-reports-header></app-reports-header>
  <div class="top__heading">
    <h3>Invoices</h3>
  </div>
  <div class="col-12 search__subscriber card">
    <form action="" [formGroup]="fingerprintSendForm">
      <div class="row searchable">
        <div class="col-12 col-md-4">
          <label>Search Subscriber</label>
          <input type="text" autocomplete="off" class="custom__field" placeholder="Subscriber Code"
            formControlName="subscriberId" id="subCode" (input)="searchSubscriberDetails()" />
          <p style="position: absolute; top: 23px; right: 23px" id="clear" (click)="clearInput()">
            Clear
          </p>
        </div>
        <p class="btn cta" (click)="searchSubscriberDetails()">Search</p>
      </div>
    </form>
  </div>
  <div class="search_param overflow" *ngIf="openSearch">
    <app-subscriber-search [searchText]="searchText" (userDetail)="getUserDetail($event)">
    </app-subscriber-search>
  </div>
  <div class="search__results">
    <!-- <table class="custom__iptv__table">
      <thead>
        <tr>
          <th></th>
          <th class="caps">{{fpSubscriberFilter.value}}</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngIf="subLoadingFlag; else showSubList">
          <td class="loading" colspan="2">
            <app-custom-spinner></app-custom-spinner>
          </td>
        </tr>

        <ng-template #showSubList>
          <tr *ngFor="let sub of subscriberList">
            <td><input type="checkbox" [checked]="sub.checked" (change)="subscriberCheckbox(sub)" />
            </td>
            <td>{{
              fpSubscriberFilter.value==="name"? sub.firstName + ' ' + sub.lastName :
              sub[fpSubscriberFilter.value]
              }}</td>
          </tr>
        </ng-template>
      </tbody>
    </table> -->
    <ng-container *ngIf="detailsAccessed">
      <table class="custom__iptv__table">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>STB ID</th>
            <th>Ethernet MAC Address</th>
            <th>Package</th>
            <th>Package Price</th>
            <th>Date</th>
            <th>Action</th>
            <!-- <th>NCF Primary</th>
              <th>NCF Secondary</th>
              <th>Type</th> -->
            <!-- <th>Total Price</th> -->
          </tr>
        </thead>
        <tbody>
          <!-- <tr *ngIf="subLoadingFlag; else showSubList">
            <td class="loading" colspan="7">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr> -->

          <!-- <ng-template #showSubList> -->

          <ng-container *ngFor="let invoice of invoiceList; let i = index">
            <tr *ngIf="invoiceList.length === 0">
              <td colspan="6" style="text-align: center;">No Records</td>
            </tr>
            <tr>
              <td>{{ i + 1 }}</td>
              <td>{{ invoice.stbId }}</td>
              <td>{{ invoice.ethernetMac }}</td>
              <td>{{ invoice.packages }}</td>
              <td>{{ invoice.bouquetPrice }}</td>
              <td>
                {{ invoice.createdDate | date : "dd/MM/yyyy hh:mm:ss a" }}
              </td>
              <!-- <td>{{ invoice.ncfPrimary }}</td> -->
              <!-- <td>{{ invoice.ncfSecond }}</td> -->
              <!-- <td>{{ invoice.type ? invoice.type : "---" }}</td> -->
              <td>
                <button (click)="showInvoiceDetails(invoice, i)">View</button>
              </td>
            </tr>
          </ng-container>
          <!-- </ng-template> -->
        </tbody>
      </table>
    </ng-container>
  </div>
</div>
<div *ngIf="isDetail" id="invoiceDetailsBox" class="invoice_detail_pop-up slider_in">
  <div class="invoice_box">
    <button (click)="closeInvoiceBar()">Close</button>
    <button style="margin-bottom: 20px" class="cta float-right" (click)="downloadPDF()">
      Download PDF
    </button>
    <h1>Invoice</h1>
    <div class="head-details">
      <div class="details">
        <p data-name="bigP">{{ invoiceDetail.invoice.lcoName }}</p>
        <p>
          {{ invoiceDetail.invoice.lcoName }},
          {{ invoiceDetail.invoice.lcoLocality }},
          {{ invoiceDetail.invoice.lcoDistrict }}
        </p>
        <p>
          {{ invoiceDetail.invoice.lcoCity }},
          {{ invoiceDetail.invoice.lcoState }},
          {{ invoiceDetail.invoice.lcoCountry }} -
          {{ invoiceDetail.invoice.lcoPin }}
        </p>
        <p>GST NO: {{ invoiceDetail.invoice.lcoGSTNO }}</p>
      </div>
      <div class="meta">
        <p data-name="bigP">
          Invoice Number: {{ invoiceDetail.invoice.invoiceNo }}
        </p>
        <p>Date: {{ invoiceDetail.invoice.createdDate }}</p>
      </div>
    </div>
    <div class="head-details">
      <div class="details">
        <p data-name="bigP">Invoice To</p>
        <p>{{ invoiceDetail.invoice.subscriberName }}</p>
        <p>
          {{ invoiceDetail.invoice.email }},
          {{ invoiceDetail.invoice.mobileNumber }}
        </p>
        <p>{{ invoiceDetail.invoice.address }}</p>
      </div>
      <div class="meta">
        <p data-name="bigP">Subscriber ID: {{ invoiceDetail.invoice.subscriberId }}</p>
        <p data-name="bigP">
          Subscriber Code: {{ invoiceDetail.invoice.subscriberCode }}
        </p>
        <p data-name="bigP">STB ID : {{ invoiceDetail.invoice.stbId }}</p>

        <p data-name="bigP">
          Invoice amount : &#8377; {{ invoiceDetail.invoice.amount }}
        </p>
      </div>
    </div>
    <div class="table-print">
      <table>
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Day(s)</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr data-name="heading">
            <td>Subscription Charges</td>
            <td></td>
            <td></td>
          </tr>
          <ng-container *ngFor="let item of invoiceDetail.subscription">
            <tr data-name="api-data">
              <td>{{ item.packages }}</td>
              <td>{{ item.duration }}</td>
              <td>{{ item.bouquetPriceWithoutGST }}</td>
            </tr>
          </ng-container>
          <tr data-name="heading">
            <td>Tax DR</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>CGST @9%</td>
            <td></td>
            <td>&#8377; {{ invoiceDetail.invoice.cgst }}</td>
          </tr>
          <tr>
            <td>SGST @9%</td>
            <td></td>
            <td>&#8377; {{ invoiceDetail.invoice.sgst }}</td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th>Sub Total</th>
            <th></th>
            <th>&#8377; {{ invoiceDetail.invoice.amount }}</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th>
              <p data-name="bigP">Total</p>
            </th>
            <th></th>
            <th>
              <p data-name="bigP">&#8377; {{ invoiceDetail.invoice.amount }}</p>
              <p data-name="bigP">&#8377; {{ invoiceDetail.invoice.inWords }} Only</p>
            </th>
          </tr>
        </thead>
      </table>
    </div>
    <p data-name="bigP" id="forLCO">For {{ invoiceDetail.invoice.lcoName }}</p>
  </div>
</div>
<div *ngIf="isDetail" id="print" class="printable">
  <img src="assets/logos/esto_header_1.png" alt="" />
  <h1>Invoice</h1>
  <div class="head-details">
    <div class="details">
      <p data-name="bigP">{{ json.invoice.lcoName }}</p>
      <p>
        {{ json.invoice.lcoName }}, {{ json.invoice.lcoLocality }},
        {{ json.invoice.lcoDistrict }}
      </p>
      <p>
        {{ json.invoice.lcoCity }}, {{ json.invoice.lcoState }},
        {{ json.invoice.lcoCountry }} - {{ json.invoice.lcoPin }}
      </p>
      <p>GST NO: {{ json.invoice.lcoGSTNO }}</p>
    </div>
    <div class="meta">
      <p data-name="bigP">Invoice Number: {{ json.invoice.invoiceNo }}</p>
      <p>Date: {{ json.invoice.createdDate }}</p>
    </div>
  </div>
  <div class="head-details">
    <div class="details">
      <p data-name="bigP">Invoice To</p>
      <p>{{ json.invoice.subscriberName }}</p>
      <p>{{ json.invoice.email }}, {{ json.invoice.mobileNumber }}</p>
      <p>{{ json.invoice.address }}</p>
    </div>
    <div class="meta">
      <p data-name="bigP">Subscriber ID: {{ json.invoice.subscriberId }}</p>
      <p data-name="bigP">Subscriber Code: {{ json.invoice.subscriberCode }}</p>
      <p data-name="bigP">STB ID : {{ json.invoice.stbId }}</p>
      <p data-name="bigP">Invoice amount : INR {{ json.invoice.amount }}</p>
    </div>
  </div>
  <div class="table-print">
    <table>
      <thead>
        <tr>
          <th>Product Name</th>
          <th>Day(s)</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr data-name="heading">
          <td>Subscription &nbsp;&nbsp; Charges</td>
          <td></td>
          <td></td>
        </tr>
        <ng-container *ngFor="let item of json.subscription">
          <tr data-name="api-data">
            <td>{{ item.packages }}</td>
            <td>{{ item.duration }}</td>
            <td>{{ item.bouquetPriceWithoutGST }}</td>
          </tr>
        </ng-container>
        <tr data-name="heading">
          <td>Tax DR</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>CGST @9%</td>
          <td></td>
          <td>INR {{ json.invoice.cgst }}</td>
        </tr>
        <tr>
          <td>SGST @9%</td>
          <td></td>
          <td>INR {{ json.invoice.sgst }}</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>Sub Total</th>
          <th></th>
          <th>INR {{ json.invoice.amount }}</th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th>
            <p data-name="bigP">Total</p>
          </th>
          <th></th>
          <th>
            <p data-name="bigP">INR {{ json.invoice.amount }}</p>
          </th>
        </tr>
        <tr>
          <th colspan="3" data-name="InWords">
            <p data-name="bigP" style="text-align: right;">INR &nbsp;&nbsp;
              <ng-container *ngFor="let word of inWordsArray">
                <span>{{word}}</span>&nbsp;
              </ng-container>
              Only
            </p>
          </th>
        </tr>
      </thead>
    </table>
  </div>
  <p data-name="bigP" id="forLCO">For {{ json.invoice.lcoName }}</p>
  <br /><br /><br /><br /><br />
  <p data-name="bigP" id="forLCO">Authorised Signatory</p>
</div>