import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BroadcasterInfo } from 'src/app/models/broadcaster/broadcaster-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-report-subscriberwise-subscription-active',
  templateUrl: './report-subscriberwise-subscription-active.component.html',
  styleUrls: ['./report-subscriberwise-subscription-active.component.scss'],
})
export class ReportSubscriberwiseSubscriptionActiveComponent implements OnInit {
  reportList = [];
  bouquetType = 0;
  broadcasterId: number;

  broadcasterList: BroadcasterInfo[] = [];

  selectedDate: any = new Date();

  currentDate = new Date();
  eve;

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    // this.fetchLocationList();
    this.selectedDate = this.selectedDate.toISOString().split('T')[0];
    this.selectedDate = this.selectedDate.substring(0, 7);
    let dateObj = new Date();
    let toDate =
      dateObj.getFullYear() +
      '-' +
      String(dateObj.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(dateObj.getDate()).padStart(2, '0');
    let body = {
      fromDate: '2018-01-01',
      toDate: toDate,
      searchKeyword: '',
      monthYear: '',
    };
    this.fetchData(body);
  }

  fetchLocationList(): void {
    this.report.getBroadcasterList().subscribe((data) => {
      this.broadcasterList = data;
    });
  }

  fetchData(body?: any): void {
    this.reportList = []
    this.loadingFlag = true
    this.report.getSubscriberWiseSubscriptionActive(body).subscribe(
      (res) => {
        this.reportList = res.data;
        if (!res.data) {
          console.log(!res.data);
          this.loadingFlag = false;
          this.isLoad = 'No Records';
          return;
        }
        if (this.reportList?.length != 0) {
          this.loadingFlag = true;
        } else {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.loadingFlag = false;
        // this.isLoad = 'No Records';
      }
    );
  }

  loadingFlag = false;
  isLoad = 'Choose Date';
  showMonth = false;
  showDateRange = false;
  dateSelector = true;
  dateTypeSelectors(num) {
    if (num == 0) {
      this.dateSelector = true;
      this.showMonth = false;
      this.showDateRange = false;
    }
    if (num == 1) {
      this.dateSelector = false;
      this.showMonth = true;
      this.showDateRange = false;
    }
    if (num == 2) {
      this.dateSelector = false;
      this.showDateRange = true;
      this.showMonth = false;
    }
  }

  reportDate =
    this.reportList.length == 0 ? 'Not Selected' : 'From Start To Now';
  dateChangeRange(ev) {
    this.reportList = [];
    let startDate = ev.startDate.format('YYYY-MM-DD');
    let endDate = ev.endDate.format('YYYY-MM-DD');
    let body = {
      fromDate: startDate,
      toDate: endDate,
      monthYear: '',
      searchKeyword: this.searchWord ? this.searchWord : '',
    };
    this.reportDate =
      ev.startDate.format('DD/MM/YYYY') +
      ' to ' +
      ev.endDate.format('DD/MM/YYYY');
   this.fetchData(body)
    this.eve = ev;
  }

  date;
  dateChangeMonth(ev) {
    this.reportList = [];
    let dateObj = new Date(ev.target.value);
    this.reportDate =
      String(dateObj.getDate()).padStart(2, '0') +
      ' ' +
      this.doc.monthNameCalculator(dateObj.getMonth()) +
      ' ' +
      dateObj.getFullYear();
    this.date =
      dateObj.getFullYear() +
      '-' +
      String(dateObj.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(dateObj.getDate()).padStart(2, '0');
    let body = {
      fromDate: '',
      toDate: '',
      monthYear: ev.target.value,
      searchKeyword: this.searchWord ? this.searchWord : '',
    };
    // // console.log(ev.target.value)
    // this.fetchData(this.body)
    this.loadingFlag = true;
    let url =
      this.config.json.apiEndpointRdbms +
      'subscriber/getSubscriberWiseSubscriptionsActive';
    this.http.post(url, body).subscribe(
      (data) => {
        this.reportList = data['data'];
        if (this.reportList.length != 0) {
          this.loadingFlag = false;
        } else {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }

  downloadTableExcel(): void {
    let name = 'SubscriberWise Subscription Active Report ';
    this.doc.exportTableToExcelwithImage(
      this.reportTable,
      name,
      'SubscriberWise_Active_Subscription',
      undefined,
      undefined,
      'Report Date :',
      this.reportDate
    );
  }

  downloadTablePdf(): void {
    let dateObj = new Date();
    let reportGenerated =
      String(dateObj.getDate()).padStart(2, '0') +
      '/' +
      String(dateObj.getMonth() + 1).padStart(2, '0') +
      '/' +
      dateObj.getFullYear();
    this.pdf.generatePdfHtml(
      '#reportTable',
      'SubscriberWise_Active_Subscription',
      `Report Date : ${this.reportDate},   Source Type : SMS    Report Generated : ${reportGenerated}`,null,null,
      "a3"
    );
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: [
        'Sl No.',
        'Subscriber ID',
        'Subscriber Code',
        'STB ID',
        'MAC Address',
        'Subscriber Name',
        'City',
        'State',
        'Status',
        'Mobile Number',
        'Bouquet ID',
        'Price Package Name',
        'Subscription type',
        'Suscription Status',
        'Start Date',
        'End Date',
        'Disconnection',
        
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((report) => {
      let csvLine = {
        slNo: i,
        subId:report.subscriber_id,
        subscriber_code: report.subscriber_code,
        stb_id:report.stb_id,
        ethernet_mac_address: report.ethernet_mac_address,
        subscriber_name: report.subscriber_name,
        city: report.city,
        state: report.state,
        subscriber_status:
          report.subscriber_status == 1 ? 'Active' : 'Inactive',
        mobile_no: report.mobile_no,
        bouquet_id:report.bouquet_id,
        price_package_name: report.price_package_name,
        subscription_type: report.subscription_type,
       
        subscription_status:
        report.subscription_status == 1 ? 'Active' : 'Inactive',
        date1:report.transactionDate,
        enddate:report.end_date,
        disconnection:(report.disconnection?report.disconnection:'---'),
       
      };
      i += 1;
      data.push(csvLine);
    });

    new ngxCsv(data, 'SubscriberWise_Active_Subscription', options);
  }

  searchWord;
  searchKeyword() {
    let value = (document.getElementById('searchKeyword') as HTMLInputElement)
      .value;
    this.searchWord = value;
    let body = {};
    if (this.showDateRange) {
      var url =
        this.config.json.apiEndpointRdbms +
        'subscriber/subscriberWiseSubscriptionsActiveFromToDate';
      body = {
        fromDate: this.eve.startDate.format('YYYY-MM-DD'),
        toDate: this.eve.endDate.format('YYYY-MM-DD'),
        searchKeyword: value,
        monthYear: '',
      };
    }
    if (this.showMonth) {
      var url =
        this.config.json.apiEndpointRdbms +
        'subscriber/getSubscriberWiseSubscriptionsActive';
      body = {
        fromDate: '',
        toDate: '',
        monthYear: this.date,
        searchKeyword: value,
      };
    }
    if (!this.showMonth && !this.showDateRange) {
      var url =
        this.config.json.apiEndpointRdbms +
        'subscriber/subscriberWiseSubscriptionsActiveFromToDate';
      let dateObj = new Date();
      let toDate =
        dateObj.getFullYear() +
        '-' +
        String(dateObj.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(dateObj.getDate()).padStart(2, '0');
      body = {
        fromDate: '2018-01-01',
        toDate: toDate,
        searchKeyword: value,
        monthYear: '',
      };
    }
    this.http.post(url, body).subscribe(
      (data) => {
        this.reportList = data['data'];
        if (this.reportList.length != 0) {
          this.loadingFlag = false;
        } else {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }


  showMonthmodal = false
  showDateRangemodal = false
  dateSelectormodal = true
  tableDisplaymodal = true

  viewModal = false
  reportType = 'Active_subscription_report '
  yearValue = ''
  dateType
  yearsArray = []

  downloadHistoricReport() {
    this.viewModal = true
    this.yearsArrayCalculator()

  }

  yearFilter() {
        this.yearValue=''
    this.yearValue = (document.getElementById('yearCalc') as HTMLSelectElement).value;
    this.yearValue=this.yearValue+'-01-01';
    this.dateType = 'year'
  }

  MonthFilter(e) {
    this.yearValue=''
    this.yearValue += e.target.value + '-01'
    console.log(this.yearValue)
    this.dateType = 'month'
  }

  dateTypeSelectorsmodal(num) {
    this.yearValue = ''
    if (num == 0) {
      this.dateSelectormodal = true
      this.showMonthmodal = false
      this.showDateRangemodal = false
      this.tableDisplaymodal = true
    }
    if (num == 1) {
      this.dateSelectormodal = false
      this.showMonthmodal = true
      this.showDateRangemodal = false
      this.tableDisplaymodal = true
    }
    if (num == 2) {
      this.dateSelectormodal = false
      this.showDateRangemodal = true
      this.showMonthmodal = false
      this.tableDisplaymodal = false
      this.yearValue = new Date().getFullYear()+'-01-01'
      this.dateType='year'
      console.log(this.yearValue)
    }
  }

  yearsArrayCalculator() {
    if (this.yearsArray.length == 0) {
      let currentYear = new Date().getFullYear();
      for (let i = currentYear; i >= 2019; i--) {
        this.yearsArray.push(i);
      }
    }
  }


  generateReport() {
    let url = this.config.json.apiEndpoint + 'reports/generateHistoricalReport?value=' +
      this.yearValue + '&type=' + this.dateType + '&reportName=' + this.reportType
    this.http.get(url).subscribe(
      (res) => {
        if (res.statusCode == 200) {
          const fileUrl = res.data.fileUrl;
          const anchor = document.createElement('a');
          anchor.href = fileUrl;
          anchor.target = '_self';
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          this.yearValue='';
          this.viewModal=false;
          this.dateTypeSelectorsmodal(0)
        }
        else {
          alert(res.message)
        }
      }
    )
  }
}
