<div class="cms__content">
  <div class="top__heading">
    <h3>Billing</h3>
    <a [routerLink]="['/reports/trai']"
      ><button class="default__button float-right">Back</button></a
    >
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <div class="filter__group col-12 col-md-6" data-name="lcoSelect">
      <label id="select_date">Search LCO</label>
      <div class="input_wrapper">
        <input
          type="text"
          name=""
          id=""
          class="custom__field"
          id="lcoSearch"
          autocomplete="off"
          (click)="getSearchDataForAll()"
          (input)="getSearchData($event)"
        />
        <app-lco-search
          *ngIf="isSearch"
          [searchText]="searchText"
          (userDetail)="getLCODetails($event)"
        ></app-lco-search>
      </div>
    </div>
    <div class="download__report col-md-6">
      <button
        class="default__button"
        (click)="downloadTablePdf()"
        title="Download PDF"
      >
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableExcel()"
        title="Download Excel"
      >
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableCSV()"
        title="Download Excel"
      >
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>
  </div>
  <div class="custom__table__filter">
    <span>
      Show
      <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
        <option
          *ngFor="let size of paginationObj.pageSizeOptions"
          [ngValue]="size"
        >
          {{ size }}
        </option>
      </select>
      Entries
    </span>
    <div class="table__right">
      <p class="table__summary">
        Total <span>{{ paginationObj.totalElements }}</span> records
      </p>
      <div class="search__container">
        <input
          type="text"
          autocomplete="off"
          class="custom__field"
          id="searchKeyword"
          [(ngModel)]="paginationObj.searchKeyword"
          (keyup.enter)="searchKeyWord()"
        />
        <button (click)="searchKeyWord()">
          <img src="./assets/search.png" alt="search" />
        </button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div data-name="billing-report" class="row scroll">
      <table class="custom__iptv__table" data-name="billing-report" #reportTable id="print">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>LCO Code</th>
            <th>Distributor Code</th>
            <th>Subscriber Code</th>
            <th>Bouquet Name</th>
            <th>Duration</th>
            <th>STB ID</th>
            <th>Bouquet Price</th>
            <th>LCO Share</th>
            <th>Distributor Share</th>
            <th>MSO Share</th>
            <th>MSO Share GST</th>
            <th>Pay Mode</th>
            <th>LCO Wallet Amount Before</th>
            <th>LCO Wallet Amount After</th>
            <th>Distributor Wallet Amount Before</th>
            <th>Distributor Wallet Amount After</th>
            <th>Subscription Type</th>
            <th>Created Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList?.length == 0 && !loadingFlag">
            <td style="text-align: center !important" colspan="19">
              {{ isLoad }}
            </td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important" colspan="19">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <ng-template #showList>
            <tr *ngFor="let report of reportList; let j = index">
              <td>
                {{
                  (paginationObj.pageNumber - 1) * paginationObj.pageSize +
                    (j + 1)
                }}
              </td>
              <td>{{ report.lcoCode? report.lcoCode : '---' }}</td>
              <td>{{ report.distributorCode? report.distributorCode : '---' }}</td>
              <td>{{ report.subscriberCode? report.subscriberCode : '---' }}</td>
              <td>{{ report.bouquetName? report.bouquetName : '---' }}</td>
              <td>{{ report.duration? report.duration : '---' }}</td>
              <td>{{ report.stbId? report.stbId : '---' }}</td>
              <td>{{ report.bouquetPrice? report.bouquetPrice : '---' }}</td>
              <td>{{ report.lcoShare? report.lcoShare : '---' }}</td>
              <td>{{ report.distributorShare? report.distributorShare : '---' }}</td>
              <td>{{ report.msoShare? report.msoShare : '---' }}</td>
              <td>{{ report.msoShareGST? report.msoShareGST : '---' }}</td>
              <td>{{ report.payMode? report.payMode : '---' }}</td>
              <td>{{ report.lcoWalletBefore? report.lcoWalletBefore : '---' }}</td>
              <td>{{ report.lcoWalletAfter? report.lcoWalletAfter : '---' }}</td>
              <td>{{ report.distributorWalletBefore? report.distributorWalletBefore : '---' }}</td>
              <td>{{ report.distributorWalletAfter? report.distributorWalletAfter : '---' }}</td>
              <td>{{ report.subscriptionType? report.subscriptionType : '---' }}</td>
              <td>{{ report.createdDate? report.createdDate : '---' }}</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <!-- <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disablePreviousBtn" (click)="pageDecrease()">
              <img src="./assets/previousPage.png" />
            </button>
          </li>
          <li>
            <span
              >Page {{ paginationObj.pageNumber }} of
              {{ paginationObj.totalPages }}</span
            >
          </li>
          <li>
            <button [disabled]="disableNextBtn" (click)="pageIncrease()">
              <img src="./assets/nextPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" />
            </button>
          </li>
        </ul>
      </div> -->
    </div>
    <div class="custom__pagination">
      <ul>
        <li>
          <button [disabled]="disableFirstBtn" (click)="pageFirstBtnClick()">
            <img src="./assets/firstPage.png" />
          </button>
        </li>
        <li>
          <button [disabled]="disablePreviousBtn" (click)="pageDecrease()">
            <img src="./assets/previousPage.png" />
          </button>
        </li>
        <li>
          <span
            >Page {{ paginationObj.pageNumber }} of
            {{ paginationObj.totalPages }}</span
          >
        </li>
        <li>
          <button [disabled]="disableNextBtn" (click)="pageIncrease()">
            <img src="./assets/nextPage.png" />
          </button>
        </li>
        <li>
          <button [disabled]="disableLastBtn" (click)="pageLastBtnClick()">
            <img src="./assets/lastPage.png" />
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
