<div class="cms__content">
  <app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Complete Logs</h3>
    <a [routerLink]="['/logs']"
      ><button class="default__button float-right">Back</button></a
    >
  </div>
  <div class="row row-top">
    <div class="date_filter">
      <div class="div_tabs" *ngIf="dateSelector">
        <button (click)="dateTypeSelectors(1)">Year</button>
        <button (click)="dateTypeSelectors(2)">Random Date</button>
      </div>
      <div class="div_tabs2" *ngIf="showMonth">
        <div class="date_box">
          <label for="month">Select Year:</label>
          <div class="date_box_orig">
            <select name="" id="yearCalc" (change)="yearFilter()">
              <option *ngFor="let item of yearsArray" value="{{ item }}">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        <button (click)="dateTypeSelectors(0)">X</button>
      </div>
      <div class="div_tabs2" *ngIf="showDateRange">
        <label for="dateRange"> Select Range : </label>
        <div class="date_box_orig">
          <app-date-picker
            id="mar_zeroeds"
            (dateChange)="dateChangeRange($event)"
          ></app-date-picker>
        </div>
        <button id="app_date_pick_button" (click)="dateTypeSelectors(0)">
          X
        </button>
      </div>
    </div>

    <div class="download__report col-md-6">
      <button
        class="default__button"
        (click)="downloadTablePdf()"
        title="Download PDF"
      >
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableExcel()"
        title="Download Excel"
      >
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableCSV()"
        title="Download Excel"
      >
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>
    <div class="col-12 col-md-6 report_selection_block">
      <label class="report_selection_label" style="width: 10rem; padding: 0"
        >Select Category :</label
      >
      <select
        class="custom__field report_selection"
        [(ngModel)]="selectedCategory"
        (change)="listItem()"
      >
        <option value="0">All</option>
        <option *ngFor="let bc of categoryList" [ngValue]="bc">
          {{ bc }}
        </option>
      </select>
    </div>
  </div>

  <div class="third_box">
    <div class="col-12 col-md-6 report">
      Show &nbsp;
      <select
        [(ngModel)]="paginationObjcompletelog.pageSize"
        (change)="pageSizeChange()"
      >
        <option
          *ngFor="let size of paginationObjcompletelog.pageSizeOptions"
          [ngValue]="size"
        >
          {{ size }}
        </option>
      </select>
      &nbsp; Entries &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <p>
        Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
      </p>
    </div>
    <div style="display: flex; gap: 20px">
      <div class="table__right">
        <p class="table__summary">
          Total
          <span>{{ paginationObjcompletelog.totalElements }}</span> record{{
            reportList.length == 1 ? "" : "s"
          }}
        </p>
      </div>
      <div class="search__container">
        <input
          type="text"
          autocomplete="off"
          class="custom__field"
          id="searchKeyword"  [(ngModel)]="paginationObjcompletelog.searchKeyword"
          (keydown.enter)="searchKeyword()"
        />
        <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()">
          <img src="./assets/search.png" alt="search" />
        </button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Category</th>
            <th>Description</th>
            <th>Date</th>
            <!-- <th>STB Level</th> -->
            <!-- <th>Status</th> -->
            <!-- <th>Target ID</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList.length == 0">
            <td *ngIf="!isLoading" colspan="10" style="text-align: center">
              <app-custom-spinner></app-custom-spinner>
            </td>
            <td colspan="6" style="text-align: center" *ngIf="isLoading">
              {{ isLoad }}
            </td>
          </tr>
          <!-- <tr *ngIf="isLoading">
            <td colspan="10" style="text-align: center;">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr> -->
          <tr *ngFor="let report of reportList; let j = index">
            <!-- <tr *ngFor="let report of Object.entries(reportList), let j= index"> -->
              <td>{{(paginationObjcompletelog.pageNumber - 1) * paginationObjcompletelog.pageSize +(j + 1)}}</td>
            <td>{{ report.category ? report.category : "---No Data---" }}</td>

            <td
              *ngIf="
                report.description || report.DESCRIPTION as description;
                else noDescription
              "
            >
              {{ description }}
            </td>
            <ng-template #noDescription>
              <td>---No Data---</td>
            </ng-template>

            <td *ngIf="report.transactionDate as date; else noDate">
              {{ date | date : "dd-MM-yyyy HH:mm:ss" : "UTC" }}
            </td>
            <ng-template #noDate>
              <td>---No Data---</td>
            </ng-template>
            <!-- <td>{{report.status}}</td> -->
            <!-- <td>{{report.targetId}}</td> -->
          </tr>
        </tbody>
      </table>
    </div>

    <div class="custom__pagination">
      <ul>
        <li>
          <button [disabled]="disableFirstBtn" (click)="pageFirstBtnClick()">
            <img src="./assets/firstPage.png" />
          </button>
        </li>
        <li>
          <button [disabled]="disablePreviousBtn" (click)="pageDecrease()">
            <img src="./assets/previousPage.png" />
          </button>
        </li>
        <li>
          <span
            >Page {{ paginationObjcompletelog.pageNumber }} of
            {{ paginationObjcompletelog.totalPages }}</span
          >
        </li>
        <li>
          <button [disabled]="disableNextBtn" (click)="pageIncrease()">
            <img src="./assets/nextPage.png" />
          </button>
        </li>
        <li>
          <button [disabled]="disableLastBtn" (click)="pageLastBtnClick()">
            <img src="./assets/lastPage.png" />
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- bouquetId
:
null
channelId
:
null
command
:
"Bouquet Created"
commandStb
:
null
dateTime
:
"2023-02-17T11:34:43.000+00:00"
endDate
:
null
startDate
:
null
status
:
"ACK"
stbId
:
null
stbLevel
:
"Individual"
stbMacAddress
:
null
stbserialNo
:
null
subscriberId
:
null
subscriberName
:
null
targetId
:
"209" -->

<!-- {
  "action": "Order Renewal",
  "username": "iptvwibro2021",
  "description": "Order Renewed Successfully for Subscriber code :: PAT43828 for following Price Packages [IPTV_130WIBROBESIC]",
  "date": "2023-02-02 15:03:34.0",
  "category": "Order"
}, -->
