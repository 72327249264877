<div class="cms__content">
    <div class="top__heading">
      <h3>Custom Reports</h3>
    </div>
  
    <div class="col-12 list__summary">
      <div class="row">
        <!-- Actual cards for category -->
        <div class="col-12">
          <div class="row sticky">
            <!-- <h4 class="sub__heading">Subscriber Reports</h4> -->
          </div>
  
          <div class="row">
        
            <div class="col-6 col-md-3">
              <a [routerLink]="['/reports/custom-reports/report-subscriber']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">custom</label>
                  <div class="card__txt">Subscriber Report</div>
                </div>
              </a>
            </div>
  
            <div class="col-6 col-md-3">
              <a [routerLink]="['/reports/custom-reports/report-subscriber-lco-distributor']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">custom</label>
                  <div class="card__txt">Active Subscriber Count Report</div>
                </div>
              </a>
            </div>
  
            <div class="col-6 col-md-3">
              <a [routerLink]="['/reports/custom-reports/report-channel-detailed-count']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">custom</label>
                  <div class="card__txt">Channel Detailed Count Report</div>
                </div>
              </a>
            </div>
           
          </div>
        </div>

      </div>
    </div>
  </div>
  