import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateLcoComponent } from './create-lco/create-lco.component';
import { ListLcoComponent } from './list-lco/list-lco.component';
import { LcoSetBouquetComponent } from './lco-set-bouquet/lco-set-bouquet.component';

const routes: Routes = [
  { path: '', redirectTo: 'list' },
  { path: 'list', component: ListLcoComponent },
  { path: ':id', component: CreateLcoComponent },
  { path: 'create', component: CreateLcoComponent },
  { path: 'selfcreate-:id', component: CreateLcoComponent },
  { path: 'set-bouquet/:id' , component:LcoSetBouquetComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LcoRoutingModule { }
