<div class="cms__content">
  <div class="top__heading">
    <h3>Subscriber Groups</h3>
    <a [routerLink]="['/manage/subscriber-groups/create']"
      ><button class="default__button float-right"  *ngIf="menuAccess">+ Add New</button></a
    >
  </div>
  <div class="clearfix"></div>

  <!-- <div class="col-12 list__summary">
        <div class="row">
            <div class="col-6 col-md-3">
                <div class="list__card">
                    <span class="list__heading">Channels</span>
                    <span class="list__item">{{groupList.length}}</span>
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div class="list__card">
                    <span class="list__heading">Active Channels</span>
                    <span class="list__item">{{activeChannelCount}}</span>
                </div>
            </div>
        </div>
    </div> -->

  <div class="col-12 list__summary">
    <div class="row">
      <div class="col-6 col-md-3 list-sum-card">
        <div class="list__card">
          <span class="list__heading">Total Groups</span>
          <span class="list__item">{{ subscriberGroupCount }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row">
      <div class="custom__table__filter">
        <span>
          Show
          <select
            [(ngModel)]="paginationObj.pageSize"
            (change)="pageSizeChange()"
          >
            <option
              *ngFor="let size of paginationObj.pageSizeOptions"
              [ngValue]="size"
            >
              {{ size }}
            </option>
          </select>
          Entries
        </span>
        <div class="table__right">
          <p class="table__summary">
            Total <span>{{ paginationObj.totalElements }}</span> records
          </p>
          <div class="search__container">
            <input
              type="text"
              class="custom__field"
              [(ngModel)]="paginationObj.searchKeyword"
              (keydown.enter)="onSearch()"
            />
            <button (click)="onSearch()" (keydown.enter)="onSearch()">
              <img src="./assets/search.png" alt="search" />
            </button>
          </div>
        </div>
      </div>

      <div class="scroll-box">
        <table class="custom__iptv__table">
          <thead>
            <tr>
              <th>Sl No</th>
              <th>ID</th>
              <th
                (click)="sortByItem(sortByProperties.name)"
                [ngClass]="{
                  selected: paginationObj.sortBy === sortByProperties.name
                }"
              >
                Name
              </th>
              <th
                (click)="sortByItem(sortByProperties.description)"
                [ngClass]="{
                  selected: paginationObj.sortBy === sortByProperties.description
                }"
              >
                Description
              </th>
              <!-- <th (click)="sortByItem(sortByProperties.groupId)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.groupId}">
                Subscriber Count
              </th> -->
              <!-- <th> Partner Name </th> -->
              <th
                (click)="sortByItem(sortByProperties.date)"
                [ngClass]="{
                  selected: paginationObj.sortBy === sortByProperties.date
                }"
              >
                Created On
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="loadingFlag; else showList">
              <td class="loading" colspan="6">
                <app-custom-spinner></app-custom-spinner>
              </td>
            </tr>

            <ng-template #showList>
              <tr *ngFor="let group of groupList; let j = index">
                <td>{{((pageNumber-1)*pageSize)+(j+1)}}</td>
                <td>{{ group.id ? group.id : "---" }}</td>
                <td>{{ group.name ? group.name : "---" }}</td>
                <td>{{ group.description ? group.description : "---" }}</td>
                <!-- <td>{{group.length}}</td> -->
                <!-- <td>{{group.lcoId? group.lcoId : '---'}}</td> -->
                <td>
                  {{ group.createdDate | date : "dd-MM-yy HH:mm:ss" }}
                  <div class="action__items">
                    <button (click)="edit(group)"  *ngIf="menuAccess">Edit</button>
                  </div>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>

      <div class="custom__pagination">
        <ul>
          <li>
            <button
              [disabled]="loadingFlag || disableFirstBtn"
              (click)="pageFirstBtnClick()"
            >
              <img src="./assets/firstPage.png" />
            </button>
          </li>
          <li>
            <button
              [disabled]="loadingFlag || disablePreviousBtn"
              (click)="pagePreviousBtnClick()"
            >
              <img src="./assets/previousPage.png" />
            </button>
          </li>
          <li>
            <span
              >Page {{ paginationObj.pageNumber }} of
              {{ paginationObj.totalPages }}</span
            >
          </li>
          <li>
            <button
              [disabled]="loadingFlag || disableNextBtn"
              (click)="pageNextBtnClick()"
            >
              <img src="./assets/nextPage.png" />
            </button>
          </li>
          <li>
            <button
              [disabled]="loadingFlag || disableLastBtn"
              (click)="pageLastBtnClick()"
            >
              <img src="./assets/lastPage.png" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
