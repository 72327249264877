import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StbInfo } from 'src/app/models/stb/stb-info';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';
import {
  PaginationObj,
  PaginationUtilities,
} from 'src/app/models/pagination/pagination-info';
import {
  PageInfo,
  PageMetadata,
  SortOrder,
} from 'src/app/models/pagination/page-info';
import { delay } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-list-stb',
  templateUrl: './list-stb.component.html',
  styleUrls: ['./list-stb.component.scss'],
})
export class ListSTBComponent implements OnInit {
  paginationObj = new PaginationObj();

  loadingFlag = false;

  showBlacklist = true;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  sortByProperties = {
    emac: 'ethernetMac',
    wmac: 'wifiMac',
    serial: 'serialNo',
    manuf: 'manufacturer',
    model: 'model',
    assigned: 'assignedFlag',
    activated: 'activationStatus',
    blacklisted: 'blacklistStatus',
    date: 'createdDate',
  };

  stbList: StbInfo[] = [];
  countBLSTB = 0;
  countACSTB = 0;
  countUASTB = 0;
  blacklistCount: number;
  Totalstbs: number;
  UnassignedCount: number;
  StbActiveCount: number;
  userType

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private auth: AuthService,
    private config: ConfigService
  ) { }
  isDistributor = false;
  user
  menus
  menuAccess = false

  ngOnInit(): void {
    this.fetchPage();
    this.fetchStbCount();
   
    if(this.config.json.userType == 'MSO'){
      this.user= true
    }
    else{
      this.user= false
    }
    this.menus=JSON.parse(atob(localStorage.getItem('menus')))
    this.checkMenuAccess()
    // this.isDistributor = this.auth.isDistributor();

  }
  checkMenuAccess(){
    for(let i of this.menus){
      if(i.name=='STB')
      {
        this.menuAccess=(i.access==0?false:true);
        console.log(this.menuAccess)
      }
    }
    console.log(this.menuAccess)
  }
  onSearch() {
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }

  fetchPage(): void {
    if (this.auth.isMSO()) {
      this.fetchmso();
    }
    if (this.auth.isDistributor()) {
      this.fetchDistributor();
    }
    if (this.auth.isLCO()) {
      this.fetchLco();
    }
    if (this.auth.isAffiliator()) {
      this.fetchAffiliator();
    }
  }

  fetchDistributor() {
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url = PaginationUtilities.urlBuilder(
      'stb/getSTBsByDistributorId',
      this.paginationObj
    );
    url += `&distributorId=${this.auth.getLcoId()}`;
    this.http.get(url).subscribe((res) => {
      this.handleFetchPage(res);
    });
  }
  fetchAffiliator() {
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url = PaginationUtilities.urlBuilder(
      'stb/getStbByAffiliatorId',
      this.paginationObj
    );
    url += `&affiliatorId=${this.auth.getLcoId()}`;
    this.http.get(url).subscribe((res) => {
      this.handleFetchPage(res);
    });
  }

  fetchmso(): void {
    // let url = PaginationUtilities.urlBuilderRdbms('stb/getSTBs', this.paginationObj);
    let url =
      this.config.json.apiEndpointRdbms +
      'stb/getSTBs/' +
      this.paginationObj.pageNumber +
      '?pageSize=' +
      this.paginationObj.pageSize +
      '&sortBy=' +
      this.paginationObj.sortBy +
      '&sortOrder=' +
      this.paginationObj.sortOrder +
      '&searchKeyword=' +
      this.paginationObj.searchKeyword;

    url = url + '&lcoId=1';
    this.loadingFlag = true;

    this.http
      .get(url)
      .pipe(delay(500))
      .subscribe((data: PageInfo<StbInfo>) => {
        this.loadingFlag = false;
        this.handleFetchPage(data);
      });
  }

  cardDetails = {
    fresh: '--',
    blackList: '--',
    assigned: '--',
    total: '--',
    ACTIVE: '--',
    unassigned: '--',
  };
  fetchStbCount() {
    let url;
    if (this.auth.isMSO()) {
      url = this.config.json.apiEndpointRdbms + 'stb/getstbCount';
    }
    if (this.auth.isLCO()) {
      url =
        this.config.json.apiEndpointRdbms +
        'stb/getstbCountByLco/' +
        this.auth.getLcoId();
    }
    if (this.auth.isDistributor()) {
      url =
        this.config.json.apiEndpointRdbms +
        'stb/getstbCountByDistributor/' +
        this.auth.getLcoId();
    }
    this.http.get(url).subscribe((res) => {
      let data = res.data;
      this.cardDetails = {
        fresh: data.fresh,
        blackList: data.blackList,
        assigned: data.assigned,
        total: data.total,
        ACTIVE: data.ACTIVE,
        unassigned: data.unassigned,
      };
    });
  }
  fetchLco(): void {
    // const url = PaginationUtilities.urlBuilderRdbms('stb/getSTBs', this.paginationObj);
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    const url = (
      PaginationUtilities.urlBuilder('stb/getSTBsByLCOId', this.paginationObj) +
      '&lcoId=' +
      this.auth.getLcoId()
    ).replace('createdDate', 'created_date');

    this.http.get(url).subscribe((data) => {
      this.loadingFlag = false;
      this.handleFetchPage(data);
    });
  }

  handleFetchPage(data: PageInfo<StbInfo>): void {
    this.stbList = data.data;
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
    this.countStb();
  }

  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
    this.paginationObj.pageNumber = metadata.pageNumber;
    this.paginationObj.pageSize = metadata.pageSize;
  }

  handlePaginationBtnDisableStatuses(): void {
    // Left section
    this.disableFirstBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;
    this.disablePreviousBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;

    // Right section
    this.disableNextBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
    this.disableLastBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder =
      this.paginationObj.sortOrder === SortOrder.DESC
        ? SortOrder.ASC
        : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record: StbInfo): void {
    this.router.navigate(['/manage/stb', record.stbId]);
  }

  updateStatus(record: StbInfo): void {
    record.activationStatus
      ? this.deactivateStb(record)
      : this.activateStb(record);
  }

  activateStb(record: StbInfo): void {
    // const url = environment.apiEndpoint + 'stb/activateStb/' + record.id;
    const url =
      this.config.json.apiEndpointRdbms + 'stb/activateSTB/' + record.stbId;

    this.http.put(url, {}).subscribe(() => {
      // record.activationStatus = true;
      record.activationStatus = 1;

      this.countStb();
      window.alert('Stb activated');
      this.fetchPage();
      this.fetchStbCount();
    });
  }

  deactivateStb(record: StbInfo): void {
    if (window.confirm("Are you sure do you want to deactivate stb " + record.ethernetMac + "?")) {
      const url =
        this.config.json.apiEndpointRdbms +
        'stb/deactivateSTB/' +
        (record as any).stbId;

      this.http.put(url, {}).subscribe(() => {
        // record.activationStatus = false;
        record.activationStatus = 0;

        this.countStb();
        window.alert('Stb deactivated');
      });
    }
  }

  // updateBlacklist(record: StbInfo): void {
  //   record.blacklistStatus ? this.unblacklistStb(record) : this.blacklistStb(record);
  // }

  updateBlacklist(record: StbInfo): void {
    if (window.confirm("Are you sure do you want to blacklist stb " + record.ethernetMac + "?")) {
      this.blacklistStb(record);
      this.showBlacklist = false;
    }
  }

  blacklistStb(record: StbInfo): void {
    const url =
      this.config.json.apiEndpointRdbms + 'stb/blacklistSTB/' + record.stbId;

    this.http.put(url, {}).subscribe(() => {
      // record.blacklistStatus = true;
      record.blacklistStatus = 1;

      this.countStb();
      window.alert('Stb blacklisted');
    });
  }

  // unblacklistStb(record: StbInfo): void {
  //   const url = environment.apiEndpointRdbms + 'stb/unblacklistSTB/' + record.id;

  //   this.http.put(url, {}).subscribe(() => {
  //     // record.blacklistStatus = false;
  //     record.blacklistStatus = 0;

  //     this.countStb();
  //     window.alert('Stb unblacklisted');
  //   });
  // }

  countStb(): void {
    this.countBLSTB = this.stbList.filter((stb) => stb.blacklistStatus).length;
    this.countACSTB = this.stbList.filter((stb) => stb.activationStatus).length;
    this.countUASTB = this.stbList.filter((stb) => !stb.assignedFlag).length;
  }
}
