import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ngxCsv } from 'ngx-csv';
import { delay } from 'rxjs/operators';
import { AdminInfo } from 'src/app/models/admin/admin-info';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { ReportTraiDataService } from 'src/app/report-trai/plugins/report-trai-data.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-list-lco',
  templateUrl: './list-lco.component.html',
  styleUrls: ['./list-lco.component.scss'],
})
export class ListLcoComponent implements OnInit {
  paginationObj = new PaginationObj();

  loadingFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  LcoActiveCount: number;
  TotalLcoCount: number;
  sortByProperties = {
    name: 'name',
    email: 'email',
    mobile: 'mobile',
    date: 'createdDate',
    status: 'status',
    cp: 'contactPerson',
    tp: 'transferPercent',
    distributorCode: 'distributorCode'
  };

  lcoList: LCOInfo[] = [];
  menus
  menuAccess = false

  activatedCount = 0;
  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService,
    private httpService: HttpClient,
    private auth: AuthService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private report: ReportTraiDataService,
  ) { }

  isDistributor = false;
  isAffiliator = false;

  ngOnInit(): void {
    this.fetchPage();
    this.fetchLcoCount();
    this.isDistributor = this.auth.isDistributor();
    this.isAffiliator = this.auth.isAffiliator();

    this.menus=JSON.parse(atob(localStorage.getItem('menus')))
    this.checkMenuAccess()
  }

  onSearch() {
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }

  checkMenuAccess(){
    for(let i of this.menus){
      if(i.name=='LCO')
      {
        this.menuAccess=(i.access==0?false:true);
        console.log(this.menuAccess)
      }
    }
    console.log(this.menuAccess)
  }

  SetBouquet(admin) {
    this.router.navigateByUrl('manage/lco/set-bouquet/' + admin.id)
  }

  LCODetails: LCOInfo;
  viewModal = false;
  viewLCODetails(lcoDetails: any) {
    let lcoId = lcoDetails.id,
      url = `${this.config.json.apiEndpoint}lco/lcoDetails/` + lcoId;
    this.http.get(url).subscribe({
      next: (res: any) => {
        this.LCODetails = res.data;
        this.viewModal = true;
      },
    });
  }

  fetchPage(): void {
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url;
    if (this.auth.isDistributor()) {
      url = PaginationUtilities.urlBuilder(
        'lco/getLCOsByDistributor',
        this.paginationObj
      );
      url += `&distributorId=${this.auth.getLcoId()}`;
    } 
    else if (this.auth.isAffiliator()){
      url = PaginationUtilities.urlBuilder(
        'lco/getLCOsByAffiliator',
        this.paginationObj
      );
      url += `&affiliatorId=${this.auth.getLcoId()}`;
    }
    else {
      url = PaginationUtilities.urlBuilder('lco/getLCOs', this.paginationObj);
    }
    this.loadingFlag = true;

    this.http
      .get(url)
      .pipe(delay(250))
      .subscribe((data: PageInfo<LCOInfo>) => {
        this.loadingFlag = false;
        this.handleFetchPage(data);
      });
  }
  fetchLcoCount() {
    let url = this.config.json.apiEndpointRdbms + 'mso/getLcoCount';
    if (this.auth.isDistributor()) {
      url = `${this.config.json.apiEndpointRdbms
        }mso/getLcoCountByDistributor/${this.auth.getLcoId()}`;
    }
    this.http.get(url).subscribe((res) => {
      // // console.log(res)
      this.LcoActiveCount = res.data.active;
      this.TotalLcoCount = res.data.count;
    });
  }
  
  handleFetchPage(data: PageInfo<LCOInfo>): void {
    this.lcoList = data.data;

    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
    this.countAdmin();
  }

  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
    this.pageSize = metadata.pageSize;
    this.pageNumber = metadata.pageNumber;
  }

  pageSize;
  pageNumber;
  handlePaginationBtnDisableStatuses(): void {
    // Left section
    this.disableFirstBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;
    this.disablePreviousBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;

    // Right section
    this.disableNextBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
    this.disableLastBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder =
      this.paginationObj.sortOrder === SortOrder.DESC
        ? SortOrder.ASC
        : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record: AdminInfo): void {
    this.router.navigate(['/manage/lco', record.id]);
  }

  countAdmin(): void {
    this.activatedCount = this.lcoList.filter((x) => x.status === 1).length;
    // //// console.log(this.lcoList)
  }

  updateStatus(record: AdminInfo): void {
    record.status === 1 ? this.deactivate(record) : this.activate(record);
  }

  deactivate(record: AdminInfo): void {
    const url =
      this.config.json.apiEndpointRdbms +
      'admin/deactivateAccount/' +
      record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 0;
      this.countAdmin();
      window.alert('Account deactivated');
    });
  }

  activate(record: AdminInfo): void {
    const url =
      this.config.json.apiEndpointRdbms + 'admin/activateAccount/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 1;
      this.countAdmin();
      window.alert('Account activated');
    });
  }

  login(userDetail) {
    let body = {
      username: userDetail.email,
      loggedIn: JSON.parse(localStorage.getItem('profileInfo') as string).email,
    };
    let openUrl =
      this.config.json.adminLogin + 'user/' + btoa(JSON.stringify(body));
    window.open(openUrl, '_blank');
  }
  
  pdfDown=false

  // downloadTableExcel(): void {
  // //   this.pdfDown=true
  // //   let name = 'LCO List ';
  //     this.doc.exportTableToExcelwithImage(
  //       this.reportTable,
  //       name,
  //       'lco_list' ,
  //       undefined,
  //       undefined,
  //     );
  // //     setTimeout(() => {
  // //       this.pdfDown = false
  // //     }, 3000);
  //   }
  async downloadTableExcel(): Promise<void> {
    const data = [];
    let i = 1;
    this.lcoList.forEach(line => {
      const excelRow = {
        'Sl No.': i,
        'LCO ID': line.id,
        'LCO Code': line.lcoCode,
        'Name': line.name,
        'Contact Person': line.contactPerson,
        'Distributor Code': line['distributorCode'],
        'Mobile': line.mobile,
        'Email': line.email,
        'Status': line.status === 0 ? 'Inactive' : 'Active',
        'createdDate':line.createdDate
      };
      data.push(excelRow);
      i++;
    });
    let blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let workbook: ExcelProper.Workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('My Sheet', {
      properties: {
        defaultColWidth: 30
      }
    });
    let ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    let ab = XLSX.utils.sheet_to_json(ws)
    let currentDate = new Date();
    var row = worksheet.getRow(1);
    row.height = 100, 20
 
      let row2 = worksheet.getRow(2)
      
        let row3 = worksheet.getRow(3)
        row3.getCell(1).value = "Report generated on :"
        row3.getCell(2).value = currentDate.getDate() + "-" + this.doc.monthNameCalculator(currentDate.getMonth()) + "-" + currentDate.getFullYear() + " [" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds() + "]"
        let row4 = worksheet.getRow(4)
        row4.getCell(1).value = 'Source type :'
        row4.getCell(2).value = "SMS"
      
    let a
    let nameRow = worksheet.addRow(['LCO List'])
    worksheet.mergeCells('A1:D1')
    a = (ab.length === 1) ? Object.keys(ab[0]) : Object.keys(ab[1])
    let headerRow = worksheet.addRow(a)
    nameRow.eachCell((cell, number) => {
      cell.font = { bold: true, size: 14 }
    })
    headerRow.eachCell((cell, number) => {
      cell.font = { bold: true }
    })
    for (let x1 of ab) {
      let x2 = Object.keys(x1);
      let temp = []
      a = x2
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }
    var myBase64Image = "/assets/logos/esto_header_1.png";
    const response = await fetch(myBase64Image); const buffer12 = await response.arrayBuffer();
    var imageId2 = workbook.addImage({
      buffer: buffer12,
      extension: 'png',
    });
    worksheet['!cols'] = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 40 }, { width: 20 }, { width: 20 }]
    worksheet.addImage(imageId2, 'A1:C1');
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: blobType });
      FileSaver.saveAs(blob, 'lco_list.xlsx');
    });
  
  }


  // downloadTablePdf(): void {
  //   if (
  //     this.lcoList == null ||
  //     this.lcoList == undefined ||
  //     this.lcoList?.length == 0
  //   ) {
  //     alert('No Data!');
  //     return;
  //   }
  //   const table = document.querySelector('#reportTable') as HTMLTableElement;

  // if (!table) {
  //   alert('Table not found!');
  //   return;
  // }

  // const lastColumnIndex = table.rows[0].cells.length - 1;

  // const rows = Array.from(table.rows);

  // rows.forEach(row => {
  //   row.cells[lastColumnIndex].style.display = 'none';
  // });

  // // Generate PDF
  // this.pdf.generatePdfHtml(
  //   '#reportTable',
  //   'lco_list',
  //   `Source type : SMS`
  // );

  // // Restore the last column
  // rows.forEach(row => {
  //   row.cells[lastColumnIndex].style.display = '';
  // });
  // }

 

//   downloadTablePdf(): void {
//   if (
//     this.lcoList == null ||
//     this.lcoList == undefined ||
//     this.lcoList?.length == 0
//   ) {
//     alert('No Data!');
//     return;
//   }

//   // Preprocess the data to update status
//   const processedLcoList = this.lcoList.map(item => ({
//     ...item,
//     status: item.status === 1 ? 'Active' : 'Inactive'
//   }));

//   // Update the DOM or data table with the processed data
//   // Assuming `#reportTable` is dynamically generated based on `lcoList`
//   this.updateTable(processedLcoList);

//   this.pdf.generatePdfHtml(
//     '#reportTable',
//     'lco_list',
//     `Source type : SMS`
//   );
// }

//

downloadTablePdf(): void {
  this.pdfDown=true
  if (
    this.lcoList == null ||
    this.lcoList == undefined ||
    this.lcoList?.length == 0
  ) {
    alert('No Data!');
    this.pdfDown = false
    return;
  }
  this.pdf.generatePdfHtml(
    '#reportTable',
    'lco_list',
    `Source type : SMS`
  );

  setTimeout(() => {
    this.pdfDown = false
  }, 3000);
}

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No.","LCO ID","LCO Code","Name","COntact Person","  Distributor Code","Mobile","Email"
      ,"Status",'createdDate']
    };
    let data = []
    let i = 1
    this.lcoList.forEach(line => {
      let csvLine = {
        slno : i,
        service_asset_id: line.id,
        resource_code: line.lcoCode,
        resource_name: line.name,
        channel_status: line.contactPerson,
        package_code: line['distributorCode'],
        bouquet_id: line.mobile,
        package_name: line.email,
        transaction_date: line.status === 0 ?'InActive':'Active',
        'createdDate':line.createdDate
      }
      data.push(csvLine);
    });
    new ngxCsv(data, 'lco_list', options);
}

}
