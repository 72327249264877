import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DistributorSearchType } from 'src/app/distributor/distributor-search/distributor-search.component';
import { LcoSearchType } from 'src/app/lco/lco-search/lco-search';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';


@Component({
  selector: 'app-create-lcm',
  templateUrl: './create-lcm.component.html',
  styleUrls: ['./create-lcm.component.scss']
})
export class CreateLcmComponent implements OnInit {
 
    @ViewChild('myInput') myInputVariable: ElementRef;
  
    constructor(
      private http: HttpClientWrapperService,
      private config: ConfigService,
      private route: Router
      ,private router:ActivatedRoute
    ) { }
    user
    prefix='https://admin.skypro.co.in/bucket/images/'
    openSearch = false
    positionSelected = false
    positionValue ='home-banner'
    message = null as string
    lcoId
    lcoSelected = false
    distributorSelected= false;
    distributorId
    searchText!: string
    isSearch = false
    lcoCode = new FormControl()
    lcoList
    adList
    dropdownList = [];
    selectedList = [];
    dropdownSettings: IDropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      textField: 'name',
    };
    paginationObj = new PaginationObj();
  
    ngOnInit(): void {
      if (this.config.json.userType == 'MSO') {
        this.user = true
      }
      else {
        this.user = false
      }
      this.getChannelList()
      this.getPathParams()
    }
  
    searchQuery
    selectedChannel=''

    
  isEdit = false
  lcoIdEdit
  getPathParams() {
    const id = this.router.snapshot.paramMap.get('id');
    this.lcoIdEdit = id
    console.log(this.lcoIdEdit)
    if (id != null || id != undefined) {
      this.isEdit = true;
      this.selectedList.push(this.lcoIdEdit)
      this.fetchLCMData(id);
    } else {
      this.isEdit = false;

    }
  }
  lcmData=[]

  fetchLCMData(id){
    let url = `${this.config.json.apiEndpoint}landingChannelSetting/lco?lcoId=` + id;
    this.http.get(url).subscribe(
      (res)=>{
        this.lcmData=res.data
        this.selectedChannel= this.lcmData['channelId']
      })
  }

    selectChannel(channel: any): void {
      this.selectedChannel = channel.channelId; 
    }

    persist(){
      let body={
        "lcoIds":this.selectedList,
        "channelId":+(this.selectedChannel)
      }
      let url=this.config.json.apiEndpoint+'landingChannelSetting/save'
      this.http.post(url,body).subscribe(
        (res)=>{
          if(res.statusCode==200){
            // this.router.name
            this.route.navigate(['/settings/landing-channel-management', 'list']);
          }else{
            alert(res.message)
          }
        }
      )
    }
  
    getSubscriberId(e: Event) {
      console.log(e)
      this.openSearch = false
      this.searchText = (e.target as HTMLInputElement).value
      if (this.searchText.length >= 3) {
        setTimeout(() => {
          this.openSearch = true
        }, 500);
      }
    }
  
  
    clearInput() {
      let input = document.getElementById('lcoCode') as HTMLInputElement
      input.value = ""
      this.searchText = "",
        this.openSearch = false
        this.distributorSelected = false;
        this.selectedList = [];
    }
  
    selectedPosition(e: Event) {
      this.positionSelected = true
      this.positionValue = (e.target as HTMLSelectElement).value
    }
  

    filteredChannelData=[]


  filteredChannels() {
    console.log(this.searchQuery)
    this.filteredChannelData =[]
    if(this.searchQuery==undefined||this.searchQuery==''||this.searchQuery==null){
      this.filteredChannelData=this.channelsData
    }else{
     this.channelsData.filter(channel =>{
      if(channel.channelName.toLowerCase().includes(this.searchQuery.toLowerCase())){
        this.filteredChannelData.push(channel)
      }
    }
    )};
  }
  
    getSearchText(e: Event) {
      this.isSearch = false
      this.searchText = (e.target as HTMLInputElement).value
      if (this.searchText.length >= 3) {
        setTimeout(() => {
          this.isSearch = true
        }, 300);
      }
    }
  
    getUser(e: DistributorSearchType) {
      let inputBox = document.getElementById('lcoCode') as HTMLInputElement;
      console.log(inputBox)
      if ((e as any) == '') {
        this.openSearch = false;
        this.searchText = undefined;
        inputBox.value = '';
        return;
      }
      inputBox.value = `${e.distributorCode}, ${e.name}`;
      this.distributorId = e.id
      this.openSearch = false;
      this.searchText = undefined;
      this.searchText = undefined;
      this.fetchLCO(this.distributorId)
    }
  
    getLCOId(e: LcoSearchType) {
      let inp = document.getElementById('lcoId') as HTMLInputElement
      inp.value = `${e.lcoName}: ${e.lcoCode}`
      this.lcoId = e.lcoId
      this.isSearch = false
    }
  selectedIdsString 
  imageURL=''
  channelsData=[]
  
  getChannelList() {
    const url =
      this.config.json.apiEndpointRdbms + 'channel/getchannelsWithLogo';
    this.http.get(url).subscribe({
      next: (res) => {
        this.channelsData=res.data
        this.filteredChannelData = res.data
      },
    });
  }
    
  
    fetchLCO(id) {
      PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
      let url;
  
      url = PaginationUtilities.urlBuilder(
        'lco/getLCOsByDistributor',
        this.paginationObj
      );
      url += `&distributorId=` + id;
      this.http.get(url).subscribe(
        (res) => {
          this.selectedIDsList=[]
          this.selectedList = []
          this.lcoList = res.data
          this.distributorSelected = true
          this.lcoList = this.lcoList.map((channel) => {
            return {
              id: channel.id,
              name: channel.name,
            };
          });
  
        }
      )
    }
    selectedIDsList = []
    onItemSelect(item: any) {
      console.log
      this.selectedList.push(item.id);
      console.log(this.selectedList);
    }
  
    onSelectAll(items: any) {
      this.selectedList = items.map((item: any) => item.id);
      console.log(this.selectedList);
    }
  
    onItemDeSelect(item: any) {
      this.selectedList = this.selectedList.filter(id => id !== item.id);
      console.log(this.selectedList);
    }
  
    onDeSelectAll() {
      this.selectedList = [];
      console.log(this.selectedList);
    }
  
  
   
  
  }
  