<div class="cms__content">
  <div class="top__heading">
    <h3>Self Registered Users</h3>
  </div>
  <div class="col-12 list__table">
    <div class="row overflow">
      <div class="custom__table__filter">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <div class="table__right">
          <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> records</p>
          <div class="search__container">
            <input type="text" autocomplete="off" class="custom__field" [(ngModel)]="paginationObj.searchKeyword"
              (keyup.enter)="$event.preventDefault(); onSearch()" />
            <button (click)="onSearch()"><img src="./assets/search.png" alt="search" /></button>
          </div>
        </div>
      </div>
      <div class="tableBox">
      <table class="custom__iptv__table">
        <thead>
          <tr>
            <th>
              Sl No.
            </th>
            <th class="label__align" (click)="sortByItem(sortByProperties.id)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.status}">
            ID
          </th>
          <th class="label__align" (click)="sortByItem(sortByProperties.userType)"
          [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.userType}">
          UserType
        </th>
            <th (click)="sortByItem(sortByProperties.name)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.name}">
              Name
            </th>

            <th (click)="sortByItem(sortByProperties.companyName)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.companyName}">
            Company Name
          </th>

            <th>Mobile</th>
            <th>Landline</th>

            <th (click)="sortByItem(sortByProperties.email)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.email}">
              Email
            </th>

            <th class="label__align" (click)="sortByItem(sortByProperties.status)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.status}">
             Approval Status
            </th>



            <th class="label__align" (click)="sortByItem(sortByProperties.status)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.status}">
              Address
            </th>
            <th>Amount</th>
            <th>Payment Status</th>
            <th> Created Date </th>
          </tr>
        </thead>

        <tbody>
          <tr *ngIf="loadingFlag; else showList">
              <td class="loading" colspan="9">
                <app-custom-spinner></app-custom-spinner>
              </td>
          </tr>

          <ng-template #showList>
            <tr *ngFor="let user of userTypeList, let j = index">
              <td>{{((pageNumber-1)*pageSize)+(j+1)}}</td>
              <td>{{user.id? user.id : '---'}}</td>
              <td>{{user.userType=="distributor"?"Distributor":
                user.userType=="lco"?"LCO":'---' }}</td>
              <td>{{user.contactPersonName? user.contactPersonName : '---'}}</td>
              <td>{{user.companyName}}</td>
              <td>{{user.mobileNumber? user.mobileNumber : '---'}}</td>
              <td>{{user.landlineNumber? user.landlineNumber : '---'}}</td>
              <td>{{user.email? user.email : '---'}}</td>
              <td>{{user.approvalStatus === 0 ? "Not Approved" : "Approved"}}</td>
              <td>{{user.address? user.address : '---' }}</td>
              <td>{{user.amount?(user.amount|currency:'INR'):(0|currency:'INR')}}</td>
              <td>{{user.paymentStatus=='1'?'Paid':'Not Paid'}}</td>
              <td>
                {{user.createdDate | date: 'dd-MM-yyyy HH:mm:ss'}}
                <div class="action__items">
                  <button (click)="processPayment(user)" *ngIf="user.approvalStatus === 0 && menuAccess">Approve</button>
                  <!-- <button (click)="processPayment(user)" *ngIf="user.approvalStatus === 0 && menuAccess && user.paymentreqStatus!='success'">Process Payment</button> -->
                  <button (click)="view(user)" *ngIf="user.approvalStatus === 1">View</button>
                  <!-- <button (click)="view(user)" *ngIf="user.approvalStatus === 0 && user.paymentreqStatus!='success'">View</button> -->
                </div>
              </td>

            </tr>
          </ng-template>
        </tbody>
      </table>
      </div>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>

<div class="modal_box" *ngIf="viewModal">
  <div class="modal_box_inner">
    <h3>{{LCODetails.userType=="distributor"?"Distributor":"LCO"}} details</h3>
    <div class="parent">
      <p class="div1">  Name</p>
      <p class="div2">{{LCODetails.contactPersonName}}</p>
      <p class="div3"> Mobile Number</p>
      <p class="div4">{{LCODetails.mobileNumber}}</p>
      <p class="div5"> Aadhar Number</p>
      <p class="div6">{{LCODetails.aadharNumber}}</p>
      <p class="div18"style="text-align:start" >GST Number</p>
      <p class="div19">{{LCODetails.gstNumber? LCODetails.gstNumber:'No GST'}}</p>
      <p class="div7" style="text-align:start">{{LCODetails.userType=="distributor"?"Distributor":"LCO"}} Pan Number</p>
      <p class="div8">{{LCODetails.panNumber}}</p>
      <p class="div40" style="text-align:start">{{LCODetails.userType=="distributor"?"Distributor":"LCO"}} Wallet Recharge Amount</p>
      <p class="div41">{{LCODetails.walletRechargeAmount?(LCODetails.walletRechargeAmount|currency:'INR'):(0|currency:'INR')}}</p>
      <p class="div42" style="text-align:start">{{LCODetails.userType=="distributor"?"Distributor":"LCO"}} One Time Amount</p>
      <p class="div43">{{LCODetails.oneTimePayment?(LCODetails.oneTimePayment|currency:'INR'):(0|currency:'INR')}}</p>
      <!-- <div class="div13">
        <h6>Subscriber Document</h6>
      </div> -->
      <div>
        <div class="div9">

          <img class="img" onerror="this.src='assets/ImagewithoutLogo.png'"
            [src]="config.json.imagPrefix+LCODetails.aadharUrl" alt="Image not Uploaded"   (click)="openImageInNewTab(config.json.imagPrefix+LCODetails.aadharUrl)">
          <div class="hover"
            (click)="openImageInNewTab(config.json.imagPrefix+LCODetails.aadharUrl)"></div>
        </div>
        <p class="div14">Aadhar Card</p>
      </div>
      <div>
        <div class="div10">
          <!-- <ng-container *ngIf="isImageFile(LCODetails.panUrl); else downloadTemplate"> -->
          <img class="img" onerror="this.src='assets/ImagewithoutLogo.png'"
            [src]="config.json.imagPrefix+LCODetails.panUrl" alt="Image not Uploaded" (click)="openImageInNewTab(config.json.imagPrefix+LCODetails.panUrl)">
          <div class="hover"
            (click)="openImageInNewTab(config.json.imagPrefix+LCODetails.panUrl)"></div>
        </div>
        <!-- </ng-container> -->
        <p class="div15">Pan Card</p>
      </div>
      <div>
        <div class="div11">
          <img class="img" onerror="this.src='assets/ImagewithoutLogo.png'"
            [src]="config.json.imagPrefix+LCODetails.photoUrl" alt="Image not Uploaded" >
          <div class="hover"
            (click)="openImageInNewTab(config.json.imagPrefix+LCODetails.photoUrl)"></div>
        </div>
        <p class="div16">Photo</p>
      </div>
      <div>
        <div class="div12" *ngIf="LCODetails.gstUrl!=''">
          <img class="img" onerror="this.src='assets/ImagewithoutLogo.png'" 
            [src]="config.json.imagPrefix+LCODetails.gstUrl" alt="Image not Uploaded"  (click)="openImageInNewTab(config.json.imagPrefix+LCODetails.gstUrl)">
          <div class="hover"
            (click)="openImageInNewTab(config.json.imagPrefix+LCODetails.gstUrl)"></div>
        </div>

        <div class="div12" *ngIf="LCODetails.gstUrl==''">
          <img class="img" onerror="this.src='assets/ImagewithoutLogo.png'" 
            [src]="config.json.imagPrefix+LCODetails.declarationUrl" alt="Image not Uploaded"  (click)="openImageInNewTab(config.json.imagPrefix+LCODetails.declarationUrl)">
          <div class="hover"
            (click)="openImageInNewTab(config.json.imagPrefix+LCODetails.declarationUrl)"></div>
        </div>
        <p class="div17">GST</p>
      </div>
      <ng-container *ngIf="LCODetails.userType=='distributor'">
      <div class="div21"><h6><b>Network Details</b></h6></div>
      <div class="div22">Network Exchange Name</div>
      <div class="div23">{{LCODetails.networkExchangeName}}</div>
      <div class="div24">Network Exchange Location</div>
      <div class="div25">{{LCODetails.networkExchangeLocation}}</div>
      <div class="div26">Datacenter Name</div>
      <div class="div27">{{LCODetails.dataCenterName}}</div>
      <div class="div28">Datacenter Location</div>
      <div class="div29">{{LCODetails.dataCenterAddress}}</div>
      <div class="div30">IpV4</div>
      <div class="div31">{{LCODetails.ipAddress}}</div>
      <div class="div32">IPV6 </div>
      <div class="div33">{{LCODetails.dataCenterName}}</div>
      <div class="div34">IP Address </div>
      <div class="div35">{{LCODetails.ipPool}}</div>
      <div class="div36">IP Pool </div>
      <div class="div37">{{LCODetails.ipPool}}</div>
    </ng-container>
    </div>
    
    <button class="float-left" *ngIf="LCODetails.approvalStatus === 0 && LCODetails.paymentStatus==1" (click)="viewModal = !viewModal"> Close </button>
    <button class="cta float-right" *ngIf="LCODetails.approvalStatus === 0 && LCODetails.paymentStatus==1" (click)="approve(LCODetails)"> Continue </button>
    <button class="cta float-right" *ngIf="LCODetails.approvalStatus === 1 ||LCODetails.paymentStatus!==1 " (click)="viewModal = !viewModal"> Close </button>
  </div>
</div>

<div class="modal__box_i" *ngIf="viewPaymentModal">
  <div class="modal_box_inner" style="width:450px;">
    <h3>Payment Details</h3>
    <div class="parent">
      <p class="div1">Name</p>
      <p class="div2">{{userDetails.companyName}}</p>
      <p class="div3"> One Time Payment</p>
      <p class="div4">
          <input type="number" autocomplete="off" [(ngModel)]="onetimePayment" id="searchKeyword" class="custom__field" *ngIf="isEdit" readonly
          />
          <input type="number" min="0" autocomplete="off" [(ngModel)]="onetimePayment" id="searchKeyword" class="custom__field" *ngIf="!isEdit" (input)="addAmount()" (keydown)="preventMinus($event)"
          />
      </p>
      <p class="div5">Wallet Recharge Amount</p>
      <p class="div6">
      <input type="number" autocomplete="off" [(ngModel)]="walletPayment" id="searchKeyword" class="custom__field" *ngIf="isEdit" readonly
       />
       <input type="number" min="0" autocomplete="off" [(ngModel)]="walletPayment" id="searchKeyword" class="custom__field" *ngIf="!isEdit" (input)="addAmount()" (keydown)="preventMinus($event)"
       />
      </p>

       <p class="div7">Amount</p>
       <p class="div8">
       {{amount|currency:'INR'}}
      </p>
      <p class="div9">Transaction ID</p>
       <p class="div10">
       {{userDetails.transactionId?userDetails.transactionId:'---'}}
      </p>
      <p class="div11">Payment Status</p>
      <p class="div12 label__align">
        <span [ngClass]="{
          safe__label: payStatus=='success',
          danger__label: payStatus!='success'
        }">
        {{ payStatus!='success' ? "X" : "&#10003;"}}
      </span>
     </p>
      <!-- <p class="div4" > -->
      <!-- <div class="div4  table__right search__container" *ngIf="searchTextSelected">
        <input type="text" autocomplete="off" [(ngModel)]="searchText" id="searchKeyword" class="custom__field" maxlength="10"
        (keyup.enter)="fetchSearchPage()" *ngIf="searchType=='mobileNumber'" minlength="10"/>
          <input type="text" autocomplete="off" [(ngModel)]="searchText" id="searchKeyword" class="custom__field" 
          (keyup.enter)="fetchSearchPage()" *ngIf="searchType!='mobileNumber'" />
        <button><img src="./assets/search.png" alt="search" (click)="fetchSearchPage()" /></button>
      </div> -->
  </div>
  <button class=" float-left" (click)="viewPaymentModal = !viewPaymentModal;onetimePayment=0;walletPayment=0"> Close </button>
  <button class="cta float-right" [disabled]="walletPayment==0 || onetimePayment==0||onetimePayment==null||walletPayment==null " *ngIf="!isEdit && payStatus!='success'"  (click)="processPaymentandContinue()"> Send Payment Request </button>
  <button class="cta float-right" (click)="checkPaymentStatus(userDetails)" *ngIf="isEdit && payStatus!='success'">Check Payment Status</button>
  <button class="cta float-right" (click)="view(userDetails)" *ngIf="payStatus=='success'">Continue</button>
</div>
</div>