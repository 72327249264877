import { DashboardInfo } from './../../models/dashboard/dashboard-info';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { FormGroup, Validators, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { PathNames } from 'src/app/constants/path-names';
import { forkJoin, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AclGroupInfo, AclMenus } from 'src/app/models/acl-group/acl-group-info';
import { ConfigService } from 'src/app/services/config.service';
@Component({
  selector: 'app-acl-create',
  templateUrl: './acl-create.component.html',
  styleUrls: ['./acl-create.component.scss']
})
export class AclCreateComponent implements OnInit {

  groupForm: FormGroup;
  isEdit = false;
  group = {
    name: "",
    description: "",
    userType:"",
    menus: {},
  }

  continueClicked = false
  nextClicked

  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private config: ConfigService
  ) { }

  testParam = ""

  accordionMapping = {}
  menuData = []
  menuAccordion = {}
  menuTree

  ngOnInit(): void {
    this.initParams();
    this.initForm();
    // //// console.log("Accordion MAP: ",this.accordionMapping)
    // //// console.log("MENU Accordion: ", this.menuAccordion)
  }

  getMenus(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'menu/getMenus';
    return this.http.get(url);
  }

  initParams() {
    this.getPathParam();
  }

  updateParentCheckStatus(menu: any) {
    menu.checked = menu.subMenu.some((submenu: any) => submenu.checked);
  }

  updateSubmenuCheckStatus(menu: any) {
    menu.subMenu.forEach((submenu: any) => {
      submenu.checked = menu.checked;
      submenu.access=false;
    });
  }

  menus = []

  checkandContinue() {
    this.continueClicked = true;
    for (let i of this.menuTree) {
      if (i.checked == true) {
        this.menus.push(i)
      }
      for (let j of i.subMenu) {
        if (j.checked == true) {
          this.menus.push(j)
        }
      }
      i.subMenu = []
    }
    this.persist()
  }

  updateFlags(param) {
    const idList = this.accordionMapping[param]
    let count = 0
    let flagTrue = 0

    for (const id of idList) {
      count += 1
      flagTrue += (this.menuAccordion[id] ? 1 : 0)
    }
    // //// console.log(flagTrue, count, param)
    if (flagTrue > 0) this.menuAccordion[param] = true
    else this.menuAccordion[param] = false
  }

  updateAccordion(param) {
    const idList = this.accordionMapping[param]
    const flagUpdate = this.menuAccordion[param]
    // //// console.log(this.menuAccordion, idList, this.accordionMapping)
    for (const id of idList) {
      this.menuAccordion[id] = flagUpdate
    }
  }

  initForm(): void {
    this.groupForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      userType: new FormControl('', Validators.required),
    });
  }

  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== PathNames.create) {
      this.isEdit = true;
      this.updateFlow(Number(id));
    } else {
      this.createFlow();
    }
  }

  createFlow() {
    this.getMenus().subscribe((data) => {
      this.menuData = data
      for (let i of this.menuData) {
        i.checked = false
        i.access = false
      }
      const menuDict = {};
      this.menuData.forEach(item => {
        menuDict[item.id] = { ...item, subMenu: [] };
      });

      this.menuTree = this.menuData.filter(item => item.parentId === 0)
        .map(item => {
          const buildSubMenu = (parentId) => {
            return this.menuData.filter(subItem => subItem.parentId === parentId)
              .map(subItem => ({
                ...menuDict[subItem.id],
                subMenu: buildSubMenu(subItem.id)
              }));
          };
          return {
            ...menuDict[item.id],
            subMenu: buildSubMenu(item.id)
          };
        });
      console.log(this.menuTree);
    })
  }

  getAclGroupById(id: number): Observable<AclGroupInfo> {
    const url = this.config.json.apiEndpointRdbms + 'aclgroup/' + id;
    return this.http.get(url);
  }

  updateForm(): void {
    this.groupForm.get('name').setValue(this.group.name);
    this.groupForm.get('description').setValue(this.group.description);
    this.groupForm.get('userType').setValue(this.group.userType);
    // this.group.menus.map((each) => {
    //   this.menuAccordion[each.id] = true;
    // })
  }

  updateFlow(id: number): void {
    forkJoin([this.getAclGroupById(id)]).subscribe(data => {
      this.group = data[0]['data'];
      console.log(this.group)
      this.updateForm();
      this.updateMenus()
    });
  }


  updateMenus() {
    this.getMenus().subscribe((data) => {
      this.menuData = data
      for (let i of this.menuData) {
        let found = false;
        for(let j in this.group.menus)
        {
          if(i.id==j)
          {
            found = true;
            i.checked = true
            console.log(this.group.menus[j])
            if(this.group.menus[j]['access']==0){
              i.access = false
            }else{
              i.access = true
            }
          }
        }
        if(found == false){
          i.checked = false
          i.access = false
        }
      }
      const menuDict = {};
      this.menuData.forEach(item => {
        menuDict[item.id] = { ...item, subMenu: [] };
      });

      this.menuTree = this.menuData.filter(item => item.parentId === 0)
        .map(item => {
          const buildSubMenu = (parentId) => {
            return this.menuData.filter(subItem => subItem.parentId === parentId)
              .map(subItem => ({
                ...menuDict[subItem.id],
                subMenu: buildSubMenu(subItem.id)
              }));
          };
          return {
            ...menuDict[item.id],
            subMenu: buildSubMenu(item.id)
          };
        });
      console.log(this.menuTree);
    })
  }

  get name(): FormControl {
    return this.groupForm.get('name') as FormControl;
  }

  get userType(): FormControl {
    return this.groupForm.get('userType') as FormControl;
  }

  checkAccess(submenu) {
    if (submenu.checked == true) {
      submenu.access = false;
    }
  }

  persist(): void {
    this.updateModel();
    this.setGroup()
    this.isEdit ? this.update() : this.save();
  }


  setGroup() {
    let obj = {}
    for (let i of this.menus) {
      if (i.access == false) {
        i.access = 0
      }
      else if (i.access == true) {
        i.access = 1
      }
    }
    for (let j of this.menus) {
      obj[j.id] = { access: j.access };
    }
    this.group.menus = obj;
  }

  save(): void {
    const url = this.config.json.apiEndpointRdbms + 'aclgroup';
    console.log(this.group)
    this.http.post(url, this.group).subscribe(data => {
      if (data.statusCode == 200) {
        this.router.navigate(['/settings/access-control', 'list'])
      }
      else {
        this.updateMenus();
        alert(data.message)
      
      }
    }, error => {
      window.alert(error.error["message"])
    });
  }

  update(): void {
    const url = this.config.json.apiEndpointRdbms + 'aclgroup';
    this.http.put(url, this.group).subscribe(data => {
      if(data.statusCode==200){
        this.router.navigate(['/settings/access-control', 'list']);
      }
      else{
        this.updateMenus();
        alert(data.message)
      }
    },
      error => {
        window.alert(error.error["message"])
      });
  }

  updateModel(): void {
    this.group.name = this.groupForm.get('name').value;
    this.group.description = this.groupForm.get('description').value;
    this.group.userType = this.groupForm.get('userType').value;
    // //// console.log(this.menuAccordion, this.group.menus)

    // for(let each in this.menuAccordion){
    //   if(this.menuAccordion[each]) {
    //     // //// console.log(this.menuAccordion[each], each)
    //     this.group.menus.push({"id":each} as AclMenus)
    //   }
    // }
    // //// console.log(this.menuAccordion, this.group.menus)


  }

}
