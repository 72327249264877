import { AclGroupInfo } from 'src/app/models/acl-group/acl-group-info';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PathNames } from 'src/app/constants/path-names';
import { LocationInfo } from 'src/app/models/location/location-info';
import { PackageInfo } from 'src/app/models/package/package-info';
import { PaginationObj } from 'src/app/models/pagination/pagination-info';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { StoreService } from 'src/app/services/store.service';
import { environment } from 'src/environments/environment';
import { MSOInfo } from 'src/app/models/mso/mso-info';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-create-mso',
  templateUrl: './create-mso.component.html',
  styleUrls: ['./create-mso.component.scss']
})
export class CreateMsoComponent implements OnInit {
  passwordStringMatch(): boolean {
    var myRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;
    if(myRegex.test(this.msoForm.get('password').value)){
      this.msoForm.get('password').setErrors(null)
      return true
    }else{
      this.msoForm.get('password').setErrors({pattern : false})
      return false
    }
  }

  mso = {} as MSOInfo;

  msoForm: FormGroup;

  isEdit = false;

  showPassword = false;
  showConfirmPassword = false;
  // phonePattern = '^[+][9][1][6-9][0-9]{9}$';
  phonePattern = "^((\\+91-?)|0)?[0-9]{10}$"
  passwordRegex = '^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$'

  locationList: LocationInfo[] = [];

  paginationObjPackage = new PaginationObj();

  loadingFlagPackage = false;

  disableFirstBtnPackage = false;
  disableLastBtnPackage = false;
  disableNextBtnPackage = false;
  disablePreviousBtnPackage = false;

  sortByPropertiesPackage = {
    name: 'name',
    desc: 'description',
    date: 'createdDate',
    status: 'status',
  };

  dropdownList = [];
  selectedList = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    textField: 'name',
  };

  AclGroupList: AclGroupInfo[] = []

  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private store: StoreService,
    private router: Router,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.getPathParam();
    this.initForm();
  }

  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== PathNames.create) {
      this.isEdit = true;
      this.fetchRecord(id);
      this.updateFlow(id);
    } else {
      this.createFlow();
    }
  }

  isNumError = false
  numValidation(e: Event){
    let input = (e.target as HTMLInputElement).value;
    let phoneRegex: RegExp = /^[6-9]\d{9}$/;
    if(input == ''){
      return
    }
    if(phoneRegex.test(input)){
      this.isNumError = false
      this.msoForm.get('mobile').setErrors(null)
    }else{
      this.msoForm.get('mobile').setErrors({pattern: true})
      this.isNumError = true
    }
  }

  createFlow(): void {
    this.fetchCreateData();
  }

  updateFlow(id: string): void {
    this.isEdit = true;
    this.fetchUpdateData(id);
  }

  fetchRecord(id: string): Observable<MSOInfo> {
    const url = this.config.json.apiEndpointRdbms + 'mso/getMSO/' + id;
    return this.http.get(url);
  }

  fetchLocationList(): Observable<LocationInfo[]> {
    const url = this.config.json.apiEndpointRdbms + 'location/getLocations';
    return this.http.get(url);
  }

  fetchAclList(): Observable<AclGroupInfo[]> {
    const url = this.config.json.apiEndpointRdbms + 'aclgroup/list?userType=mso';
    return this.http.get(url);
  }

  fetchCreateData(): void {
    forkJoin([
      this.fetchAclList()
    ]).subscribe(data => {
      this.AclGroupList = data[0]['data'];
    });
  }

  fetchUpdateData(id: string): void {
    forkJoin([
      this.fetchRecord(id),
      this.fetchAclList()
    ]).subscribe(data => {
      this.mso = data[0];
      this.AclGroupList = data[1]['data'];
      this.updateForm();
    });
  }



  getAclList(): void {
    this.fetchAclList().subscribe(data => {
      this.AclGroupList = data;
      this.formatAclList();
    });
  }

  formatAclList(): void {
    this.dropdownList = this.AclGroupList.map(grp => {
      return {
        id: grp.id,
        name: grp.name
      };
    });

    // this.selectedList = this.category.channels ? this.category.channels.map(channel => {
    //   return {
    //     id: channel.id,
    //     name: channel.name
    //   };
    // }) : [];
  }

  initForm(): void {
    this.msoForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
      }),
      mobile: new FormControl('', {
        validators: [Validators.required, Validators.pattern(this.phonePattern)],
      }),
      landline: new FormControl('', {
        validators: [Validators.required],
      }),
      password: new FormControl(''),
      confirmPassword: new FormControl(''),
      status: new FormControl(1, [Validators.required]),
      aclGroupId: new FormControl(''),
      address: new FormControl('')
    });

    this.conditionalValidators();
  }

  get email(): FormControl {
    console.log(this.msoForm);

    return this.msoForm.get('email') as FormControl;
  }

  get mobile(): FormControl {
    return this.msoForm.get('mobile') as FormControl;
  }

  get landline(): FormControl {
    return this.msoForm.get('landline') as FormControl;
  }

  get password(): FormControl {
    this.passwordStringMatch()
    return this.msoForm.get('password') as FormControl;
  }

  get cpassword(): FormControl {
    return this.msoForm.get('confirmPassword') as FormControl;
  }


  conditionalValidators(): void {

    if (!this.isEdit) {
      this.msoForm.get('password').setValidators(Validators.required);
      this.msoForm.get('password').setValidators(Validators.minLength(8));
      // this.msoForm.get('password').setValidators(Validators.pattern(this.passwordRegex));
      this.msoForm.get('confirmPassword').setValidators(Validators.required);
      this.msoForm.setValidators(this.confirmPasswordValidator);
    }
  }

  updateForm(): void {
    this.msoForm.get('name').setValue(this.mso.name);
    this.msoForm.get('email').setValue(this.mso.email);
    this.msoForm.get('mobile').setValue(this.mso.mobile);
    this.msoForm.get('landline').setValue(this.mso.landline);
    this.msoForm.get('address').setValue(this.mso.address);
    this.msoForm.get('status').setValue(this.mso.status);
    this.msoForm.get('aclGroupId').setValue(this.mso.aclGroupId);

  }

  persist(): void {

    this.updateModel()
    this.isEdit ? this.update() : this.save();
  }

  style = {
    position: 'relative'
  }

  save(): void {
    const url = this.config.json.apiEndpointRdbms + 'mso/createMSO';

    this.http.post(url, this.mso).subscribe(data => {

      if (data.statusCode == 200) {
        this.router.navigate(['/manage/mso', 'list']);

      }
      else {
        alert(data.message)
      }

    },
      (error) => {
        window.alert(error.error["message"])
      }
    );
  }

  update(): void {

    const url = this.config.json.apiEndpointRdbms + 'mso/updateMSO';
    const body = {
      "id": this.mso.id,
      "name": this.mso.name,
      "email": this.mso.email
    }

    this.http.put(url, this.mso).subscribe(data => {
      // if(data.statusCode==200){
        this.router.navigate(['/manage/mso', 'list']);
        // }
        // else
        // {
        // window.alert(data.message)
        // }
      
      
    },
      (error) => {
        window.alert(error.error["message"])
      }
    );
  }

  updateModel(): void {
    this.mso.name = this.msoForm.get('name').value;
    this.mso.email = (this.msoForm.get('email').value as string).toLowerCase();
    this.mso.mobile = this.msoForm.get('mobile').value;
    this.mso.landline = this.msoForm.get('landline').value;
    this.mso.address = this.msoForm.get('address').value;
    this.mso.status = this.msoForm.get('status').value;
    this.mso.aclGroupId = this.msoForm.get('aclGroupId').value;

    if (!this.isEdit) {
      this.mso.password = this.msoForm.get('password').value;
    }
  }

  submit(): void {
    this.persist();
  }


  confirmPasswordValidator(group: AbstractControl): ValidationErrors | null {
    if (group.get('confirmPassword').value !== group.get('password').value) {
      return { mismatch: true };
    }
  }

  // formatChannelList(): void {
  //   this.dropdownList = this.channelList.map(channel => {
  //     return {
  //       id: channel.id,
  //       name: channel.name
  //     };
  //   });

  //   this.selectedList = this.category.channels ? this.category.channels.map(channel => {
  //     return {
  //       id: channel.id,
  //       name: channel.name
  //     };
  //   }) : [];
  // }

  ///////////////////////////////////////////////////////////////////////////////////////

  fetchPackageListObs(): Observable<PackageInfo[]> {
    const url = this.config.json.apiEndpointRdbms + 'bouquet/getBouquets';
    return this.http.get(url);
  }

}
