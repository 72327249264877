import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv';

@Component({
  selector: 'app-report-boquet-reconciliation-count',
  templateUrl: './report-boquet-reconciliation-count.component.html',
  styleUrls: ['./report-boquet-reconciliation-count.component.scss']
})
export class ReportBoquetReconciliationCountComponent implements OnInit {
  reportList = [];
  isLoad = 'No Data';
  isLoading = false;
  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config: ConfigService,
    private http: HttpClientWrapperService) { }

  ngOnInit(): void {
    this.fetchData();
  }

  viewModal = false
  reportType = ''
  yearValue = ''
  dateType
  yearsArray = []
  showMonth = false
  showDateRange = false
  dateSelector = true
  tableDisplay = true

  downloadHistoricReport() {
    this.viewModal = true
    this.yearsArrayCalculator()

  }

  yearFilter() {
    this.yearValue = (document.getElementById('yearCalc') as HTMLSelectElement).value;
    this.yearValue=this.yearValue+'-01-01';
    this.dateType = 'year'
  }

  MonthFilter(e) {
    this.yearValue=''
    this.yearValue += e.target.value + '-01'
    console.log(this.yearValue)
    this.dateType = 'month'
  }

  yearsArrayCalculator() {
    if (this.yearsArray.length == 0) {
      let currentYear = new Date().getFullYear();
      for (let i = currentYear; i >= 2019; i--) {
        this.yearsArray.push(i);
      }
    }
  }
 
  generateReport() {
    let url = this.config.json.apiEndpoint + 'reports/generateHistoricalReportForReconcilation?value=' +
      this.yearValue + '&type=' + this.dateType + '&reportName=Bouquet_reconcilation_report' 
    this.http.get(url).subscribe(
      (res) => {
        if (res.statusCode == 200) {
          const fileUrl = res.data.fileUrl;
          const anchor = document.createElement('a');
          anchor.href = fileUrl;
          anchor.target = '_self';
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          this.reportType='';this.yearValue='';
          this.viewModal=false;
          this.dateTypeSelectors(0)
        }
        else {
          alert(res.message)
        }
      }
    )
  }

  
  dateTypeSelectors(num) {
    this.yearValue = ''
    if (num == 0) {
      this.dateSelector = true
      this.showMonth = false
      this.showDateRange = false
      this.tableDisplay = true
    }
    if (num == 1) {
      this.dateSelector = false
      this.showMonth = true
      this.showDateRange = false
      this.tableDisplay = true
    }
    if (num == 2) {
      this.dateSelector = false
      this.showDateRange = true
      this.showMonth = false
      this.tableDisplay = false
      this.yearValue = new Date().getFullYear()+'-01-01'
      this.dateType='year'
      console.log(this.yearValue)
    }
  }

  fetchData(): void {
    this.report.getboquetCountReconcilation().subscribe(
      (data) => {
        this.isLoading = false;
        if(!(data.data)){
          this.isLoading = false;
          this.isLoad = 'No Records';
          this.reportList = []
          return
        }
        this.reportList = data.data;
        console.log(this.reportList)
        if (this.reportList?.length == 0) {
          this.isLoading = false;
          this.isLoad = 'No Records';
        } else {
          this.isLoading = true;
        }
      },
      () => {
        this.isLoad = 'No Records';
        this.isLoading = false;
      }
    );
  }

  downloadTableExcel(): void {
    let name = 'Subscriber Reconciliation Count';
    this.doc.exportTableToExcelwithImage(this.reportTable, name, 'Bouquet_Reconciliation_Count');
  }

  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable', 'Bouquet_Reconciliation_Count', `Source Type : SMS`);
  }
  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: [
        
        'SMS',
        'DRM',
        'Type',
        'Mismatch',
        'Mismatch Desc',
        
      ],
    };
    let data = [];

    this.reportList.forEach((line) => {
      let csvLine = {
        subscriberIds: line['SMS-activeBouquets'],
        readFlag: line['DRM-activeBouquets'],
        type:line['type'],
        mismatch1:line['Mismatch1'],
        mismatch: line['Mismatch %']
        
        
      };
      data.push(csvLine);
    });

    new ngxCsv(data, 'bouquet_Reconciliation_Count', options);
  }

  searchKeyword() {
    let value = (document.getElementById('searchKeyword') as HTMLInputElement)
      .value;
    let url =
      this.config.json.apiEndpointRdbms + 'osd/list?searchKeyword=' + value;
    this.http.get(url).subscribe(
      (data) => {
        this.reportList = data;
        this.isLoading = false;
        if (this.reportList.length == 0) {
          this.isLoad = 'No Records';
        } else {
          this.isLoading = true;
        }
      },
      () => {
        this.isLoad = 'No Records';
        this.isLoading = false;
      }
    );
  }


}
