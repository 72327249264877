import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DistributorComponent } from './distributor/distributor.component';
import { DistributorRoutingModule } from './distributor-routing.module';
import { CreateDistributorComponent } from './create-distributor/create-distributor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DistributorCreateParentComponent } from './distributor-create-parent/distributor-create-parent.component';
import { AddPackagesComponent } from './add-packages/add-packages.component';
import { SetPackagesPricesComponent } from './set-packages-prices/set-packages-prices.component';
import { DistributorSearchComponent } from './distributor-search/distributor-search.component';
import { AddAlacarteePackagesComponent } from './add-alacartee-packages/add-alacartee-packages.component';



@NgModule({
  declarations: [
    DistributorComponent,
    CreateDistributorComponent,
    DistributorCreateParentComponent,
    AddPackagesComponent,
    SetPackagesPricesComponent,
    DistributorSearchComponent,
    AddAlacarteePackagesComponent,
  ],
  imports: [
    CommonModule,
    DistributorRoutingModule,
    ReactiveFormsModule,FormsModule
  ],
  exports:[
    DistributorSearchComponent
  ]
})
export class DistributorModule { }
