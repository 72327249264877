import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { Observable } from 'rxjs';
import { AclGroupInfo, AclMenus } from 'src/app/models/acl-group/acl-group-info';
import { ConfigService } from 'src/app/services/config.service';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { environment } from 'src/environments/environment';
import { PageMetadata } from 'src/app/models/pagination/page-info';
@Component({
  selector: 'app-acl',
  templateUrl: './acl.component.html',
  styleUrls: ['./acl.component.scss']
})
export class AclComponent implements OnInit {

  aclGroupList: AclGroupInfo[] = []
  paginationObj = new PaginationObj();
  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  loadingFlag

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }
  menus
  menuAccess = false

  ngOnInit(): void {
    this.fetchData()
    this.menus=JSON.parse(atob(localStorage.getItem('menus')))
    this.checkMenuAccess()
  }
  
  checkMenuAccess(){
    for(let i of this.menus){
      if(i.name=='Access Control')
      {
        this.menuAccess=(i.access==0?false:true);
        console.log(this.menuAccess)
      }
    }
    console.log(this.menuAccess)
  }

  getAclGroupList(): Observable<any> {
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url;

    url = PaginationUtilities.urlBuilder(
      'aclgroup/list',
      this.paginationObj
    );

    // const url = this.config.json.apiEndpointRdbms + 'aclgroup/list/1';
    return this.http.get(url);
  }

  getMenus(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'menu/getMenus';
    return this.http.get(url);
  }

  // fetchMenus(){
  //   this.getMenus().subscribe((data)=>//// console.log("MENU: ",data))
  // }

  fetchData() {
    this.getAclGroupList().subscribe((data) => {
      this.loadingFlag = false;
        this.handleFetchPage(data);
    })
  }

  edit(record: AclGroupInfo): void {
    this.router.navigate(['/settings/access-control', record.id]);
  }

  handleFetchPage(data): void {
    this.aclGroupList = data.data;
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }

  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
    this.pageSize = metadata.pageSize;
    this.pageNumber = metadata.pageNumber;
  }

  pageSize;
  pageNumber;
  handlePaginationBtnDisableStatuses(): void {
    // Left section
    this.disableFirstBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;
    this.disablePreviousBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;

    // Right section
    this.disableNextBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
    this.disableLastBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchData();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

}
